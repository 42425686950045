import React, { useState, useRef, ReactChild } from 'react';
import GraphicPlaceholder from '../GraphicPlaceholder';
import Bar from './bar';
import useWindowSize from '../../../hooks/useWindowSize';

const HistogramChart: React.FC<{
  data: Record<string, any>[];
  lineAmount?: number;
  height?: number;
  yaxis?: string;
  xaxis?: string;
  verticalGrids?: boolean;
  xAxisLabelMarginTop?: number;
  xAxisLabelsRotation?: number;
  children?: ReactChild;
}> = ({
  data,
  lineAmount = 2,
  height = 200,
  yaxis = 'y-axis',
  xaxis = 'x-axis',
  verticalGrids = false,
  xAxisLabelsRotation = 0,
  children,
  xAxisLabelMarginTop = 0,
}): JSX.Element => {
  const vertical_bar_main_hood = useRef(null);
  let component_hood_padding_left = 20;
  let component_hood_padding_right = 20;
  const min_bar_value = 0;

  const [chartWidth, setChartWidth] = useState(200);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [activeBar, setActiveBar] = useState(null);
  // coordinates of the active bar need for translation
  const [activeBarX, setActiveBarX] = useState(0);
  const [activeBarY] = useState(0);

  const handleResize = (): void => {
    setWindowWidth(window.innerWidth);
    const vertical_bar_main = vertical_bar_main_hood.current;
    if (!vertical_bar_main) {
      setChartWidth(0);
    }
    const vertical_bar_main_style = vertical_bar_main?.currentStyle || window.getComputedStyle(vertical_bar_main);
    component_hood_padding_left = parseInt(vertical_bar_main_style.paddingLeft, 10);
    component_hood_padding_right = parseInt(vertical_bar_main_style.paddingRight, 10);
    setChartWidth(
      Math.round(vertical_bar_main.offsetWidth - component_hood_padding_left - component_hood_padding_right - 10),
    );
  };
  useWindowSize(handleResize);

  const getMaxValue = (): number => {
    let hold = 0;
    data.forEach(point => {
      if (point.value > hold) {
        hold = point.value;
      }
    });
    if (hold < lineAmount) {
      lineAmount = hold;
    }
    return hold;
  };

  const getMaxLineHeight = (): Record<string, number> => {
    const maxValue = getMaxValue();
    let line_interval = Math.ceil(maxValue / (min_bar_value || lineAmount));
    if (maxValue > lineAmount * 10 && maxValue < lineAmount * 100) {
      line_interval = Math.ceil(line_interval / 10) * 10;
    } else if (maxValue >= lineAmount * 100) {
      line_interval = Math.ceil(line_interval / 100) * 100;
    }
    const min_height = line_interval * lineAmount;

    return { min_height, line_interval };
  };

  const barHeight = (value): Record<string, number> => {
    const percent = (value * 100) / line_params.min_height;
    const bheight = (bottom_line * percent) / 100;
    const y = bottom_line - bheight;
    return { y, height: bheight };
  };

  if (!data || data.length === 0) {
    return (
      <div
        ref={vertical_bar_main_hood}
        style={{
          height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="vertical_bar_main_hood"
      >
        <div>
          <GraphicPlaceholder variant="bar" />
        </div>
      </div>
    );
  }

  const handleUnfocusBar = (): void => {
    setActiveBar(null);
  };
  const handleTouchEnd = handleUnfocusBar;
  const handleFocusBar = (id: string, index: number): void => {
    setActiveBar(`#${id}`);
    setActiveBarX(39 + width_y * index);
  };

  const chartHeight = height;
  const width_y = (chartWidth - 55) / data.length;

  const line_params = getMaxLineHeight();
  const bottom_line = Math.ceil(chartHeight - chartHeight / 9 - 12);

  const lnx_1_style = { stroke: '#DEDFE1', strokeWidth: 1 };

  const line_label = [];
  const line_y = [];

  for (let i = 0; i < lineAmount; i += 1) {
    line_label.push(Math.ceil(line_params.min_height - line_params.line_interval * i));
    line_y.push(Math.ceil(5 + ((bottom_line - 5) / lineAmount) * i));
  }

  return (
    <div ref={vertical_bar_main_hood} className="vertical_bar_main_hood">
      {!!chartWidth && (
        <div>
          <svg width={chartWidth} height={chartHeight}>
            {/* y axis text */}
            <text x={yaxis.length - chartHeight + 80} y="10" fill="#4A4A68" fontSize="12" transform="rotate(-90 0,00)">
              {yaxis}
            </text>
            {/* x axis text */}
            <text x={chartWidth / 2 - xaxis.length * 3} y={chartHeight - chartHeight / 22} fill="#4A4A68" fontSize="12">
              {xaxis}
            </text>
            {/* labels on the x axis */}
            {data.map((point, i) => {
              const x = 40 + i * width_y + (width_y - data.length) / 2 - point.label.length * 2;
              const y = chartHeight - chartHeight / 9;
              const tranformX = xAxisLabelsRotation !== 0 ? x + xAxisLabelMarginTop : x;
              return (
                <text
                  x={x}
                  y={y}
                  fill="#bbb"
                  fontSize="9"
                  transform={`rotate(${xAxisLabelsRotation}, ${tranformX}, ${y})`}
                >
                  <tspan>{windowWidth > 768 ? point.label : point.label.split('-')[0]}</tspan>
                </text>
              );
            })}
            {/* labels on the y axis */}
            {line_label.map((ln_y, i) => (
              <text x={18 - 5 * (`${line_label[i]}`.length - 1)} y={line_y[i] + 3} fill="#bbb" fontSize="9">
                {line_label[i]}
              </text>
            ))}
            {/* 0 label on the y axis */}
            <text x="18" y={bottom_line + 3} fill="#bbb" fontSize="9">
              0
            </text>
            {/* lines parallel to x axis */}
            {line_label.map((ln_y, i) => (
              <line x1="28" y1={line_y[i] - 5} x2={chartWidth} y2={line_y[i] - 5} style={lnx_1_style} />
            ))}
            {/* x axis line */}
            <line x1="28" y1={bottom_line} x2={chartWidth} y2={bottom_line} style={lnx_1_style} />
            <line x1="28" x2="28" y1={bottom_line} y2={0} style={lnx_1_style} />
            {/* bars themselves */}
            {data.map((point, i) => {
              if (point.value > 0) {
                return (
                  <>
                    {!React.isValidElement(children) ? (
                      <Bar
                        y={barHeight(point.value).y}
                        height={barHeight(point.value).height}
                        width={width_y}
                        index={i}
                        balloonLabel={point.label}
                        balloonText={point.value}
                      />
                    ) : (
                      <g transform={`translate(${39 + width_y * i}, 0)`}>
                        {React.cloneElement(children as React.ReactElement<any>, {
                          y: barHeight(point.value).y,
                          height: barHeight(point.value).height,
                          width: width_y,
                          index: i,
                          balloonLabel: point.label,
                          balloonText: point.value,
                          onMouseOver: handleFocusBar,
                        })}
                      </g>
                    )}
                    {verticalGrids ? (
                      <line
                        x1={39 + width_y + width_y * i}
                        x2={39 + width_y + width_y * i}
                        y1={bottom_line}
                        y2={0}
                        style={lnx_1_style}
                      />
                    ) : (
                      ''
                    )}
                  </>
                );
              }
              if (verticalGrids) {
                return (
                  <line
                    x1={39 + width_y + width_y * i}
                    x2={39 + width_y + width_y * i}
                    y1={bottom_line}
                    y2={0}
                    style={lnx_1_style}
                  />
                );
              }
              return null;
            })}
            <use
              transform={`translate(${activeBarX}, ${activeBarY})`}
              data-testid="active-bar"
              href={activeBar}
              onMouseLeave={handleUnfocusBar}
              onTouchEnd={handleTouchEnd}
            />
            Sorry, your browser does not support inline SVG.
          </svg>
        </div>
      )}
    </div>
  );
};
export default HistogramChart;
