import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { QuestionType } from 'helpers/constants';
import { removeQuestionFromPart } from '../../../api/assessment.api';
import './InterviewQuestionList.scss';
import InterviewContent from './InterviewContent';
import { QuestionContext, setQuestionContext } from '../../../store/reducers/interview';

interface Props {
  assessmentId: number;
  interview: any;
  setInterview: any;
  loading: boolean;
  questions: any[];
  selectedQuestion: any;
  setSelectedQuestion: any;
  selectedQuestionSkill: any;
  setSelectedQuestionSkill: any;
  skillQuestions: any;
  setSkillQuestions: any;
  setQuestionMap: any;
  setSkillMap: any;
  setAdditionalCriteriaSkillMap: any;
  setShowAddSectionModal: any;
  setNewTopicPosition: any;
  setNewAdditionalCriteriaPosition: any;
  showPreSelectedSkillModal: any;
  additionalCriteriaSkillLength: number;
}

const InterviewQuestionList: React.FC<Props> = ({
  assessmentId,
  interview,
  setInterview,
  loading,
  questions,
  selectedQuestion,
  setSelectedQuestion,
  selectedQuestionSkill,
  setSelectedQuestionSkill,
  skillQuestions,
  setSkillQuestions,
  setQuestionMap,
  setSkillMap,
  setAdditionalCriteriaSkillMap,
  setShowAddSectionModal,
  setNewTopicPosition,
  setNewAdditionalCriteriaPosition,
  showPreSelectedSkillModal,
  additionalCriteriaSkillLength,
}) => {
  const [skills, setSkills] = useState([]);
  const [removingMap, setRemovingMap] = useState({});
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  /**
   * Add interview intro and wrap-up sections if they don't exist.
   * i.e., they have no questions.
   */
  const addInformationalSections = (
    interview: any,
    skillMapBuilder: any,
    skillBuilder: any[],
    skillQuestionsBuilder: any,
    newTopicSkillIndex: any,
  ): void => {
    if (interview.wrap_up_subject && !skillMapBuilder[interview.wrap_up_subject.id]) {
      skillBuilder.splice(newTopicSkillIndex + 1, 0, {
        id: interview.wrap_up_subject.id,
        name: interview.wrap_up_subject.subject,
        questions_type: QuestionType.Informational,
        collapsed: false,
      });
      skillMapBuilder[interview.wrap_up_subject.id] = interview.wrap_up_subject;
      skillQuestionsBuilder[interview.wrap_up_subject.id] = [];
    }
    if (interview.intro_subject && !skillMapBuilder[interview.intro_subject.id]) {
      skillBuilder.unshift({
        id: interview.intro_subject.id,
        name: interview.intro_subject.subject,
        questions_type: QuestionType.Informational,
        collapsed: false,
      });
      skillMapBuilder[interview.intro_subject.id] = interview.intro_subject;
      skillQuestionsBuilder[interview.intro_subject.id] = [];
    }
  };

  useEffect(() => {
    const skillBuilder = [];
    const skillQuestionsBuilder = {};
    const questionMapBuilder = {};
    const skillMapBuilder = {};
    const additionalCriteriaSkillMapBuilder = {};
    let currentSkill = null;
    let newTopicIndex = null;
    let newTopicSkillIndex = null;
    if (questions) {
      questions.forEach((question, i) => {
        questionMapBuilder[question.question_id] = question;
        if (
          newTopicIndex === null &&
          (question.primary_skill?.subject === interview.wrap_up_subject.subject ||
            question.question_type.question_type === QuestionType.AdditionalCriteria ||
            question.question_type?.question_type === QuestionType.Rating)
        ) {
          newTopicIndex = i;
          newTopicSkillIndex = skillBuilder.length - 1;
        }
        let existingSkill = false;
        question.skills.forEach(skill => {
          if (currentSkill && (currentSkill.id === skill.id || currentSkill.id === question.primary_skill?.id)) {
            existingSkill = true;
          }
        });
        if (!existingSkill) {
          currentSkill = {
            ...(question.primary_skill || question.skills[0]),
            questions_type: question.question_type.question_type,
          };
          skillBuilder.push({
            ...currentSkill,
            name: currentSkill.name || currentSkill.subject,
            collapsed: false,
          });
          if (question.question_type.question_type === QuestionType.AdditionalCriteria) {
            additionalCriteriaSkillMapBuilder[currentSkill.id] = currentSkill;
          } else {
            skillMapBuilder[currentSkill.id] = currentSkill;
          }
        }
        if (!skillQuestionsBuilder[currentSkill.id]) {
          skillQuestionsBuilder[currentSkill.id] = [];
        }
        skillQuestionsBuilder[currentSkill.id].push(question);
      });
      if (questions.length !== 0 && newTopicIndex !== null) {
        // set the position of the last non-informational question in the interview content
        setNewTopicPosition(questions[newTopicIndex].position - 1);
        // use the position before the overall question to add new additional criteria sections
        setNewAdditionalCriteriaPosition(questions[questions.length - 1].position - 1);
      }

      addInformationalSections(interview, skillMapBuilder, skillBuilder, skillQuestionsBuilder, newTopicSkillIndex);
    }
    setSkills(skillBuilder);
    setSkillQuestions(skillQuestionsBuilder);
    setSkillMap(skillMapBuilder);
    setAdditionalCriteriaSkillMap(additionalCriteriaSkillMapBuilder);
    setQuestionMap(questionMapBuilder);
  }, [
    questions,
    setNewTopicPosition,
    setQuestionMap,
    setSkillMap,
    setAdditionalCriteriaSkillMap,
    setNewAdditionalCriteriaPosition,
    interview,
    setSkillQuestions,
  ]);

  const toggleSkillCollapse = async (toggledSkill): Promise<any> => {
    const tmpSkills = [...skills];
    tmpSkills.forEach(skill => {
      if (skill.id === toggledSkill.id) {
        skill.collapsed = !skill.collapsed;
      }
    });
    setSkills(tmpSkills);
  };

  const handleAddQuestion = (
    skillIndex: number,
    currentSkillQuestions: any[],
    questionContext: QuestionContext,
  ): void => {
    const position = currentSkillQuestions?.length && currentSkillQuestions[currentSkillQuestions.length - 1].position;
    dispatch(setQuestionContext(questionContext));
    showPreSelectedSkillModal(skills[skillIndex], position);
  };

  const handleRemoveQuestion = (questionId: number): void => {
    // Set loading state for current question
    let tmpRemovingMap = {
      ...removingMap,
      [questionId]: true,
    };
    setRemovingMap({ ...tmpRemovingMap });

    /*
     * Immediately remove question from list, but store old question list
     * to revert it if the request fails
     */
    const previousQuestionList = [...questions];
    for (let i = 0; i < questions.length; i += 1) {
      if (questions[i].question_id === questionId) {
        const tmpQuestions = questions;
        tmpQuestions.splice(i, 1);
        setInterview({
          ...interview,
          questions: tmpQuestions.map(question => {
            // Update positions of questions after the one that was removed
            if (question.position > i) {
              return {
                ...question,
                position: question.position - 1,
              };
            }
            return question;
          }),
        });
        break;
      }
    }

    removeQuestionFromPart(assessmentId, interview.id, questionId)
      .then(() => {
        addToast({
          msg: 'Question successfully removed from interview.',
          type: 'success',
        });
      })
      .catch(() => {
        addToast({
          msg: 'Unable to remove question from interview.',
          type: 'error',
        });
        setInterview({
          ...interview,
          questions: previousQuestionList,
        });
      });

    // remove loading from current question
    tmpRemovingMap = {
      ...removingMap,
      [questionId]: false,
    };
    setRemovingMap({ ...tmpRemovingMap });
  };
  return (
    <div className="interview-question-list">
      <InterviewContent
        loading={loading}
        skills={skills}
        skillQuestions={skillQuestions}
        removingMap={removingMap}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        selectedQuestionSkill={selectedQuestionSkill}
        setSelectedQuestionSkill={setSelectedQuestionSkill}
        setShowAddSectionModal={setShowAddSectionModal}
        toggleSkillCollapse={toggleSkillCollapse}
        handleAddQuestion={handleAddQuestion}
        handleRemoveQuestion={handleRemoveQuestion}
        interview={interview}
        questions={questions}
        setInterview={setInterview}
        assessmentId={assessmentId}
        additionalCriteriaSkillLength={additionalCriteriaSkillLength}
      />
    </div>
  );
};

export default InterviewQuestionList;
