import React from 'react';
import { useHistory } from 'react-router-dom';
import './OrganizationTemplates.scss';

export const templates = [
  {
    name: 'Alooba Assess Assessment Introduction',
    value: 'assessment-intro',
    purpose: 'hiring',
    templateKey: 'invite',
    product: 'Alooba Assess',
  },
  {
    name: 'Alooba Growth Exercise Introduction',
    value: 'exercise-intro',
    purpose: 'ld',
    templateKey: 'invite',
    product: 'Alooba Growth',
  },
];

const OrganizationTemplates = (): JSX.Element => {
  const history = useHistory();
  const handleClick = (e, template): void => {
    e.stopPropagation();
    e.preventDefault();
    history.push(`/organization-templates/${template}`);
  };
  const viewOrEdit = 'edit';
  return (
    <div className="template-list">
      <h2>Organization Templates</h2>
      <p>
        Here you can {viewOrEdit} the templates shown to candidates (or employees for Alooba Growth) at the beginning of
        their assessment (or exercise) and in their invitation email.
      </p>
      <table>
        <tr className="template-row">
          <th>Template Name</th>
          <th>Product</th>
        </tr>
        {templates.map(template => (
          <tr className="template-row">
            <td>
              <a href={`/organization-templates/${template}`} onClick={e => handleClick(e, template.value)}>
                <span className="template-name">{template.name}</span>
              </a>
            </td>
            <td>
              <span className="template-product">{template.product}</span>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default OrganizationTemplates;
