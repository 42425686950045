import React, { useState, useMemo, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Editor } from 'react-draft-wysiwyg';
import { mdToDraftjs, draftjsToMd } from 'draftjs-md-converter';
import { EditorState, Modifier, convertToRaw, convertFromRaw } from 'draft-js';
import { RootState } from 'store/rootReducer';
import { Button } from 'components/Shared';
import { capitalizeFirstLetter } from 'helpers/text';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getOrganizationTemplate, updateOrganizationTemplate } from 'api/organization.api';
import { handleError } from 'handleError';
import './OrganizationTemplates.scss';
import { templates } from './index';

interface Props {
  template: string;
}

const VariableButton = (props: any): JSX.Element => {
  const { variableName, value, editorState, setEditorState } = props;
  const insertVariable = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      value,
      editorState.getCurrentInlineStyle(),
    );
    const newState = EditorState.push(editorState, contentState, 'insert-characters');
    setEditorState(newState);
  };

  return <Button variant="sub-primary md" text={variableName} onClick={insertVariable} />;
};

const TemplateEditor = ({ template }: Props): JSX.Element => {
  const { addToast } = useToasts();

  const currentTemplate = useMemo(() => {
    return templates.filter(t => t.value === template)[0];
  }, [template]);

  const [candidateOrEmployee, assessmentOrExercise] = useMemo(() => {
    if (currentTemplate.purpose === 'ld') {
      return ['employee', 'exercise'];
    }
    return ['candidate', 'assessment'];
  }, [currentTemplate]);

  const initialValue = `This template will be sent to ${candidateOrEmployee}s via email and will be shown at the beginning of their ${assessmentOrExercise}.`;

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [templateData, setTemplateData] = useState(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { userDetails } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (!templateData) {
      if (currentTemplate?.purpose && userDetails?.recruiter_detail?.organisation_id) {
        getOrganizationTemplate(
          userDetails.recruiter_detail.organisation_id,
          currentTemplate.templateKey,
          currentTemplate.purpose,
        )
          .then(res => {
            if (res.data.text) {
              const content = convertFromRaw(mdToDraftjs(res.data.text));
              setEditorState(EditorState.createWithContent(content));
            }
            setTemplateData(res.data);
            setLoading(false);
          })
          .catch(e => {
            handleError(e);
            addToast({
              type: 'error',
              msg: `Error fetching ${currentTemplate.name} template from server.`,
            });
          });
      }
    }
  }, [currentTemplate, templateData, userDetails, addToast]);

  const saveTemplateDetails = (): void => {
    setSaving(true);
    const result = draftjsToMd(convertToRaw(editorState.getCurrentContent()), {
      BOLD: '**',
    });
    updateOrganizationTemplate(
      userDetails.recruiter_detail.organisation_id,
      currentTemplate.purpose,
      currentTemplate.templateKey,
      result,
    )
      .then(() => {
        addToast({
          type: 'success',
          msg: `${currentTemplate.name} template updated successfully.`,
        });
        setSaving(false);
      })
      .catch(e => {
        handleError(e);
        addToast({
          type: 'error',
          msg: `There was an error saving ${currentTemplate.name} template.`,
        });
        setSaving(false);
      });
  };

  return (
    <div className="organization-templates">
      <h2>Edit {currentTemplate?.name} Template</h2>
      <p className="templates-subtext">Changes to this template will only affect new {assessmentOrExercise}s.</p>
      {loading ? (
        <>
          <Skeleton height={220} width="calc(85% - 20px)" />
          <Skeleton height={200} width="15%" style={{ marginLeft: '20px' }} />
        </>
      ) : (
        <div className="organization-template-editor">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            placeholder={initialValue}
            toolbar={{
              options: ['inline', 'blockType', 'link', 'remove', 'history'],
              inline: {
                options: ['bold', 'italic', 'underline'],
              },
              blockType: {
                inDropdown: false,
                options: ['Normal', 'H1', 'H2', 'H3'],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              },
            }}
          />
          <div className="variables">
            <h3>Variables</h3>
            <div className="variables-list">
              <VariableButton
                variableName={`${capitalizeFirstLetter(candidateOrEmployee)}'s First Name`}
                value="{{user_first_name}}"
                editorState={editorState}
                setEditorState={setEditorState}
              />
              <VariableButton
                variableName="Role Title"
                value="{{role_title}}"
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>
          </div>
        </div>
      )}
      <Button
        variant="primary md"
        text="Save"
        loading={loading || saving || !userDetails?.recruiter_detail?.organisation_id}
        onClick={() => saveTemplateDetails()}
      />
    </div>
  );
};

export default TemplateEditor;
