import { GetAssessmentCandidatesQueryParams } from 'api/contract';

export const formatCandidateComparisonData = (
  firstCandidate,
  secondCandidate,
  params: GetAssessmentCandidatesQueryParams,
  tests: string[],
): any => {
  if (!firstCandidate || !secondCandidate || !params) {
    return null;
  }
  const result: any = [];
  result.overall_scores = {};
  result.overall_scores['Total Score'] = [];
  result.overall_scores['Total Score'].push({
    value: firstCandidate['Total Score'],
    reference: firstCandidate.Reference,
  });
  result.overall_scores['Total Score'].push({
    value: secondCandidate['Total Score'],
    reference: secondCandidate.Reference,
  });
  result.test_scores = {};
  tests.forEach(test => {
    result.test_scores[test] = [];
    const firstTestScore = firstCandidate?.test_scores?.find(ts => ts.test_type === test);
    if (firstTestScore) {
      firstTestScore.reference = firstCandidate.Reference;
      result.test_scores[test].push(firstTestScore);
    }
    const secondTestScore = secondCandidate?.test_scores?.find(ts => ts.test_type === test);
    if (secondTestScore) {
      secondTestScore.reference = secondCandidate.Reference;
      result.test_scores[test].push(secondTestScore);
    }
  });
  result.subject_scores = {};
  params.skills.forEach(skill => {
    result.subject_scores[skill] = [];
    result.subject_scores[skill].push({
      value: firstCandidate[skill],
      reference: firstCandidate.Reference,
    });
    result.subject_scores[skill].push({
      value: secondCandidate[skill],
      reference: secondCandidate.Reference,
    });
  });
  result.candidate_information = {};
  params.candidateInformationFields.forEach(field => {
    result.candidate_information[field] = [];
    result.candidate_information[field].push({
      value: firstCandidate[field],
      reference: firstCandidate.Reference,
    });
    result.candidate_information[field].push({
      value: secondCandidate[field],
      reference: secondCandidate.Reference,
    });
  });
  return result;
};
