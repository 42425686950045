import React, { useState } from 'react';
import './tooltip.scss';
import { putOnClipboard } from 'helpers';
import { useToasts } from 'react-toast-notifications';
import ImageWithTooltip from './index';

interface Props {
  icon?: string;
  tooltip?: string;
  copiedTxt?: string;
  value?: string;
  label?: any;
  style?: any;
  isLink?: boolean;
}

const CopyLink = ({ icon, tooltip, value, copiedTxt, label, style = null, isLink = false }: Props): JSX.Element => {
  const [copied, setCopied] = useState<boolean>(false);
  const { addToast } = useToasts();

  const copyToClipboard = (e): void => {
    e.preventDefault();
    e.stopPropagation();

    if (!copied) {
      setCopied(true);
      putOnClipboard(value);
      setTimeout(() => {
        addToast({
          type: 'success',
          msg: copiedTxt,
        });
      }, 200);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    }
  };

  return (
    <ImageWithTooltip
      icon={icon}
      onClick={copyToClipboard}
      tooltip={tooltip}
      text={label}
      style={style}
      isLink={isLink}
      linkTo={value}
    />
  );
};

export default CopyLink;
