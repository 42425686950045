import React, { useMemo, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/mode-python';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/Shared/Button';
import ExecuteCodeModal from 'components/CandidateDetails/Modals/ExecuteCodeModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckIcon from '../../../../images/icons/check-color.svg';
import CrossIcon from '../../../../images/icons/cross-color.svg';
import useCollapsableResponse from '../../../../hooks/useCollapsableResponses';
import CodingResponseTestCase from './CodingResponseTestCase';
import { getCorrectness } from '../testUtils';
import './index.scss';
import '../index.scss';

interface CodingResponseProps {
  questionDetails: any;
}

export default function CodingResponse({ questionDetails }: CodingResponseProps): any {
  const [collapsedMap, toggleCollapsed] = useCollapsableResponse({});
  const [modalVisible, setModalVisible] = useState(false);
  const responseLanguage = questionDetails.response.executor?.subject_name?.toLowerCase();
  const modelResponseLanguage = questionDetails.response.model_response_executor?.subject_name?.toLowerCase();
  const fallbackLanguage = 'python';
  const { correctness, correctCases, wrongCases } = useMemo(() => {
    const correctness = getCorrectness(
      questionDetails.score,
      questionDetails.max_score,
      !questionDetails.response_submitted || questionDetails.response.response === '',
    );
    let casesCorrect = 0;
    let casesWrong = 0;
    if (questionDetails.response.test_cases) {
      questionDetails.response.test_cases.forEach(testCase => {
        if (testCase.score === 1) casesCorrect += 1;
        else casesWrong += 1;
      });
    }

    return {
      correctness,
      correctCases: casesCorrect,
      wrongCases: casesWrong,
    };
  }, [
    questionDetails.response.test_cases,
    questionDetails.response.response,
    questionDetails.score,
    questionDetails.max_score,
    questionDetails.response_submitted,
  ]);
  const candidateSolution = questionDetails.response.response || '';

  return (
    <>
      <div className="response-section-wrapper">
        <div className="model-response-section">
          {questionDetails?.response?.model_response && (
            <div className="question-response-section coding-response-section">
              <div
                className="response-title collapsable"
                onClick={() => toggleCollapsed('model_response')}
                role="input"
              >
                <h4>
                  EXAMPLE SOLUTION
                  {modelResponseLanguage ? ` (in ${modelResponseLanguage})` : ''}
                </h4>
                <div className="right-info">
                  <FontAwesomeIcon className="icon" icon={collapsedMap.model_response ? faAngleDown : faAngleUp} />
                </div>
              </div>
              <div className={`candidate-response-wrapper ${collapsedMap.model_response ? 'hidden' : ''}`}>
                <AceEditor
                  width="100%"
                  height="100%"
                  mode={modelResponseLanguage ?? fallbackLanguage}
                  theme="textmate"
                  name="coding-response"
                  fontSize={14}
                  showPrintMargin={false}
                  readOnly
                  showGutter
                  highlightActiveLine={false}
                  value={questionDetails.response.model_response}
                  setOptions={{
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className="candidate-response-section">
          <div className="question-response-section coding-response-section">
            <div className="response-title">
              <h4>
                CANDIDATE&apos;S SOLUTION
                {responseLanguage ? ` (in ${responseLanguage})` : ''}
              </h4>
              <div className="right-info">
                {correctness.icon !== null && (
                  <img src={correctness.icon} alt={`Answer icon ${correctness.key}`} className="right-info" />
                )}
              </div>
            </div>
            <div className={`candidate-response-wrapper ${correctness.key}`}>
              <AceEditor
                width="100%"
                height="100%"
                mode={responseLanguage ?? fallbackLanguage}
                theme="textmate"
                name="coding-response"
                fontSize={14}
                showPrintMargin={false}
                readOnly
                showGutter
                highlightActiveLine={false}
                value={candidateSolution}
                setOptions={{
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </div>
          </div>
          {questionDetails.response?.test_cases ? (
            <div className="mt2">
              <Button type="submit" variant="primary sm" text="Open In Editor" onClick={() => setModalVisible(true)} />
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          <ExecuteCodeModal
            setModalVisibility={setModalVisible}
            input={questionDetails.response?.response}
            isShown={modalVisible}
            questionDetails={questionDetails}
          />
        </div>
      </div>
      <div className="test-cases-section">
        {questionDetails.response?.test_cases ? (
          <div className="question-response-section coding-response-section">
            <div className="response-title">
              <h4>TEST CASES</h4>
              <div className="right-info">
                Total &ensp;
                <img src={CheckIcon} alt="Answer icon passed" className="right-info" />
                &ensp;
                <p className="light-text">Passed:</p>
                {correctCases}
                &ensp;
                <img src={CrossIcon} alt="Answer icon failed" className="right-info" />
                &ensp;
                <p className="light-text">Failed:</p>
                {wrongCases}
              </div>
            </div>
            {questionDetails.response.test_cases.map((testCase, index) => (
              <CodingResponseTestCase testCase={testCase} index={index} position={questionDetails.position} />
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
