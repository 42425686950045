import React, { useState, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { deleteParticipant } from 'api/assessment.api';
import { Modal } from 'components/Shared/Modal';
import { handleError } from 'handleError';
import { RefreshContext } from '../Participants/context/refresh';
import '../Participants/Modals/index.scss';

interface DeleteCandidateAssessmentModalProps {
  row: any;
  setModalVisibility: any;
}
const DeleteCandidateAssessmentModal = (props: DeleteCandidateAssessmentModalProps): JSX.Element => {
  const { row, setModalVisibility } = props;
  const [deleting, setDeleting] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useContext(RefreshContext);
  const { addToast } = useToasts();

  const handleButtonAction = async (e: React.SyntheticEvent<EventTarget>): Promise<any> => {
    e.stopPropagation();
    e.preventDefault();
    if (deleting) {
      return;
    }
    setDeleting(true);
    const errorToast = (msg: string): void => {
      addToast({
        type: 'error',
        msg,
      });
    };
    deleteParticipant({
      candidate_test_id: row.candidate_test_id,
    })
      .then(response => {
        if (response.data.success) {
          setFirstLoad(!firstLoad);
          addToast({
            type: 'success',
            msg: `${row.Name} has been Deleted.`,
          });
        } else {
          errorToast(response.data.message);
        }
      })
      .catch(e => {
        handleError(e);
        errorToast('Unable to remove Participant');
      });
    setDeleting(false);
    setModalVisibility(false);
  };

  const customProps = {
    loading: deleting,
    actionText: 'Delete',
    showCancel: true,
    cancelVariant: 'sub-primary',
    hideCloseButton: true,
    handleButtonAction,
    containerClass: 'participants-modal',
  };

  return (
    <Modal {...{ ...props, ...customProps }}>
      <>
        <h2 data-testid="delete-candidate-modal-header">{`Delete ${row.Name}'s Assessment`}</h2>
        <br />
        <p>Be careful, this is irreversible.</p>
        <hr />
      </>
    </Modal>
  );
};
export default DeleteCandidateAssessmentModal;
