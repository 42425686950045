import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeCloakingSuccess } from 'store/reducers/profile';
import './CloakingCandidateSettings.scss';
import SettingsSwitcher from '../Shared/Switcher/SettingsSwitcher';
import api from '../../api';
import { refreshCurrentTokens } from '../../store/actions/auth.actions';
import store from '../../store/store';

interface Props {
  organization?: any;
  userDetails?: any;
  loading: boolean;
}

const CloakingCandidateSettings: React.FC<Props> = (props: Props) => {
  const { organization, userDetails, loading } = props;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const handleChange = async (checked): Promise<any> => {
    return api
      .patch(`/recruiter-detail/${userDetails.id}`, {
        client_id: process.env.REACT_APP_CLIENT_ID,
        cloak_candidates: checked,
      })
      .then(() => {
        dispatch(changeCloakingSuccess({ checked }));
        store.dispatch(refreshCurrentTokens());
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(loading);
    }, 150);
  }, [loading, setIsLoading]);

  const label =
    'Cloaking candidate details can help reduce bias & unfairness. You’ll still be able to reveal candidate details with this feature on.';
  const switcherProps = useMemo(() => {
    return {
      label,
      loading: isLoading || !userDetails?.recruiter_detail,
      initialValue: userDetails?.recruiter_detail?.cloak_candidates,
      disabled: organization?.cloak_candidates === true,
    };
  }, [organization, userDetails, isLoading]);
  return (
    <>
      <h2 className="mb4 mt8">Settings</h2>
      <div className="main-container container">
        {!switcherProps.disabled && (
          <SettingsSwitcher
            title="Candidate Cloaking"
            handleChange={handleChange}
            successMessage="Your settings have been updated."
            errorMessage="An error occured while updating your settings. Please try again later!"
            initialValue={userDetails?.recruiter_detail?.cloak_candidates ?? false}
            innerProps={switcherProps}
            divClassName="cloak-switcher"
          />
        )}
        {switcherProps.disabled && (
          <p className="organization-cloaking-enabled">Candidate cloaking is enabled for your organization.</p>
        )}
      </div>
    </>
  );
};

export default CloakingCandidateSettings;
