/* eslint-disable jsx-a11y/role-has-required-aria-props */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './CustomAutocomplete.scss';

interface CustomAutocompleteProps {
  options: any[];
  onInputChange: (value: string, action: { action: string }) => void;
  onChange: (option: any, isManuallySelected: boolean) => void;
  placeholder: string;
  value: string;
  formatOptionLabel: (option: any) => JSX.Element;
  autoFocus?: boolean;
  setIsEditing: (isEditing: boolean) => void;
  rightElement?: React.ReactNode; // Element to show on the right side of input
  dropdownContent?: React.ReactNode; // Custom content to show in dropdown instead of options
  showDropdownOverride?: boolean; // Override isOpen state
}

const CustomAutocomplete = ({
  options,
  onInputChange,
  onChange,
  placeholder,
  value,
  formatOptionLabel,
  autoFocus = false,
  setIsEditing,
  rightElement,
  dropdownContent,
  showDropdownOverride,
}: CustomAutocompleteProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const inputRef = useRef(null);
  const menuRef = useRef(null);
  const dropdownContentRef = useRef(null);

  // If showDropdownOverride is true, show the custom content
  // Otherwise, check if isOpen is true and options exist
  const showCustomContent = showDropdownOverride === true && dropdownContent != null;
  const showOptionsDropdown = !showCustomContent && isOpen && options.length > 0;

  // For CSS classes
  const isDropdownVisible = showCustomContent || showOptionsDropdown;

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const handleInputChange = (e): void => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsOpen(true);
    setActiveIndex(-1);
    onInputChange?.(newValue, { action: 'input-change' });
  };

  const handleKeyDown = (e): void => {
    // Skip keyboard navigation when showing custom dropdown content
    if (showCustomContent) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (options.length > 0) {
          setActiveIndex(prev => (prev < options.length - 1 ? prev + 1 : prev));
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (options.length > 0) {
          setActiveIndex(prev => (prev > 0 ? prev - 1 : 0));
        }
        break;
      case 'Enter':
        e.preventDefault();
        if (options.length > 0 && activeIndex >= 0) {
          onChange?.(options[activeIndex], true);
          setIsOpen(false);
        } else if (options.length > 0) {
          onChange?.(options[0], true);
          setIsOpen(false);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsOpen(false);
        inputRef.current?.blur();
        setIsEditing(false);
        break;
      default:
        break;
    }
  };

  const handleClickOutside = useCallback(
    e => {
      // If we're showing custom content, let the parent component handle clicks
      if (showCustomContent) {
        return;
      }

      // Don't close if clicking inside dropdown content
      if (menuRef.current && menuRef.current.contains(e.target)) {
        return;
      }

      // Don't close if clicking inside input area
      if (inputRef.current && inputRef.current.contains(e.target)) {
        return;
      }

      setIsOpen(false);
      // Only call setIsEditing when actually closing dropdown
      if (isOpen) {
        setIsEditing(false);
      }
    },
    [setIsEditing, showCustomContent, isOpen],
  );

  const handleClearInput = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    setInputValue('');
    onChange?.(null, false);
    onInputChange?.('', { action: 'input-clear' });
    setIsOpen(false);
    // Return focus to input after clearing
    inputRef.current?.focus();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className={`autocomplete-container ${isDropdownVisible ? 'focused' : ''}`}>
      <div className="autocomplete-input-wrapper">
        <input
          ref={inputRef}
          type="text"
          className={`autocomplete-input ${showCustomContent ? 'hidden-when-custom-content' : ''}`}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => !showDropdownOverride && setIsOpen(true)}
          placeholder={placeholder}
          autoFocus={autoFocus}
          // Delay closing the dropdown slightly to allow for option selection
          onBlur={() => !showDropdownOverride && setTimeout(() => setIsOpen(false), 200)}
          role="combobox"
          aria-expanded={isDropdownVisible}
          aria-autocomplete="list"
        />
        <div className="autocomplete-buttons">
          {inputValue && !showCustomContent && (
            <button type="button" className="autocomplete-clear-btn" onClick={handleClearInput}>
              ✕
            </button>
          )}
          {rightElement}
        </div>
      </div>

      {/* Show dropdown wrapper if either custom content or options should be shown */}
      {isDropdownVisible && (
        <div className="autocomplete-dropdown-wrapper">
          <hr />
          {showCustomContent && (
            <div ref={dropdownContentRef} className="autocomplete-custom-content">
              {dropdownContent}
            </div>
          )}

          {showOptionsDropdown && (
            <div ref={menuRef} className="autocomplete-dropdown">
              {options.map((option, index) => (
                <div
                  role="option"
                  aria-selected={index === activeIndex}
                  tabIndex={-1}
                  key={option.job_title_level_id || index + option.title}
                  className={`autocomplete-option ${index === activeIndex ? 'active' : ''}`}
                  onMouseDown={e => {
                    e.preventDefault();
                    onChange?.(option, true);
                    setIsOpen(false);
                  }}
                  onMouseEnter={() => setActiveIndex(index)}
                >
                  {formatOptionLabel(option)}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomAutocomplete;
