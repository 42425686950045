import React, { useState } from 'react';
import Warning from '../../../images/warning.svg';
import Success from '../../../images/success.svg';
import Error from '../../../images/error.svg';
import Close from '../../../images/close.svg';
import './modal.scss';

export interface AlertProps {
  msg: string;
  type: string;
}

export const Snack: React.FC<{ children: AlertProps }> = ({ children: { type, msg } }): any => {
  const [show, setShowAlert] = useState<boolean>(true);
  const imageType = (): string => {
    if (type === 'success') return Success;
    if (type === 'error') return Error;
    return Warning;
  };
  const hideModal = (): any => {
    setShowAlert(!show);
  };

  return (
    <>
      {show ? (
        <div className="alerting">
          <div className="alert-content">
            <div className={type}>
              <img src={imageType()} alt="alert" />
            </div>
            <p>{msg}</p>
          </div>
          <div role="button" tabIndex={0} className="close-button" onClick={hideModal}>
            <img src={Close} alt="close" />
          </div>
        </div>
      ) : null}
    </>
  );
};
