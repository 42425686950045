import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Shared/Tooltip';
import { RootState } from 'store/rootReducer';
import SettingsSwitcher from 'components/Shared/Switcher/SettingsSwitcher';

function AutoTopupConfig(): JSX.Element {
  const hasPaymentMethod = useSelector((state: RootState) => state.subscription?.hasPaymentMethod);
  const tooltipText = hasPaymentMethod
    ? 'For uninterrupted access, auto top-up is required and cannot be disabled.'
    : 'You must add a payment method to enable auto top-up';

  return (
    <div className="auto-topup-config">
      <h2>Auto Top-Up</h2>
      <p className="auto-topup main-container container">
        <Tooltip tooltip={tooltipText}>
          <SettingsSwitcher
            title=""
            handleChange={null}
            successMessage={null}
            errorMessage={null}
            initialValue={hasPaymentMethod}
            innerProps={{
              loading: false,
              initialValue: hasPaymentMethod,
              disabled: true,
            }}
            divClassName="cloak-switcher"
          />
        </Tooltip>
        <p>
          When auto top-up is enabled, whenever your balance dips below zero, the smallest bundle will be purchased to
          bring your balance above zero.
        </p>
      </p>
    </div>
  );
}

export default AutoTopupConfig;
