import CardContainer from 'components/Shared/MainContainer/CardContainer';
import './index.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SubscriptionPricingTable from 'components/Billing/SubscriptionPricingTable';
import { Button, Input } from 'components/Shared';
import FreeTrialModal from 'components/Billing/FreeTrialModal';
import { checkFreeTrialEligibility, checkTrialCode, requestFreeTrial } from 'api/subscription.api';
import { handleError } from 'handleError';
import PayInvoiceMessage from 'components/Billing/PayInvoiceMessage';
import { Modal } from 'components/Shared/Modal';
import { hasAdminPrivileges, hasOutstandingPayment } from '../../helpers/user';

const SubscriptionPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const trialCode = queryParams.get('trial_code');

  const handleScheduleCallButton = (): void => {
    window.location.href = `${process.env.REACT_APP_LANDING_PAGE_URL}/discuss-plans/`;
  };

  const [promoCode, setPromoCode] = useState(trialCode);
  const [trialCodeError, setTrialCodeError] = useState('');
  const [promoModalVisibility, setPromoModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trialEligibilityLoading, setTrialEligibilityLoading] = useState(true);
  const [eligibleForTrial, setEligibleForTrial] = useState(false);
  const [trialUsed, setTrialUsed] = useState(true);
  const [requestTrialModalVisibility, setRequestTrialModalVisibility] = useState(false);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTrialCodeError('');
    setPromoCode(e.target.value);
  };

  useEffect(() => {
    checkFreeTrialEligibility().then(res => {
      if (res.data?.data?.is_eligible) {
        setEligibleForTrial(true);
      }
      if (!res.data?.data?.trial_used) {
        setTrialUsed(false);
      }
      setTrialEligibilityLoading(false);
    });
  }, []);

  const handleRequestTrial = (): void => {
    setRequestTrialModalVisibility(true);
    requestFreeTrial();
  };

  const applyPromoCode = useCallback((): void => {
    if (!promoCode) {
      setTrialCodeError('Please enter a valid promo or trial code');
      return;
    }
    setLoading(true);
    checkTrialCode(promoCode)
      .then(() => {
        setTrialCodeError('');
        setPromoModalVisibility(true);
      })
      .catch(e => {
        if (e.response?.data?.errors?.length > 0) {
          setTrialCodeError(e.response.data.errors[0]);
        }
        handleError(e);
      })
      .finally(() => setLoading(false));
  }, [promoCode]);

  useEffect(() => {
    if (trialCode) {
      applyPromoCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasAdminPrivileges()) {
    return <Redirect to="/no-active-subscription" />;
  }
  return (
    <div className="signup-flow">
      <Helmet>
        <title>Choose Your Subscription Plan</title>
      </Helmet>
      {hasOutstandingPayment() ? (
        <PayInvoiceMessage />
      ) : (
        <CardContainer>
          <div className="wrapper">
            <h1>Choose Your Subscription Plan</h1>
            <p className="welcome">
              Select the best plan that fits your organization&apos;s needs. Each plan is designed to offer you
              flexibility and the necessary tools to enhance your hiring process. For a detailed comparison of features
              and benefits, visit our{' '}
              <a href={`${process.env.REACT_APP_LANDING_PAGE_URL}/pricing`} target="__blank">
                pricing page
              </a>
              . If you are not sure what to choose, or need a more tailored solution, our team is here to help.
            </p>
            <SubscriptionPricingTable parentLoading={trialEligibilityLoading} eligibleForTrial={eligibleForTrial} />
            <h3>Not sure which plan is right for you or have specific needs?</h3>
            <div className="schedule-call-section">
              <p className="schedule-call-text">
                Our sales team is ready to guide you through the options and answer any questions you may have. Schedule
                a call with one of our friendly team members at a time that best suites you.
              </p>
              <div>
                <Button text="Schedule a Call" variant="sub-primary md" onClick={handleScheduleCallButton} />
              </div>
            </div>
            {!eligibleForTrial && !trialUsed && (
              <>
                <h3>Request Free Trial</h3>
                <div className="schedule-call-section">
                  <p className="schedule-call-text">
                    Experience Alooba for free. Request access to a trial and start exploring our features.
                  </p>
                  <div>
                    <Button text="Request Trial" variant="sub-primary md" onClick={handleRequestTrial} />
                  </div>
                </div>
              </>
            )}
            <div className="promo-section">
              <h3>Have a promo or trial code?</h3>
              <div className="promo-form">
                <Input
                  type="text"
                  value={promoCode}
                  label="Code"
                  placeholder="Enter your promo or trial code here"
                  onChange={handleCodeChange}
                  errorTxt={trialCodeError}
                  disabled={loading}
                />
                <div style={trialCodeError ? { marginBottom: '26px' } : {}}>
                  <Button text="Submit" variant="sub-primary md" onClick={applyPromoCode} disabled={loading} />
                </div>
              </div>
            </div>
            <FreeTrialModal
              isShown={promoModalVisibility}
              setModalVisibility={setPromoModalVisibility}
              trialCode={promoCode}
            />
            <Modal
              isShown={requestTrialModalVisibility}
              setModalVisibility={setRequestTrialModalVisibility}
              cancelButtonText="Close"
              showCancel
              containerClass="request-trial-modal"
            >
              <>
                <h3>Thank You for Your Interest!</h3>
                <p>
                  Our free trial slots are currently full. You can unlock immediate access by subscribing today or wait
                  until a trial slot becomes available.
                </p>
              </>
            </Modal>
          </div>
        </CardContainer>
      )}
    </div>
  );
};

export default SubscriptionPage;
