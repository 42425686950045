import { TextField } from '@material-ui/core';
import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import './index.scss';

export type CustomTextFieldProps = {
  textFieldProps: TextFieldProps;
  containerClassName?: string;
  children?: React.ReactNode;
};
const CustomTextField = ({ textFieldProps, containerClassName, children }: CustomTextFieldProps): JSX.Element => {
  const { className } = textFieldProps;
  const classes = `custom-text-input ${className}`;
  return (
    <div className={containerClassName}>
      <TextField {...textFieldProps} className={classes} />
      {children}
    </div>
  );
};
export default CustomTextField;
