import React from 'react';
import './ScheduledDuration.scss';
import useHover from 'hooks/useHover';
import Fade from 'components/Shared/Fade';
import DurationIcon from '../../images/icons/duration.svg';
import PencilIcon from '../../images/icons/pencil-blue.svg';

interface Props {
  loading: boolean;
  duration: number;
  isEditable?: boolean;
  handleEdit?: any;
}

const InterviewScheduledDuration: React.FC<Props> = ({ loading, duration, isEditable, handleEdit }) => {
  const [hoverRef, isHovering] = useHover();

  const minutes = duration ? Math.floor(duration / 60) : null;

  return (
    <div ref={hoverRef} className="scheduled-duration" onClick={handleEdit} role="button" tabIndex={-1}>
      <img className="icon" src={DurationIcon} alt="duration icon" />
      <div className="label">Scheduled Duration: </div>
      {!loading && !!duration && <div className="duration value">{minutes} minutes</div>}
      <Fade fadeIn={!loading && isEditable && isHovering}>
        <img className="edit-duration-icon" src={PencilIcon} alt="edit duration icon" />
      </Fade>
    </div>
  );
};

export default InterviewScheduledDuration;
