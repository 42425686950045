import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { changeAiCandidateFeedbackSuccess } from 'store/reducers/organization';
import { setOrganizationSetting } from '../../api/organization.api';
import SettingsSwitcher from '../Shared/Switcher/SettingsSwitcher';

interface Props {
  organization?: any;
  loading: boolean;
}

const CandidateFeedbackSettings: React.FC<Props> = (props: Props) => {
  const { loading, organization } = props;
  const dispatch = useDispatch();

  const handleChange = async (checked): Promise<any> => {
    return setOrganizationSetting(organization.id, 'ai-candidate-feedback', checked ? 'true' : 'false').then(() => {
      dispatch(changeAiCandidateFeedbackSuccess({ checked }));
    });
  };

  const label =
    'Enable personalized feedback to be shown with the Areas For Review on assessments with show results enabled. This text is AI generated to provide constructive feedback based on their specific performance in the assessment.';
  const switcherProps = useMemo(
    () => ({
      label,
      loading: loading || !organization,
      initialValue: organization.ai_candidate_feedback,
    }),
    [organization, loading],
  );
  return (
    <div>
      <SettingsSwitcher
        title="AI Generated Personalized Candidate Feedback"
        handleChange={handleChange}
        successMessage="Candidate feedback settings updated for your organization."
        errorMessage="An error occured while changing candidate feedback settings. Please try again later!"
        initialValue={organization.ai_candidate_feedback}
        innerProps={switcherProps}
        beta
      />
    </div>
  );
};

export default CandidateFeedbackSettings;
