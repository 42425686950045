import React from 'react';
import './Input.scss';

export interface CircularRadioInputProps {
  label?: string;
  size?: number;
  checked?: boolean;
  handleClick?: (event: React.SyntheticEvent<EventTarget>) => void;
}

const CircularRadioInput = ({ label, checked, size, handleClick }: CircularRadioInputProps): JSX.Element => {
  size = size ?? 40;
  const invertColor = checked ? '#004385' : '#fff';
  const color = checked ? '#fff' : '#004385';
  const divStyle = {
    height: `${size}px`,
    width: `${size}px`,
    border: `2px solid #004385`,
    backgroundColor: invertColor,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const labelStyle = { color, fontWeight: 'bold' } as React.CSSProperties;
  return (
    <div aria-hidden="true" className="circular-radio-button" style={divStyle} onClick={handleClick}>
      <span style={labelStyle}>{label}</span>
    </div>
  );
};

export default CircularRadioInput;
