import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { selectCandidateRow } from 'helpers/store';
import CardContainer from '../Shared/MainContainer/CardContainer';
import ProgressBar from '../Shared/ProgressBar';
import Button from '../Shared/Button';
import ImageWithTooltip from '../Shared/Tooltip';
import TextWithTooltip from '../Shared/Tooltip/TextWithTooltip';
import SuspiciousActivityIcon from '../../images/icons/suspicious-activity.svg';
import BenchmarkIcon from '../../images/icons/benchmark.svg';
import RequiresEvaluationIcon from '../../images/icons/requires-evaluation-blue.svg';
import { relativeDate, fullDateTime } from '../../helpers/datetime';
import './index.scss';
import { OrganizationDetails } from '../../api/profile.api';

interface CandidateCardProps {
  assessment: any;
  candidate: any;
  subjects: any[];
  userDetails: any;
  organization: OrganizationDetails;
}

const CandidateCard: React.FC<CandidateCardProps> = ({
  assessment,
  candidate,
  subjects,
  userDetails,
  organization,
}) => {
  const history = useHistory();
  const handleButtonClick = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    selectCandidateRow(candidate);
    history.push(`/candidate-details/${candidate.Reference}/overview`);
  };

  const shouldCloakPII = useMemo(() => {
    return (
      (userDetails.recruiter_detail && userDetails.recruiter_detail.cloak_candidates) ||
      organization.cloak_candidates ||
      assessment?.cloak_candidates
    );
  }, [assessment, userDetails, organization]);
  return (
    <CardContainer className="top-results-card">
      <h3>{shouldCloakPII ? '' : candidate.Name}</h3>
      <p className="candidate-reference">
        {candidate.Reference}
        <div className="candidate-flags">
          {candidate.suspicious_activity ? (
            <ImageWithTooltip
              pointer={false}
              icon={SuspiciousActivityIcon}
              tooltip="Suspicious Activity"
              alt="Suspicious Activity"
              noHover
            />
          ) : (
            ''
          )}
          {candidate.Status === 'Requires Evaluation' ? (
            <ImageWithTooltip
              icon={RequiresEvaluationIcon}
              tooltip="Requires Evaluation"
              alt="Requires Evaluation"
              noHover
            />
          ) : (
            ''
          )}
          {candidate.Benchmark === candidate.candidate_test_id ? (
            <ImageWithTooltip icon={BenchmarkIcon} tooltip="Benchmark" alt="Benchmark" noHover />
          ) : (
            ''
          )}
        </div>
      </p>
      <h1 className="candidate-score">{`${Math.round(candidate['Total Score']) || 0}%`}</h1>
      {subjects.map(subject =>
        candidate[subject.subject] ? (
          <ProgressBar className="mt3" title={subject.subject} progress={candidate[subject.subject]} showLegend />
        ) : (
          <></>
        ),
      )}
      {candidate.Status === 'Requires Evaluation' ? (
        <a href={`/candidate-details/${candidate.Reference}/overview`}>
          <Button variant="primary" text="Complete Evaluation" onClick={e => handleButtonClick(e)} />
        </a>
      ) : (
        <a href={`/candidate-details/${candidate.Reference}/overview`}>
          <Button variant="sub-primary" text="View Detail" onClick={e => handleButtonClick(e)} />
        </a>
      )}
      <p className="muted">
        <TextWithTooltip
          pointer={false}
          text={`Submitted ${relativeDate(candidate['Completed On'], userDetails.user_timezone)}`}
          tooltip={fullDateTime(candidate['Completed On'], userDetails.user_timezone)}
        />
      </p>
    </CardContainer>
  );
};
export default CandidateCard;
