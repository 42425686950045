import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import 'styles/spacing.styles.scss';
import './index.scss';
import * as AAPI from 'api/assessment.api';
import Skeleton from 'react-loading-skeleton';
import CardContainer from 'components/Shared/MainContainer/CardContainer';
import FunnelChart from 'components/Shared/FunnelChart/index';
import HistogramChart from 'components/Shared/Histogram/index';
import StackedProgressBars from 'components/Shared/StackedProgressBars/index';
import PieChart from 'components/Shared/PieChart/index';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import EditScore from './EditScore';
import OverviewCarousel from './OverviewCarousel';
import AgeHistogram from './AgeHistogram';
import DynamicHistogram from './DynamicHistogram';
import LocationPieChart from './LocationPieChart';
import GenderPieChart from './GenderPieChart';
import { DEFAULT_PASSING_SCORE } from '../../helpers/constants';

type ParticipationFunnelStage =
  | 'Invited'
  | 'Invites seen'
  | 'Invite links clicked'
  | 'Information Submitted'
  | 'Assessment Submitted'
  | 'Passed';

const ResultsOverview: React.FC = () => {
  const { currentAssessment: assessment } = useSelector((state: RootState) => state.assessment);
  const [isLoadingFeedbacksData, setIsLoadingFeedbacksData] = useState(false);
  const [feedbacksData, setFeedbacksData] = useState({
    questions: [],
    options: [],
    summary: Object,
  });
  const [funnelData, setFunnelData] = useState(null);
  const [histogramHeight] = useState(244);
  const [avgs, setAvgs] = useState(null);
  const [passRate, setPassRate] = useState(null);
  const [scoreHistogramData, setScoreHistogramData] = useState(null);

  const [ageHistogramData, setAgeHistogramData] = useState(null);
  const [experienceHistogramData, setExperienceHistogramData] = useState(null);
  const [genderPieChartData, setGenderPieChartData] = useState(null);
  const [locationPieChartData, setLocationPieChartData] = useState(null);
  const [customParticipantStats, setCustomParticipantStats] = useState([]);
  const anonymisedMessage = 'Excluding information provided within the last 24 hours as this field is anonymized.';
  const invitesSeenTooltip = `This metric excludes any invites seen from ${
    assessment.purpose === 'ld' ? 'participants' : 'candidates'
  } with email tracking blockers enabled.`;

  const createFunnelData = (data: Record<string, number>): Record<string, any>[] => {
    let result = Object.keys(data).map((point: ParticipationFunnelStage) => ({
      label: point,
      value: data[point],
      tooltip: point === 'Invites seen' ? invitesSeenTooltip : null,
    }));

    const invited = result.find(point => point.label === 'Invited').value;
    const seen = result.find(point => point.label === 'Invites seen').value;
    const clicked = result.find(point => point.label === 'Invite links clicked').value;
    //  We only capture the Invites Seen based on Mandrill’s tracking pixel, which some email clients & browsers block.
    // to make sure that the data we are showing makes sense, we add the following conditions:
    // if the Invites Seen metric is within the bottom 20% of the drop off between Invited and Invite Links Clicked,
    // and there is at least 10 invite links clicked, and the number of invited is more than 20% higher than the number of clicks,
    // we should exclude the Invites Seen metric from the funnel all together.
    if (clicked > 10 && invited > clicked * 1.2 && (seen - clicked) / (invited - clicked) < 0.2) {
      result = result.filter(point => point.label !== 'Invites seen');
    }
    return result;
  };

  useEffect(() => {
    const benchmark_score = assessment.benchmark_score ? assessment.benchmark_score * 100 : DEFAULT_PASSING_SCORE;

    AAPI.getFunnelData(assessment.id, assessment.passing_score ?? benchmark_score).then(response => {
      setFunnelData(createFunnelData(response.data.data));
      setPassRate(
        response.data.data['Assessment Submitted']
          ? `${((response.data.data.Passed / response.data.data['Assessment Submitted']) * 100).toFixed(2)}%`
          : '-',
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment.id, assessment.benchmark_score, assessment.passing_score, assessment.candidates_count]);

  useEffect(() => {
    AAPI.getHistogramData(assessment.id).then(response => {
      const resData = response.data.data;
      let empty = true;
      Object.keys(resData).forEach(key => {
        resData[key].label = `${resData[key].label * 10}-${resData[key].label * 10 + 10}`;
        if (resData[key].value) {
          empty = false;
        }
      });
      if (empty) {
        setScoreHistogramData([]);
      } else {
        setScoreHistogramData(resData);
      }
    });
    AAPI.getSubjectAvgs(assessment.id).then(response => {
      setAvgs(response.data.data);
    });
    setIsLoadingFeedbacksData(true);
    AAPI.getFeedbackSummaryData(assessment.id)
      .then(response => {
        setFeedbacksData(response.data.data);
      })
      .finally(() => {
        setIsLoadingFeedbacksData(false);
      });

    AAPI.getParticipantStats(assessment.id).then(response => {
      setAgeHistogramData(response.data.data['Year of Birth']);

      setExperienceHistogramData(response.data.data['Years of Experience']);
      setGenderPieChartData(response.data.data.Gender);
      setLocationPieChartData(response.data.data.Location);
      setCustomParticipantStats(
        Object.entries(response.data.data)
          .filter(([k]) => {
            return !['Years of Experience', 'Year of Birth', 'Gender', 'Location'].includes(k);
          })
          .map(([, v]) => v),
      );
    });
  }, [assessment.id]);
  const hidePassRate = (): boolean => {
    if (scoreHistogramData) {
      if (scoreHistogramData.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0) === 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="mt5 resultsoverview">
      <Grid className="pb2" container spacing={3}>
        <Grid item xs={12} sm={4}>
          <CardContainer>
            <EditScore title="Passing Score" />
          </CardContainer>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardContainer>
            <h3>Average Score</h3>
            {funnelData?.[4]?.value ? (
              <h2>{assessment.average_score !== null ? `${assessment.average_score}%` : '-'}</h2>
            ) : (
              <h2>-</h2>
            )}
          </CardContainer>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardContainer>
            <h3>Pass Rate</h3>
            {hidePassRate() ? <h2>-</h2> : <h2>{passRate || <Skeleton />}</h2>}
          </CardContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <CardContainer>
            <h3 className="spacing-bottom">Participation Funnel</h3>
            {funnelData ? (
              <FunnelChart data={funnelData} height={244} strokeWidth={2} base="max" />
            ) : (
              <Skeleton height={250} />
            )}
          </CardContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <CardContainer>
            <h3 className="spacing-bottom">Distribution of scores</h3>
            {scoreHistogramData ? (
              <HistogramChart
                height={300}
                data={scoreHistogramData}
                lineAmount={6}
                yaxis="Number of Candidates"
                xaxis="Score"
              />
            ) : (
              <Skeleton height={250} />
            )}
          </CardContainer>
        </Grid>
        <Grid item xs={12}>
          <CardContainer className="overview-carousel">
            <OverviewCarousel avgs={avgs} />
          </CardContainer>
        </Grid>
        <Grid item xs={12}>
          {!isLoadingFeedbacksData ? (
            <StackedProgressBars
              questions={feedbacksData.questions}
              options={feedbacksData.options}
              summary={feedbacksData.summary}
              title={assessment.purpose === 'ld' ? 'Participant Sentiment' : 'Candidate Sentiment'}
              tooltipText={
                assessment.purpose === 'ld'
                  ? 'Participants were asked for their opinion at the end of the exercise.'
                  : 'Candidates were asked for their opinion at the end of the assessment.'
              }
            />
          ) : (
            <Skeleton height={250} />
          )}
        </Grid>
        {!genderPieChartData ? null : (
          <Grid item xs={12} md={6}>
            <CardContainer>
              <h3 className="spacing-bottom">Gender</h3>
              <GenderPieChart genderStats={genderPieChartData} height={histogramHeight} />
              <i style={{ fontSize: '12px', color: '#000000', margin: 'auto' }}>
                {genderPieChartData.is_anonymous ? anonymisedMessage : ''}
              </i>
            </CardContainer>
          </Grid>
        )}

        {!ageHistogramData ? null : (
          <Grid item xs={12} md={6}>
            <CardContainer>
              <h3 className="spacing-bottom">Age</h3>
              <AgeHistogram yearStats={ageHistogramData} histogramHeight={histogramHeight} />
              <i style={{ fontSize: '12px', color: '#000000', margin: 'auto' }}>
                {ageHistogramData.is_anonymous ? anonymisedMessage : ''}
              </i>
            </CardContainer>
          </Grid>
        )}

        {!experienceHistogramData ? null : (
          <Grid item xs={12} md={6}>
            <CardContainer>
              <h3 className="spacing-bottom">Years of Experience</h3>
              <DynamicHistogram
                stats={experienceHistogramData}
                histogramHeight={histogramHeight}
                xAxisLabel="Years of Experience"
                yAxisLabel="Number of Candidates"
              />
              <i style={{ fontSize: '12px', color: '#000000', margin: 'auto' }}>
                {experienceHistogramData.is_anonymous ? anonymisedMessage : ''}
              </i>
            </CardContainer>
          </Grid>
        )}

        {!locationPieChartData ? null : (
          <Grid item xs={12} md={6}>
            <CardContainer>
              <h3 className="spacing-bottom">Location</h3>
              <LocationPieChart locationStats={locationPieChartData} height={histogramHeight} />
              <i style={{ fontSize: '12px', color: '#000000', margin: 'auto' }}>
                {locationPieChartData.is_anonymous ? anonymisedMessage : ''}
              </i>
            </CardContainer>
          </Grid>
        )}
        {customParticipantStats.map(stats => {
          const data = stats.summary.map(({ label, count }) => {
            return { label, value: count };
          });
          return (
            <>
              <Grid item xs={12} md={6} key={stats.field}>
                <CardContainer>
                  <h3 className="spacing-bottom">{stats.field}</h3>
                  {stats.field_type === 'DROPDOWN' ? (
                    <PieChart height={histogramHeight} data={data} />
                  ) : (
                    <DynamicHistogram
                      stats={stats}
                      histogramHeight={250}
                      xAxisLabel={stats.field}
                      yAxisLabel="Number of Candidates"
                    />
                  )}
                </CardContainer>
              </Grid>
            </>
          );
        })}
      </Grid>
    </div>
  );
};

export default ResultsOverview;
