import React from 'react';
import { v4 as uuid } from 'uuid';
import './Input.scss';
import Skeleton from 'react-loading-skeleton';

interface Props {
  value?: string;
  defaultValue?: string;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  id?: string;
  handleClick?: any;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  errorTxt?: string;
  loading?: boolean;
  placeholder?: string;
  inputRef?: any;
}

const Input = ({
  value,
  defaultValue,
  label,
  disabled,
  readOnly,
  name,
  id,
  handleClick,
  onChange,
  onBlur,
  onFocus,
  errorTxt,
  loading,
  inputRef,
  placeholder = 'Write a message.',
}: Props): JSX.Element => {
  if (label && !id) {
    id = uuid();
  }
  return (
    <div className="alooba-input">
      {loading && label ? <Skeleton height={10} width={100} /> : <label htmlFor={id}>{label}</label>}
      {loading ? (
        <Skeleton height={115} />
      ) : (
        <textarea
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onClick={handleClick}
          onBlur={onBlur}
          onFocus={onFocus}
          defaultValue={defaultValue}
          readOnly={readOnly}
          name={name}
          id={id}
          ref={inputRef}
          className={`textarea ${errorTxt ? 'errorInput' : ''}`}
        />
      )}
      <p className="errorMsg">{errorTxt}</p>
    </div>
  );
};

export default Input;
