import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BillingDetailsResult } from 'api/payment.api';

interface BillingState {
  loading: boolean | null;
  billingDetails: BillingDetailsResult | null;
  error: string | null;
}

interface BillingResponse {
  data: BillingDetailsResult;
}

const initialState: BillingState = {
  loading: null,
  billingDetails: null,
  error: null,
};

const billingReducer = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    getBillingDetailsStart: state => {
      state.loading = true;
      state.error = null;
    },
    getBillingDetailsSuccess: (state, action: PayloadAction<BillingResponse>) => {
      state.billingDetails = action.payload.data;
      state.loading = false;
    },
    getBillingDetailsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { getBillingDetailsStart, getBillingDetailsSuccess, getBillingDetailsFailure } = billingReducer.actions;

export default billingReducer.reducer;
