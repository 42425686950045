import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useChatbot } from 'hooks';
import Header from '../../Header';
import Footer from '../../Footer';

interface IProps extends RouteProps {
  component: React.ComponentType;
  path: string;
  showChatbot?: boolean;
  isFixedHeader?: boolean;
}

export const PublicRoute: React.FC<IProps> = ({ showChatbot, component, isFixedHeader = true, ...options }: IProps) => {
  useChatbot(showChatbot);

  return (
    <>
      <div className="content">
        <Header homeUrl={process.env.REACT_APP_LANDING_PAGE_URL} toggleMobileMenu={false} hideAvatarIcon />
        <main className={`main collapsed-main ${isFixedHeader ? 'fixed-header-main' : ''}`}>
          <Route {...options} component={component} />
        </main>
      </div>
      <Footer />
    </>
  );
};
