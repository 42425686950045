import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import './Layout.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import GlobalBars from 'components/Shared/GlobalBars';
import Footer from 'components/Footer';
import Menu from 'components/Menu/Menu';
import Header from 'components/Header';
import TrialBanner from 'components/TrialBanner';
import { MenuModal } from '../Modal/menu';
import { emptyJwt } from '../../../helpers';

export interface Decoded {
  ufid: number;
  ut?: string;
  paid?: boolean;
}

interface Props {
  hasBars?: boolean;
  children: React.ReactNode;
}

const Layout: React.FC = ({ children, hasBars }: Props) => {
  const [showMobileMenu, setMobileMenu] = useState<boolean>(false);
  const [showDemoBar, setShowDemoBar] = useState(false);
  const [trialBannerShow, setTrialBannerShow] = useState<boolean>(false);

  const toggleMobileMenu = (): any => {
    setMobileMenu(true);
  };
  const hideMenuModal = (): any => {
    setMobileMenu(false);
  };

  const { userDetails, loading } = useSelector((state: RootState) => state.profile);
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const { collapsed, hasPageHeader } = useSelector((state: RootState) => state.layout);
  const subscription = useSelector((state: RootState) => state.subscription);
  const organisationId = userDetails?.recruiter_detail?.organisation_id;

  useEffect(() => {
    const decoded: Decoded = jwt_decode(accessToken || emptyJwt);
    setShowDemoBar(decoded && decoded.ut === 'recruiter' && decoded.paid === false && !subscription?.subscription);
  }, [accessToken, subscription]);

  useEffect(() => {
    setTrialBannerShow(subscription?.subscription?.status === 'trialing');
  }, [subscription?.subscription?.status]);

  return (
    <>
      <Header toggleMobileMenu={toggleMobileMenu} accomodateBanner={trialBannerShow} />
      {trialBannerShow && <TrialBanner subscription={subscription.subscription} organisationId={organisationId} />}
      <div className={`content ${trialBannerShow ? 'has-banner' : ''}`}>
        <aside>
          <Menu />
        </aside>
        <main className={`main ${collapsed ? 'collapsed-main' : ''} ${hasPageHeader ? 'fixed-header-main' : ''}`}>
          {hasBars && (
            <GlobalBars
              notVerified={!(userDetails && userDetails.is_activated)}
              email={userDetails && userDetails.email}
              hasPaid={showDemoBar}
              loading={loading}
            />
          )}
          {children}
        </main>
      </div>
      <Footer />
      <MenuModal showAlert={showMobileMenu} hideMenuModal={hideMenuModal}>
        <Menu />
      </MenuModal>
    </>
  );
};

export default Layout;
