import { Checkbox } from '@material-ui/core';
import ConfigurationIcon from 'images/icons/configuration-blue.svg';
import React, { useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import '../Shared/Dropdown/Dropdown.scss';
import { TableField } from './completedTableFields';
import './index.scss';
import CustomMenuItem from '../Shared/CustomMenuItem';
import CustomListItemText from '../Shared/CustomListItemText';
import CustomMenu from '../Shared/CustomMenu';

interface Props {
  columns: TableField[];
  shouldCloak: boolean;
  toggleColumns: (columns: string[]) => any;
}
const ConfigurableColumns = ({ columns, shouldCloak, toggleColumns }: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDropdown, setDropdown] = useState<boolean>(false);
  const [localColumns, setLocalColumns] = useState<TableField[]>([...columns]);
  const [changedColumns, setChangedColumns] = useState<string[]>([]);
  const wrapperRef = useRef(null);
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { organizationDetails: organization } = useSelector((state: RootState) => state.organization);

  const handleDropdown = (event: React.MouseEvent<HTMLImageElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    setDropdown(!showDropdown);
    setAnchorEl(event.currentTarget);
  };

  const columnToggled = (column: TableField): any => {
    setLocalColumns(
      localColumns.map(item => {
        const itemCopy = { ...item };
        if (itemCopy.label === column.label) {
          itemCopy.hidden = !itemCopy.hidden;
        }
        return itemCopy;
      }),
    );
    setChangedColumns(old => {
      const index = old.indexOf(column.label);
      if (index > -1) {
        old.splice(index, 1);
        return old;
      }
      return [...old, column.label];
    });
  };
  const onClickOutside = (): any => {
    if (changedColumns.length > 0 && showDropdown) {
      toggleColumns(changedColumns);
      setChangedColumns([]);
    }
    setDropdown(false);
  };

  const shouldCloakPII = useMemo(() => {
    return userDetails?.recruiter_detail?.cloak_candidates || organization.cloak_candidates || shouldCloak;
  }, [userDetails, organization, shouldCloak]);

  return (
    <div className="dropdown pt2" ref={wrapperRef}>
      <img
        src={ConfigurationIcon}
        onClick={handleDropdown}
        className={showDropdown ? 'highlighted columns-dropdown-toggle' : 'columns-dropdown-toggle'}
        alt="toggle-configurable-columns"
        id="toggle-configurable-columns"
      />
      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={showDropdown}
        onClose={onClickOutside}
        onChange={null}
        getContentAnchorEl={null}
        disableScrollLock
        className="custom-select-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: 300,
          },
        }}
      >
        {localColumns
          .filter(option => !(option.cloak && shouldCloakPII))
          .map(option => (
            <CustomMenuItem onClick={() => columnToggled(option)} key={option.label} value={option.label}>
              <CustomListItemText primary={option.label} />
              <Checkbox
                style={{
                  color: '#4A4A68',
                  width: '20px',
                }}
                color="primary"
                checked={!option.hidden}
              />
            </CustomMenuItem>
          ))}
      </CustomMenu>
    </div>
  );
};
export default ConfigurableColumns;
