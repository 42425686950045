import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import FieldContainer from '../FieldContainer';
import './index.scss';
import usePrevious from '../../../../helpers/usePrevious';

export type scoreFilterProps = {
  name: string;
  label: string;
  description?: string;
  canHide: boolean;
  visible: boolean;
  initialValue?: {
    min?: number;
    max?: number;
  };
  onChange: any;
  hide: any;
  isPercent?: boolean;
  restrictions?: { min?: number; max?: number };
};

const ScoreFilter = ({
  name,
  label,
  description,
  canHide,
  visible,
  initialValue,
  onChange,
  hide,
  isPercent,
  restrictions = { min: 0, max: 100 },
}: scoreFilterProps): JSX.Element => {
  const [isMin, setIsMin] = useState<boolean>(!initialValue?.max);
  const [value, setValue] = useState<number>(initialValue?.min || initialValue?.max || restrictions.min);
  const prevVisible = usePrevious(visible);
  const isThereInitialValue = useMemo(() => Boolean(initialValue?.max || initialValue?.min), [initialValue]);

  const onRangeChange = useCallback(
    (newValue: number, isScoreMin: boolean): void => {
      if (onChange && (newValue !== value || isScoreMin !== isMin)) {
        setIsMin(isScoreMin);
        onChange({
          [`${name}[min]`]: isScoreMin ? newValue : null,
          [`${name}[max]`]: isScoreMin ? null : newValue,
        });
      }
    },
    [isMin, name, onChange, value],
  );
  const onValueChange = useCallback(
    (event: any): void => {
      const currentValue = Number(event.target.value);
      if (!isPercent || (currentValue >= restrictions.min && currentValue <= restrictions.max)) {
        setValue(currentValue);
        onRangeChange(currentValue, isMin);
      }
    },
    [isMin, isPercent, onRangeChange, restrictions.max, restrictions.min],
  );
  useEffect(() => {
    if (visible && !prevVisible && !isThereInitialValue) {
      onValueChange({ target: { value: isPercent ? 60 : restrictions.min } });
    }
  }, [visible, isPercent, onValueChange, restrictions.min, prevVisible, isThereInitialValue]);
  const resetAndHide = (): void => {
    setIsMin(true);
    const emptyValue = {
      [name]: null,
      [`${name}[min]`]: null,
      [`${name}[max]`]: null,
    };
    onChange(emptyValue);
    hide();
  };
  const toggleIsMin = (): void => {
    onRangeChange(value, !isMin);
  };
  return (
    <FieldContainer hide={resetAndHide} label="" description={description} canHide={canHide} visible={visible}>
      <div className="score-filter-container">
        <div className="score-part">{label}</div>
        {isMin ? (
          <FontAwesomeIcon onClick={toggleIsMin} className="icon-direction" icon={faChevronRight} />
        ) : (
          <FontAwesomeIcon onClick={toggleIsMin} className="icon-direction" icon={faChevronLeft} />
        )}
        <div className="score-part score-filter">
          <input min={restrictions.min} max={restrictions.max} type="number" value={value} onChange={onValueChange} />
        </div>
        {isPercent && <div className="score-part">%</div>}
      </div>
    </FieldContainer>
  );
};
export default React.memo(ScoreFilter);
