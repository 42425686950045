import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import SkillQuestion from './SkillQuestion';
import './InterviewQuestionList.scss';

interface Props {
  skill: any;
  questions: any[];
  selectedQuestion: any;
  setSelectedQuestion: any;
  handleRemoveQuestion: any;
  onQuestionDragEnd: any;
  removingMap: any;
}

const SkillQuestions: React.FC<Props> = ({
  skill,
  questions,
  selectedQuestion,
  setSelectedQuestion,
  handleRemoveQuestion,
  onQuestionDragEnd,
  removingMap,
}) => {
  return (
    <DragDropContext onDragEnd={onQuestionDragEnd}>
      <Droppable droppableId={`${skill.id}`}>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {questions.map((question, index) => (
              <SkillQuestion
                key={question.id}
                question={question}
                index={index}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                handleRemoveQuestion={handleRemoveQuestion}
                removingMap={removingMap}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SkillQuestions;
