import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { hasCompletedSignup, hasPaid } from 'helpers/user';
import { Link } from 'react-router-dom';
import avatarIcon from '../../assets/avatar-icon.png';

type IconProps = {
  size?: string | number;
  fill?: string;
};

const Avatar: React.FC<IconProps> = () => {
  const [showOptions, setShowOptions] = useState(false);

  const toggleShow = (): void => {
    setShowOptions(!showOptions);
  };

  const { accessToken } = useSelector((state: RootState) => state.auth);

  return (
    <div className="icon avatar-dropdown" onMouseEnter={toggleShow} onMouseLeave={toggleShow}>
      <img src={avatarIcon} alt="Avatar Icon" height="24" />
      <div className={`avatar-dropdown-content ${showOptions ? 'show' : ''}`}>
        {hasCompletedSignup(accessToken) && hasPaid() && <Link to="/personal-details">Personal Details</Link>}
        <Link to="/logout">Log out</Link>
      </div>
    </div>
  );
};

export const AvatarIcon = React.memo(Avatar);
