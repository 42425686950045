import React from 'react';

type IconProps = {
  size?: string | number;
  fill?: string;
};

const NavigateLeft: React.FC<IconProps> = ({ size = 12, fill = '#8C8CA1' }) => {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.70312 1.40625L3.10938 6L7.70312 10.5938L6.29688 12L0.296875 6L6.29688 0L7.70312 1.40625Z"
          fill={fill}
        />
      </svg>
    </>
  );
};
export const NavigateLeftIcon = React.memo(NavigateLeft);

const NavigateRight: React.FC<IconProps> = ({ size = 12, fill = '#8C8CA1' }) => {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.70312 0L7.70312 6L1.70312 12L0.296875 10.5938L4.89062 6L0.296875 1.40625L1.70312 0Z" fill={fill} />
      </svg>
    </>
  );
};
export const NavigateRightIcon = React.memo(NavigateRight);

const NavigateBottomFull: React.FC<IconProps> = ({ size = 8, fill = '#0E0E2C' }) => {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.0078125 0.515625H9.99219L5.02344 5.48438L0.0078125 0.515625Z" fill={fill} />
      </svg>
    </>
  );
};
export const NavigateBottomFullIcon = React.memo(NavigateBottomFull);
