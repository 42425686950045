import React, { useState, useEffect, useMemo } from 'react';
import './index.scss';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Label,
  PieChart,
  Pie,
  PolarRadiusAxis,
  Legend,
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import { Page, Image, Document, pdf } from 'react-pdf-renderer-v2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import MainLogo from 'images/logo.svg';
import { downloadFile } from 'helpers';
import { hasOrgFeature } from 'Authorization/Check';
import CircleProgressBar from '../Shared/CircleProgressBar';
import { RootState } from '../../store/rootReducer';
import { getCandidateDetails, getTestDetails } from '../../store/actions/candidate.actions';
import { Modal } from '../Shared/Modal';
import { getPercentileOrdinalIndicator } from '../../helpers/numbers';
import { blue, dark, error, grey, orange, slate, success } from '../../styles/colors';

const COLORS = [
  blue,
  dark,
  success,
  grey,
  orange,
  slate,
  error,
  '#E57373', // Red
  '#BA68C8', // Purple
  '#64B5F6', // Blue
  '#81C784', // Green
  '#FFD54F', // Yellow
  '#FF8A65', // Deep Orange
  '#90A4AE', // Gray
  '#607D8B', // Blue Gray
  '#00E676', // Green
  '#FFD740', // Amber
  '#FF5722', // Deep Orange
  '#9E9D24', // Olive
  '#FF80AB', // Pink
  '#AED581', // Lime Green
  '#B0BEC5', // Steel Gray
  '#F06292', // Pink
  '#4FC3F7', // Light Blue
  '#FFB74D', // Orange
];

const ScoreChart = ({ skill }): JSX.Element => {
  return (
    <div className="subject-score" key={skill.subject}>
      <div className="score">
        <CircleProgressBar strokeWidth={8} size={100} percent={skill.scored_percent}>
          <div className="scored-percent">
            <strong style={{ fontSize: '25px' }}>{Math.round(skill.scored_percent)}%</strong>
          </div>
        </CircleProgressBar>
      </div>
      <h3 className="subject-name">{skill.subject}</h3>
    </div>
  );
};
const CandidateReport = ({ reference }): JSX.Element => {
  const canDownloadCandidateReport = hasOrgFeature('export_results');
  const dispatch = useDispatch();
  const { candidate, results } = useSelector((state: RootState) => state.candidate);
  const [imgsData, setImgsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingText] = useState('Generating Report');
  const { currentAssessment } = useSelector((state: RootState) => state.assessment);
  const scoresBuckets = useMemo(() => {
    if (!candidate?.candidates_scores_buckets) return [];
    const buckets = [...candidate.candidates_scores_buckets].map(bucket => ({
      ...bucket,
      label: `${bucket.bucket * 10}-${bucket.bucket * 10 + 10}`,
    }));
    buckets.sort((bucket1, bucket2) => bucket1.bucket - bucket2.bucket);
    return buckets;
  }, [candidate?.candidates_scores_buckets]);
  const orderedSkills = useMemo(() => {
    if (!candidate?.subject_scores) return [];
    const skills = [...candidate.subject_scores];
    skills.sort((skill1, skill2) => skill2.max_score - skill1.max_score);
    return skills;
  }, [candidate?.subject_scores]);
  const totalMaxScore = useMemo(() => {
    if (!orderedSkills.length) return 0;
    return orderedSkills.reduce((total, skill) => total + skill.max_score, 0);
  }, [orderedSkills]);
  const leftSkills = useMemo(() => {
    if (orderedSkills.length <= 1) return [];
    return orderedSkills.slice(0, Math.min(orderedSkills.length / 2, 2));
  }, [orderedSkills]);
  const rightSkills = useMemo(() => {
    if (orderedSkills.length <= 1) return [];
    return orderedSkills
      .slice(Math.min(orderedSkills.length / 2, 2), Math.min(orderedSkills.length / 2, 2) * 2)
      .slice(0, leftSkills.length);
  }, [orderedSkills, leftSkills]);
  useEffect(() => {
    dispatch(getCandidateDetails(reference, true));
  }, [dispatch, reference]);
  const getImageData = async (element: HTMLElement): Promise<string> => {
    return new Promise(resolve => {
      html2canvas(element, {
        backgroundColor: '#fff',
        scale: 3,
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        resolve(imgData);
      });
    });
  };
  const personalityProfilingTest = useMemo(() => {
    if (!candidate?.tests) return null;
    const tests = candidate.tests.filter(test => test.test_type === 'Personality Profiling' && test.is_submitted);
    if (tests.length === 0) return null;
    return tests[0];
  }, [candidate?.tests]);
  useEffect(() => {
    if (personalityProfilingTest) {
      dispatch(getTestDetails(reference, personalityProfilingTest.id));
    }
  }, [reference, dispatch, personalityProfilingTest]);
  useEffect(() => {
    if (
      canDownloadCandidateReport &&
      candidate &&
      currentAssessment &&
      (!personalityProfilingTest || results.tests[personalityProfilingTest.id]) &&
      Object.keys(currentAssessment).length > 0 &&
      imgsData.length === 0
    ) {
      setTimeout(() => {
        const elements = document.querySelectorAll('.report');
        const promises: Promise<string>[] = [];

        for (let i = 0; i < elements.length; i += 1) {
          promises.push(getImageData(elements[i] as HTMLElement));
        }
        Promise.all(promises)
          .then(imageDataArray => {
            setImgsData(imageDataArray);
            return imageDataArray;
          })
          .then(async imageDataArray => {
            const PdfDocument = (
              <Document title={candidate.full_name}>
                <Page size="A4" wrap>
                  {imageDataArray.map(imgData => (
                    <Image source={imgData} key={imgData} />
                  ))}
                </Page>
              </Document>
            );
            const blob = await pdf(PdfDocument).toBlob();
            await downloadFile(blob, `${candidate.full_name} Alooba Report`);
            setIsLoading(false);
            window.close();
          });
      }, 100);
    }
  }, [results.tests, personalityProfilingTest, candidate, currentAssessment, imgsData, canDownloadCandidateReport]);
  const bucketsGroupedBySubject = useMemo(() => {
    if (!candidate?.candidates_skills_scores_buckets) return [];
    const buckets = [...candidate.candidates_skills_scores_buckets].map(bucket => ({
      ...bucket,
      label: `${bucket.bucket * 10}-${bucket.bucket * 10 + 10}`,
    }));
    return orderedSkills.map(skill => {
      const skillBuckets = buckets.filter(bucket => bucket.subject === skill.subject);
      // Add missing buckets
      for (let i = 0; i < 10; i += 1) {
        if (!skillBuckets.some(bucket => bucket.bucket === i)) {
          skillBuckets.push({
            subject: skill.subject,
            bucket: i,
            count: 0,
            label: `${i * 10}-${i * 10 + 10}`,
          });
        }
      }
      skillBuckets.sort((bucket1, bucket2) => bucket1.bucket - bucket2.bucket);
      return {
        ...skill,
        buckets: skillBuckets,
      };
    });
  }, [candidate?.candidates_skills_scores_buckets, orderedSkills]);

  if (!candidate || !currentAssessment) return null;
  if (!canDownloadCandidateReport) {
    window.close();
    return null;
  }
  return (
    <div className="report-container">
      <div className="report">
        <div className="report-header">
          <div className="report-logo">
            <img src={MainLogo} alt="Main Logo" style={{ width: '142px' }} />
          </div>
          <div className="report-title">
            <span className="report-role-title">{currentAssessment.role?.title}</span>
            <br />
            {candidate.full_name}&apos;s Alooba Report
          </div>
          <div className="report-contact-info">
            <div className="report-contact-text">
              <div>
                Email: <strong>{candidate.email_address}</strong>
              </div>
              {candidate.phone && (
                <div>
                  Phone: <strong>{candidate.phone}</strong>
                </div>
              )}
              {candidate.submitted_at && (
                <div>
                  Submitted On: <strong>{moment(candidate.submitted_at).format('MMMM Do YYYY h:mm A')}</strong>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="report">
        <div className="report-body">
          <div className="report-section report-introduction">
            This report provides a comprehensive overview of <strong>{candidate.full_name}</strong>&apos;s performance
            in the <strong>{currentAssessment.role?.title}</strong> assessment conducted by <strong>Alooba</strong>. Our
            goal is to offer valuable insights into <strong>{candidate.full_name}</strong>
            &apos;s skills, competencies, and areas of expertise, as well as to compare their performance with other
            candidates who underwent the same assessment.
          </div>
        </div>
      </div>
      <div className="report">
        <div className="report-body">
          <div className="report-section-title">
            <strong>What Was Assessed?</strong>
          </div>
          <div className="report-section report-overall-performance">
            <div className="report-row">
              <div className="report-graph">
                <ResponsiveContainer width="50%" height={300}>
                  <PieChart data={orderedSkills}>
                    <Pie
                      isAnimationActive={false}
                      data={orderedSkills}
                      dataKey="max_score"
                      nameKey="subject"
                      cx="50%"
                      cy="50%"
                      startAngle={0}
                      endAngle={360}
                      innerRadius={70}
                      outerRadius={90}
                      fill="#004385"
                      labelLine={false}
                    >
                      {orderedSkills.map((entry, index) => (
                        <Cell key={`cell-${entry.subject}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend
                      align="right"
                      verticalAlign="middle"
                      layout="vertical"
                      formatter={(value, entry, index) =>
                        `${value} : ${Math.round((orderedSkills[index].max_score / totalMaxScore) * 100)}%`
                      }
                    />
                  </PieChart>
                </ResponsiveContainer>
                <div className="graph-title">Assessment Skill Weight Distribution</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="report">
        <div className="report-body">
          <div className="report-section-title">
            <strong>Candidate Highlights</strong>
          </div>
          <div className="report-section report-overall-performance">
            <div className="report-row skills-scores">
              {leftSkills.map(skill => (
                <ScoreChart skill={skill} key={skill.subject} />
              ))}
              <div className="subject-score active">
                <div className="score">
                  <CircleProgressBar strokeWidth={8} size={150} percent={candidate.score_percent}>
                    <div className="scored-percent">
                      <strong style={{ fontSize: '32px' }}>{Math.round(candidate.score_percent)}%</strong>
                    </div>
                  </CircleProgressBar>
                </div>
                <h3 className="subject-name">Total Score</h3>
              </div>
              {rightSkills.map(skill => (
                <ScoreChart skill={skill} key={skill.subject} />
              ))}
            </div>
            <div className="report-row">
              <div className="report-graph">
                <ResponsiveContainer width="100%" height={200}>
                  <BarChart data={scoresBuckets}>
                    <XAxis dataKey="label" tickCount={10} tick={{ fontSize: 12 }}>
                      <Label value="Score Range" offset={-2} position="insideBottom" fontSize={12} />
                    </XAxis>
                    <YAxis
                      tick={{ fontSize: 12 }}
                      dataKey="count"
                      width={70}
                      allowDecimals={false}
                      label={{
                        value: 'N. of candidates',
                        angle: -90,
                        position: 'outsideBottomLeft',
                        fontSize: 12,
                      }}
                    />
                    <Bar dataKey="count" isAnimationActive={false}>
                      {scoresBuckets.map(entry => (
                        <Cell
                          fill={
                            entry.bucket === Math.min(9, Math.floor(candidate.score_percent / 10))
                              ? '#F68C3E'
                              : '#004385'
                          }
                          key={entry.bucket}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                <div className="graph-title">
                  Candidate Performance Rank
                  {candidate.percentile ? (
                    <span className="percentile-text" style={{ fontSize: '13px', color: '#004385' }}>
                      <br />
                      <span>{Math.round(candidate.percentile * 100)}</span>
                      <span className="percentile-ordinal-indicator">
                        {getPercentileOrdinalIndicator(candidate.percentile)}
                      </span>
                      <span> Percentile</span>
                    </span>
                  ) : null}
                </div>
              </div>
              {orderedSkills.length > 2 && (
                <div className="report-graph">
                  <ResponsiveContainer width="100%" height={300}>
                    <RadarChart cx="50%" cy="50%" outerRadius={100} width={500} height={300} data={orderedSkills}>
                      <PolarGrid radialLines />
                      <PolarRadiusAxis angle={-20} domain={[0, 100]} />
                      <PolarAngleAxis dataKey="subject" fontSize={12} fontFamily="Work Sans" fontWeight="bold" />
                      <Radar
                        name="subject"
                        dataKey="scored_percent"
                        stroke="#004385"
                        fill="#004385"
                        fillOpacity={0.6}
                        isAnimationActive={false}
                      />
                    </RadarChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {bucketsGroupedBySubject.length > 1 &&
        bucketsGroupedBySubject.map(skill => (
          <div className="report" key={skill.subject}>
            <div className="report-body">
              <div className="report-section-title">
                <strong>{skill.subject}</strong>
              </div>
              <div className="report-section report-overall-performance">
                <div className="report-row">
                  <div className="report-graph">
                    <ResponsiveContainer width="100%" height={200}>
                      <BarChart data={skill.buckets}>
                        <XAxis dataKey="label" tickCount={10} tick={{ fontSize: 12 }}>
                          <Label value="Score Range" offset={-2} position="insideBottom" fontSize={12} />
                        </XAxis>
                        <YAxis
                          dataKey="count"
                          tick={{ fontSize: 12 }}
                          width={70}
                          allowDecimals={false}
                          label={{
                            value: 'N. of candidates',
                            angle: -90,
                            position: 'outsideBottomLeft',
                            fontSize: 12,
                          }}
                        />
                        <Bar dataKey="count" isAnimationActive={false}>
                          {skill.buckets.map(entry => (
                            <Cell
                              fill={
                                entry.bucket === Math.min(9, Math.floor(skill.scored_percent / 10))
                                  ? '#F68C3E'
                                  : '#004385'
                              }
                              key={entry.bucket}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    <div className="graph-title">{skill.subject} Score Rank</div>
                  </div>
                  <div className="report-graph">
                    <div className="subject-score active">
                      <div className="score">
                        <CircleProgressBar strokeWidth={8} size={150} percent={skill.scored_percent}>
                          <div className="scored-percent">
                            <strong style={{ fontSize: '32px' }}>{Math.round(skill.scored_percent)}%</strong>
                          </div>
                        </CircleProgressBar>
                      </div>
                      <h3 className="subject-name">{skill.subject} Score</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {personalityProfilingTest && results.tests[personalityProfilingTest.id] && (
        <div className="report">
          <div className="report-body">
            <div className="report-section-title">
              <strong>Personality Profiling</strong>
            </div>
            <div className="report-section report-overall-performance">
              <div className="report-row">
                The candidate was asked to take a Big-5 personality assessment which is used to gain insight into the
                top five personality traits: Openness, Conscientiousness, Extroversion, Agreeableness, and Neuroticism.
              </div>
              <div className="report-row">
                <div className="report-graph">
                  <ResponsiveContainer width="100%" height={300}>
                    <RadarChart
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      width={500}
                      height={300}
                      data={results.tests[personalityProfilingTest.id].subject_scores}
                    >
                      <PolarGrid radialLines />
                      <PolarAngleAxis dataKey="subject_name" fontSize={12} fontFamily="Work Sans" fontWeight="bold" />
                      <PolarRadiusAxis angle={-20} domain={[0, 100]} />
                      <Radar
                        name="subject_name"
                        dataKey="scored_percent"
                        stroke="#004385"
                        fill="#004385"
                        fillOpacity={0.6}
                        isAnimationActive={false}
                      />
                    </RadarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal isShown={isLoading} showCancel={false} containerClass="generating-assessment-modal">
        <div className="generating-assessment-modal-content">
          <h2>{loadingText}</h2>
          <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
          <p>Please do not close this tab.</p>
        </div>
      </Modal>
    </div>
  );
};
export default CandidateReport;
