import store from '../store/store';
import { Candidate, getCandidateSuccess } from '../store/reducers/candidate';
import { getCandidateDetails } from '../store/actions/candidate.actions';

export const selectCandidateRow = (row: any): void => {
  selectCandidate({
    id: row.candidate_test_id,
    email_address: row.Email,
    reference: row.Reference || row.reference,
    status: row.Status,
    first_name: '',
    last_name: '',
    full_name: row.Name,
    invited_at: row['Invited On'] ? new Date(`${row['Invited On'].replace(/-/g, '/')} UTC`).toISOString() : null,
    submitted_at: row['Submitted On'] ? new Date(`${row['Submitted On'].replace(/-/g, '/')} UTC`).toISOString() : null,
    phone: '',
    hiring_status: '',
    candidate_test_information: [],
    information_fields: [],
    related_assessments: [],
    suspicious_activities: [],
    comments: [],
    tests: [],
    score: undefined,
    score_percent: row['Total Score'] ? row['Total Score'] : null,
  });
};
export const selectCandidate = (candidate: Candidate, isVerificationCode = false): void => {
  store.dispatch(getCandidateSuccess(candidate));
  store.dispatch(
    getCandidateDetails(
      isVerificationCode ? candidate.verification_code : candidate.reference,
      false,
      isVerificationCode,
    ),
  );
};
