import CheckIcon from '../../../images/icons/check-color.svg';
import PartialCheckIcon from '../../../images/icons/partial-check.svg';
import CrossIcon from '../../../images/icons/cross-color.svg';
import RequiresEvaluationIcon from '../../../images/icons/requires-evaluation-blue.svg';
import MinusIcon from '../../../images/icons/minus.svg';
import DefinitelyIcon from '../../../images/icons/definitely.svg';
import YesIcon from '../../../images/icons/thumbsup.svg';
import MaybeIcon from '../../../images/icons/maybe.svg';
import NoIcon from '../../../images/icons/thumbsdown.svg';

export const correctnessIconMap = {
  correct: CheckIcon,
  wrong: CrossIcon,
  bad: CrossIcon,
  ok: PartialCheckIcon,
  good: CheckIcon,
  evaluate: RequiresEvaluationIcon,
  unanswered: MinusIcon,
};

interface Correctness {
  key: string;
  label: string;
  icon: any;
}

export const getCorrectness = (
  score: number | null,
  maxScore: number,
  unanswered: boolean,
  type?: string,
): Correctness => {
  type = type || 'Response';
  if (!maxScore) {
    return null;
  }
  let key = '';
  let label = '';
  if (unanswered) {
    key = 'unanswered';
    label = 'Not Answered';
  } else if (score === null) {
    key = 'evaluate';
    label = 'Requires Evaluation';
  } else if (score >= maxScore) {
    key = 'correct';
    label = `Perfect ${type}`;
  } else if (score <= 0) {
    key = 'wrong';
    label = `Wrong ${type}`;
  } else if (score > 0 && score < maxScore * 0.4) {
    key = 'bad';
    label = `Bad ${type}`;
  } else if (score >= maxScore * 0.4 && score < maxScore * 0.7) {
    key = 'ok';
    label = `OK ${type}`;
  } else if (score >= maxScore * 0.7 && score < maxScore) {
    key = 'good';
    label = `Good ${type}`;
  }

  return { key, label, icon: key ? correctnessIconMap[key] : null };
};

export const ratingIconMap = {
  Definitely: DefinitelyIcon,
  Yes: YesIcon,
  Maybe: MaybeIcon,
  No: NoIcon,
};
