import React from 'react';
import './pill.scss';

interface Props {
  variant?: string;
  text?: string;
  onClick?: any;
}

// variant can be primary, orange
const FlatPill = ({ variant, text, onClick }: Props): JSX.Element => (
  <span role="button" tabIndex={0} onClick={onClick} className={`flat-pill ${variant}`}>
    {text}
  </span>
);

export default FlatPill;
