import { handleError } from 'handleError';
import store, { AppThunk } from '../store';
import { getTemplateJobTitleDetails, getTemplates } from '../../api/assessment.api';
import {
  getTemplatesStart,
  getTemplatesSuccess,
  getTemplatesFailure,
  setJobTitles,
  setLevels,
  setStages,
} from '../reducers/template';

export const fetchTemplates = (): AppThunk => {
  return async dispatch => {
    let { templates } = store.getState().template;
    const { loading } = store.getState().template;

    if (!templates) {
      const cachedTemplates = localStorage.getItem('@alooba/assessment-templates');
      const cachedAt = localStorage.getItem('@alooba/assessment-templates-timestamp');
      // Refresh the local cache if it's over a day old
      if (cachedTemplates && parseInt(cachedAt, 10) > Date.now() - 24 * 60 * 60 * 1000) {
        templates = JSON.parse(cachedTemplates);
        dispatch(getTemplatesSuccess(templates));
      }
    }

    if (loading) {
      return;
    }

    dispatch(getTemplatesStart());
    getTemplates()
      .then(res => {
        const templates = res && res.data && res.data.data;
        localStorage.setItem('@alooba/assessment-templates', JSON.stringify(templates));
        localStorage.setItem('@alooba/assessment-templates-timestamp', Date.now().toString());
        dispatch(getTemplatesSuccess(templates));
      })
      .catch(err => {
        handleError(Object.assign(err, { stack: new Error().stack }));
        dispatch(getTemplatesFailure(err.message));
      });
  };
};

export const fetchJobTitleDetails = (): AppThunk => {
  return async dispatch => {
    const { job_titles } = store.getState().template;
    if (job_titles) {
      return;
    }
    getTemplateJobTitleDetails()
      .then(res => {
        dispatch(setJobTitles(res.data.job_titles));
        dispatch(setLevels(res.data.levels));
        dispatch(setStages(res.data.stages));
      })
      .catch(err => {
        handleError(Object.assign(err, { stack: new Error().stack }));
      });
  };
};
