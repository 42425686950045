import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Shared/Icon/Chevron';
import Can from '../Permissions/Can';
import SubNavItem from './SubNavigationItem';
import { MenuItemType } from './Menu';
import { uncollapseMenu } from '../../store/reducers/layout';

export type NavigationItemProps = {
  item: MenuItemType;
  showMenu?: boolean;
  collapsed: boolean;
};

const NavigationItem: React.FC<NavigationItemProps> = ({ item, showMenu, collapsed }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [expandedSubNav, setExpandedSubNav] = useState(!collapsed);

  const toggleSubNavExpand = (e): void => {
    e.preventDefault();
    if (collapsed) {
      setExpandedSubNav(true);
      dispatch(uncollapseMenu());
    } else {
      setExpandedSubNav(!expandedSubNav);
    }
  };

  const handleClick = (e, menuItem: MenuItemType): void => {
    e.preventDefault();
    e.stopPropagation();
    if (menuItem.isLegacy) {
      window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}${menuItem.itemId}`;
    } else {
      history.push(menuItem.itemId);
    }
  };

  return (
    <Can
      role="admin"
      perform="dashboard:list"
      yes={() =>
        showMenu ? (
          <ul key={item.itemId} className="side-navigation-panel-select">
            <li className="side-navigation-panel-select-wrap">
              <Link
                to={item.itemId}
                onClick={(e): void => (item.menuSubItems ? toggleSubNavExpand(e) : handleClick(e, item))}
                className={`side-navigation-panel-select-option ${
                  item.itemId === location.pathname ? 'side-navigation-panel-select-inner-option-selected' : ''
                }`}
              >
                <span className="side-navigation-panel-select-option-wrap">
                  {item.icon && <item.icon />}
                  <span className="side-navigation-panel-select-option-text">{item.title}</span>
                  {item.menuSubItems &&
                    item.menuSubItems.length > 0 &&
                    (expandedSubNav ? <ChevronUpIcon /> : <ChevronDownIcon />)}
                </span>
              </Link>
            </li>

            {item.menuSubItems && expandedSubNav && !collapsed && (
              <ul className="side-navigation-panel-select-inner">
                {item.menuSubItems.map(subNavItem => (
                  <li key={subNavItem.itemId}>
                    <SubNavItem subNavItem={subNavItem} handleClick={handleClick} />
                  </li>
                ))}
              </ul>
            )}
          </ul>
        ) : (
          <></>
        )
      }
    />
  );
};

export default NavigationItem;
