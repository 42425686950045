import React, { useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { MenuItemType } from './Menu';

export type SubNavigationItemProps = {
  subNavItem: MenuItemType;
  handleClick: (e, menuItem: MenuItemType) => void;
};

const purposeMap = {
  'Alooba Assess': 'hiring',
  'Alooba Growth': 'ld',
  'Alooba Junior': 'junior',
};

const SubNavItem: React.FC<SubNavigationItemProps> = ({ subNavItem, handleClick }) => {
  const location = useLocation();
  const { selectedProducts } = useSelector((state: RootState) => state.assessmentCandidate);

  const { currentAssessment } = useSelector((state: RootState) => state.assessment);
  const isSubNavActive = useMemo(() => {
    if (location.pathname === '/search-candidates') {
      if (selectedProducts && selectedProducts.length === 1) {
        return subNavItem.itemId.includes(`?purpose=${purposeMap[selectedProducts[0]]}`);
      }
    }
    if (location.pathname.startsWith('/assessment/') && currentAssessment) {
      const { purpose } = currentAssessment;
      return (
        (subNavItem.itemId === '/alooba-assess' && purpose === 'hiring') ||
        (subNavItem.itemId === '/alooba-junior' && purpose === 'junior') ||
        (subNavItem.itemId === '/alooba-growth' && purpose === 'ld')
      );
    }
    return subNavItem.itemId === location.pathname || location.pathname.startsWith(subNavItem.itemId);
  }, [location.pathname, subNavItem.itemId, selectedProducts, currentAssessment]);

  if (subNavItem?.showMenu === false) return <></>;

  return (
    <div
      className={`side-navigation-panel-select-inner-wrap 
        ${isSubNavActive ? 'side-navigation-panel-select-inner-option-selected' : ''}
      `}
    >
      <Link
        to={subNavItem.itemId}
        onClick={(e): void => handleClick(e, subNavItem)}
        className="side-navigation-panel-select-inner-option"
      >
        <div className="point-container">
          <span
            className={`point ${isSubNavActive ? 'blue-point' : ''}
          `}
          />
        </div>
        {subNavItem.title}
      </Link>
    </div>
  );
};

export default SubNavItem;
