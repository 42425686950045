import TextSkeleton from 'components/Shared/TextSkeleton';
import React from 'react';
import { ScoreBucket } from 'store/reducers/candidate';
import CandidatePositionHistogram from './CandidatePositionHistogram';
import './OverviewTab.scss';

interface CandidatePositionProps {
  loading: { candidate: boolean };
  candidates_scores_buckets: ScoreBucket[];
  score_percent: number;
}
const CandidatePosition: React.FC<CandidatePositionProps> = ({ loading, candidates_scores_buckets, score_percent }) => {
  return (
    <div className="score-box">
      <div className="score-box-content overall-score">
        {candidates_scores_buckets?.length > 0 && <h3>Position Within Distribution</h3>}
        <div className="score">
          <h2>
            <TextSkeleton loading={loading.candidate} width="60px">
              {candidates_scores_buckets?.length > 0 ? (
                <CandidatePositionHistogram buckets={candidates_scores_buckets} score_percent={score_percent} />
              ) : undefined}
            </TextSkeleton>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default CandidatePosition;
