import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircleProgressBar from 'components/Shared/CircleProgressBar';
import { toggleCollapseSubject } from 'store/reducers/candidate';
import EvaluationIcon from 'images/icons/requires-evaluation-blue.svg';
import QuestionMarkIcon from 'images/icons/question-mark.svg';
import './QuestionSidebar.scss';
import ImageWithTooltip from 'components/Shared/Tooltip';
import Tooltip from 'components/Shared/Tooltip/LightTooltip';
import { NO_TEST_SCORE_AVAILABLE } from 'store/actions/candidate.actions';
import QuestionButton from './QuestionButton';

const LoadingSkeleton = (): any => (
  <>
    <br />
    <Skeleton width={180} height={20} />
    <br />
    <br />
    <Skeleton width={36} height={48} />
    &ensp;
    <Skeleton width={36} height={48} />
    &ensp;
    <Skeleton width={36} height={48} />
    &ensp;
    <Skeleton width={36} height={48} />
    &ensp;
    <Skeleton width={36} height={48} />
    &ensp;
    <Skeleton width={36} height={48} />
  </>
);

export default function QuestionSidebar({
  testId,
  questionLength,
  onQuestionSelected,
  selectedIndex,
  subjectsWithQuestions,
  questions,
  isInterview,
  loading,
}: {
  testId: number;
  questionLength: number;
  onQuestionSelected: (question: any) => void;
  selectedIndex: number;
  subjectsWithQuestions?: any[];
  questions?: any[];
  isInterview?: boolean;
  loading?: boolean;
}): any {
  const dispatch = useDispatch();
  const additionalCriteriaSubjectCount = useMemo(() => {
    if (!subjectsWithQuestions) {
      return 0;
    }
    return subjectsWithQuestions.filter(
      subject =>
        subject.questions &&
        subject.questions.length &&
        subject.questions[0].question_type?.question_type === 'Additional Criteria',
    ).length;
  }, [subjectsWithQuestions]);
  return (
    <div className="question-sidebar">
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          {!isInterview ? (
            <div className="title-section">
              QUESTIONS
              <span className="total-questions">{questionLength}</span>
            </div>
          ) : (
            <div className="title-section">INTERVIEW CONTENT</div>
          )}
          {subjectsWithQuestions &&
            subjectsWithQuestions.map((subject, index) => {
              const calculatedMaxScore = subject.questions.reduce((total, question) => {
                return total + parseInt(question.max_score, 10);
              }, 0);
              const scoredPercent = subject.scored_percent ? parseInt(subject.scored_percent, 10) : 0;
              return (
                <div key={`${subject.subject_id} ${subject.questions?.[0]?.position}`}>
                  {index === subjectsWithQuestions.length - additionalCriteriaSubjectCount - 1 &&
                    additionalCriteriaSubjectCount > 0 && <div className="title-section">ADDITIONAL CRITERIA</div>}
                  <div className="subject-section-wrapper">
                    <div className="category-section">
                      <span>{subject.subject}</span>
                      <span className="line" />
                      {scoredPercent > 0 && calculatedMaxScore !== subject.max_score ? (
                        <ImageWithTooltip
                          icon={QuestionMarkIcon}
                          tooltip={`You might notice the percentage score for this skill doesn’t add up to the sum of each question’s score. That’s because some questions cover multiple skills and there was at least one question in another skill that also covers ${subject.subject} which was included in the calculation of this score.`}
                        />
                      ) : (
                        ''
                      )}
                      {subject.max_score > 0 && (
                        <CircleProgressBar strokeWidth={5} size={48} percent={scoredPercent}>
                          <div className="scored-percent">
                            {subject.score === NO_TEST_SCORE_AVAILABLE ? (
                              <Tooltip title="Requires Evaluation" placement="top" arrow>
                                <img src={EvaluationIcon} alt="Requires evaluation icon" />
                              </Tooltip>
                            ) : (
                              `${scoredPercent}%`
                            )}
                          </div>
                        </CircleProgressBar>
                      )}
                      <FontAwesomeIcon
                        className="icon subject-collapse"
                        icon={subject.collapsed ? faAngleDown : faAngleUp}
                        onClick={() =>
                          dispatch(
                            toggleCollapseSubject({
                              testId,
                              subjectIndex: index,
                            }),
                          )
                        }
                      />{' '}
                    </div>
                    <div className={`questions-list ${subject.collapsed ? 'hidden' : ''}`}>
                      {subject.questions &&
                        subject.questions.map(question => (
                          <QuestionButton
                            question={questions[question.position - 1]}
                            onQuestionSelected={onQuestionSelected}
                            selected={question.position - 1 === selectedIndex}
                            isInterview={isInterview}
                            key={`s-question-${question.position}`}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
}
