import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import useHover from 'hooks/useHover';
import ConditionalWrapper from 'components/Shared/ConditionalWrapper';
import Input from 'components/Shared/Input';
import Fade from 'components/Shared/Fade';
import Tooltip from 'components/Shared/Tooltip';
import PencilIcon from '../../../../images/icons/pencil-blue.svg';
import './EditableNumber.scss';

interface Props {
  name?: string;
  tooltip?: string;
  prefixIcon?: JSX.Element;
  suffixText?: string;
  minValue: number;
  maxValue: number;
  defaultValue?: number;
  initialValue: number;
  onSave: (newValue: number) => Promise<boolean>;
  onErrorMsg?: (msg: string) => void;
}

const EditableNumber: React.FC<Props> = ({
  name,
  tooltip,
  prefixIcon,
  suffixText,
  minValue,
  maxValue,
  defaultValue,
  initialValue,
  onSave: onValueChange,
  onErrorMsg,
}) => {
  const [value, setValue] = useState(initialValue);
  const [lastSavedValue, setLastSavedValue] = useState(initialValue);
  const [hoverRef, isHovering] = useHover();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sendError = (msg: string): void => {
    if (onErrorMsg) {
      onErrorMsg(msg);
    }
  };

  const enterEditMode = (): void => {
    setEditMode(true);
  };
  const exitEditMode = (): void => {
    setEditMode(false);
  };

  const saveValue = async (): Promise<void> => {
    exitEditMode();
    if (value === lastSavedValue) {
      return;
    }
    if (value === null) {
      sendError(`${name ?? 'Value'} cannot be empty`);
      setValue(lastSavedValue);
      return;
    }
    if (value < minValue || value > maxValue) {
      sendError(`${name ?? 'Value'} must be between ${minValue} and ${maxValue}`);
      setValue(lastSavedValue);
      return;
    }
    setIsLoading(true);
    const success = await onValueChange(value);
    setIsLoading(false);
    if (success) {
      setLastSavedValue(value);
    } else {
      setValue(lastSavedValue);
    }
  };

  const handleEdit = (): void => {
    enterEditMode();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const duration = value === '' ? null : Number(value);
    setValue(duration);
  };

  const handleKey = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      saveValue();
    } else if (event.key === 'Escape') {
      exitEditMode();
      setValue(lastSavedValue);
    }
  };

  return (
    <div className="editable-number-outer-container">
      <div onClick={handleEdit} ref={hoverRef} role="button" tabIndex={0}>
        <ConditionalWrapper
          condition={Boolean(tooltip) && !editMode}
          wrapper={children => <Tooltip tooltip={tooltip}>{children}</Tooltip>}
        >
          <div className="editable-number">
            {prefixIcon}
            {editMode ? (
              <>
                <Input
                  autoFocus
                  withoutTopMargin
                  type="number"
                  inputMode="numeric"
                  value={value?.toString() ?? ''}
                  min={minValue}
                  max={maxValue}
                  placeholder={defaultValue?.toString()}
                  onChange={handleChange}
                  onBlur={saveValue}
                  onKeyDown={handleKey}
                />
                {Boolean(defaultValue) && <div className="default-value">{`default: ${defaultValue}`}</div>}
              </>
            ) : (
              <>
                <span className="value">{value}</span>
                {suffixText && (
                  <>
                    <span>&nbsp;</span>
                    <span>{suffixText}</span>
                  </>
                )}
                {!editMode &&
                  (isLoading ? (
                    <FontAwesomeIcon className="fa-spin loading-icon" width={10} icon={faSpinner} />
                  ) : (
                    <Fade fadeIn={isHovering}>
                      <img className="edit-icon" src={PencilIcon} alt="edit" />
                    </Fade>
                  ))}
              </>
            )}
          </div>
        </ConditionalWrapper>
      </div>
    </div>
  );
};

export default EditableNumber;
