import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getSubscriptionPlans } from '../../api/subscription.api';
import './SubscriptionPricingTable.scss';
import SubscriptionPlanOption from './SubscriptionPlanOption';

interface Props {
  parentLoading?: boolean;
  eligibleForTrial?: boolean;
}

export const FREE_TRIAL_PLAN = 'Professional';

const SubscriptionPricingTable: React.FC<Props> = ({ parentLoading, eligibleForTrial }: Props) => {
  const [loading, setLoading] = useState(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [currency, setCurrency] = useState('USD');

  const updateSubscriptionPlans = (plans): void => {
    setSubscriptionPlans(
      plans.map(plan => {
        if (!plan.prices[currency] || plan.prices[currency].length === 0) return plan;
        const fromPrice = plan.prices[currency].reduce((prev, current) => {
          if (!prev.monthly_price) return current;
          return prev.monthly_price < current.monthly_price ? prev : current;
        })?.monthly_price;
        return { ...plan, fromPrice };
      }),
    );
  };

  useEffect(() => {
    getSubscriptionPlans().then(data => {
      updateSubscriptionPlans(data.data.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // We only want this to run once

  useEffect(() => {
    if (subscriptionPlans.length > 0) {
      updateSubscriptionPlans(subscriptionPlans);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]); // We only want this to run when the currency changes to avoid an infinite loop

  const handleCurrencyChange = (newCurrency: string): void => {
    setCurrency(newCurrency);
  };

  const loadingSkeleton = (
    <div className="pricing-table">
      <Skeleton className="skeleton plan" height={200} />
      <Skeleton className="skeleton plan" height={200} />
      <Skeleton className="skeleton plan" height={200} />
    </div>
  );

  return (
    <>
      {parentLoading ? (
        loadingSkeleton
      ) : (
        <>
          {loading && subscriptionPlans.length === 0 && loadingSkeleton}
          {!loading && subscriptionPlans.length > 0 && (
            <div className="pricing-table">
              {subscriptionPlans.map(plan => (
                <SubscriptionPlanOption
                  key={plan.id}
                  plan={plan}
                  currency={currency}
                  setCurrency={handleCurrencyChange}
                  canUseTrial={eligibleForTrial && plan.name === FREE_TRIAL_PLAN}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SubscriptionPricingTable;
