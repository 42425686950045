import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Pill, Input } from '../Shared';

const GroupPlaceholder = (): JSX.Element => {
  return (
    <>
      {[1, 2, 3].map(group => (
        <div key={group} className="member-form">
          <div className="member-form-content">
            <Input loading />
            <div className="members">
              <label htmlFor="member">
                <Skeleton height={12} width={100} />
              </label>
              <div className="add-members mt1">
                {[1, 2, 3].map(member => (
                  <span key={member} className="mt2 ml2">
                    <Pill loading />
                  </span>
                ))}
                <div className="members_dropdown mt2 ml2">
                  <Skeleton height={30} width={150} />
                </div>
              </div>
            </div>
          </div>
          <div className="delete">
            <Skeleton height={20} width={12} />
          </div>
        </div>
      ))}
    </>
  );
};

export default GroupPlaceholder;
