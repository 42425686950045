import React, { useRef, useState } from 'react';
import useWindowSize from 'hooks/useWindowSize';
import GraphicPlaceholder from '../GraphicPlaceholder';
import PieSlice from './slice';
import PieLabel from './label';

const PieChart: React.FC<{
  data: Record<string, any>[];
  height?: number;
}> = ({ data, height }): JSX.Element => {
  const [width, setWidth] = useState(0);
  const [showDetailedLabels, setShowDetailedLabels] = useState(false);
  const [translationX, setTranslationX] = useState(140);
  const [translationY, setTranslationY] = useState(122);
  const [pieRadius, setPieRadius] = useState(0);
  const [activeSlice, setActiveSlice] = useState(null);
  const [allowTouchEvents, setAllowTouchEvents] = useState(false);
  const pie_main_hood = useRef(null);

  useWindowSize(() => {
    if (pie_main_hood.current) {
      const w = Math.floor(pie_main_hood.current.offsetWidth);
      let d = Math.min(w * 0.5, height * 0.9);
      setTranslationX(w / 4);
      setTranslationY(height / 2);
      setShowDetailedLabels(false);

      if (w <= 700) {
        setTranslationX(w / 4 + 2); // add 2 pixels to account for the border on hover
        setTranslationY(height / 2);
        setShowDetailedLabels(true);
      }

      // if the parent width is too small to display the labels
      // hide the labels, the user will be able to touch the slices
      // to view the details anyways
      if (w <= 400) {
        d = Math.floor(pie_main_hood.current.offsetWidth * 0.95);
        setTranslationX(w / 2);
        setTranslationY(height / 3);
        setShowDetailedLabels(false);
      }

      setWidth(w);
      setPieRadius(d / 2);
      if (w <= 550) {
        setAllowTouchEvents(true);
      } else {
        setAllowTouchEvents(false);
      }
    }
  });

  if (!data) {
    return null;
  }

  if (!data || data.length === 0) {
    return (
      <div
        data-testid="graphic-placeholder"
        style={{
          height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <GraphicPlaceholder variant="pie" />
        </div>
      </div>
    );
  }

  const colorPool = ['#E85252', '#F88C42', '#35485A', '#ECF1F4', '#F3CA55', '#004283'];

  const getCoordinatesForPercent = (percent): Record<string, number> => {
    const a = 2 * Math.PI * percent;
    const x = Math.cos(a) * pieRadius;
    const y = Math.sin(a) * pieRadius;
    return { x, y, a };
  };

  const total = data.reduce((sum: number, current) => sum + current.value, 0);

  let accumulatedValue = 0;
  const otherSlice = {
    label: 'Other',
    value: 0,
  };
  const slices = data
    .sort((slice, nextSlice) => {
      if (slice.value < nextSlice.value) {
        return 1;
      }
      if (slice.value > nextSlice.value) {
        return -1;
      }
      return 0;
    })
    .map((slice, i) => {
      const maxSlices = colorPool.length - 2;
      if (i > maxSlices && data.length > maxSlices) {
        otherSlice.value += slice.value;
        return i === data.length - 1 ? otherSlice : null;
      }
      return slice;
    })
    .filter(slice => slice !== null)
    .map((slice, i, arr) => {
      // the starting point of each slice is the ending point of the previous slice
      // the ending point  is the point relative to the value
      const next = arr[i + 1];
      if (next) {
        slice.start = getCoordinatesForPercent(accumulatedValue / total);
        slice.end = getCoordinatesForPercent((accumulatedValue + slice.value) / total);
      } else {
        // last slice has a different rule: ending point must close the circle
        slice.start = getCoordinatesForPercent(accumulatedValue / total);
        slice.end = getCoordinatesForPercent(1);
      }

      if (i === 0) {
        slice.start = getCoordinatesForPercent(0);
        slice.end = getCoordinatesForPercent(slice.value / total);
      }

      accumulatedValue += slice.value;
      slice.color = colorPool.pop();
      slice.id = `slice-${slice.label}-${slice.color}-${slice.value}-${accumulatedValue}`
        .replace('#', '')
        .replace(' ', '-');

      slice.percentage = ((slice.value / total) * 100).toFixed(1).replace(/[.,]0$/, '');
      slice.description = `${slice.label}\n${slice.value} (${slice.percentage} %)`;
      return slice;
    });

  const getCoordinatesForLabel = (
    i: number,
    arrLength: number,
    svgWidth: number,
    r: number,
  ): Record<string, number> => {
    let x = svgWidth * 0.05 + r;
    if (r * 2 < svgWidth * 0.5) {
      x = svgWidth * 0.05 + r;
    }
    const y = 30 * (i - Math.round(0.5 * arrLength));
    return { x, y };
  };

  const handleFocusSlice = (id: string): void => {
    setActiveSlice(`#${id}`);
  };

  const handleClickEvent = (id: string): void => {
    if (allowTouchEvents) {
      handleFocusSlice(id);
    }
  };

  const handleUnfocusSlice = (): void => {
    if (!allowTouchEvents) {
      setActiveSlice(null);
    }
  };

  const handleTouchEnd = (): void => {
    setActiveSlice(null);
  };

  return (
    <div ref={pie_main_hood}>
      <svg width={width} height={height} data-testid="piechart">
        <g transform={`translate(${translationX}, ${translationY})`}>
          {slices.map(
            ({ value, percentage, label, start, end, color, id, description }, i, arr): JSX.Element => {
              const pieLabel = getCoordinatesForLabel(i, arr.length, width, pieRadius);
              return (
                <React.Fragment key={`fragment-${id}`}>
                  <rect key={`rect-${id}`} x={pieLabel.x} y={pieLabel.y} width={20} height={20} fill={color} />
                  <PieLabel
                    key={`label-${id}`}
                    value={value}
                    percentage={percentage}
                    text={label}
                    sliceId={id}
                    onMouseOver={handleFocusSlice}
                    onTouchStart={handleClickEvent}
                    onMouseLeave={handleUnfocusSlice}
                    x={pieLabel.x}
                    y={pieLabel.y}
                    showFullLabel={showDetailedLabels}
                  />
                  <PieSlice
                    isActive={activeSlice === `#${id}`}
                    key={`slice-${id}`}
                    sliceId={id}
                    description={description}
                    onMouseOver={handleFocusSlice}
                    onTouchStart={handleClickEvent}
                    startPoint={start}
                    endPoint={end}
                    r={pieRadius}
                    color={color}
                  />
                </React.Fragment>
              );
            },
          )}
          <use
            data-testid="active-slice"
            href={activeSlice}
            onMouseLeave={handleUnfocusSlice}
            onTouchEnd={handleTouchEnd}
            stroke="#000000"
            strokeOpacity={0.7}
            strokeWidth={2}
          />
        </g>
      </svg>
    </div>
  );
};

export default PieChart;
