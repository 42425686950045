import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import crypto from 'crypto';
import './dashboard.scss';
import { Button } from 'components/Shared';
import { convertToTemplate, deleteAssessment, duplicateAssessment, updateAssessmentData } from 'api/assessment.api';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { AssessmentPurpose } from 'components/Assessment';
import DeleteModal from 'components/Shared/DeleteModal';
import InviteCandidateModal from 'components/Shared/Modals/InviteCandidateModal/InviteCandidateModal';
import { Modal } from 'components/Shared/Modal';
import { getOrgGroups } from 'store/actions/profile.actions';
import { appendOrgAssessments, getOrgAssessments } from 'store/actions/assessment.actions';
import { getAssessmentsSuccess, setHasFiltered } from 'store/reducers/assessment';
import { RootState } from 'store/rootReducer';
import PlanUpgradeModal from 'components/Shared/PlanUpgradeModal';
import { isLimitedOrganization } from 'helpers/user';
import OrganizationLimitedModal from 'components/Shared/OrganizationLimitedModal';
import { goToLegacyApp } from 'hooks';
import AssessmentInfiniteScroll from './assessmentInfiniteScroll';
import authorize, { hasOrgFeature } from '../../Authorization/Check';
import { debounce } from '../../helpers/events';
import Filters from './Filters';

interface AssessmentDashboardProps {
  assessmentPurpose: AssessmentPurpose;
}

export const TEST_NAMES_TO_FEATURES = {
  MCQ: 'concepts_test',
  'Data Analysis': 'data_analysis_test',
  SQL: 'sql_test',
  'Free Response': 'free_response_test',
  'Personality Profiling': 'personality_test',
  'Analytics Coding': 'coding_test',
};

const Assessments: React.FC<AssessmentDashboardProps> = ({ assessmentPurpose }: AssessmentDashboardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [, setHasAssessmentsChanged] = useState<any>([]);
  const [currentAssessment, setCurrentAssessment] = useState<any>({});
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [showOrganizationLimitedModal, setShowOrganizationLimitedModal] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const assessmentWord = assessmentPurpose === 'ld' ? 'exercise' : 'assessment';
  const testWord = assessmentPurpose === 'ld' ? 'exercise' : 'test';

  const previewCode = localStorage.getItem('preview_code');
  const suggestionId = localStorage.getItem('suggestion_id');
  if (previewCode || suggestionId) {
    const url = new URL(window.location.href);
    window.history.replaceState({}, document.title, url.pathname);
    history.push(`/alooba-assess/create-assessment`);
  }
  const perPage = 14;
  const { addToast } = useToasts();

  const { groups, groupsLoading } = useSelector((state: RootState) => state.organization);

  const userDetails = useSelector((state: RootState) => state.profile.userDetails);

  const { assessments, loading, filtering, hasFiltered, loadingMore, totalAssessments } = useSelector(
    (state: RootState) => state.assessment[assessmentPurpose],
  );

  useEffect(() => {
    if (!userDetails.recruiter_detail?.user_id) {
      return;
    }
    dispatch(getOrgGroups(userDetails.id));
  }, [dispatch, userDetails.id, userDetails.recruiter_detail?.user_id]);

  const filters = useMemo(() => {
    const groupsArr = [{ id: 'null', name: 'Anyone' }];
    if (!groupsLoading && groups !== null) {
      const sortedGroups = Object.entries(groups).sort((a, b) => {
        const nameA = a[1] as string;
        const nameB = b[1] as string;
        return nameA.localeCompare(nameB);
      });

      sortedGroups.forEach(item => {
        if (item[0] !== 'null') {
          groupsArr.push({ id: item[0], name: item[1] as string });
        }
      });
    }

    return [
      {
        label: 'Assessment or Role Name',
        placeholder: 'Search Assessments',
        name: 'searchTerm',
        type: 'textFilter',
        category: 'main',
        canHide: false,
        visible: true,
      },
      {
        label: 'Group',
        name: 'groups',
        type: 'MultiSelectFilter',
        category: 'main',
        multiple: true,
        options: groupsArr,
        defaultValues: [],
        optionKey: 'id',
        optionLabel: 'name',
        optionsMap: {
          null: 'Anyone',
          ...groups,
        },
        defaultToNull: true,
        canHide: false,
        visible: true,
      },
      {
        label: 'Status',
        name: 'status',
        type: 'MultiSelectFilter',
        category: 'main',
        multiple: false,
        options: ['Current', 'Unpublished', 'All'],
        defaultValues: ['Current'],
        canHide: false,
        visible: true,
      },
    ];
  }, [groups, groupsLoading]);

  const [filterValues, setFilterValues] = useState({
    searchTerm: null,
    groups: [],
    status: 'Current',
  });

  const onFilterChange = (data): void => {
    const newFilterValues = {
      ...filterValues,
      ...data,
    };
    setPage(2);
    setHasFiltered(true);
    dispatch(setHasFiltered({ purpose: assessmentPurpose, value: true }));
    setFilterValues(newFilterValues);
  };

  const handleDeleteModal = (e, assessment): void => {
    e.preventDefault();
    setShowDeleteModal(true);
    setCurrentAssessment(assessment);
  };

  const handleArchiveModal = (e, assessment): void => {
    if (assessment.is_archived && isLimitedOrganization()) {
      setShowOrganizationLimitedModal(true);
      return;
    }
    let canUnarchiveAssessment = true;
    assessment.product?.parts?.forEach(part => {
      if (!hasOrgFeature(TEST_NAMES_TO_FEATURES[part.type?.test_type])) {
        canUnarchiveAssessment = false;
      }
    });
    if (assessment.is_archived && !canUnarchiveAssessment) {
      setShowUpgradeModal(true);
      return;
    }
    e.preventDefault();
    setShowArchiveModal(true);
    setCurrentAssessment(assessment);
  };

  const handleInviteModal = (e, assessment): void => {
    e.preventDefault();
    if (isLimitedOrganization()) {
      setShowOrganizationLimitedModal(true);
      return;
    }
    setCurrentAssessment(assessment);
    setShowInviteModal(true);
  };

  const setAssessmentsWithTransition = (updatedAssessments, height, newTotal): any => {
    // set div height to px or height transitions dont work
    document.documentElement.style.setProperty('--assessment-card-height', `${height}px`);
    const firstPageHash = crypto
      .createHash('sha256')
      .update(JSON.stringify(updatedAssessments.map(a => a.id + a.is_archived)))
      .digest('hex');
    dispatch(
      getAssessmentsSuccess({
        response: {
          data: updatedAssessments,
          total: newTotal,
        },
        firstPageHash,
        purpose: assessmentPurpose,
      }),
    );
  };
  useEffect(() => {
    // set it back to auto once state finishes updating and transition completes
    document.documentElement.style.setProperty('--assessment-card-height', 'auto');
  }, [setHasAssessmentsChanged]);

  const handleDelete = async (): Promise<void> => {
    const restAssessments = assessments.filter(test => test.id !== currentAssessment.id);
    setDeleting(true);
    try {
      await deleteAssessment(currentAssessment.id);

      setShowDeleteModal(false);
      setDeleting(false);
      addToast({
        type: 'success',
        msg: `Assessment with the name ${currentAssessment.recruiter_test_name} has been deleted!`,
      });
      const assessmentCardHeight = document.getElementById(currentAssessment.id).clientHeight;
      setAssessmentsWithTransition(restAssessments, assessmentCardHeight, totalAssessments - 1);
      setHasAssessmentsChanged(Math.random().toString(36).substring(7));
    } catch (error) {
      setShowDeleteModal(false);
      setDeleting(false);
      addToast({
        type: 'error',
        msg: `Error deleting assessment with the name ${currentAssessment.recruiter_test_name}.`,
      });
    }
  };

  const handleArchive = async (): Promise<void> => {
    const assessmentName = assessmentPurpose === 'ld' ? 'Exercise' : 'Assessment';
    const actionName = currentAssessment.is_archived ? 'republish' : 'unpublish';
    setArchiving(true);
    updateAssessmentData({
      id: currentAssessment.id,
      is_archived: !currentAssessment.is_archived,
    })
      .then(() => {
        addToast({
          type: 'success',
          msg: `${assessmentName} with the name ${currentAssessment.recruiter_test_name} has been ${actionName}ed!`,
        });
        let restAssessments = assessments.map(test => {
          if (test.id === currentAssessment.id) {
            return { ...test, is_archived: !test.is_archived };
          }
          return test;
        });
        if (filterValues.status !== 'All') {
          restAssessments = assessments.filter(test => test.id !== currentAssessment.id);
        }
        setAssessmentsWithTransition(
          restAssessments,
          document.getElementById(currentAssessment.id).clientHeight,
          restAssessments.length,
        );
        setHasAssessmentsChanged(Math.random().toString(36).substring(7));
      })
      .catch(() => {
        addToast({
          type: 'error',
          msg: `Error ${actionName}ing ${assessmentName.toLowerCase()} with the name
          ${currentAssessment.recruiter_test_name}.`,
        });
      })
      .finally(() => {
        setShowArchiveModal(false);
        setArchiving(false);
      });
  };

  const handleDuplicate = async (e, assessment): Promise<void> => {
    e.preventDefault();
    if (isLimitedOrganization() && assessment.is_published && !assessment.is_archived) {
      setShowOrganizationLimitedModal(true);
      return;
    }
    addToast({
      type: 'success',
      msg: `Duplicating ${assessment.recruiter_test_name}`,
    });
    try {
      const response = await duplicateAssessment(assessment.id);

      const newAssessment = { ...assessment };
      newAssessment.id = +response.data.assessment.id;
      newAssessment.recruiter_test_name = `${assessment.recruiter_test_name} (Copy)`;
      newAssessment.candidates_count = 0;
      newAssessment.completed_candidates_count = 0;
      newAssessment.public_url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/take-assessment/${response.data.assessment.public_url_slug}`;
      newAssessment.public_url_slug = response.data.assessment.public_url_slug;
      newAssessment.average_score = undefined;
      newAssessment.evaluation_candidates_count = 0;
      newAssessment.creator_first_name = userDetails.first_name;
      newAssessment.creator_last_name = userDetails.last_name;
      newAssessment.email = userDetails.email;

      const resAssessments = [newAssessment, ...assessments];

      if (totalAssessments + 1 > assessments.length) {
        resAssessments.pop();
      }

      const assessmentCardHeight = document.getElementById(assessments[0].id).clientHeight;
      setAssessmentsWithTransition(resAssessments, assessmentCardHeight, totalAssessments + 1);
      setHasAssessmentsChanged(Math.random().toString(36).substring(7));
      addToast({
        type: 'success',
        msg: `Copy of ${assessment.recruiter_test_name} created`,
      });
    } catch (error) {
      if (error?.response?.data?.message) {
        addToast({
          type: 'error',
          msg: error.response.data.message,
        });
      } else {
        addToast({
          type: 'error',
          msg: `Error duplicating assessment with name ${assessment.recruiter_test_name}.`,
        });
      }
    }
  };

  const handleConvertToTemplate = async (e, assessment): Promise<void> => {
    e.preventDefault();
    addToast({
      type: 'success',
      msg: `Converting ${assessment.recruiter_test_name} to template...`,
    });
    try {
      await convertToTemplate(assessment.id);
      const restAssessments = assessments.filter(test => test.id !== assessment.id);

      addToast({
        type: 'success',
        msg: `Assessment with the name ${assessment.recruiter_test_name} has been converted to a template!`,
      });
      const assessmentCardHeight = document.getElementById(assessment.id).clientHeight;
      setAssessmentsWithTransition(restAssessments, assessmentCardHeight, totalAssessments - 1);
      setHasAssessmentsChanged(Math.random().toString(36).substring(7));
    } catch (error) {
      if (error?.response?.data?.message) {
        addToast({
          type: 'error',
          msg: error.response.data.message,
        });
      } else {
        addToast({
          type: 'error',
          msg: `Error converting assessment with name ${assessment.recruiter_test_name}.`,
        });
      }
    }
  };

  // This needs to be disabled, since having the [page] dependency
  // like the linter wants would cause an infinite rerender loop
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    (async () => {
      setHasFiltered(false);
      dispatch(
        getOrgAssessments(
          {
            perPage,
            page,
            searchTerm: null,
            groups: [],
            status: 'Current',
          },
          assessmentPurpose,
        ),
      );
      setPage(s => s + 1);
    })();
    return () => {
      dispatch(setHasFiltered({ purpose: assessmentPurpose, value: false }));
    };
  }, []);

  useEffect(() => {
    if (hasFiltered) {
      (async () => {
        dispatch(
          getOrgAssessments(
            {
              perPage,
              page: 1,
              searchTerm: filterValues.searchTerm,
              groups: filterValues.groups,
              status: filterValues.status,
            },
            assessmentPurpose,
          ),
        );
      })();
    }
  }, [filterValues]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const goToFirstCreateAssessmentPage = (e, assessmentPurpose: AssessmentPurpose): void => {
    if (assessmentPurpose === 'ld') {
      goToLegacyApp(e, `create-assessment-customized-content?purpose=${assessmentPurpose}`);
    } else if (assessmentPurpose === 'junior') {
      history.push(`/alooba-junior/create-assessment`);
    } else {
      history.push(`/alooba-assess/create-assessment`);
    }
  };

  const observableRef = useRef(null);

  useEffect(() => {
    const currentRef = observableRef.current;
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (assessments && entry.isIntersecting && totalAssessments > assessments.length && !loadingMore) {
          dispatch(
            appendOrgAssessments(
              {
                perPage,
                page,
                searchTerm: filterValues.searchTerm,
                groups: filterValues.groups,
                status: filterValues.status,
              },
              assessmentPurpose,
            ),
          );
          setPage(s => s + 1);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      },
    );
    if (currentRef) observer.observe(currentRef);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [
    loadingMore,
    page,
    assessments,
    totalAssessments,
    assessmentPurpose,
    filterValues.searchTerm,
    filterValues.groups,
    filterValues.status,
    dispatch,
    observableRef,
  ]);

  const deleteModalBody = (
    <>
      <p>{`Are you sure you want to delete the assessment called "${currentAssessment.recruiter_test_name}"?`}</p>
      <p>All associated {assessmentPurpose === 'ld' ? 'employees' : 'candidates'} will also be removed.</p>
      <p>This action is not reversible.</p>
    </>
  );
  const deleteModalProps = {
    loadingTxt: 'Deleting Assessment...',
    loading: deleting,
    isShown: showDeleteModal,
    actionText: 'Delete Assessment',
    handleButtonAction: handleDelete,
    setModalVisibility: setShowDeleteModal,
    title: 'Delete Assessment',
    body: deleteModalBody,
    showCancel: true,
  };
  const assessmentName = assessmentPurpose === 'ld' ? 'Exercise' : 'Assessment';
  const candidateName = assessmentPurpose === 'ld' ? 'employee' : 'candidate';
  const assessmentNameLower = assessmentName.toLowerCase();
  const actionName = currentAssessment.is_archived ? 'Republish' : 'Unpublish';
  const archiveModalBody = !currentAssessment.is_archived ? (
    <>
      <b>Are you sure you want to unpublish this {assessmentNameLower}?</b>
      <br />
      You are about to unpublish the {assessmentNameLower}. This means that you will no longer be able to invite{' '}
      {candidateName}s, and any {candidateName}s who have already been invited will no longer be able to take the{` `}
      {assessmentNameLower}. <br />
      Unpublishing an {assessmentNameLower} does not delete it or any of the associated data, including {candidateName}{' '}
      results. By default this{` `}
      {assessmentNameLower} will no longer be visible on this dashboard to you
      <b> and other users</b>, however, you can find unpublished{` `}
      {assessmentNameLower}s by changing the Status filter.
    </>
  ) : (
    <>Are you sure you want to republish this {assessmentNameLower}?</>
  );
  const archiveModalProps = {
    loadingTxt: `${actionName}ing ${assessmentName}...`,
    loading: archiving,
    isShown: showArchiveModal,
    actionText: `${actionName} ${assessmentName}`,
    handleButtonAction: handleArchive,
    setModalVisibility: setShowArchiveModal,
    showCancel: true,
  };
  return (
    <div id="dashboard">
      <div className="heading">
        <h2 className="mb4">
          <div>
            <div>{assessmentPurpose === 'hiring' && 'Alooba Assess'}</div>
            <div>{assessmentPurpose === 'junior' && 'Alooba Junior'}</div>
            <div>{assessmentPurpose === 'ld' && 'Alooba Growth'}</div>
            <div>
              {assessmentPurpose === 'hiring' && (
                <span className="assessment-description">
                  Assess the relevant skills of your candidates to identify the best candidates quickly and easily.
                </span>
              )}
            </div>
            <div>
              {assessmentPurpose === 'ld' && (
                <span className="assessment-description">
                  Assess the skill level of your existing team to identify areas for improvement.
                </span>
              )}
            </div>
            <div>
              {assessmentPurpose === 'junior' && (
                <span className="assessment-description">
                  Future-proof your workforce with graduate & intern data literacy assessments.
                </span>
              )}
            </div>
          </div>
        </h2>
        {authorize('assessment', 'add') ? (
          <Button
            addButton
            onClick={e => goToFirstCreateAssessmentPage(e, assessmentPurpose)}
            variant="primary md"
            text={`Create ${assessmentPurpose === 'ld' ? 'Exercise' : 'Assessment'}`}
          />
        ) : (
          ''
        )}
      </div>
      <Filters
        filters={filters}
        onFilterChange={debounce(onFilterChange, 400)}
        loading={loading}
        filtering={filtering}
      />
      <AssessmentInfiniteScroll
        {...{
          loading,
          assessments,
          loadingMore,
          showDeleteModal: handleDeleteModal,
          showArchiveModal: handleArchiveModal,
          handleDuplicate,
          handleConvertToTemplate,
          assessmentPurpose,
          hasFiltered,
          handleInviteModal,
          goToFirstCreateAssessmentPage,
        }}
      />
      <div className="next-page-monitor" ref={observableRef} />
      <DeleteModal {...deleteModalProps} />
      <InviteCandidateModal
        userDetails={userDetails}
        assessment={currentAssessment}
        defaultDaysToComplete={currentAssessment.days_to_complete}
        setModalVisibility={setShowInviteModal}
        purpose={assessmentPurpose}
        isShown={showInviteModal}
      />
      <Modal {...archiveModalProps}>
        <div>
          <h2>{`${actionName} ${assessmentName}`}</h2>
          <br />
          {archiveModalBody}
          <hr />
        </div>
      </Modal>
      <PlanUpgradeModal
        isShown={showUpgradeModal}
        setModalVisibility={setShowUpgradeModal}
        title={`Upgrade your account to re-publish this ${assessmentWord}`}
      >
        <p>
          This {assessmentWord} includes {testWord}s that are not included on your current subscription plan. <br />
          Edit your {assessmentWord} or upgrade your subscription to have access to all {testWord} types and many other
          amazing features Alooba has to offer.
        </p>
      </PlanUpgradeModal>
      <OrganizationLimitedModal
        isShown={showOrganizationLimitedModal}
        setModalVisibility={setShowOrganizationLimitedModal}
        title="Account Limited"
      />
    </div>
  );
};

export default Assessments;
