import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AdditionalCreditPackage } from 'api/contract';
import { purchaseAdditionalCredits } from 'api/subscription.api';
import { Modal } from 'components/Shared/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { handleError } from 'handleError';

interface PurchaseCreditsModalProps {
  bundle: AdditionalCreditPackage;
  setModalVisibility: any;
}

const PurchaseCreditsModal = (props: PurchaseCreditsModalProps): JSX.Element => {
  const { bundle } = props;
  const [purchasing, setPurchasing] = useState<boolean>(false);
  const { addToast } = useToasts();
  const hasPaymentMethod = useSelector((state: RootState) => state.subscription?.hasPaymentMethod);
  const last4Digits = useSelector((state: RootState) => state.subscription?.card_last_4_digits);
  const billingEmailAddress = useSelector((state: RootState) => state.subscription?.billing_email_address);
  const handleButtonAction = async (e: React.SyntheticEvent<EventTarget>): Promise<any> => {
    e.stopPropagation();
    e.preventDefault();
    setPurchasing(true);
    const errorToast = (msg: string): void => {
      addToast({
        type: 'error',
        msg,
      });
    };
    try {
      const response = await purchaseAdditionalCredits(bundle.price_id);
      if (response.data.error) {
        errorToast(response.data.message);
      } else {
        addToast({
          type: 'success',
          msg: 'Your credit purchase request has been processed.',
        });
      }
    } catch (e) {
      errorToast('Unable to purchase credits');
      handleError(e);
    }
    setPurchasing(false);
    props.setModalVisibility(false);
  };
  const customProps = {
    loading: purchasing,
    actionText: 'Buy Now',
    showCancel: true,
    cancelVariant: 'sub-primary',
    handleButtonAction,
    containerClass: 'confirm-purchase-modal',
    loadingTxt: hasPaymentMethod ? 'Purchasing credits...' : 'Processing...',
  };

  const userLocale = navigator.language || 'en-US';
  const amountCharged = new Intl.NumberFormat(userLocale, {
    style: 'currency',
    currency: bundle.currency,
    currencyDisplay: 'symbol',
  }).format(bundle.cost);

  const formatter = new Intl.NumberFormat(userLocale);
  const formattedCredits = formatter.format(bundle.credits);

  return (
    <Modal {...{ ...props, ...customProps }}>
      <>
        <h2 style={{ textAlign: 'center' }}>Confirm Credit Purchase</h2>
        <br />
        {hasPaymentMethod ? (
          <p>
            Your card ending in {last4Digits} will be charged {amountCharged}, and {formattedCredits} credits will be
            applied to your account immediately.
          </p>
        ) : (
          <p>
            An invoice of {amountCharged} will be sent to your billing email address {billingEmailAddress}.{' '}
            {formattedCredits} credits will be applied to your account after the payment has been received.
          </p>
        )}
      </>
    </Modal>
  );
};
export default PurchaseCreditsModal;
