import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEscape, stopEvent } from '../../../helpers/events';
import Button from '../Button';
import './modal.scss';

export interface ModalProps {
  isShown?: boolean;
  isSubmit?: boolean;
  children?: JSX.Element;
  headerText?: string;
  actionText?: string;
  containerClass?: string;
  handleButtonAction?: any;
  loading?: boolean;
  disabled?: boolean;
  loadingTxt?: string;
  showCancel?: boolean;
  extraButton?: any;
  handleClose?: any;
  cancelButtonText?: string;
  cancelVariant?: string;
  buttonVariant?: string;
  setModalVisibility?: any;
  isCoding?: boolean;
  infoIcon?: string;
  infoText?: string;
}
export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  isSubmit,
  children,
  headerText,
  actionText,
  containerClass,
  handleButtonAction,
  loading,
  disabled,
  loadingTxt,
  cancelButtonText,
  showCancel,
  extraButton,
  handleClose,
  cancelVariant,
  buttonVariant,
  setModalVisibility,
  isCoding,
  infoIcon,
  infoText,
}): JSX.Element => {
  const handleEscpape = (event): void => {
    if (isEscape(event)) {
      hideModal(event);
      window.removeEventListener('keydown', handleEscpape);
    }
  };
  window.addEventListener('keydown', handleEscpape);
  const hideModal = (e?: React.SyntheticEvent<EventTarget>): any => {
    stopEvent(e);
    if (setModalVisibility) {
      setModalVisibility(false);
    }
    if (handleClose) {
      handleClose(e);
    }
  };
  const modal = (
    <>
      <div className="modal-backdrop" />
      <div className="modal-wrapper" style={isCoding ? { minWidth: '50%' } : {}}>
        <div className={`modal-container ${containerClass || ''}`}>
          <div className="close-button" onClick={hideModal} onKeyPress={hideModal} tabIndex={-1} role="button">
            <FontAwesomeIcon className="icon" icon={faTimes} />
          </div>
          <div className="modal-content">{children}</div>
          <span>{headerText}</span>
          <div className="actions-buttons">
            {actionText && (
              <Button
                type={`${isSubmit ? 'submit' : 'button'}`}
                onClick={handleButtonAction}
                variant={`${buttonVariant || 'primary'} md`}
                text={actionText}
                loading={loading}
                loadingTxt={loadingTxt}
                disabled={loading || disabled}
              />
            )}
            {showCancel && (
              <Button
                type="button"
                onClick={hideModal}
                variant={`${cancelVariant || 'sub-primary'} md`}
                text={cancelButtonText || 'Cancel'}
              />
            )}
            {extraButton}
            {infoText && (
              <div className="modal-info">
                {infoIcon && <img src={infoIcon} alt={infoIcon} />}
                <span>{infoText}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
