import React, { useEffect, useMemo, useCallback, useState, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { getSqlResults } from 'api/candidate.api';
import { setTestQuestionProperty } from 'store/reducers/candidate';
import Skeleton from 'react-loading-skeleton';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/mode-sql';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/Shared/Button';
import ExecuteSQLModal from 'components/CandidateDetails/Modals/ExecuteSQLModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTokenData from 'hooks/useTokenData';
import useCollapsableResponse from '../../../../hooks/useCollapsableResponses';
import { getCorrectness } from '../testUtils';
import warningIcon from '../../../../images/warning.svg';
import SQLOutputTable from './SQLOutputTable';
import './index.scss';
import '../index.scss';

interface SQLResponseProps {
  testId: number;
  questionDetails: any;
}

export default function SQLResponse({ testId, questionDetails }: SQLResponseProps): any {
  const dispatch = useDispatch();
  const [collapsedMap, toggleCollapsed] = useCollapsableResponse({});
  const [modalVisible, setModalVisible] = useState(false);
  const tokenData = useTokenData();

  const getResultsAndSetProperty = useCallback(
    (query, propertyName): void => {
      if (!tokenData.paid) return;
      getSqlResults({
        query,
        test_question_id: questionDetails?.test_question_id,
      })
        .then(response => {
          dispatch(
            setTestQuestionProperty({
              testId,
              propertyName,
              questionIndex: questionDetails?.position - 1,
              value: response.data,
            }),
          );
        })
        .catch(e => {
          dispatch(
            setTestQuestionProperty({
              testId,
              propertyName,
              questionIndex: questionDetails?.position - 1,
              value: { message: `Error: ${e?.message}` },
            }),
          );
        });
    },
    [dispatch, testId, questionDetails?.position, questionDetails?.test_question_id, tokenData.paid],
  );

  useEffect(() => {
    if (!questionDetails?.candidate_output && questionDetails?.response.response) {
      getResultsAndSetProperty(questionDetails?.response.response, 'candidate_output');
    }
    if (!questionDetails?.expected_output && questionDetails?.response.model_response) {
      getResultsAndSetProperty(questionDetails?.response.model_response, 'expected_output');
    }
  }, [
    questionDetails?.candidate_output,
    questionDetails?.expected_output,
    questionDetails?.response.model_response,
    questionDetails?.response.response,
    getResultsAndSetProperty,
  ]);

  const correctness = useMemo(() => {
    return getCorrectness(
      questionDetails?.score,
      questionDetails?.max_score,
      !questionDetails.response_submitted || questionDetails.response?.response === '',
    );
  }, [
    questionDetails.score,
    questionDetails.max_score,
    questionDetails.response?.response,
    questionDetails.response_submitted,
  ]);

  const UpgradeYourAccount = ({ expectedOutput }: { expectedOutput?: boolean }): ReactElement => (
    <div className="upgrade-your-account">
      <img className="warning-icon" src={warningIcon} alt="warning" width={30} />
      <div style={{ marginRight: '8px' }}>
        Please upgrade your account to be able to see the {expectedOutput ? 'expected SQL' : "candidate's SQL"} output.{' '}
      </div>
      <Button
        variant="primary md"
        text="Upgrade Your Account"
        onClick={() => {
          window.open(`${process.env.REACT_APP_ALOOBA_LEGACY_URL}/request-demo`, 'noopener');
        }}
      />
    </div>
  );

  return (
    <div className="sql-response-section response-section-wrapper">
      <div className="model-response-section">
        {questionDetails?.response.model_response && (
          <div className="question-response-section coding-response-section">
            <div className="response-title collapsable" onClick={() => toggleCollapsed('model_response')} role="input">
              <h4>EXAMPLE OF A CORRECT QUERY</h4>
              <div className="right-info">
                <FontAwesomeIcon className="icon" icon={collapsedMap.model_response ? faAngleDown : faAngleUp} />
              </div>
            </div>
            <div className={`candidate-response-wrapper ${collapsedMap.model_response ? 'hidden' : ''}`}>
              <AceEditor
                width="100%"
                height="100%"
                mode="sql"
                theme="textmate"
                name="coding-response"
                fontSize={14}
                showPrintMargin={false}
                readOnly
                showGutter
                highlightActiveLine={false}
                value={questionDetails?.response.model_response}
                setOptions={{
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </div>
          </div>
        )}

        {tokenData.paid === true ? (
          <div className="question-response-section coding-response-section">
            <div className="response-title">
              <h4>EXPECTED OUTPUT</h4>
            </div>
            {questionDetails?.expected_output ? (
              <SQLOutputTable
                fields={questionDetails?.expected_output.result?.fields || []}
                data={questionDetails?.expected_output.result?.data || []}
                message={questionDetails?.expected_output?.message}
              />
            ) : (
              <Skeleton width={200} height={32} />
            )}
          </div>
        ) : (
          <UpgradeYourAccount expectedOutput />
        )}
      </div>

      <div className="candidate-response-section">
        <div className="question-response-section coding-response-section">
          <div className="response-title">
            <h4>CANDIDATE&apos;S QUERY</h4>
            <div className="right-info">
              {correctness.icon !== null && (
                <img src={correctness.icon} alt={`Answer icon ${correctness.key}`} className="right-info" />
              )}
            </div>
          </div>
          <div className={`candidate-response-wrapper ${correctness.key}`}>
            <AceEditor
              width="100%"
              height="100%"
              mode="sql"
              theme="textmate"
              name="coding-response"
              fontSize={14}
              showPrintMargin={false}
              readOnly
              showGutter
              highlightActiveLine={false}
              value={questionDetails?.response.response ?? ''}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </div>
          {questionDetails?.response.response ? (
            <div className="mt2">
              <Button type="submit" variant="primary sm" text="Open In Editor" onClick={() => setModalVisible(true)} />
            </div>
          ) : (
            ''
          )}
        </div>

        {questionDetails?.response.response && tokenData.paid ? (
          <div className="question-response-section coding-response-section">
            <div className="response-title">
              <h4>CANDIDATE&apos;S OUTPUT</h4>
            </div>
            {questionDetails?.candidate_output ? (
              <SQLOutputTable
                fields={questionDetails?.candidate_output.result?.fields || []}
                data={questionDetails?.candidate_output.result?.data || []}
                className={correctness.key}
                message={questionDetails?.candidate_output?.message}
              />
            ) : (
              <Skeleton width={200} height={32} />
            )}
          </div>
        ) : (
          !tokenData.paid && <UpgradeYourAccount />
        )}
      </div>
      <div>
        <ExecuteSQLModal
          setModalVisibility={setModalVisible}
          input={questionDetails.response.response}
          isShown={modalVisible}
          questionDetails={questionDetails}
        />
      </div>
    </div>
  );
}
