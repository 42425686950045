import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { RootState } from 'store/rootReducer';
import { timeFormat, fixPercentage, getFormattedAssessmentName } from 'helpers';
import RoleIcon from 'images/icons/role.svg';
import LocationIcon from 'images/icons/pin.svg';
import GroupIcon from 'images/icons/user-group.svg';
import InvitedIcon from 'images/icons/invite-candidate.svg';
import RequiresEvaluationIcon from 'images/icons/requires-evaluation.svg';
import CompletedIcon from 'images/icons/check.svg';
import ViewMoreIcon from 'images/icons/view-more-blue.svg';
import ScoreIcon from 'images/icons/score.svg';
import TextWithTooltip from 'components/Shared/Tooltip';
import { faTrashAlt, faCopy, faPencilAlt, faEye, faExchangeAlt, faArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatIcon from 'components/Shared/StatIcon';
import { goToLegacyApp } from 'hooks';
import { useHistory } from 'react-router-dom';
import AssessmentFlatPill from './assessmentFlatPill';
import AssessmentCardPublished from './assessmentCardPublished';
import AssessmentCardNotPublished from './assessmentCardNotPublished';
import TimeStat from './timeStat';
import authorize from '../../Authorization/Check';
import { updateAssessmentCloaking } from '../../store/reducers/assessment';
import { setCurrentAssessment } from '../../store/actions/assessment.actions';
import { updateAssessmentData } from '../../api/assessment.api';

interface AssessmentCardProps {
  assessment?: any;
  showDeleteModal?: any;
  showArchiveModal?: any;
  handleDuplicate?: any;
  handleConvertToTemplate?: any;
  handleInviteModal?: any;
}
const AssessmentCard: React.FC<AssessmentCardProps> = ({
  assessment,
  showDeleteModal,
  showArchiveModal,
  handleDuplicate,
  handleConvertToTemplate,
  handleInviteModal,
}: AssessmentCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const [showDropdown, setdropdown] = useState<boolean>(false);
  const { groups } = useSelector((state: RootState) => state.organization);
  const { addToast } = useToasts();
  const history = useHistory();

  const getGroupName = (id): string => {
    if (groups && groups[id]) {
      return groups[id];
    }
    if (!id) {
      return 'Anyone';
    }
    return '';
  };

  const stopPropagation = (e: React.SyntheticEvent<EventTarget>): void => {
    e.stopPropagation();
  };
  const handleDropdown = (e: React.SyntheticEvent<EventTarget>): void => {
    stopPropagation(e);
    e.preventDefault();
    setdropdown(!showDropdown);
  };
  const onMouseLeave = (e: React.SyntheticEvent<EventTarget>): void => {
    stopPropagation(e);
    setdropdown(false);
  };
  const handleToggleCandidateCloaking = (e): void => {
    stopPropagation(e);
    e.preventDefault();
    if (assessment) {
      const newVal = !assessment.cloak_candidates;
      updateAssessmentData({ id: assessment.id, cloak_candidates: newVal })
        .then(() => {
          dispatch(
            updateAssessmentCloaking({
              id: assessment.id,
              purpose: assessment.purpose,
              cloak_candidates: newVal,
            }),
          );
          addToast({
            type: 'success',
            msg: `Candidate cloaking ${newVal === true ? 'enabled' : 'disabled'} for assessment
              ${assessment.recruiter_test_name}`,
          });
        })
        .catch(e => {
          addToast({
            type: 'error',
            msg: 'There was an error updating the Candidate Cloaking settings. Please try again later!',
          });
          throw e;
        });
    }
  };
  const { hours, mins } = timeFormat(assessment.total_time_length);
  const percentage = fixPercentage(parseFloat(assessment.average_score));
  const legacyUrl = process.env.REACT_APP_ALOOBA_LEGACY_URL;
  let isLegacy = true;
  let href;
  if (assessment.is_published !== '0') {
    isLegacy = false;
    href = `/assessment/${assessment.id}-${getFormattedAssessmentName(assessment.recruiter_test_name)}/overview`;
  } else if (authorize('assessment', 'edit', assessment.role.user_id)) {
    href = `${legacyUrl}/create-assessment-customized-confirm/${assessment.id}`;
  } else {
    href = '#';
  }
  const linkCardProps = {
    className: 'main-container assessment-card',
    onMouseLeave,
    id: assessment.id,
    legacy: isLegacy,
    handleClick: () => dispatch(setCurrentAssessment(assessment)),
  };
  let actionClass = 'actions draft';
  let assessmentCardComponent = <AssessmentCardNotPublished {...{ assessment }} />;
  let isPublished = false;
  if (assessment && assessment.is_published === '1') {
    actionClass = 'actions';
    assessmentCardComponent = <AssessmentCardPublished {...{ assessment, handleInviteModal }} />;
    isPublished = true;
  }

  const canDelele = assessment.id && authorize('assessment', 'delete', assessment.role.user_id);

  const isThereRole = assessment && assessment.role;
  const cloakingText = assessment.cloak_candidates ? 'Uncloak' : 'Cloak';
  const productName = assessment.purpose === 'ld' ? 'exercise' : 'assessment';
  const tooltip = assessment.creator_first_name ? (
    <>
      Created by {assessment.creator_first_name} {assessment.creator_last_name}
      <br />
      {assessment.email}
    </>
  ) : (
    <>Created by Unknown</>
  );

  return (
    <div {...linkCardProps}>
      <a
        href={href}
        onClick={e => {
          e.preventDefault();
          stopPropagation(e);
          dispatch(setCurrentAssessment(assessment));
          if (linkCardProps.legacy) {
            window.location.assign(href);
          } else {
            history.push(href);
          }
        }}
        className="assessment-details"
      >
        <div className="first-row">
          <div className="first-col">
            <TextWithTooltip text={assessment.recruiter_test_name} noHover tooltip={tooltip} />
            {canDelele && (
              <StatIcon id="delete" handleImageClick={handleDropdown} icon={ViewMoreIcon} alt="view-more" />
            )}
          </div>
        </div>
        <div className="second-row">
          <div className="first-col">
            {isThereRole && (
              <StatIcon icon={RoleIcon} label={assessment.role.title} alt="role" tooltip="Role Title" noHover />
            )}
          </div>
          <div className="second-col">
            <StatIcon icon={GroupIcon} label={getGroupName(assessment.group_id)} alt="group" tooltip="Group" noHover />
          </div>
          <div className="third-col" />
        </div>
        <div className="third-row">
          <div className="first-col">
            {isThereRole && assessment.role.location && (
              <StatIcon
                icon={LocationIcon}
                label={assessment.role.location.description}
                alt="location"
                tooltip="Location"
                noHover
              />
            )}
          </div>
          <div className="second-col time-stat">
            <TimeStat {...{ hours, mins }} />
          </div>

          {isPublished && authorize('assessment', 'view', assessment.role.user_id) ? (
            <div className="third-col">
              <StatIcon
                icon={InvitedIcon}
                value={assessment.candidates_count}
                alt="Invited"
                tooltip={assessment.purpose === 'ld' ? 'Number of employees invited' : 'Number of candidates invited'}
                noHover
              />
              <StatIcon
                icon={CompletedIcon}
                value={assessment.completed_candidates_count}
                alt="Completed"
                tooltip={`Number of submitted ${productName}s`}
                noHover
              />
              {assessment.evaluation_candidates_count > 0 && (
                <StatIcon
                  icon={RequiresEvaluationIcon}
                  value={assessment.evaluation_candidates_count}
                  alt="Requires Evaluation"
                  tooltip={`Number of ${productName}s that still require manual evaluation`}
                  noHover
                />
              )}
              <StatIcon
                icon={ScoreIcon}
                value={percentage}
                alt="Average Score"
                tooltip={`Current average score of submitted ${productName}s`}
                noHover
              />
            </div>
          ) : null}
        </div>
        <AssessmentFlatPill {...{ assessment }} />
      </a>
      <div className={actionClass} onClick={stopPropagation} onKeyPress={stopPropagation} role="button" tabIndex={0}>
        {assessmentCardComponent}
        {showDropdown && (
          <div className="dropdown max-content">
            {isPublished && !assessment.is_sample && (
              <>
                <p
                  onClick={e => goToLegacyApp(e, `edit-assessment-customized-content/${assessment.id}`)}
                  onKeyPress={e => goToLegacyApp(e, `edit-assessment-customized-content/${assessment.id}`)}
                  role="presentation"
                  tabIndex={-1}
                >
                  <FontAwesomeIcon className="icon fa-w-16" icon={faPencilAlt} />
                  <span>Edit Configuration</span>
                </p>
                {!assessment.is_sample && (
                  <p
                    onClick={e => goToLegacyApp(e, `edit-assessment-customized-questions/${assessment.id}`)}
                    onKeyPress={e => goToLegacyApp(e, `edit-assessment-customized-questions/${assessment.id}`)}
                    role="presentation"
                    tabIndex={-1}
                  >
                    <FontAwesomeIcon className="icon fa-w-16" icon={faPencilAlt} />
                    <span>Edit Questions</span>
                  </p>
                )}
                <p
                  onClick={e => handleDuplicate(e, assessment)}
                  onKeyPress={e => handleDuplicate(e, assessment)}
                  role="presentation"
                  tabIndex={-1}
                >
                  <FontAwesomeIcon className="icon fa-w-16" icon={faCopy} />
                  <span>Duplicate</span>
                </p>
                {assessment.candidates_count === 0 && (
                  <p
                    onClick={e => handleConvertToTemplate(e, assessment)}
                    onKeyPress={e => handleConvertToTemplate(e, assessment)}
                    role="presentation"
                    tabIndex={-1}
                  >
                    <FontAwesomeIcon className="icon fa-w-16" icon={faExchangeAlt} />
                    <span>Convert to Template</span>
                  </p>
                )}
              </>
            )}
            {isPublished && authorize('assessment', 'edit', assessment.role.user_id) && (
              <>
                <p
                  onClick={e => handleToggleCandidateCloaking(e)}
                  onKeyPress={e => handleToggleCandidateCloaking(e)}
                  role="presentation"
                  tabIndex={-1}
                >
                  <FontAwesomeIcon className="icon fa-w-16" icon={faEye} />
                  <span>{cloakingText} Candidates</span>
                </p>
                <p onClick={e => showArchiveModal(e)} role="presentation" tabIndex={-1}>
                  <FontAwesomeIcon className="icon fa-w-16" icon={faArchive} />
                  <span>{assessment.is_archived ? 'Republish' : 'Unpublish'}</span>
                </p>
              </>
            )}
            <p onClick={e => showDeleteModal(e)} onKeyPress={e => showDeleteModal(e)} role="presentation" tabIndex={-1}>
              <FontAwesomeIcon className="icon fa-w-16" icon={faTrashAlt} />
              <span>Delete</span>
            </p>
          </div>
        )}
        {canDelele && (
          <StatIcon
            id="delete"
            handleImageClick={handleDropdown}
            icon={ViewMoreIcon}
            alt="view-more"
            highlighted={showDropdown}
          />
        )}
      </div>
    </div>
  );
};

export default AssessmentCard;
