import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import AloobaTooltip from './AloobaTooltip';
import styles from './Tooltip.module.scss';
import './tooltip.scss';

interface Props {
  children?: JSX.Element;
  tooltip?: string | JSX.Element;
  text?: string;
  component?: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  onClick?: any;
  icon?: string;
  faIcon?: IconDefinition;
  isLink?: boolean;
  linkTo?: string;
  alt?: string;
  highlighted?: boolean;
  noHover?: boolean;
  style?: any;
  pointer?: boolean;
  linkTarget?: string;
}

const ImageWithTooltip: React.FC<Props> = ({
  children,
  tooltip,
  text,
  component = null,
  icon,
  faIcon,
  onClick,
  alt,
  isLink,
  linkTo,
  highlighted,
  noHover,
  placement = 'top',
  style = null,
  pointer = true,
  linkTarget = '_self',
}) => {
  const [isHighlighted, setisHighlighted] = useState<boolean>(false);

  const handleMouseEnter = (): void => {
    if (!noHover) {
      setisHighlighted(true);
    }
  };

  const handleMouseLeave = (): void => {
    if (!noHover) {
      setisHighlighted(false);
    }
  };

  const highlightedClass = isHighlighted && !highlighted ? 'highlighted' : '';
  const Inner: any = () => (
    <div
      role="button"
      tabIndex={0}
      className={`with-tooltip ${pointer ? '' : 'normal-mouse'} ${highlighted ? 'highlighted' : ''} ${
        noHover ? 'no-highlight' : ''
      }`}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={style}
    >
      {icon &&
        (isLink ? (
          <a href={linkTo} target={linkTarget}>
            <img src={icon} alt={alt || 'tooltip'} className={highlightedClass} />
          </a>
        ) : (
          <img src={icon} alt={alt || 'tooltip'} className={highlightedClass} />
        ))}
      {!icon &&
        faIcon &&
        (isLink ? (
          <a href={linkTo}>
            <FontAwesomeIcon icon={faIcon} className={`fa ${highlightedClass}`} />
          </a>
        ) : (
          <FontAwesomeIcon icon={faIcon} className={`fa ${highlightedClass}`} />
        ))}
      {text && <h5 className={`card-title ${styles.withTooltip}`}>{text}</h5>}
      {component}
      {children}
    </div>
  );

  if (!tooltip) {
    return Inner();
  }
  return (
    <AloobaTooltip arrow title={tooltip} placement={placement}>
      {Inner()}
    </AloobaTooltip>
  );
};

export default ImageWithTooltip;
