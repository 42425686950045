import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import Markdown from 'components/Shared/Markdown/Markdown';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloudDownload from '../../../../images/icons/cloud-download-blue.svg';
import '../index.scss';
import './QuestionDetails.scss';

interface QuestionBackgroundProps {
  background?: string;
  hasImage?: boolean;
  question?: string;
  explanationText?: string;
  dataSets?: any[] | null;
  showBackgroundTitle?: boolean;
  recruiterTestId?: number;
  questionId?: number;
  subjects?: any[];
}

const MAX_HEIGHT = 120;
export default function QuestionBackground({
  background,
  hasImage,
  question,
  explanationText,
  dataSets,
  showBackgroundTitle,
  recruiterTestId,
  questionId,
  subjects,
}: QuestionBackgroundProps): any {
  const [collapsed, setCollapsed] = useState(true);
  const [hideShowMore, setHideShowMore] = useState(true);
  const collapseText = collapsed ? 'Show' : 'Hide';
  const backgroundRef = useRef(null);

  /**
   * Update this value on window resize to force the recalculation
   * of the background's scroll height and update the "Show" button visibility.
   */
  const handleResize = useCallback((): void => {
    setTimeout(() => {
      setHideShowMore(backgroundRef?.current?.scrollHeight <= MAX_HEIGHT && !dataSets);
    }, 100);
  }, [dataSets]);

  const toggleCollapsed = (): void => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    handleResize();
    if (questionId) {
      setCollapsed(true);
    }
  }, [questionId, handleResize, dataSets]);

  const reactMarkdownRenderers = useMemo(
    () => ({
      img: renderProps => (
        <div className="markdown-image">
          <img
            onLoad={handleResize}
            src={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}${renderProps.src}`}
            alt="question information"
          />
        </div>
      ),
    }),
    [handleResize],
  );

  return (
    <div className="question-side">
      <div
        ref={backgroundRef}
        className={`question-background question-background-collapsable ${collapsed ? 'collapsed' : ''} ${
          collapsed && !hideShowMore ? 'gradient-overlay' : ''
        }`}
      >
        {questionId && hasImage ? (
          <div className="question-image">
            <img
              src={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}/get-question-image/question/${questionId}`}
              alt="Question Details"
            />
          </div>
        ) : (
          ''
        )}
        {/* Question background */}
        {showBackgroundTitle && <div className="background-title">Background</div>}
        {background && (
          <div>
            <Markdown
              className="long-str-wrap"
              components={reactMarkdownRenderers}
              content={background}
              subjects={subjects?.map(s => s.subject)}
            />
          </div>
        )}

        {dataSets && dataSets.length ? (
          <div className={`data-sets ${collapsed && !hideShowMore ? 'hidden' : ''}`}>
            <div className="background-title">Data Sets</div>
            {dataSets.map(dataSet => (
              <a
                href={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}/download-data-set-recruiter/${recruiterTestId}/${dataSet.remote_id}/${questionId}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="data-set-row">
                  <div className="data-set-card">
                    <img src={CloudDownload} alt="Cloud download icon" />
                    <p>{dataSet.file_name}</p>
                  </div>
                  <div className="data-set-description long-str-wrap">
                    <p>{dataSet.description}</p>
                  </div>
                </div>
              </a>
            ))}
          </div>
        ) : (
          ''
        )}

        {/* Question text */}
        {question && (
          <Markdown
            className="question long-str-wrap"
            components={reactMarkdownRenderers}
            content={question}
            subjects={subjects?.map(s => s.subject)}
          />
        )}
        {/* Show/Hide message */}
        {!hideShowMore && (
          <div className={`show-hide ${!collapsed ? 'relative' : ''}`}>
            <div className="show-hide-message" onClick={toggleCollapsed} role="input">
              {collapseText} <FontAwesomeIcon className="icon" icon={collapsed ? faAngleDown : faAngleUp} />{' '}
            </div>
            <hr />
          </div>
        )}
      </div>
      {explanationText && (
        <>
          <div>
            <h4>EXPLANATION</h4>
          </div>
          <Markdown
            className="grey-section long-str-wrap"
            components={reactMarkdownRenderers}
            content={explanationText}
            subjects={subjects?.map(s => s.subject)}
          />
        </>
      )}
    </div>
  );
}
