import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import './button.scss';
import { Badge } from '@material-ui/core';

interface Props {
  type?: string;
  variant?: string;
  text?: string;
  onClick?: any;
  disabled?: boolean;
  addButton?: boolean;
  loading?: boolean;
  loadingTxt?: string;
  loadingPlaceholder?: boolean;
  placeholderStyle?: string;
  id?: string;
  badgeText?: string;
  tooltip?: string;
}

const Button = ({
  type,
  variant,
  text,
  onClick,
  addButton,
  disabled,
  placeholderStyle,
  loadingPlaceholder,
  loading,
  loadingTxt,
  id,
  badgeText,
  tooltip,
}: Props): JSX.Element => {
  const internal = (): React.ReactChild => {
    if (loadingPlaceholder) {
      return <Skeleton width={100} height={34} />;
    }
    if (loading) {
      return (
        <span>
          <span className="pr5">{loadingTxt || 'Loading...'}</span>
          <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
        </span>
      );
    }
    return text;
  };
  const handleClick = (e): void => {
    if (!loading) {
      if (onClick) onClick(e);
    }
  };
  const loadingClass = loading ? ' loading' : '';
  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={handleClick}
      className={loadingPlaceholder ? placeholderStyle : `${variant}${loadingClass}`}
      disabled={disabled}
      data-testid={id}
      id={id}
      title={tooltip}
    >
      {addButton && <FontAwesomeIcon className="icon" icon={faPlus} />}
      {internal()}
      {badgeText && <Badge badgeContent={badgeText} color="secondary" />}
    </button>
  );
};

export default Button;
