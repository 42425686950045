import React, { useMemo } from 'react';
import AnswerOption from './AnswerOption';
import './AnswerDetails.scss';

export interface Props {
  question: any;
}

export default function MCQAnswer({ question }: Props): any {
  const [selectedAnswer, correctAnswer] = useMemo(() => {
    let selected;
    let correct;
    for (let i = 0; i < question.answers.length; i += 1) {
      if (question.answers[i].selected) {
        selected = question.answers[i];
      }
      if (question.answers[i].correct) {
        correct = question.answers[i];
      }
      if (selected && correct) break;
    }
    return [selected, correct];
  }, [question]);

  return (
    <div className="mcq-answer">
      <div className="question-response-section">
        <h4>ANSWER</h4>
        {selectedAnswer && (
          <AnswerOption
            answer={selectedAnswer}
            isCorrect={selectedAnswer?.correct}
            isCandidateAnswer
            subjects={question?.subjects?.map(s => s.subject)}
          />
        )}
        {!selectedAnswer?.correct && <AnswerOption answer={correctAnswer} isCorrect />}
      </div>
    </div>
  );
}
