import Button from 'components/Shared/Button';
import Input from 'components/Shared/Input/textArea';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import './CommentInput.scss';

interface CommentInputProps {
  initialValue?: string;
  submitButtonText?: string;
  onCancel: () => void;
  onSubmit: (string) => void;
}
const CommentInput: React.FC<CommentInputProps> = ({
  onCancel,
  onSubmit,
  initialValue = '',
  submitButtonText = 'Done',
}) => {
  const commentSubmitting = useSelector((state: RootState) => state.candidate.loading.comments);
  const [comment, setComment] = useState(initialValue);
  return (
    <div className="comment-input">
      <div>
        <Input
          disabled={commentSubmitting}
          placeholder="Write a note..."
          value={comment}
          onChange={v => setComment(v.target.value)}
        />
      </div>
      <div className="buttons">
        <Button
          disabled={commentSubmitting}
          text={submitButtonText}
          variant="primary md"
          onClick={() => onSubmit(comment)}
        />
        <Button disabled={commentSubmitting} variant="sub-primary md" onClick={() => onCancel()} text="Cancel" />
      </div>
    </div>
  );
};

export default CommentInput;
