import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchCandidatesQueryParams } from 'api/contract';

interface SuggestedCandidatesState {
  paginator: any;
  currentPage: number;
  queryParams: SearchCandidatesQueryParams | null;
}

const initialState: SuggestedCandidatesState = {
  paginator: {
    data: [],
  },
  currentPage: 1,
  queryParams: null,
};

const SuggestedCandidatesReducer = createSlice({
  name: 'suggestedCandidates',
  initialState,
  reducers: {
    updateQueryParams: (state, action: PayloadAction<any>) => {
      state.queryParams = {
        ...state.queryParams,
        ...action.payload,
      };
    },
    setPaginator: (state, action: PayloadAction<any>) => {
      state.paginator = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
});

export const { updateQueryParams, setPaginator, setCurrentPage } = SuggestedCandidatesReducer.actions;
export default SuggestedCandidatesReducer.reducer;
