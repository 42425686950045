import React, { useMemo } from 'react';
import './index.scss';
import RequiresEvaluationIcon from 'images/icons/requires-evaluation-blue.svg';
import QuestionMarkIcon from 'images/icons/question-mark.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import SkillResource from '../SkillResource';
import LightTooltip from '../../../Shared/Tooltip/LightTooltip';

const SubjectDetails = ({ subject, topics }): any => {
  const totalMaxScore = useMemo(() => {
    return topics.reduce((total, topic) => total + topic.max_score, 0);
  }, [topics]);
  const totalScore = useMemo(() => {
    return topics.reduce((total, topic) => total + topic.score, 0);
  }, [topics]);
  const totalRequiresEvaluation = useMemo(() => {
    return topics.reduce((total, topic) => total + topic.requires_evaluation, 0);
  }, [topics]);

  const aiGeneratedContentDisclaimer =
    'Please be aware that the following text was generated by an AI language model, specifically tailored to your results. However, it is important to note that the views and opinions expressed in the text do not necessarily reflect those of our organization.';
  return (
    <div className="subject-details">
      <div className="subject-details-title">
        <h3>{subject.subject}</h3>
        {subject.id === 0 && (
          <LightTooltip title={aiGeneratedContentDisclaimer} arrow>
            <img src={QuestionMarkIcon} alt="question mark" />
          </LightTooltip>
        )}
      </div>
      {subject.isLoading ? (
        <div className="spinner-container">
          <FontAwesomeIcon className="fa-spin loading-icon" width={10} icon={faSpinner} />
        </div>
      ) : (
        <>
          <p>{subject.description}</p>
          {subject.skill_resources.length > 0 && (
            <div className="skill-resource-container">
              {subject.skill_resources.map(resource => (
                <SkillResource key={resource.id} link={resource.link} title={resource.title} />
              ))}
            </div>
          )}
          {subject.requires_evaluation > 0 && (
            <div className="alert">
              <img className="personal-info-icon" src={RequiresEvaluationIcon} alt="Requires Evaluation Icon" />
              <span>Some {subject.subject} questions are still pending evaluation.</span>
            </div>
          )}
          <div className="topics-container">
            <div>
              {topics
                .filter(topic => topic.subject_id === subject.id)
                .map(topic => (
                  <div key={topic.id}>
                    <div className="topic-title">
                      <span>{topic.topic}</span>
                      <span className="line" />
                      <div className="scored-percent">
                        {topic.requires_evaluation ? '?' : topic.score} / {topic.max_score}
                      </div>
                    </div>
                    {topic.skill_resources.length > 0 && (
                      <div className="skill-resource-container">
                        {topic.skill_resources.map(resource => (
                          <SkillResource key={resource.id} link={resource.link} title={resource.title} />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              {subject.max_score > totalMaxScore && (
                <div>
                  <div className="topic-title">
                    <span>Unclassified</span>
                    <span className="line" />
                    <div className="scored-percent">
                      {subject.requires_evaluation > totalRequiresEvaluation ? '?' : subject.score - totalScore} /{' '}
                      {subject.max_score - totalMaxScore}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default SubjectDetails;
