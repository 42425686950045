import React from 'react';
import HistogramChart from 'components/Shared/Histogram/index';

interface Frequency {
  label: string;
  count: number;
}
interface YearStats {
  total: number;
  summary: Frequency[];
}
const AgeHistogram: React.FC<{
  yearStats: YearStats;
  histogramHeight: number;
}> = ({ yearStats, histogramHeight }): JSX.Element => {
  const ageGroups = yearStats.summary
    .map(o => new Date().getFullYear() - parseInt(o.label, 10))
    .filter(age => !Number.isNaN(age));
  const maxAge = Math.ceil(Math.max(...ageGroups) / 5) * 5;
  const minAge = Math.floor(Math.min(...ageGroups) / 5) * 5;

  const birthYearHistogramBuckets = [];
  const bucketIntervals = 5;

  let min = minAge;
  let max = minAge;

  while (max <= maxAge) {
    min = max;
    max = min + bucketIntervals;
    birthYearHistogramBuckets.push({
      label: `${min}-${max}`,
      value: 0,
      min,
      max,
    });
  }

  yearStats.summary.forEach(({ label, count }) => {
    const age = new Date().getFullYear() - parseInt(label, 10);
    if (!Number.isNaN(age)) {
      const bracket = birthYearHistogramBuckets.find(e => age <= e.max && age >= e.min);

      bracket.value += count;
    }
  });

  return (
    <HistogramChart
      height={histogramHeight}
      data={birthYearHistogramBuckets}
      lineAmount={2}
      yaxis="Number of Candidates"
      xaxis="Age"
    />
  );
};

export default AgeHistogram;
