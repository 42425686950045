import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Dropdown from '../Dropdown';
import { NavigateLeftIcon, NavigateRightIcon } from '../Icon/Navigate';
import './index.scss';

interface DynamicTableProps {
  loading: boolean;
  tablePaginatorProps: any;
}
const DynamicTablePaginator = ({ loading = true, tablePaginatorProps }: DynamicTableProps): JSX.Element => {
  const { paginator, isFirst, isLast, prevPage, nextPage, changePerPage } = tablePaginatorProps;
  const { total, from, to } = paginator;
  const perPage: number = paginator.per_page || 0;
  const visible = (): boolean => true;
  if (loading) {
    return (
      <div className="dynamic-table-paginator">
        <div className="paginator skeleton">
          <Skeleton height={32} />
        </div>
      </div>
    );
  }
  if (total === 0) {
    return <></>;
  }
  return (
    <div className="dynamic-table-paginator">
      <div className="paginator">
        {visible() && (
          <div className="paginator-actions">
            <div className="paginator-dropdown action">
              <Dropdown
                loading={loading}
                isSearchable={false}
                value={`${perPage}`}
                menuPlacement="top"
                placeholder={`${perPage}`}
                handleChange={changePerPage}
              />
            </div>
            <div className="paginator-values action">
              <span className="first">{from}</span>
              <span className="dash">-</span>
              <span className="last">{to}</span>
              <span className="of">of</span>
              <span className="total">{total}</span>
            </div>
            <div className="paginator-arrows action">
              <span
                className={`left ${isFirst ? 'pointer' : ''}`}
                onClick={prevPage}
                onKeyPress={prevPage}
                role="navigation"
                aria-hidden
                tabIndex={-55}
              >
                <NavigateLeftIcon />
              </span>
              <span
                className={`right ${isLast ? 'pointer' : ''}`}
                onClick={nextPage}
                onKeyPress={nextPage}
                role="navigation"
                aria-hidden
                tabIndex={-54}
              >
                <NavigateRightIcon />
              </span>
            </div>
          </div>
        )}
        {!visible() && (
          <div className="paginator-actions">
            <div className="paginator-values action">
              <span className="of">Total</span>
              <span className="total">{total}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default DynamicTablePaginator;
