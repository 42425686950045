import React, { useState, useRef } from 'react';
import { faTrashAlt, faCalendar, faClock, faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stopEvent } from 'helpers/events';
import { resendInvitationEmail } from 'api/candidate.api';
import { useToasts } from 'react-toast-notifications';
import ActionMenu, { MenuPositions } from '../Shared/Dropdown/ActionMenu';
import DeleteParticipantModal from '../Shared/Modals/DeleteCandidateModal';
import ExtendExpiryModal from './Modals/ExtendExpiryModal';
import TestDurationModal from './Modals/TestDurationModal';
import './ActionMenuContainer.scss';

interface ActionMenuProps {
  row: any;
  assessment: any;
  userDetails: any;
  showTestDuration?: boolean;
  getData?: any;
  shouldCloakPII?: boolean;
  onSendInvitation?: any;
}
const ActionMenuContainer: React.FC<any> = ({
  row,
  assessment,
  userDetails,
  showTestDuration = true,
  getData,
  shouldCloakPII = false,
  onSendInvitation,
}: ActionMenuProps): JSX.Element => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteParticipantModalProps = useRef(null);
  deleteParticipantModalProps.current = {
    setModalVisibility: setShowDeleteModal,
    isShown: showDeleteModal,
    candidate: row,
    getData,
  };
  const [sendingInvitation, setSendingInvitation] = useState<boolean>(false);

  const NOT_STARTED_STATUSES = [
    'Invited',
    'Seen Invite',
    'Opened Assessment',
    'Invite Not Delivered',
    'Invite Undeliverable',
  ];
  const handleDeleteModal = (e: React.SyntheticEvent<EventTarget>): void => {
    stopEvent(e);
    setShowDeleteModal(!showDeleteModal);
  };
  const { addToast } = useToasts();

  const [showExtendExpiryModal, setShowExtendExpiryModal] = useState<boolean>(false);
  const extendExpiryModalProps = useRef(null);
  extendExpiryModalProps.current = {
    setModalVisibility: setShowExtendExpiryModal,
    isShown: showExtendExpiryModal,
    row,
    assessment,
    userDetails,
  };
  const handleExtendExpiry = (e: React.SyntheticEvent<EventTarget>): void => {
    stopEvent(e);
    setShowExtendExpiryModal(!showExtendExpiryModal);
  };

  const [showTestDurationModal, setShowTestDurationModal] = useState<boolean>(false);
  const testDurationModalProps = useRef(null);
  testDurationModalProps.current = {
    setModalVisibility: setShowTestDurationModal,
    isShown: showTestDurationModal,
    row,
    assessment,
    userDetails,
  };
  const handleTestDuration = (e: React.SyntheticEvent<EventTarget>): void => {
    stopEvent(e);
    setShowTestDurationModal(!showTestDurationModal);
  };

  const handleSendInvitation = (e: React.SyntheticEvent<EventTarget>): void => {
    stopEvent(e);
    if (sendingInvitation || !onSendInvitation) {
      return;
    }
    setSendingInvitation(true);
    onSendInvitation(row).finally(() => setSendingInvitation(false));
  };
  const sendInvitationItem = (): any => {
    if (!row['Send Invitation']) {
      return <></>;
    }

    return (
      <p onClick={handleSendInvitation} onKeyPress={handleSendInvitation} role="presentation" tabIndex={-1} key="send">
        <FontAwesomeIcon className="icon" icon={faEnvelope} />
        <span>Send Invitation</span>
        {sendingInvitation && (
          <span className="re-send-invitation-loader">
            <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
          </span>
        )}
      </p>
    );
  };

  const handleResendInvitation = (e: React.SyntheticEvent<EventTarget>): void => {
    stopEvent(e);
    if (sendingInvitation) {
      return;
    }
    setSendingInvitation(true);
    resendInvitationEmail(row.candidate_test_id)
      .then(() => {
        const candidateIdentifier = shouldCloakPII ? row.Reference : row.Email;
        addToast({
          type: 'success',
          msg: `The invitation has been resent to ${candidateIdentifier}`,
        });
      })
      .catch(err => {
        addToast({
          type: 'error',
          msg: err.response?.data?.error ?? 'Unable to send invitation',
        });
      })
      .finally(() => setSendingInvitation(false));
  };
  const resendInvitation = (): any => {
    return (
      <p
        onClick={handleResendInvitation}
        onKeyPress={handleResendInvitation}
        role="presentation"
        tabIndex={-1}
        key="resend"
      >
        <FontAwesomeIcon className="icon" icon={faEnvelope} />
        <span>Re-send Invitation</span>
        {sendingInvitation && (
          <span className="re-send-invitation-loader">
            <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
          </span>
        )}
      </p>
    );
  };
  const testDurationCard = (): any => {
    if (row['Increase Test Duration']) {
      return (
        <p
          onClick={handleTestDuration}
          onKeyPress={handleTestDuration}
          role="presentation"
          tabIndex={-1}
          key="duration"
        >
          <FontAwesomeIcon className="icon" icon={faClock} />
          <span>Increase Test Duration</span>
        </p>
      );
    }
    return <></>;
  };
  const extendExpiryCard = (): any => {
    if (row['Extend Expiry']) {
      return (
        <p onClick={handleExtendExpiry} onKeyPress={handleExtendExpiry} role="presentation" tabIndex={-1} key="extend">
          <FontAwesomeIcon className="icon" icon={faCalendar} />
          <span>Extend Invitation Expiry</span>
        </p>
      );
    }
    return <></>;
  };
  const deleteCard = (): any => {
    if (row['Delete Assessment']) {
      return (
        <p
          data-testid="delete-candidate-assessment-button"
          onClick={handleDeleteModal}
          onKeyPress={handleDeleteModal}
          role="presentation"
          tabIndex={-1}
          key="delete"
        >
          <FontAwesomeIcon className="icon" icon={faTrashAlt} />
          <span>Delete Assessment</span>
        </p>
      );
    }
    return <></>;
  };

  const isErased = row.data_erasure_request_id !== null && row.data_erasure_request_id !== undefined;
  let menuItems = [deleteCard()];
  if (!assessment.is_archived) {
    if (!isErased) menuItems = [extendExpiryCard(), ...menuItems];
    if (showTestDuration && !isErased) {
      menuItems = [testDurationCard(), ...menuItems];
    }
    if (NOT_STARTED_STATUSES.includes(row.Status)) {
      menuItems = [resendInvitation(), ...menuItems];
    }
    menuItems = [sendInvitationItem(), ...menuItems];
  }

  return (
    <>
      <ActionMenu
        id={`participant-actions-${row.candidate_test_id}`}
        items={menuItems}
        position={MenuPositions.BottomLeft}
      />
      <DeleteParticipantModal {...deleteParticipantModalProps.current} is_cloaked={shouldCloakPII} />
      <ExtendExpiryModal {...extendExpiryModalProps.current} shouldCloakPII={shouldCloakPII} />
      {showTestDuration && <TestDurationModal {...testDurationModalProps.current} shouldCloakPII={shouldCloakPII} />}
    </>
  );
};
export default React.memo(ActionMenuContainer);
