import React, { useCallback, useEffect, useState } from 'react';
import {
  createAssessmentFromTemplate,
  deleteAssessment,
  getTemplate,
  searchTemplates,
  updateAssessmentTitle,
  assssmentFromJobDetails,
  createAssessment,
  getTemplateByPreviewCode,
  assessmentBySuggestionId,
} from 'api/assessment.api';
import { useToasts } from 'react-toast-notifications';
import OrganizationLimitedModal from 'components/Shared/OrganizationLimitedModal';
import { AssessmentPurpose } from 'components/Assessment';
import PlanUpgradeModal from 'components/Shared/PlanUpgradeModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { handleError } from 'handleError';
import { fetchJobTitleDetails } from 'store/actions/template.actions';
import './index.scss';
import { goToLegacyApp } from 'hooks';
import { Button } from 'components/Shared';
import AiGenerationIcon from 'images/icons/ai-doc.svg';
import Tooltip from 'components/Shared/Tooltip/LightTooltip';
import { debounce } from '../../helpers/events';
import SubHeader from '../Shared/SubHeader';
import TemplateAssessmentSummary from './TemplateAssessmentSummary';
import GeneratedAssessmentSummary from './GeneratedAssessmentSummary';
import GeneratingAssessment from './GeneratingAssessment';
import AssessmentDetails from './AssessmentDetails';
import CustomAutocomplete from './CustomAutocomplete';
import JobDescriptionExtraction from './JobDescriptionExtraction';

interface Props {
  assessmentPurpose?: AssessmentPurpose;
}

const CreateAssessment: React.FC<Props> = ({ assessmentPurpose }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [showMainInput, setShowMainInput] = useState<boolean>(true);
  const [showAssessmentSummary, setShowAssessmentSummary] = useState<boolean>(false);
  const [showAssessmentDetails, setShowAssessmentDetails] = useState<boolean>(false);
  const [showGeneratingAssessment, setShowGeneratingAssessment] = useState<boolean>(false);
  const [showNoMatchingRole, setShowNoMatchingRole] = useState<boolean>(false);
  const [source, setSource] = useState<string>('Template');
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [selectedTemplateDetails, setSelectedTemplateDetails] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [showOrganizationLimitedModal, setShowOrganizationLimitedModal] = useState<boolean>(false);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [roleInput, setRoleInput] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [createdAssessment, setCreatedAssessment] = useState<any>(null);
  const [generatedRoleTitle, setGeneratedRoleTitle] = useState<string>('');

  const [creatingAssessment, setCreatingAssessment] = useState<boolean>(false);

  const [showJobDescriptionInput, setShowJobDescriptionInput] = useState<boolean>(false);
  const [jobDescription, setJobDescription] = useState<string>('');
  const [extractingFromJobDescription, setExtractingFromJobDescription] = useState(false);

  const type = assessmentPurpose === 'ld' ? 'Exercise' : 'Assessment';
  const tests = assessmentPurpose === 'ld' ? 'exercises' : 'tests';

  const { job_titles, levels, stages } = useSelector((state: RootState) => state.template);

  const previewCode = localStorage.getItem('preview_code');
  const suggestionId = localStorage.getItem('suggestion_id');

  // Create an assessment based on a suggestion if a suggestion id is provided
  useEffect(() => {
    if (suggestionId) {
      assessmentBySuggestionId(suggestionId)
        .then(async response => {
          const extractedData = response.data.data;

          setRoleInput(extractedData.role.title);
          setGeneratedRoleTitle(extractedData.role.title);
          setJobDescription(extractedData.role.job_description);
          setSelectedAssessment({
            title: extractedData.title,
            stage: 'Screening',
            job_title: extractedData.role.title,
            job_title_level_id: extractedData.role.job_title_level_id,
          });
          setSelectedTemplateDetails(null);

          createFromConfiguration(extractedData);
        })
        .catch(error => {
          let errorMessage = 'There was an error generating your assessment';
          if (error.response?.data?.error) {
            errorMessage = error.response.data.error;
            if (error.response.data.message) {
              errorMessage += `: ${error.response.data.message}`;
            }
          }

          addToast({
            type: 'error',
            msg: errorMessage,
          });
        })
        .finally(() => {
          localStorage.removeItem('suggestion_id');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionId]);

  // Create an assessment based on the given template if a preview code is provided
  useEffect(() => {
    if (previewCode && job_titles && levels && stages) {
      getTemplateByPreviewCode(previewCode)
        .then(async res => {
          const { data } = res;
          const template = data.data;
          template.product_id = template.id;
          template.job_title = template.title;
          setCreatingAssessment(true);
          await createFromTemplate(template, true);
        })
        .catch(e => {
          handleError(e);
        })
        .finally(() => {
          setCreatingAssessment(false);
          localStorage.removeItem('preview_code');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job_titles, previewCode, levels, stages]);

  useEffect(() => {
    if (!job_titles) {
      dispatch(fetchJobTitleDetails());
    }
  }, [job_titles, dispatch]);

  useEffect(() => {
    if (!roleInput.trim()) {
      setTemplateOptions([]);
    }
  }, [roleInput]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    debounce(input => {
      if (input.trim().length > 0) {
        searchTemplates(input)
          .then(res => {
            const { data } = res;
            setTemplateOptions(data);
          })
          .catch(e => {
            addToast({
              type: 'error',
              msg: e.response?.data?.message ? e.response?.data?.message : 'There was an error fetching templates',
            });
          })
          .finally(() => {
            setSearching(false);
          });
      } else {
        setTemplateOptions([]);
      }
    }, 350),
    [],
  );

  useEffect(() => {
    if (isEditing && roleInput.trim()) {
      if (templateOptions.length === 0) {
        setSearching(true);
        handleInputChange(roleInput);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleInputChange, isEditing]);

  const createFromTemplate = async (template, template_manually_selected): Promise<void> => {
    setIsEditing(false);
    setSource('Template');
    if (selectedAssessment?.product_id === template.product_id) {
      return;
    }
    if (template_manually_selected) {
      setRoleInput(template.title);
      setGeneratedRoleTitle(template.title);
    } else {
      setGeneratedRoleTitle(roleInput);
    }
    setSelectedAssessment(template);
    setCreatingAssessment(true);

    try {
      // delete the previously draft assessment when creating a different one
      if (createdAssessment) {
        deleteAssessment(createdAssessment.id).catch(error => {
          if (error.response && error.response.status !== 404) {
            throw error;
          }
        });
      }
      const [createFromTemplateResponse, getTemplateResponse] = await Promise.all([
        createAssessmentFromTemplate({
          template_id: template.product_id,
          purpose: assessmentPurpose,
          role_title: template_manually_selected ? template.title : roleInput,
        }),
        getTemplate(template.product_id),
      ]);

      setCreatedAssessment(createFromTemplateResponse.data);
      setSelectedTemplateDetails(getTemplateResponse.data.data);
    } catch (error) {
      if (error.response?.status === 402) {
        setShowMainInput(true);
        setShowAssessmentSummary(false);
        setSelectedAssessment(null);
        setShowUpgradeModal(true);
      } else {
        addToast({
          type: 'error',
          msg: error.response?.data?.message || `There was an error creating your assessment`,
        });
      }
    } finally {
      setCreatingAssessment(false);
    }
  };

  const createFromConfiguration = async (configuration): Promise<void> => {
    setIsEditing(false);
    setSource('Job Description');
    setCreatingAssessment(true);

    try {
      // delete the previously draft assessment when creating a different one
      if (createdAssessment?.id) {
        deleteAssessment(createdAssessment.id).catch(error => {
          if (error.response && error.response.status !== 404) {
            throw error;
          }
        });
      }

      const createFromConfigurationResponse = await createAssessment({
        purpose: assessmentPurpose,
        ...configuration,
      });

      setCreatedAssessment(createFromConfigurationResponse.data);
      setSelectedTemplateDetails(null);
    } catch (error) {
      setSelectedAssessment(null);
      addToast({
        type: 'error',
        msg: error.response?.data?.message || `There was an error creating your assessment`,
      });
    } finally {
      setCreatingAssessment(false);
    }
  };

  const saveAssessmentTitle = (title: string): void => {
    if (!title || !createdAssessment) {
      return;
    }
    updateAssessmentTitle(createdAssessment.id, title, selectedAssessment.stage).catch(e => {
      handleError(e);
    });
  };

  // highlight the matched search terms in the role title in the dropdown
  const highlightMatches = (roleTitle: string, roleInput: string): JSX.Element => {
    if (!roleInput) {
      return <span>{roleTitle}</span>;
    }
    const escapedSearchTerm = roleInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const searchWords = escapedSearchTerm.split(/\s+/).filter(Boolean);
    const regex = new RegExp(`(${searchWords.map(word => `${word}`).join('|')})`, 'gi');
    const parts = roleTitle.split(regex);
    return (
      <span>
        {parts.map(part =>
          part.toLowerCase().match(regex) ? <strong style={{ fontWeight: 'bold' }}>{part}</strong> : part,
        )}
      </span>
    );
  };

  const formatOptionLabel = (option): JSX.Element => {
    return highlightMatches(option.title, roleInput);
  };

  const handleGenerateFromJobDescription = async (description: string): Promise<void> => {
    setSource('Job Description');
    if (!description.trim()) {
      addToast({
        type: 'error',
        msg: 'Please provide a job description',
      });
      return;
    }

    setShowJobDescriptionInput(false);
    setExtractingFromJobDescription(true);

    try {
      const response = await assssmentFromJobDetails(description);
      const extractedData = response.data.data;

      setRoleInput(extractedData.role.title);
      setGeneratedRoleTitle(extractedData.role.title);
      setSelectedAssessment({
        title: extractedData.title,
        stage: 'Screening',
        job_title: extractedData.role.title,
        job_title_level_id: extractedData.role.job_title_level_id,
      });
      setSelectedTemplateDetails(null);
      setExtractingFromJobDescription(false);

      createFromConfiguration(extractedData);
    } catch (error) {
      setExtractingFromJobDescription(false);
      let errorMessage = 'There was an error generating your assessment from the job description';
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
        if (error.response.data.message) {
          errorMessage += `: ${error.response.data.message}`;
        }
      }

      addToast({
        type: 'error',
        msg: errorMessage,
      });
    }
  };

  // Generate button for the right side of the input
  const generateButton = (
    <Tooltip title="Generate From Job Description" placement="top" arrow>
      <button
        type="button"
        className="generate-icon-btn"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setShowJobDescriptionInput(true);
        }}
      >
        <img src={AiGenerationIcon} alt="Generate" />
      </button>
    </Tooltip>
  );

  const handleJobDescriptionKeyDown = (event: React.KeyboardEvent): void => {
    // Check for Ctrl+Enter or Cmd+Enter (metaKey for Mac)
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      event.preventDefault();
      if (jobDescription.trim()) {
        handleGenerateFromJobDescription(jobDescription);
      }
    }
  };

  // Custom job description input dropdown content
  const jobDescriptionDropdownContent = (
    <div className="job-description-input-area">
      <button
        type="button"
        className="close-button"
        onClick={() => {
          setShowJobDescriptionInput(false);
          setJobDescription('');
        }}
      >
        ✕
      </button>
      <div className="job-description-header">
        <p>
          Provide your job description and we&apos;ll create an assessment tailored to your specific role requirements.
        </p>
      </div>
      <div className="job-description-area">
        <p>Write or paste your full job description here:</p>
        <textarea
          className="job-description-textarea"
          placeholder=""
          rows={jobDescription.trim().split('\n').length > 3 ? 8 : 3}
          value={jobDescription}
          onChange={e => setJobDescription(e.target.value)}
          onKeyDown={handleJobDescriptionKeyDown}
          autoFocus
        />
      </div>
      {jobDescription.trim().length > 0 && (
        <div className="job-description-actions">
          <button type="button" className="primary md" onClick={() => handleGenerateFromJobDescription(jobDescription)}>
            Generate Assessment
          </button>
        </div>
      )}
    </div>
  );

  // Add a function to close job description input on outside click
  const handleClickOutsideJobDescription = (e): void => {
    // Only close if clicked outside of the job description area
    if (
      showJobDescriptionInput &&
      !e.target.closest('.job-description-input-area') &&
      !e.target.closest('.generate-icon-btn') &&
      !e.target.closest('.tooltip')
    ) {
      setShowJobDescriptionInput(false);
    }
  };

  // Add event listener for outside clicks
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideJobDescription);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideJobDescription);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showJobDescriptionInput]);

  useEffect(() => {
    setShowMainInput((isEditing || !selectedAssessment) && !extractingFromJobDescription);
    setShowAssessmentSummary(!isEditing && selectedAssessment && !extractingFromJobDescription);
    setShowAssessmentDetails(
      selectedAssessment &&
        (selectedTemplateDetails || createdAssessment) &&
        !isEditing &&
        !creatingAssessment &&
        !extractingFromJobDescription,
    );
    setShowGeneratingAssessment(creatingAssessment && selectedAssessment);
    setShowNoMatchingRole(
      roleInput &&
        templateOptions.length === 0 &&
        !searching &&
        !extractingFromJobDescription &&
        !creatingAssessment &&
        !selectedAssessment,
    );
  }, [
    isEditing,
    selectedAssessment,
    selectedTemplateDetails,
    creatingAssessment,
    createdAssessment,
    roleInput,
    templateOptions,
    searching,
    extractingFromJobDescription,
  ]);

  return (
    <div>
      <SubHeader>
        <div className="page-title">Create {type}</div>
      </SubHeader>
      <div className="full-width-container create-assessment">
        {(showMainInput || showAssessmentSummary) && (
          <div className="main-container">
            <div className="main-input">
              {showMainInput && (
                <>
                  <h2>What role are you hiring for?</h2>
                  <CustomAutocomplete
                    autoFocus
                    placeholder="Eg. Data Analyst, Software Engineer, etc"
                    formatOptionLabel={formatOptionLabel}
                    options={templateOptions}
                    onInputChange={(input, { action }) => {
                      if (['input-blur', 'menu-close'].includes(action)) {
                        return;
                      }
                      setRoleInput(input);
                      if (!input.trim()) {
                        setSearching(false);
                        return;
                      }
                      setSearching(true);
                      handleInputChange(input);
                    }}
                    onChange={(template, template_manually_selected) => {
                      if (!template) {
                        setRoleInput('');
                        setSelectedAssessment(null);
                        setSelectedTemplateDetails(null);
                        return;
                      }
                      createFromTemplate(template, template_manually_selected);
                    }}
                    value={roleInput}
                    setIsEditing={setIsEditing}
                    rightElement={generateButton}
                    dropdownContent={showJobDescriptionInput ? jobDescriptionDropdownContent : undefined}
                    showDropdownOverride={showJobDescriptionInput}
                  />
                  <div className="break" />
                </>
              )}
              {showAssessmentSummary && source === 'Template' && (
                <TemplateAssessmentSummary
                  title={generatedRoleTitle}
                  setTitle={title => {
                    setGeneratedRoleTitle(title);
                    setRoleInput(title);
                  }}
                  template={selectedAssessment}
                  onUpdateTemplate={assessment => {
                    if (assessment.product_id !== selectedAssessment.product_id) {
                      createFromTemplate(assessment, false);
                    }
                  }}
                  saveAssessmentTitle={title => {
                    saveAssessmentTitle(title);
                  }}
                />
              )}
              {showAssessmentSummary && source === 'Job Description' && (
                <GeneratedAssessmentSummary
                  title={generatedRoleTitle}
                  jobDescription={jobDescription}
                  setJobDescription={setJobDescription}
                  generateFromJobDescription={handleGenerateFromJobDescription}
                />
              )}
            </div>
          </div>
        )}
        {showNoMatchingRole && (
          <div className="no-role-found">
            <p>
              We&apos;re sorry, but we don&apos;t currently offer assessments for {roleInput} roles. We do our best to
              try to cover as many roles as possible, so there is a good chance that we may include your role in the
              future.
            </p>
            <p>
              In the meantime try selecting a different role that you are currently looking to hire for, or{' '}
              <b>create a custom assessment</b>.
              <div className="create-custom-assessment">
                <Button
                  variant="primary md"
                  text="Create Custom Assessment"
                  onClick={e => goToLegacyApp(e, `create-assessment-customized-content?purpose=${assessmentPurpose}`)}
                />
              </div>
            </p>
          </div>
        )}
        {showGeneratingAssessment && (
          <GeneratingAssessment assessment={selectedAssessment} roleTitle={generatedRoleTitle} />
        )}
        {extractingFromJobDescription && <JobDescriptionExtraction />}
        {showAssessmentDetails && (
          <AssessmentDetails
            template={selectedTemplateDetails}
            stage={selectedAssessment.stage}
            title={generatedRoleTitle}
            createdAssessment={createdAssessment}
          />
        )}
        <PlanUpgradeModal
          isShown={showUpgradeModal}
          setModalVisibility={setShowUpgradeModal}
          title={`Upgrade your account to use this ${type.toLowerCase()}`}
        >
          <div>
            <p>
              This template includes {tests} that are not included on your current subscription plan. Upgrade your
              subscription to access this template and many other amazing features Alooba has to offer.
            </p>
          </div>
        </PlanUpgradeModal>
        <OrganizationLimitedModal
          isShown={showOrganizationLimitedModal}
          setModalVisibility={setShowOrganizationLimitedModal}
          title="Account Limited"
        />
      </div>
    </div>
  );
};

export default CreateAssessment;
