import React, { useState, useEffect } from 'react';
import { Button } from 'components/Shared';
import AiGenerationIcon from 'images/icons/ai-doc.svg';

interface Props {
  title: string;
  jobDescription?: string;
  setJobDescription?: (jobDescription: string) => void;
  generateFromJobDescription?: (jobDescription: string) => void;
}

function GeneratedAssessmentSummary({
  title,
  jobDescription,
  setJobDescription,
  generateFromJobDescription,
}: Props): JSX.Element {
  const [isJobDescriptionEditing, setIsJobDescriptionEditing] = useState(false);
  const [editedJobDescription, setEditedJobDescription] = useState(jobDescription || '');

  useEffect(() => {
    setEditedJobDescription(jobDescription || '');
  }, [jobDescription]);

  const handleRegenerateAssessment = (): void => {
    // This would call the same API as in CreateAssessment.tsx
    if (editedJobDescription.trim() && setJobDescription) {
      setJobDescription(editedJobDescription);
      generateFromJobDescription(editedJobDescription);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    // Check for Ctrl+Enter or Cmd+Enter (metaKey for Mac)
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      event.preventDefault();
      if (editedJobDescription.trim() && editedJobDescription !== jobDescription) {
        handleRegenerateAssessment();
      }
    }
  };

  const renderJobDescriptionSection = (): JSX.Element | null => {
    return (
      <div className="job-description-section">
        {isJobDescriptionEditing ? (
          <div className="job-description-edit-container">
            <h3>Edit Job Description</h3>
            <textarea
              className="job-description-textarea"
              value={editedJobDescription}
              onChange={e => setEditedJobDescription(e.target.value)}
              rows={Math.min(15, editedJobDescription.split('\n').length + 2)}
              autoFocus
              onKeyDown={handleKeyDown} // Add this line
            />
            <div className="job-description-actions">
              <Button
                variant="sub-primary sm"
                onClick={() => {
                  setEditedJobDescription(jobDescription || '');
                  setIsJobDescriptionEditing(false);
                }}
                text="Cancel"
              />
              <Button
                variant="primary sm"
                onClick={handleRegenerateAssessment}
                text="Regenerate Assessment"
                disabled={!editedJobDescription.trim() || editedJobDescription === jobDescription}
              />
            </div>
          </div>
        ) : (
          <div className="job-description-source">
            <span>
              <strong>Source:</strong> Job Description
            </span>
            <button
              type="button"
              className="edit-icon-button"
              onClick={() => setIsJobDescriptionEditing(true)}
              aria-label="Edit job description"
            >
              <img src={AiGenerationIcon} alt="Edit job description" />
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="template-summary">
      <h2 onClick={() => setIsJobDescriptionEditing(true)}>{title}</h2>
      {renderJobDescriptionSection()}
    </div>
  );
}

export default GeneratedAssessmentSummary;
