import React, { useRef, useEffect, useState } from 'react';

const getFileUrl = (file: string): string => {
  return `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/response-uploaded-file/${file}`;
};

const getTranscriptionFileUrl = (file: string): string => {
  return `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/video-response-transcription/${file}`;
};

const getFileName = (file: string): string => {
  return file.split('/').pop();
};

export const shouldShowFileIcon = (file: string): boolean => {
  const extension = file.split('.').pop();
  const imagesAndVideoExtensions = ['jpg', 'jpeg', 'svg', 'png', 'mp4', 'webm'];
  if (imagesAndVideoExtensions.indexOf(extension) >= 0) {
    return false;
  }
  return true;
};

export const isVideoFile = (file: string): boolean => {
  const extension = file.split('.').pop();
  const videoExtensions = ['mp4', 'webm'];
  if (videoExtensions.indexOf(extension) >= 0) {
    return true;
  }
  return false;
};
export const RenderVideo = ({ file, transcriptionFile }): any => {
  const videoRef = useRef(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('error', () => {
        setError(true);
      });
    }
    return () => {
      if (video) {
        video.removeEventListener('error');
      }
    };
  }, [videoRef]);
  if (error) {
    return <p>Video upload failed.</p>;
  }
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video controls preload="metadata" crossOrigin="anonymous" key={file}>
      <source ref={videoRef} src={getFileUrl(file)} />
      {transcriptionFile && (
        <track default src={getTranscriptionFileUrl(transcriptionFile)} kind="captions" srcLang="en" label="English" />
      )}
    </video>
  );
};
export const renderFile = (file: string, transcription_file?: string): JSX.Element => {
  const extension = file.split('.').pop();
  const imageExtensions = ['jpg', 'jpeg', 'svg', 'png'];
  if (imageExtensions.indexOf(extension) >= 0) {
    return (
      <a href={getFileUrl(file)} download target="_blank" rel="noreferrer">
        <img src={getFileUrl(file)} alt={getFileName(file)} />
      </a>
    );
  }
  if (file === 'uploading') {
    return <p>Video upload failed.</p>;
  }
  if (isVideoFile(file)) {
    return <RenderVideo file={file} transcriptionFile={transcription_file} />;
  }

  return (
    <a href={getFileUrl(file)} download target="_blank" rel="noreferrer">
      {getFileName(file)}
    </a>
  );
};
