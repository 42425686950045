import React from 'react';
import './Input.scss';
import Skeleton from 'react-loading-skeleton';

interface Props {
  value?: string;
  min?: number;
  max?: number;
  defaultValue?: string;
  placeholder?: string;
  type?: string;
  inputMode?: 'search' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal';
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  id?: string;
  handleClick?: any;
  onChange?: any;
  onKeyDown?: any;
  onBlur?: any;
  onFocus?: any;
  errorTxt?: string;
  loading?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  prefix?: string;
  suffix?: any;
  icon?: string;
  addErrorMargin?: boolean;
  withoutTopMargin?: boolean;
  required?: boolean;
}

const Input = ({
  onChange,
  onKeyDown,
  disabled,
  value,
  min,
  max,
  placeholder,
  type,
  inputMode,
  label,
  handleClick,
  defaultValue,
  readOnly,
  name,
  onBlur,
  onFocus,
  id,
  errorTxt,
  loading,
  autoComplete,
  autoFocus,
  prefix,
  suffix,
  icon,
  addErrorMargin,
  withoutTopMargin,
  required,
}: Props): JSX.Element => {
  if (label && !id) {
    id = label
      .toLowerCase()
      .replace(/[\s+]/g, '-')
      .replace(/[^a-z-]/g, '');
  }
  return (
    <div className="alooba-input" style={withoutTopMargin ? { marginTop: '0' } : {}}>
      {label && (
        <label htmlFor={id}>
          {label} {required && <span className="required-input">*</span>}
        </label>
      )}
      {loading ? (
        <Skeleton height={40} />
      ) : (
        <div
          className={`input-group ${prefix ? 'prefix' : ''} ${suffix ? 'suffix' : ''}
          ${disabled === true ? 'disabled' : ''}
          ${errorTxt ? 'errorInput' : ''}
          ${!errorTxt && addErrorMargin ? 'errorMargin' : ''}`}
        >
          {prefix && <span className="input-group-addon prefix">{prefix}</span>}
          {icon && <img className="icon" src={icon} alt="icon" />}
          <input
            data-testid={id}
            value={value}
            min={min}
            max={max}
            placeholder={placeholder}
            type={type}
            inputMode={inputMode}
            autoComplete={autoComplete}
            disabled={disabled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onClick={handleClick}
            defaultValue={defaultValue}
            readOnly={readOnly}
            name={name}
            onBlur={onBlur}
            onFocus={onFocus}
            id={id}
            className={`${type} ${errorTxt ? 'errorInput' : ''}`}
            autoFocus={autoFocus}
          />
          {suffix && <span className="input-group-addon suffix">{suffix}</span>}
        </div>
      )}
      {errorTxt && <p className="errorMsg">{errorTxt}</p>}
    </div>
  );
};

export default Input;
