import React from 'react';
import { NoPageContent } from 'components/Shared';
import { AssessmentPurpose } from 'components/Assessment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AssessmentCard from './assessmentCard';
import AssessmentLoader from './assessmentLoader';
import authorize from '../../Authorization/Check';

interface AssessmentInfiniteScrollProps {
  loading?: boolean;
  loadingMore?: boolean;
  assessments?: any;
  showDeleteModal?: any;
  showArchiveModal?: any;
  handleDuplicate?: any;
  handleConvertToTemplate?: any;
  assessmentPurpose?: AssessmentPurpose;
  hasFiltered?: boolean;
  handleInviteModal?: any;
  goToFirstCreateAssessmentPage(e, assessmentPurpose): void;
}

const AssessmentInfiniteScroll = ({
  assessments,
  loading,
  loadingMore,
  showDeleteModal,
  showArchiveModal,
  handleDuplicate,
  handleConvertToTemplate,
  assessmentPurpose,
  hasFiltered = false,
  handleInviteModal,
  goToFirstCreateAssessmentPage,
}: AssessmentInfiniteScrollProps): JSX.Element => {
  const capitalize = (s): string => {
    return s && s[0].toUpperCase() + s.slice(1);
  };

  const getEmptyAssessmentsTitle = (): string => {
    const assessmentNoun = assessmentPurpose === 'ld' ? 'exercises' : 'assessments';
    if (hasFiltered) {
      return `There are no ${assessmentNoun} matching the current filter`;
    }
    const service = { hiring: 'Assess', junior: 'Junior', ld: 'Growth' }[assessmentPurpose];
    if (service) {
      return `No Alooba ${service} ${capitalize(assessmentNoun)} Created Yet`;
    }
    return '';
  };

  const getBtnText = (is_description = false): string => {
    if (hasFiltered) return null;
    const noun = assessmentPurpose === 'ld' ? 'exercise' : 'assessment';
    if (is_description) return `Click on the 'Create ${capitalize(noun)}' button to start creating your ${noun}s.`;
    return `Create ${capitalize(noun)}`;
  };

  if (loading) {
    return <AssessmentLoader />;
  }
  if (assessments && assessments.length > 0) {
    return (
      <div className="infinite-scroll-wrapper">
        <TransitionGroup>
          {assessments.map(assessment => {
            return authorize('assessment', 'view', assessment.role.user_id) ? (
              <CSSTransition key={assessment.id} timeout={{ enter: 500, exit: 700 }} classNames="assessment-card">
                <div>
                  <AssessmentCard
                    key={assessment.id}
                    assessment={assessment}
                    showDeleteModal={e => showDeleteModal(e, assessment)}
                    showArchiveModal={e => showArchiveModal(e, assessment)}
                    handleDuplicate={e => handleDuplicate(e, assessment)}
                    handleConvertToTemplate={e => handleConvertToTemplate(e, assessment)}
                    handleInviteModal={handleInviteModal}
                  />
                </div>
              </CSSTransition>
            ) : (
              ''
            );
          })}
        </TransitionGroup>
        {loadingMore && (
          <p className="loadmore">
            <FontAwesomeIcon className="fa-spin mr3" icon={faSpinner} />
            Loading more...
          </p>
        )}
      </div>
    );
  }
  return (
    <NoPageContent
      title={getEmptyAssessmentsTitle()}
      handleClick={e => goToFirstCreateAssessmentPage(e, assessmentPurpose)}
      btnTxt={getBtnText()}
      desc={getBtnText(true)}
    />
  );
};

export default AssessmentInfiniteScroll;
