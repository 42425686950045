import api from './index';

export async function authenticate(partner: string, code: string): Promise<any> {
  return api.post(
    `${process.env.REACT_APP_SERVER_URL}/integrations/${partner}/auth?client_id=${process.env.REACT_APP_CLIENT_ID}`,
    { code },
  );
}

export async function generateKey(partner: string): Promise<any> {
  return api.put(
    `${process.env.REACT_APP_SERVER_URL}/integrations/${partner}/key?client_id=${process.env.REACT_APP_CLIENT_ID}&generate=true`,
  );
}

export async function getIntegrations(): Promise<any> {
  return api.get(`${process.env.REACT_APP_SERVER_URL}/integrations?client_id=${process.env.REACT_APP_CLIENT_ID}`);
}

export async function saveToken(partner: string, token: string): Promise<any> {
  return api.post(
    `${process.env.REACT_APP_SERVER_URL}/integrations/${partner}/key?client_id=${process.env.REACT_APP_CLIENT_ID}`,
    { key: token },
  );
}

export async function connectSmartRecruiter(companyId: string): Promise<any> {
  return api.post(
    `${process.env.REACT_APP_SERVER_URL}/integrations/smartrecruiters/auth?client_id=${process.env.REACT_APP_CLIENT_ID}`,
    {
      companyId,
    },
  );
}
