import { ordinal_number } from 'helpers/numbers';
import React, { useState, useEffect, useRef } from 'react';
import { Accuracy, SelfAwarenessData } from '.';
import './SelfAwarenessChart.scss';

interface Point {
  x: number;
  y: number;
}

interface Line {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

interface AxisLabel extends Point {
  label: string;
}

interface DataPoint extends Point {
  accuracy: Accuracy;
  skillPercentiles: SelfAwarenessData;
}

interface BalloonDataPoint extends DataPoint {
  arrowA: Point;
  arrowB: Point;
  arrowC: Point;
}

interface Props {
  skills: SelfAwarenessData[];
  onSkillActivate: (skill: SelfAwarenessData) => void;
}

/**
 * The svg is 160x160 (not pixels)
 * 100x100 for the grid items inside the grid will have
 * relative postions meaning (0,0) will be at the top left of the grid and not the SVG.
 *
 * The grid has margins of 30 to its left and 5 at the top
 * to allow some space for labels and etc.
 *
 */
const SelfAwarenessChart: React.FC<Props> = ({ skills, onSkillActivate }) => {
  const chart = useRef();

  const gridInterval = 10;
  const [verticalGrid, setVerticalGrid] = useState<Line[]>([]);
  const [verticalAxisLabels, setVerticalAxisLabels] = useState<AxisLabel[]>([]);
  const [horizontalGrid, setHorizontalGrid] = useState<Line[]>([]);
  const [horizontalAxisLabels, setHorizontalAxisLabels] = useState<AxisLabel[]>([]);
  const [balloonDataPoint, setBalloonDataPoint] = useState<BalloonDataPoint>(null);
  const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const balloonWidth = 53;
  const balloonHeight = 25;
  useEffect(() => {
    const timer1 = setTimeout(() => setIsLoaded(true), 500);
    return () => {
      clearTimeout(timer1);
    };
  }, []);
  useEffect(() => {
    setBalloonDataPoint(null);
    setDataPoints(
      skills.map((skill: SelfAwarenessData) => {
        let accuracy = Accuracy.Accurate;
        if (skill.selfRating - skill.scorePercentile > 10) {
          accuracy = Accuracy.Overestimate;
        }

        if (skill.scorePercentile - skill.selfRating > 10) {
          accuracy = Accuracy.Underestimate;
        }
        const dataPoint = {
          x: skill.scorePercentile,
          y: 100 - skill.selfRating,
          shortLabel: skill.shortLabel,
          accuracy,
          skillPercentiles: skill,
        };

        if (skill.isHighlighted) {
          showBalloonFor(dataPoint);
        }

        return dataPoint;
      }),
    );
  }, [skills]);
  useEffect(() => {
    if (chart) {
      // labels on the left for the horizontal lines
      const horizontalLabels: AxisLabel[] = [];

      // labels on the bottom for the the vertical lines
      const verticalLabels: AxisLabel[] = [];

      // vertical grid lines are nested inside a <g>
      // first line starts at 0 because its
      //  position is relative to it's parent
      const xGrids: Line[] = [];
      while (xGrids.length < 11) {
        verticalLabels.push({
          label: `${xGrids.length * gridInterval}`,
          x: xGrids.length * gridInterval,
          y: 105,
        });
        xGrids.push({
          y1: 0,
          y2: 100,
          x1: xGrids.length * gridInterval,
          x2: xGrids.length * gridInterval,
        });
      }
      setVerticalGrid(xGrids);
      setVerticalAxisLabels(verticalLabels);

      // horizontal grid lines are similar to its vertical counterpart
      // wherein its positioning is relative to its parent <g>
      const yGrids: Line[] = [];

      // label for the zero value
      horizontalLabels.push({
        label: `0`,
        x: -3,
        y: 100 - yGrids.length * gridInterval,
      });

      while (yGrids.length < 11) {
        yGrids.push({
          x1: 0,
          x2: 100,
          y1: yGrids.length * gridInterval,
          y2: yGrids.length * gridInterval,
        });

        horizontalLabels.push({
          label: `${yGrids.length * gridInterval}`,
          x: -3,
          y: 100 - yGrids.length * gridInterval,
        });
      }
      setHorizontalGrid(yGrids);
      setHorizontalAxisLabels(horizontalLabels);
    }
  }, [chart]);

  const showBalloonFor = (point: DataPoint): void => {
    let arrowA = { x: 23, y: 24 };
    let arrowB = { x: 25, y: 28 };
    let arrowC = { x: 27, y: 24 };
    let x = point.x - balloonWidth / 2;
    let y = point.y - balloonHeight - 10;
    x += 0;
    y += 0;

    // too high
    if (point.y < 25) {
      y += 42.5;
      const midPoint = balloonWidth / 2;
      arrowA = { x: midPoint - 2, y: 1 };
      arrowB = { x: midPoint, y: -3 };
      arrowC = { x: midPoint + 2, y: 1 };
    }

    setBalloonDataPoint({
      ...point,
      x,
      y,
      arrowA,
      arrowB,
      arrowC,
    });
  };

  return (
    <div className="self-awareness-chart-wrap">
      <svg version="1.1" viewBox="0 0 160 160" className="chart-svg" ref={chart}>
        <g className="plot" transform="translate(30,5)">
          <g className="grid-label legend self-rating" transform="rotate(-90)">
            <text x="-50" y="-10">
              Relative Self Rating Percentile
            </text>
          </g>
          <g className="grid-label legend score">
            <text x="50" y="110">
              Relative Score Percentile
            </text>
          </g>
          {verticalGrid.map((line: Line) => (
            <g className="x-grid grid-line">
              <line x1={line.x1} x2={line.x2} y1={line.y1} y2={line.y2} />
            </g>
          ))}
          {verticalAxisLabels.map((label: AxisLabel) => (
            <g className="grid-label vertical">
              <text x={label.x} y={label.y}>
                {label.label}
              </text>
            </g>
          ))}
          {horizontalGrid.map((line: Line) => (
            <g className="y-grid grid-line">
              <line x1={line.x1} x2={line.x2} y1={line.y1} y2={line.y2} />
            </g>
          ))}
          {horizontalAxisLabels.map((label: AxisLabel) => (
            <g className="grid-label horizontal">
              <text x={label.x} y={label.y}>
                {label.label}
              </text>
            </g>
          ))}
          <g className="accuracy-baseline">
            <line x1={0} y1={90} x2={90} y2={0} />
            <line x1={10} y1={100} x2={100} y2={10} />
          </g>

          <g className={isLoaded ? 'skill-level-estimation-labels' : ''}>
            <text x={50} y={20} className="overestimate">
              Overestimates Skill Level
            </text>
            <text x={50} y={50} className="accurate">
              Accurately Estimates Skill Level
            </text>
            <text x={50} y={80} className="underestimate">
              Underestimates Skill Level
            </text>
          </g>
          {dataPoints.map((p: DataPoint) => {
            return (
              <g
                className="datapoint"
                onMouseOver={() => onSkillActivate(p.skillPercentiles)}
                onMouseLeave={() => onSkillActivate(null)}
              >
                <circle className={p.accuracy} cx={p.x} cy={p.y} r="4" />
                <text x={p.x} y={p.y + 0.5} className="underestimate">
                  {p.skillPercentiles.shortLabel}
                </text>
              </g>
            );
          })}

          {balloonDataPoint ? (
            <g className="tooltip" transform={`translate(${balloonDataPoint.x}, ${balloonDataPoint.y})`}>
              <polygon
                className="balloon"
                points={`
                    ${balloonDataPoint.arrowA.x}, ${balloonDataPoint.arrowA.y} 
                    ${balloonDataPoint.arrowB.x}, ${balloonDataPoint.arrowB.y} 
                    ${balloonDataPoint.arrowC.x}, ${balloonDataPoint.arrowC.y} 
                `}
              />
              <rect x={0} y={0} width={balloonWidth} height={balloonHeight} />

              <path id="path1" d="M0,3.5 H45 M3.5,6.6 H45" />
              {balloonDataPoint.skillPercentiles.label.length <= 25 ? (
                <text x="3" y="5.5" className="skill-name">
                  {balloonDataPoint.skillPercentiles.label}
                </text>
              ) : (
                <text x="3" y="5" className="skill-name">
                  <textPath href="#path1">{balloonDataPoint.skillPercentiles.label}</textPath>
                </text>
              )}
              <text x="3" y="12" className="accuracy">
                {balloonDataPoint.skillPercentiles.selfAwareness}
              </text>
              <text x="3" y="17" className="rating-label">
                Self Rating:
              </text>
              <text x="23" y="17" className="score">
                {ordinal_number(balloonDataPoint.skillPercentiles.selfRating)} Percentile
              </text>
              <text x="3" y="22" className="rating-label">
                Score:
              </text>
              <text x="23" y="22" className="score">
                {ordinal_number(balloonDataPoint.skillPercentiles.scorePercentile)} Percentile
              </text>
            </g>
          ) : (
            ''
          )}
        </g>
      </svg>
    </div>
  );
};

export default SelfAwarenessChart;
