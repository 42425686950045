import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  assessmentTitle?: string;
  roleTitle?: string;
  assessment?: any;
}

function GeneratingAssessment({ assessmentTitle, roleTitle, assessment }: Props): JSX.Element {
  if (!assessmentTitle && roleTitle) {
    if (assessment?.stage) {
      assessmentTitle = `${roleTitle} ${assessment.stage} Assessment`;
    } else {
      assessmentTitle = `${roleTitle} Assessment`;
    }
  }
  if (!assessmentTitle) {
    assessmentTitle = 'Assessment';
  }

  return (
    <div className="main-container generating-assessment">
      <h3 className="generating-assessment-text">Generating your {assessmentTitle}...</h3>
      <FontAwesomeIcon size="3x" className="fa-spin" icon={faCircleNotch} color="#004385" />
    </div>
  );
}

export default GeneratingAssessment;
