import React, { useState, useMemo } from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import SubjectScore from './SubjectScore/index';
import SubjectDetails from './SubjectDetails/index';

const AreasForReview = (): any => {
  const [currentSubject, setCurrentSubject] = useState(0);
  const { candidateResources, candidate } = useSelector((state: RootState) => state.candidate);

  const hasOrganisationResources = useMemo(() => {
    return (
      candidateResources.subjects.some(subject => subject.skill_resources.some(resource => resource.organisation_id)) ||
      candidateResources.topics.some(topic => topic.skill_resources.some(resource => resource.organisation_id))
    );
  }, [candidateResources]);

  return (
    <div className="areas-for-review-tab">
      <div className="tab-header">
        <h3>Areas For Review</h3>
        <div className="description">
          <p className="description">
            Here is an overview of how you performed across each of the areas within the assessment.
          </p>
          <p>
            This should help give you an indication of where you may benefit from doing a little reading up on.{' '}
            <a href="https://google.com">Google</a>, <a href="https://youTube.com">YouTube</a>,{' '}
            <a href="https://stackoverflow.com">Stack Overflow</a>, and <a href="https://www.mooc.org/">MOOCs</a> are
            generally good starting places.
          </p>
          {(candidateResources.subjects.some(subject => subject.skill_resources.length > 0) ||
            candidateResources.topics.some(topic => topic.skill_resources.length > 0)) && (
            <p>
              Alooba {hasOrganisationResources ? `and ${candidate?.organisation_name} have` : 'has'} also handpicked a
              few resources for you to learn from.
            </p>
          )}
        </div>
      </div>
      <div className="main-container test-results-content">
        <div className="side-by-side">
          <div className="question-sidebar">
            <div className="subject-section-wrapper">
              {candidateResources.subjects.map((subject, index) => (
                <SubjectScore
                  subject={subject}
                  index={index}
                  onClick={setCurrentSubject}
                  active={currentSubject === index}
                />
              ))}
            </div>
          </div>
          <div className="right test-question-details">
            {candidateResources.subjects.length > 0 && candidateResources.subjects[currentSubject] && (
              <SubjectDetails
                subject={candidateResources.subjects[currentSubject]}
                topics={candidateResources.topics
                  .filter(topic => topic.subject_id === candidateResources.subjects[currentSubject].id)
                  .sort((topic1, topic2) => topic2.max_score - topic1.max_score)
                  .sort((topic1, topic2) => topic1.score / topic1.max_score - topic2.score / topic2.max_score)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AreasForReview;
