import React, { useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Button } from 'components/Shared';
import { useDispatch } from 'react-redux';
import { setCurrentAssessment } from 'store/actions/assessment.actions';
import { goToLegacyApp } from 'hooks';
import { publishAssessment } from 'api/assessment.api';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { getFormattedAssessmentName } from 'helpers';
import QuestionIcon from '../../images/icons/question-color.svg';
import DurationIcon from '../../images/icons/clock-colored.svg';
import EasyIcon from '../../images/icons/difficulty-easy-colored.svg';
import MediumIcon from '../../images/icons/difficulty-medium-colored.svg';
import HardIcon from '../../images/icons/difficulty-hard-colored.svg';

interface Props {
  template: any;
  createdAssessment: any;
  stage: string;
  title: string;
}

function AssessmentDetails({ template, createdAssessment, stage, title }: Props): JSX.Element {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const [publishing, setPublishing] = useState(false);

  let parts = [];
  if (template && template.parts) {
    parts = [...template.parts];
  } else if (createdAssessment && createdAssessment.parts) {
    parts = Object.keys(createdAssessment.parts).map(key => createdAssessment.parts[key]);
  }
  if (parts.length > 1) {
    parts.sort((a, b) => {
      return a.index - b.index;
    });
  }

  const getAssessmentStageText = (): string => {
    if (stage === 'In-Depth') {
      return 'an in-depth assessment';
    }
    if (stage === 'Screening') {
      return 'a quick initial screening';
    }
    return `a ${stage.toLowerCase()}`;
  };

  const numberWords = {
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
  };
  const formatDuration = (duration): ReactElement => {
    const hours = Math.trunc(duration / 60 / 60);
    const minutes = (duration - hours * 60 * 60) / 60;
    return (
      <>
        {hours > 0 && (
          <>
            <span className="value">{hours}&nbsp;</span>
            <span className="label">{hours > 1 ? 'hours' : 'hour'}</span>
          </>
        )}
        {minutes > 0 && (
          <>
            <span className="value">{minutes}&nbsp;</span>
            <span className="label">{minutes > 1 ? 'minutes' : 'minute'}</span>
          </>
        )}
      </>
    );
  };

  const publish = (): void => {
    setPublishing(true);
    dispatch(setCurrentAssessment({}));
    publishAssessment(createdAssessment.id)
      .then(() => {
        addToast({
          type: 'success',
          msg: 'Assessment published successfully',
        });
        history.push(
          `/assessment/${createdAssessment?.id}-${getFormattedAssessmentName(
            createdAssessment?.recruiter_test_name,
          )}/overview`,
        );
      })
      .catch(e => {
        addToast({
          type: 'error',
          msg: e.response?.data?.message ? e.response?.data?.message : 'There was an error fetching assessments',
        });
      })
      .finally(() => {
        setPublishing(false);
      });
  };

  return (
    <div>
      <div className="main-container assessment-details">
        <div className="details-card-header">
          <h3>{`${title} ${stage} Assessment`}</h3>
        </div>
        <br />
        {parts.length === 1 ? (
          <p>
            This assessment consists of a single{' '}
            <strong>{parts[0].type === 'MCQ' ? 'Concepts & Knowledge' : parts[0].type}</strong> test tailored
            specifically to do {getAssessmentStageText()} for your {title} role.
          </p>
        ) : (
          <p>
            This assessment consists of {numberWords[parts.length]} tests tailored specifically to do{' '}
            {getAssessmentStageText()} for your {title} role.
            <br />
            Candidates will be required to take all tests to complete the assessment, giving you a full picture of their
            skills across the relevant areas.
          </p>
        )}
        {createdAssessment.subjects_order_by_contribution.length > 0 && (
          <>
            <h5>Skills Assessed:</h5>
            <div className="skills">
              {createdAssessment.subjects_order_by_contribution.map(subject => {
                return (
                  <span className="skill" key={subject.id}>
                    {subject.subject}
                  </span>
                );
              })}
            </div>
          </>
        )}
        <div className="parts">
          {parts.map(part => {
            return (
              <div className="part-container">
                <div className="part">
                  <h3 className="type">{part.type === 'MCQ' ? 'Concepts & Knowledge' : part.type} Test</h3>
                  <div className="description">
                    {part.type === 'MCQ' && <p>A multiple choice quiz covering multiple topics.</p>}
                    {part.type === 'Data Analysis' && (
                      <>
                        <p>
                          A practical data analysis test with real data that will need to be imported into their
                          analytical tool of choice to answer questions to common basic business problems.
                        </p>
                      </>
                    )}
                    {part.type === 'SQL' && (
                      <p>A practical SQL test requiring them to write SQL statements to query a database.</p>
                    )}
                    {(part.type === 'Python Coding' || part.type === 'Analytics Coding') && (
                      <p>
                        A practical coding test requiring them to write Python or R code to answer analytical problems
                        for the given datasets.
                      </p>
                    )}
                    {part.type === 'Free Response' && (
                      <>
                        <p>
                          They will dive deeper into providing more complex responses that will require some manual
                          grading.
                        </p>
                      </>
                    )}
                    {part.type === 'Personality Profiling' && (
                      <p>Gain insight into your candidates&apos; personality profiles.</p>
                    )}
                  </div>
                  {part.type !== 'Personality Profiling' && (
                    <div className="stats">
                      <div className="stat">
                        <img className="icon" src={DurationIcon} alt="Scheduled duration icon" />
                        {formatDuration(part.duration)}
                        <span className="label">&nbsp;test duration</span>
                      </div>
                      <div className="stat">
                        <img className="icon" src={QuestionIcon} alt="Number of questions icon" />
                        <span className="value">{part.num_questions}&nbsp;</span>
                        <span className="label">question{part.num_questions !== 1 && 's'}</span>
                      </div>
                      {part.difficulty && (
                        <div className="stat">
                          {part.difficulty === 'Easy' && (
                            <>
                              <img className="icon" src={EasyIcon} alt="Difficulty icon" />
                              <span className="value">Standard</span>
                              <span className="label">&nbsp;difficulty</span>
                            </>
                          )}
                          {part.difficulty === 'Medium' && (
                            <>
                              <img className="icon" src={MediumIcon} alt="Difficulty icon" />
                              <span className="value">Advanced</span>
                              <span className="label">&nbsp;difficulty</span>
                            </>
                          )}
                          {part.difficulty === 'Hard' && (
                            <>
                              <img className="icon" src={HardIcon} alt="Difficulty icon" />
                              <span className="value">Expert</span>
                              <span className="label">&nbsp;difficulty</span>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {parts.length > 1 && <div className="part-index">{part.index}</div>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="main-container action-buttons">
        <Button
          variant="sub-primary lg"
          text="Preview Test Questions"
          onClick={e => {
            goToLegacyApp(e, `test-intro/${createdAssessment.preview_code}/1`, true);
          }}
        />
        <Button
          variant="primary lg"
          text="Create & Publish Assessment"
          onClick={publish}
          loading={publishing}
          loadingTxt="Publishing Assessment..."
          disabled={publishing}
        />
      </div>
    </div>
  );
}

export default AssessmentDetails;
