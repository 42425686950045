import React from 'react';
import { faPencilAlt, faTimes, faUserAltSlash, faRegistered } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionMenu, { MenuPositions } from 'components/Shared/Dropdown/ActionMenu';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

interface ActionMenuProps {
  row: any;
  handleClickUpdateUser: any;
  setSelectedUser: any;
  setShowDeleteUserModal: any;
  setShowSuspendUserModal: any;
}
const ActionMenuContainer: React.FC<ActionMenuProps> = ({
  row,
  handleClickUpdateUser,
  setSelectedUser,
  setShowDeleteUserModal,
  setShowSuspendUserModal,
}: ActionMenuProps): JSX.Element => {
  const { userDetails } = useSelector((state: RootState) => state.profile);
  return (
    <>
      <td>
        <div>
          <ActionMenu
            id={`hiring-team-actions-${row.user_id}`}
            key={`hiring-team-action-menu-${row.user_id}`}
            items={[
              // update user modal
              row.Status !== 'Suspended' && (
                <p key="edit-user" onClick={() => handleClickUpdateUser(row)}>
                  <FontAwesomeIcon className="icon" icon={faPencilAlt} />
                  Edit
                </p>
              ),
              // Suspend user modal
              row.Permissions !== 'Owner' && row.id !== userDetails?.id && (
                <p
                  key="suspend-user"
                  onClick={() => {
                    setSelectedUser(row);
                    setShowSuspendUserModal(true);
                  }}
                >
                  <FontAwesomeIcon className="icon" icon={row.Status === 'Suspended' ? faRegistered : faUserAltSlash} />
                  {row.Status === 'Suspended' ? 'Reactivate' : 'Suspend'}
                </p>
              ),
              // delete user modal
              row.Permissions !== 'Owner' && row.id !== userDetails?.id && (
                <p
                  key="delete-user"
                  onClick={() => {
                    setSelectedUser(row);
                    setShowDeleteUserModal(true);
                  }}
                >
                  <FontAwesomeIcon className="icon" icon={faTimes} />
                  Delete
                </p>
              ),
            ]}
            position={MenuPositions.BottomLeft}
          />
        </div>
      </td>
    </>
  );
};
export default ActionMenuContainer;
