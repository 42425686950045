import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useFormik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { extendExpiry } from 'api/assessment.api';
import moment from 'moment-timezone';
import { convertTimezone, formatDateTime, fullDateTime } from 'helpers/datetime';
import FormContainer from 'components/Shared/MainContainer/FormContainer';
import DateTimePicker from 'components/Shared/DateTimePicker';
import { handleApiError } from 'helpers/auth-flow';
import { useDispatch } from 'react-redux';
import MemoizedTimezoneDropDown from 'components/Shared/Dropdown/MemoizedTimezoneDropDown';
import Input from 'components/Shared/Input';
import { stopEvent } from 'helpers/events';
import { Button } from 'components/Shared';
import ParticipantModal from './ParticipantModal';
import './index.scss';
import { RefreshContext as ParticipantsRefreshContext } from '../context/refresh';

interface ExtendExpiryModalProps {
  row: any;
  assessment: any;
  setModalVisibility: any;
  userDetails: any;
  shouldCloakPII: boolean;
  /**
   * Callback function to be called when the expiry date is successfully extended.
   *
   * @param expiryDate      The new expiry date in UTC
   * @param candidateStatus The new candidate status
   *
   * @returns void
   */
  onSuccess?: (expiryDate: string, candidateStatus: string) => void;
}
const ExtendExpiryModal = (props: ExtendExpiryModalProps): JSX.Element => {
  const { row, setModalVisibility, assessment, userDetails, onSuccess } = props;
  const [confirming, setConfirming] = useState<boolean>(false);
  const [mustValidate, setMustValidate] = useState<boolean>(false);
  const [mustConfirm, setMustConfirm] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [values, setValues] = useState<any>({
    tz_code: userDetails.user_timezone,
    candidate_test_id: row.candidate_test_id,
    new_expiry_date: '',
    new_expiry_date_verbose: '',
    tz_offset: '',
  });
  const showToast = (msg: string, success: boolean): void => {
    addToast({
      type: success ? 'success' : 'error',
      msg,
    });
  };
  const [, setFirstLoad, , timezones] = useContext(ParticipantsRefreshContext);
  const currentExpiry = moment(row['Expires On']);
  const validator = useCallback((): boolean => {
    if (values.new_expiry_date === '') {
      setErrorText('You must provide a new expiry date.');
      return false;
    }
    const nowLiteral = moment().utc().format('YYYY-MM-DD HH:mm:ss');
    const now = moment(nowLiteral);
    const expiryUtc = moment(values.new_expiry_date).tz(values.tz_code).utc().format('YYYY-MM-DD HH:mm:ss');
    const newExpiry = moment(expiryUtc);
    if (newExpiry.diff(currentExpiry, 'minutes') <= 1441) {
      setErrorText('New expiry date must be at least 24 hours after current expiry date.');
      return false;
    }
    if (newExpiry.diff(now, 'minutes') <= 1441) {
      setErrorText('New expiry date must be at least 24 hours from now.');
      return false;
    }
    setErrorText('');
    return true;
  }, [values.new_expiry_date, values.tz_code, currentExpiry]);

  const { shouldCloakPII } = props;
  useEffect(() => {
    if (shouldCloakPII) {
      setDisplayName(row.Reference);
    } else {
      setDisplayName(row.Name);
    }
  }, [shouldCloakPII, row]);

  useEffect(() => {
    if (mustValidate) {
      validator();
      setMustValidate(false);
    }
  }, [mustValidate, validator]);

  const handleButtonAction = async (e: React.SyntheticEvent<EventTarget>): Promise<any> => {
    stopEvent(e);
    if (!validator()) {
      return;
    }
    if (confirming) {
      return;
    }
    setConfirming(true);
    if (!mustConfirm) {
      setMustConfirm(true);
      setTimeout(() => {
        setConfirming(false);
      }, 500);
      return;
    }
    const bodyFormData = new FormData();
    bodyFormData.append('id', row.candidate_test_id);
    const errorToast = (msg: string): void => {
      addToast({
        type: 'error',
        msg,
      });
    };
    extendExpiry(formik.values)
      .then(response => {
        if (response.data.error) {
          errorToast(response.data.message);
        } else {
          addToast({
            type: 'success',
            msg: `Assessment expiry has been extended for ${displayName}.`,
          });

          const dateUtc = convertTimezone(values.new_expiry_date, values.tz_code, 'UTC');

          onSuccess?.call(null, dateUtc, response.data.candidate_status);
        }
        setFirstLoad(true);
      })
      .catch(e => {
        // if the user is unauthenticated in the old app, redirect them to the login page
        const authenticationErrorMessage = `You are logged out. Please login and try extending the expiry again.`;
        const errorMessage = 'Unable to extend expire date';
        handleApiError(e, dispatch, null, showToast, errorMessage, authenticationErrorMessage);
      });
    setConfirming(false);
    setMustConfirm(false);
    setModalVisibility(false);
  };
  const handleClose = (): void => {
    setMustConfirm(false);
  };
  const handleBack = (e): void => {
    stopEvent(e);
    setMustConfirm(false);
  };
  const extraButton = (
    <div style={{ marginRight: '8px' }}>
      <Button type="button" onClick={handleBack} variant="sub-primary md" text="Back" />
    </div>
  );
  const customProps = {
    loading: confirming,
    actionText: 'Confirm',
    showCancel: true,
    cancelVariant: 'sub-primary',
    handleButtonAction,
    containerClass: 'participants-modal extend-expiry',
    isSubmit: true,
    handleClose,
    extraButton: mustConfirm && extraButton,
  };
  const handleTimezoneChange = (option): void => {
    const changeValues = {
      tz_code: option.code,
      tz_offset: option.offset,
      new_expiry_date: '',
    };
    setValues({
      ...values,
      ...changeValues,
    });
  };
  const handleDateTimeChange = (value: string): void => {
    setValues({
      ...values,
      ...{
        new_expiry_date: value,
        new_expiry_date_verbose: fullDateTime(value, values.tz_code),
      },
    });
    setMustValidate(true);
  };
  const dataInfoRow = (title: string, value: string): any => {
    return (
      <div className="data-row">
        <div className="title">{`${title}:`}</div>
        <div className="value">{value}</div>
      </div>
    );
  };
  const customInput = (
    <Input
      type="text"
      label="New Expiry Date"
      autoComplete="off"
      placeholder="Select a new expiry"
      errorTxt={errorText}
      onChange={e => handleDateTimeChange(e.target.value)}
    />
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      candidate_test_id: values.candidate_test_id,
      new_expiry_date: values.new_expiry_date,
      new_expiry_date_verbose: values.new_expiry_date_verbose,
      tz_offset: values.tz_offset,
      tz_code: values.tz_code,
    },
    onSubmit: (): void => {
      validator();
    },
  });
  const DateTimePickerProps = (() => {
    const expiresOn: Date = moment(fullDateTime(row['Expires On'], userDetails.user_timezone)).toDate();
    const today: Date = moment(fullDateTime(Date(), userDetails.user_timezone)).toDate();

    return {
      customInput,
      utc: values.new_expiry_date,
      handleChange: handleDateTimeChange,
      props: {
        // User can't choose a date before today or even earlier than the current expiration date.
        minDate: expiresOn > today ? expiresOn : today,
      },
    };
  })();
  return (
    <ParticipantModal {...{ ...props, ...customProps }}>
      <>
        <h2>Extend Assessment Expiry</h2>
        <br />
        {!mustConfirm ? (
          <>
            <p>{`You can extend the expiry date for ${displayName}. You'll have a chance to double check the details on the next step. Once confirmed, ${displayName} will be emailed.`}</p>
          </>
        ) : (
          <>
            <p>Please triple check the details.</p>
            <p>
              {`As soon as you confirm, we’ll email ${displayName} ${
                shouldCloakPII ? '' : `at ${row.Email}`
              } to confirm the extension.`}
            </p>
          </>
        )}
        <div className="extend-expiry-data">
          {dataInfoRow('Assessment', assessment.recruiter_test_name)}
          {dataInfoRow('Candidate', displayName)}
          {dataInfoRow(
            'Current Expiry Date',
            `${fullDateTime(row['Expires On'], userDetails.user_timezone, 'lll [(GMT]Z[)]')} ${
              userDetails.user_timezone
            }`,
          )}
          {mustConfirm &&
            dataInfoRow(
              'New Expiry Date',
              `${formatDateTime(values.new_expiry_date, values.tz_code, 'lll [(GMT]Z[)]')} ${values.tz_code}`,
            )}
        </div>
        {!mustConfirm && (
          <FormContainer>
            <div className="container">
              <div className="row">
                <MemoizedTimezoneDropDown
                  id="timezone"
                  label="Timezone"
                  placeholder="Search Timezones..."
                  value={formik.values.tz_code}
                  handleChange={handleTimezoneChange}
                  menuPlacement="top"
                  defaultTimezones={timezones}
                />
                <DateTimePicker {...DateTimePickerProps} />
              </div>
            </div>
          </FormContainer>
        )}
      </>
    </ParticipantModal>
  );
};
export default ExtendExpiryModal;
