import React from 'react';
import Button from '../Button';
import './noContent.scss';

interface Props {
  handleClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
  title?: string;
  desc?: string;
  btnTxt?: string;
  icon?: boolean;
}

const NoPageContent = ({ handleClick, title, desc, btnTxt, icon = true }: Props): JSX.Element => {
  return (
    <div className="no-group-content">
      <h3 className="my4">{title}</h3>
      <p className="mb4">{desc}</p>
      {btnTxt && <Button addButton={icon} onClick={handleClick} variant="primary md" text={btnTxt} />}
    </div>
  );
};

export default NoPageContent;
