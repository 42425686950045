import React from 'react';
import Skeleton from 'react-loading-skeleton';

const AssessmentLoader = (): JSX.Element => {
  return (
    <>
      {[1, 2, 3, 4].map(item => {
        return (
          <div key={item}>
            <div className="assessment-card skeleton">
              <div className="assessment-details">
                <div className="first-row">
                  <p className="first-col">
                    <Skeleton height={16} width={250} />
                  </p>
                </div>
                <div className="second-row">
                  <p className="first-col">
                    <Skeleton className="mr2" height={18} width={18} />
                    <Skeleton height={16} width={200} />
                  </p>
                  <p className="second-col">
                    <Skeleton className="mr2" height={18} width={18} />
                    <Skeleton height={16} width={150} />
                  </p>
                </div>
                <div className="third-row">
                  <p className="first-col">
                    <Skeleton className="mr2" height={18} width={18} />
                    <Skeleton height={16} width={300} />
                  </p>
                  <p className="second-col">
                    <Skeleton className="mr2" height={18} width={18} />
                    <Skeleton height={16} width={100} />
                  </p>
                  <p className="third-col">
                    <Skeleton className="mr1" height={16} width={18} />
                    <Skeleton className="mr4" height={16} width={14} />
                    <Skeleton className="mr1" height={16} width={16} />
                    <Skeleton className="mr4" height={16} width={14} />
                    <Skeleton className="mr1" height={18} width={18} />
                    <Skeleton className="mr4" height={16} width={14} />
                    <Skeleton className="mr1" height={16} width={18} />
                    <Skeleton className="mr4" height={16} width={26} />
                  </p>
                </div>
              </div>
              <div className="actions">
                <Skeleton className="mr4" height={20} width={20} />
                <Skeleton className="mr4" height={20} width={20} />
                <Skeleton className="mr4" height={20} width={20} />
                <Skeleton height={20} width={20} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AssessmentLoader;
