import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UsersState {
  // userGroups is a Dictionary with user ID as the key and
  // the array of group ids the user is assigned to
  userGroups: { [id: number]: number[] };
  gettingUsers: boolean;
  users: any;
}

const initialState: UsersState = {
  userGroups: {},
  gettingUsers: false,
  users: null,
};

// Group that a user is assigned to
export interface UserGroup {
  id: number;
  name: string;
  organisation_id: number;
  users?: any[];
}
const usersReducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUserGroupsSuccess: (state: UsersState, action: PayloadAction<{ data: UserGroup[] }>) => {
      state.userGroups = {};
      action.payload.data.forEach((group: UserGroup) => {
        if (group.users.length > 0) {
          group.users.forEach(user => {
            if (!state.userGroups[user.id]) {
              state.userGroups[user.id] = [];
            }

            // prevent duplicates from being added
            if (!state.userGroups[user.id].find(g => g === group.id)) {
              state.userGroups[user.id].push(group.id);
            }
          });
        }
      });
    },
    setUserGroups: (state: UsersState, action: PayloadAction<{ userId: number; groups: number[] }>) => {
      state.userGroups[action.payload.userId] = action.payload.groups;
    },
    setGettingUsers: (state: UsersState, action: PayloadAction<boolean>) => {
      state.gettingUsers = action.payload;
    },
    setUsers: (state: UsersState, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
  },
});
export const { getUserGroupsSuccess, setUserGroups, setGettingUsers, setUsers } = usersReducer.actions;
export default usersReducer.reducer;
