import rules, { RulesOptions } from './rbac-rules';

const check = (userRules: RulesOptions, role: string, action: string, data?: Record<string, unknown>): boolean => {
  const permissions = userRules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

interface IProps {
  role: string;
  perform: string;
  data?: Record<string, unknown>;
  yes: () => JSX.Element;
  no?: () => JSX.Element;
}

const Can = (props: IProps): JSX.Element | null => {
  const { role, perform, data, yes, no } = props;
  if (check(rules, role, perform, data)) return yes();
  if (no) return no();
  return null;
};

export default Can;
