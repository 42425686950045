import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import StickyTableCellRight from './StickyTableCellRight';

const ActionMenuCell = (props): JSX.Element => {
  const { actionMenu, row, hasSticky } = props;
  if (!actionMenu) {
    return <></>;
  }
  if (hasSticky.current) {
    return (
      <StickyTableCellRight className="action-menu-cell sticky-right sticky">{actionMenu(row)}</StickyTableCellRight>
    );
  }
  return <TableCell className="action-menu-cell">{actionMenu(row)}</TableCell>;
};

export default React.memo(ActionMenuCell);
