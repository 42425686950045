import CandidateDetails from 'components/CandidateDetails';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { activatePageHeader } from '../store/reducers/layout';

interface UrlParams {
  reference?: string;
}

const CandidateDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  dispatch(activatePageHeader());
  const { reference }: UrlParams = useParams();
  return (
    <div className="container">
      <Helmet>
        <title>{`Candidate Details ${decodeURIComponent(reference)} | alooba.com`}</title>
      </Helmet>
      <CandidateDetails />
    </div>
  );
};

export default CandidateDetailsPage;
