import axios from 'axios';
import api from './index';
import apiLegacy from '../api-legacy';
import { GetOrganizationUsersQueryParams } from './contract';

export interface OrganizationDetails {
  id: number;
  name: string;
  website: string;
  industry: any;
  size_employees: string;
  is_recruitment_company?: number;
  cloak_candidates?: boolean;
  organisation_logo_url?: string;
  brand_color?: string;
  ai_candidate_feedback?: boolean;
}

export interface ArrayPayload {
  data: Array<Record<string, unknown>>;
}
export interface ObjectPayload {
  data: Record<string, unknown>;
}
export interface ProfileResult {
  [x: string]: any;
  payload?: ObjectPayload;
}
export interface OrganizationResult {
  data: any;
  payload?: ObjectPayload;
}
export interface HiringTeamResult {
  data: any;
  payload?: ArrayPayload;
}

export interface AddHiringTeamUserFormData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string;
  permissions: string; // 'admin' | 'standard' | 'restricted'
  organisation_id: number;
}

export interface DeleteHiringTeamFormData {
  user_id: number;
  organisation_id: number;
}

export interface SuspendHiringTeamFormData {
  user_id: number;
  organisation_id: number;
}

export interface UpdateHiringTeamFormData {
  user_id: number;
  organisation_id: number;
  permissions?: string; // 'admin' | 'standard' | 'restricted'
  mfa_enabled?: boolean;
  phone_number?: any;
  status?: string; // 'Active' | 'Suspend'
}

export interface UserAndOrganizationDetailsFormData {
  first_name: string;
  last_name: string;
  job_title: string;
  organization_name: string;
  phone_number?: any;
  user_timezone?: string;
}

export async function getOrganization(orgId: number): Promise<OrganizationResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.get(url);
  return response.data;
}

export async function setOrganizationDetails(orgId: number, patchData: any): Promise<OrganizationResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.patch(url, patchData);
  return response.data;
}

export async function uploadLogo(organisation: OrganizationDetails, file: File): Promise<void> {
  // step 1: get a presigned url where the file will be uploaded
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${organisation.id}/logo-upload-url?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.get(url, { params: { filename: file.name } });

  const uploadUrl = response.data.url;

  // step 2: upload the file
  // need to use a new axios instance here because the Authorization cannot be
  // present while X-Amz-Algorithm is in the query params
  const uploadResponse = await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
  if (uploadResponse.status >= 200 && uploadResponse.status < 300) {
    // step 3: update the organization  logo url
    setOrganizationDetails(organisation.id, {
      cloak_candidates: organisation.cloak_candidates,
      organisation_logo_url: response.data.organisation_logo_url,
    });
  }
}

export async function getOrganizationUsers(orgId: number, data?: GetOrganizationUsersQueryParams): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/users?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.get(url, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      ...data,
    },
  });
  return response;
}
export async function getOrganizationInsights(orgId: number, product: string): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${orgId}/insights?product=${product}&client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.get(url);
  return response;
}

export async function getOrganizationGroups(): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/groups?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.get(url);
  return response;
}

export async function getUserGroups(userId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/users/${userId}/groups?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.get(url);
  return response;
}

export async function checkGroupAssessment(groupId: number): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/groups/${groupId}/has-assessment-ajax?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await apiLegacy.get(url);
  return response.data && response.data.has_assessment;
}

export async function createOrganizationGroups(): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/groups`;
  const response = await api.post(url, {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  });
  return response;
}

export async function addGroupUser(groupId: number, userId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/groups/${groupId}/users`;
  const response = await api.post(url, {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    user_id: userId,
  });
  return response;
}

export async function removeGroupUser(orgId: number, userId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/groups/${orgId}/users/${userId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.delete(url);
  return response;
}

export async function updateOrganizationGroup(groupId: number, name: string): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/groups/${groupId}`;
  const response = await api.patch(url, {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    name,
  });
  return response;
}

export async function deleteOrganizationGroups(groupId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/groups/${groupId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  const response = await api.delete(url);
  return response;
}

export async function userApi(userId: number): Promise<ProfileResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/users/${userId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;

  const response = await api.get(url);

  return response.data;
}

export async function addHiringTeamUser(
  addHiringTeamUserFormData: AddHiringTeamUserFormData,
): Promise<HiringTeamResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${addHiringTeamUserFormData.organisation_id}/users`;
  const response = await api.post(url, {
    ...addHiringTeamUserFormData,
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  });
  return response.data;
}

export async function deleteHiringTeamUser(
  deleteHiringTeamFormData: DeleteHiringTeamFormData,
): Promise<HiringTeamResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${deleteHiringTeamFormData.organisation_id}/users/${deleteHiringTeamFormData.user_id}`;
  const response = await api.delete(url, {
    data: {
      ...deleteHiringTeamFormData,
      client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    },
  });
  return response.data;
}

export async function updateHiringTeamUser(formData: UpdateHiringTeamFormData): Promise<HiringTeamResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/organizations/${formData.organisation_id}/users/${formData.user_id}`;
  const response = await api.patch(url, {
    ...formData,
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  });

  return response.data;
}

export async function updateUserAndOrganizationDetails(
  userAndOrganizationDetailsFormData: UserAndOrganizationDetailsFormData,
): Promise<HiringTeamResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/users/complete-signup-flow`;
  const response = await api.post(url, {
    ...userAndOrganizationDetailsFormData,
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  });

  return response.data;
}

export async function getUserExtraSignupData(cancelToken): Promise<ProfileResult> {
  const url = `${process.env.REACT_APP_SERVER_URL}/users/extra-signup-data?client_id=${process.env.REACT_APP_CLIENT_ID}`;

  const response = await api.get(url, {
    cancelToken,
  });

  return response.data;
}
