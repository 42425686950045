import React from 'react';
import Tooltip from 'components/Shared/Tooltip';
import CopyLink from 'components/Shared/Tooltip/copyLink';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import styles from './StatIcon.module.scss';

interface Props {
  children?: JSX.Element;
  value?: number | string;
  icon?: string;
  faIcon?: IconDefinition;
  label?: string;
  tooltip?: string;
  alt?: string;
  isLink?: boolean;
  linkTo?: string;
  id?: string;
  handleImageClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  highlighted?: boolean;
  noHover?: boolean;
  copiedTxt?: string;
  copyLink?: boolean;
  disabled?: boolean;
  linkTarget?: string;
}
const StatIcon = ({
  children,
  value,
  icon,
  faIcon,
  label,
  tooltip,
  alt,
  isLink,
  linkTo,
  id,
  handleImageClick,
  onMouseEnter,
  onMouseLeave,
  highlighted,
  noHover,
  copiedTxt,
  copyLink,
  disabled = false,
  linkTarget = '_self',
}: Props): JSX.Element => {
  return (
    <div
      id={id}
      className={`${styles.StatIcon} stat-icon ${disabled ? 'stat-icon-disabled' : ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {copyLink ? (
        <CopyLink
          tooltip={tooltip}
          copiedTxt={copiedTxt}
          icon={icon}
          value={linkTo}
          isLink={isLink}
          style={{ marginTop: '-5px' }}
        />
      ) : (
        <>
          <Tooltip
            data-testid={`stat-icon-${id}`}
            highlighted={highlighted}
            noHover={noHover}
            onClick={handleImageClick}
            icon={icon}
            faIcon={faIcon}
            isLink={disabled ? false : isLink}
            linkTo={linkTo}
            alt={alt}
            tooltip={tooltip}
            linkTarget={linkTarget}
            style={{
              minHeight: 25,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {children || (
              <>
                {(value || value === 0) && <h5>{value}</h5>}
                {label && <p>{label}</p>}
              </>
            )}
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default StatIcon;
