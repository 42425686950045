import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useChatbot } from 'hooks';
import { deactivatePageHeader } from '../store/reducers/layout';
import RecruitersInvitation from '../components/Auth/RecruiterInvitation';

const RecruiterInvitationPage: React.FC = () => {
  useChatbot();
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  return (
    <>
      <Helmet>
        <title>Create Password | alooba.com</title>
        <meta name="description" content="On this page the invited recruiter creates a password" />
      </Helmet>
      <RecruitersInvitation />
    </>
  );
};

export default RecruiterInvitationPage;
