import moment from 'moment-timezone';

export const DEFAULT_TIMEZONE = process.env.REACT_APP_DEFAULT_TIMEZONE;
export const getTimezone = (timezone: string): string => timezone || DEFAULT_TIMEZONE;
export const getMoment = (datetime?: string): moment.Moment => {
  return moment.utc(datetime);
};
export const getHoursAgo = (momentDatetime: moment.Moment): number => {
  const now = moment.utc();
  return Math.abs(momentDatetime.diff(now, 'hours'));
};
export const relativeDate = (datetime: string, timezone: string): string => {
  if (!moment(datetime).isValid()) {
    return 'N/A';
  }
  const currentDate = getMoment(datetime);
  const hoursAgo = getHoursAgo(currentDate);
  if (hoursAgo > 48) {
    return fullDate(datetime, timezone);
  }
  return getMoment(datetime).tz(getTimezone(timezone)).fromNow();
};
export const fullDateTime = (datetime: string, timezone: string, format = 'lll'): string => {
  if (!moment(datetime).isValid()) {
    return 'N/A';
  }
  return getMoment(datetime).tz(getTimezone(timezone)).format(format);
};
export const fullDate = (datetime: string, timezone: string): string => {
  if (!moment(datetime).isValid()) {
    return 'N/A';
  }
  return getMoment(datetime).tz(getTimezone(timezone)).format('ll');
};
export const fromNow = (days: number): moment.Moment => {
  return moment.utc().add(days, 'days');
};
export const formatDateTime = (datetime: string, toTimezone: string, format = 'lll', fromUtc = false): string => {
  if (!moment(datetime).isValid()) {
    return 'N/A';
  }
  if (fromUtc) {
    return moment.utc(datetime).tz(toTimezone).format(format);
  }
  return moment(datetime).tz(toTimezone, true).format(format);
};
export const isValidDate = (d: unknown): boolean => {
  return d instanceof Date && !Number.isNaN(d);
};
export const getMinutesLabel = (minutes: number): string => (minutes === 1 ? 'minute' : 'minutes');

export const getMinutesPerSeconds = (seconds: number): number => {
  if (!seconds || Number.isNaN(seconds)) {
    return 0;
  }
  return seconds / 60;
};

export const secondsToHms = (d): string => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay + sDisplay;
};

export const getRelativeDate = (dateA, dateB): string => {
  const delta = Math.round((dateA - dateB) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;

  let fuzzy;

  if (delta <= 1) {
    fuzzy = '1 second';
  } else if (delta < minute) {
    fuzzy = `${delta} seconds`;
  } else if (delta < 2 * minute) {
    fuzzy = '1 minute';
  } else if (delta < hour) {
    fuzzy = `${Math.floor(delta / minute)} minutes`;
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = '1 hour';
  } else if (delta < day) {
    fuzzy = `${Math.floor(delta / hour)} hours`;
  } else if (delta < day * 2) {
    fuzzy = '1 day';
  } else {
    fuzzy = `${Math.floor(delta / day)} days`;
  }

  return fuzzy;
};

export const convertTimezone = (
  dateTime: string,
  fromTimezone: string,
  toTimezone: string,
  format = 'YYYY-MM-DD HH:mm:ss',
): string => {
  return moment.utc(dateTime).tz(fromTimezone, true).tz(toTimezone).format(format);
};

export const formatDuration = (seconds: number): string => {
  const secondsPerHour = 3600;
  const hoursPerDay = 24;
  const daysPerYear = 365;

  let remainingSeconds = seconds;

  const hours = Math.floor(remainingSeconds / secondsPerHour);
  remainingSeconds -= hours * secondsPerHour;

  const days = Math.floor(hours / hoursPerDay);
  const years = Math.floor(days / daysPerYear);

  const remainingDays = days % daysPerYear;
  const remainingHours = hours % hoursPerDay;

  const components = [];
  if (years > 0) components.push(`${years} year${years !== 1 ? 's' : ''}`);
  if (remainingDays > 0) {
    components.push(`${remainingDays} day${remainingDays !== 1 ? 's' : ''}`);
  }
  if (remainingHours > 0) {
    components.push(`${remainingHours} hour${remainingHours !== 1 ? 's' : ''}`);
  }
  let result: string = components.join(', ');
  const lastComma = result.lastIndexOf(',');
  if (lastComma !== -1) {
    result = `${result.substring(0, lastComma)} and${result.substring(lastComma + 1)}`;
  }

  return result || '0 hours';
};
