import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'components/Shared/Markdown/Markdown';
import TextArea from 'components/Shared/Input/textArea';
import RatingQuestion from 'components/Shared/Test/RatingQuestion';
import { saveQuestionResponse, saveNewAnswer } from 'api/assessment.api';
import { saveNewComment, updateComment, deleteComment } from 'api/comment.api';
import { setTestQuestionProperty } from 'store/reducers/candidate';
import useCollapsableResponse from '../../../../hooks/useCollapsableResponses';
import './index.scss';
import '../index.scss';

interface QuestionBackgroundProps {
  candidateReference: string;
  testId: number;
  questionDetails?: any;
}

export default function InterviewResponse({
  candidateReference,
  testId,
  questionDetails,
}: QuestionBackgroundProps): any {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [collapsedMap, toggleCollapsed] = useCollapsableResponse({
    model_response: true,
  });
  const [question, setQuestion] = useState<any>(questionDetails);
  const [responseValue, setResponseValue] = useState<string>(questionDetails.response?.response);
  const [comment, setComment] = useState<any>();
  const [notesValue, setNotesValue] = useState<string>();

  const isAdditionalCriteria = useMemo(() => questionDetails.question_type?.question_type === 'Additional Criteria', [
    questionDetails,
  ]);

  useEffect(() => {
    setQuestion(questionDetails);
    setResponseValue(questionDetails.response?.response ?? '');
    setComment(questionDetails.comments.length > 0 ? questionDetails.comments[0] : null);
  }, [questionDetails]);
  useEffect(() => {
    setNotesValue(comment ? comment.comment : '');
  }, [comment]);

  const updateResponse = (newResponse): void => {
    if (newResponse !== question.response.response) {
      dispatch(
        setTestQuestionProperty({
          testId,
          questionIndex: question.position - 1,
          propertyName: 'response',
          value: {
            ...question.response,
            response: newResponse,
          },
        }),
      );
      saveQuestionResponse(candidateReference, testId, question.test_question_id, newResponse)
        .then(() => {
          addToast({
            type: 'success',
            msg: `Response updated.`,
          });
        })
        .catch(e => {
          addToast({
            type: 'error',
            msg: `Error updating the response.`,
          });
          throw e;
        });
    }
  };

  const updateNotes = (newNotes): void => {
    const callback = (response, questionIndex): void => {
      dispatch(
        setTestQuestionProperty({
          testId,
          questionIndex,
          propertyName: 'comments',
          value: response.data?.data ? [response.data.data] : [],
        }),
      );
      addToast({
        type: 'success',
        msg: `Notes updated.`,
      });
    };
    if ((newNotes && !comment) || (newNotes && comment && newNotes !== comment.comment)) {
      dispatch(
        setTestQuestionProperty({
          testId,
          questionIndex: question.position - 1,
          propertyName: 'comments',
          value: [{ comment: newNotes, saving: true }],
        }),
      );
    }
    if (newNotes && !comment) {
      setComment({ comment: newNotes });
      saveNewComment(newNotes, candidateReference, testId, questionDetails.test_question_id).then(res =>
        callback(res, question.position - 1),
      );
    } else if (comment && newNotes && newNotes !== comment.comment) {
      setComment({ comment: newNotes });
      updateComment(candidateReference, comment.id, newNotes).then(res => callback(res, question.position - 1));
    } else if (comment && !newNotes) {
      setComment({ comment: newNotes });
      deleteComment(candidateReference, comment.id).then(res => callback(res, question.position - 1));
    }
  };

  const updateRating = (selectedAnswerId): void => {
    const previousAnswer = question.answers.filter(answer => {
      return answer.selected === 1;
    });
    if (previousAnswer.length === 0 || previousAnswer[0].id !== parseInt(selectedAnswerId, 10)) {
      saveNewAnswer(candidateReference, testId, questionDetails.test_question_id, selectedAnswerId)
        .then(response => {
          addToast({
            type: 'success',
            msg: `Rating updated.`,
          });
          if (response?.data?.data?.answers) {
            dispatch(
              setTestQuestionProperty({
                testId,
                questionIndex: question.position - 1,
                propertyName: 'answers',
                value: response.data.data.answers ? response.data.data.answers : [],
              }),
            );
          }
        })
        .catch(e => {
          addToast({
            type: 'error',
            msg: `Error updating the rating.`,
          });
          throw e;
        });
    }
  };

  const reactMarkdownRenderers = useMemo(
    () => ({
      img: renderProps => (
        <img src={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}${renderProps.src}`} alt={renderProps.src} />
      ),
    }),
    [],
  );

  const interviewerNotesPlaceholder = useMemo(() => {
    if (isAdditionalCriteria) {
      return 'Enter notes about how well the candidate performed in the specified criteria here';
    }
    return 'Enter notes about how well the candidate responded to the question here';
  }, [isAdditionalCriteria]);

  return (
    <div className="free-response-section response-section-wrapper">
      {!!(
        question?.response?.positive_indicators ||
        question?.response?.negative_indicators ||
        question?.response?.model_response
      ) && (
        <div className="indicators">
          {question?.response?.positive_indicators && (
            <div className="question-response-section">
              <div
                className="response-title collapsable"
                onClick={() => toggleCollapsed('positive_indicators')}
                role="input"
              >
                <h4>INDICATORS OF A GOOD RESPONSE</h4>
                <div className="right-info">
                  <FontAwesomeIcon className="icon" icon={collapsedMap.positive_indicators ? faAngleDown : faAngleUp} />
                </div>
              </div>
              <Markdown
                className={`grey-section long-str-wrap ${collapsedMap.positive_indicators ? 'hidden' : ''}`}
                components={reactMarkdownRenderers}
                content={question.response.positive_indicators}
              />
            </div>
          )}

          {question?.response?.negative_indicators && (
            <div className="question-response-section">
              <div
                className="response-title collapsable"
                onClick={() => toggleCollapsed('negative_indicators')}
                role="input"
              >
                <h4>INDICATORS OF A BAD RESPONSE</h4>
                <div className="right-info">
                  <FontAwesomeIcon className="icon" icon={collapsedMap.negative_indicators ? faAngleDown : faAngleUp} />
                </div>
              </div>
              <Markdown
                className={`grey-section long-str-wrap ${collapsedMap.negative_indicators ? 'hidden' : ''}`}
                components={reactMarkdownRenderers}
                content={question.response.negative_indicators}
              />
            </div>
          )}

          {question?.response?.model_response && (
            <div className="question-response-section">
              <div
                className="response-title collapsable"
                onClick={() => toggleCollapsed('model_response')}
                role="input"
              >
                <h4>MODEL RESPONSE</h4>
                <div className="right-info">
                  <FontAwesomeIcon className="icon" icon={collapsedMap.model_response ? faAngleDown : faAngleUp} />
                </div>
              </div>
              <Markdown
                className={`grey-section long-str-wrap ${collapsedMap.model_response ? 'hidden' : ''}`}
                components={reactMarkdownRenderers}
                content={question.response.model_response}
              />
            </div>
          )}
        </div>
      )}

      <div className="candidate-response-section">
        {question?.has_response && !isAdditionalCriteria && (
          <div>
            <label className="response-title" htmlFor="candidate-response">
              <h4>CANDIDATE&apos;S RESPONSE</h4>
            </label>
            <TextArea
              id="candidate-response"
              placeholder="Enter notes about what the candidate responded with"
              value={responseValue}
              onChange={e => {
                setResponseValue(e.target.value);
              }}
              onBlur={e => updateResponse(e.target.value)}
            />
          </div>
        )}
        {question?.is_rating && <RatingQuestion question={question} onChange={updateRating} />}
        <div className="interviewer-notes-section">
          <label className="response-title" htmlFor="interviewer-notes">
            <h4>INTERVIEWER&apos;S NOTES</h4>
          </label>
          <TextArea
            id="interviewer-notes"
            placeholder={interviewerNotesPlaceholder}
            value={notesValue}
            onChange={e => {
              setNotesValue(e.target.value);
            }}
            onBlur={e => updateNotes(e.target.value)}
            loading={question?.comments && question?.comments?.length && question?.comments[0].saving}
          />
        </div>
      </div>
    </div>
  );
}
