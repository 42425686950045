import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Skeleton from 'react-loading-skeleton';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FixedSizeList } from 'react-window';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from 'store/rootReducer';
import { getCandidatesByHiringStatuses } from 'api/assessment.api';
import { updateHiringStatus } from 'api/candidate.api';
import { relativeDate, fullDateTime } from 'helpers/datetime';
import RejectCandidateModal from 'components/Shared/RejectCandidateModal';
import LightTooltip from 'components/Shared/Tooltip/LightTooltip';
import { handleError } from 'handleError';
import { selectCandidateRow } from 'helpers/store';
import styles from './ParticipantsKanban.module.scss';
import './DragAndDrop.scss';

interface ParticipantsKanbanProps {
  assessmentId: number;
  cloakCandidates?: boolean;
}

const ParticipantsKanbanLoading: React.FC = () => (
  <>
    <Skeleton style={{ width: 'calc(25% - 15px)', height: '80vh', marginRight: '15px' }} />
    <Skeleton style={{ width: 'calc(25% - 15px)', height: '80vh', marginRight: '15px' }} />
    <Skeleton style={{ width: 'calc(25% - 15px)', height: '80vh', marginRight: '15px' }} />
    <Skeleton style={{ width: 'calc(25% - 15px)', height: '80vh', marginRight: '15px' }} />
  </>
);

const columnHeight = window.innerHeight - 300;

export const onDragEnd = (result, columns, setColumns, indexes, addToast, setRejectionContext): void => {
  if (!result.destination) return;
  if (result.source.droppableId === result.destination.droppableId) return;
  // the columns we can use in case we need to rollback
  const rollbackColumns = JSON.parse(JSON.stringify(columns));

  const { source, destination } = result;
  const sourceColumn = columns[indexes[source.droppableId]];
  const destColumn = columns[indexes[destination.droppableId]];
  const sourceItems = [...sourceColumn.items];
  const destItems = [...destColumn.items];

  const [[removed]] = [sourceItems.splice(source.index, 1)];

  destItems.splice(destination.index, 0, removed);

  const newColumns = columns;
  newColumns[indexes[source.droppableId]].items = sourceItems;
  newColumns[indexes[destination.droppableId]].items = destItems;

  setColumns(newColumns);

  if (result.destination.droppableId !== 'Rejected') {
    updateHiringStatus(removed.reference, destination.droppableId)
      .then(() => {
        addToast({
          type: 'success',
          msg: `Updated ${removed.reference}'s hiring status to ${destination.droppableId}.`,
        });
      })
      .catch(e => {
        addToast({
          type: 'error',
          msg: 'Error updating the hiring status.',
        });
        setColumns(rollbackColumns);
        handleError(e);
      });
  } else {
    setRejectionContext({
      candidate: removed,
      columnsBeforeDragEnd: rollbackColumns,
    });
  }
};

interface RejectionContext {
  candidate: any;
  columnsBeforeDragEnd: any;
}

const ParticipantsKanban = ({ assessmentId, cloakCandidates = false }: ParticipantsKanbanProps): JSX.Element => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const [candidatesByStatuses, setCandidatesByStatuses] = useState([]);
  const [indexes, setIndexes] = useState({});
  const [loading, setLoading] = useState(true);
  const [rejectionContext, setRejectionContext] = useState<RejectionContext>();

  useEffect(() => {
    setLoading(true);
    getCandidatesByHiringStatuses(assessmentId).then(response => {
      setCandidatesByStatuses(response.data?.data);

      // Index columns by their names so we can easily access them when moving candidates
      const newIndexes = {};
      for (let i = 0; i < response.data?.data?.length; i += 1) {
        newIndexes[response.data?.data[i].name] = i;
      }
      setIndexes(newIndexes);

      if (response.data.data) setLoading(false);
    });
  }, [assessmentId]);

  const getInvitedAt = (candidate): string => {
    if (!userDetails?.user_timezone) {
      return 'Loading...';
    }
    return relativeDate(candidate.invited_on, userDetails.user_timezone);
  };

  const getInvitedAtVerbose = (candidate): string => {
    if (!userDetails?.user_timezone) {
      return 'Loading...';
    }
    return fullDateTime(candidate.invited_on, userDetails.user_timezone, 'll [at] LT [(GMT]Z[)]');
  };

  const handleCardClick = (e, candidate): void => {
    e.stopPropagation();
    e.preventDefault();
    selectCandidateRow(candidate);
    history.push(`/candidate-details/${candidate.reference}/overview`);
  };

  const KanbanItem = ({ provided, snapshot, candidate, style = {} }): any => (
    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={style}>
      <a
        href={`/candidate-details/${candidate.reference}/overview`}
        onClick={e => handleCardClick(e, candidate)}
        role="button"
        tabIndex={-1}
        key={candidate.reference}
        className={`candidateCard ${snapshot.isDragging ? 'dragging' : ''}`}
      >
        <b>
          <small>{candidate.reference}</small>
        </b>
        {cloakCandidates ? (
          ''
        ) : (
          <div className="piiData">
            <b>{`${candidate.candidate.first_name} ${candidate.candidate.last_name}`}</b>
            <br />
            <small>{`${candidate.candidate.email_address}`}</small>
          </div>
        )}
        <p className="statusBadge">{candidate.status[0].status}</p>
        <LightTooltip title={`Invited at ${getInvitedAtVerbose(candidate)}`} placement="top" arrow>
          <div className="invitedOn">
            <FontAwesomeIcon className="icon" icon={faCalendar} />
            {getInvitedAt(candidate)}
          </div>
        </LightTooltip>
      </a>
    </div>
  );

  const Row = (props): any => {
    const { data: items, index, style } = props;
    const candidate = items[index];

    return (
      <Draggable draggableId={`${candidate.id}`} index={index} key={candidate.id}>
        {(provided, snapshot) => (
          <KanbanItem provided={provided} snapshot={snapshot} candidate={candidate} style={style} />
        )}
      </Draggable>
    );
  };

  const onRejectCanceled = (): void => {
    setCandidatesByStatuses(rejectionContext.columnsBeforeDragEnd);
    setRejectionContext(null);
  };

  const onRejectConfirmed = (rejectionDetails): void => {
    const { reference } = rejectionContext.candidate;
    updateHiringStatus(reference, 'Rejected', rejectionDetails)
      .then(() => {
        let message = `${reference}'s application has been rejected.`;
        if (rejectionDetails.notifyCandidate) {
          message = `${reference}'s application has been rejected and will be notified.`;
        }
        addToast({
          type: 'success',
          msg: message,
        });
        setRejectionContext(null);
      })
      .catch(e => {
        addToast({
          type: 'error',
          msg: 'Error updating the hiring status.',
        });
        setCandidatesByStatuses(rejectionContext.columnsBeforeDragEnd);
        throw e;
      });
  };

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <ParticipantsKanbanLoading />
      ) : (
        <div className={styles.participantsKanban}>
          {rejectionContext ? (
            <RejectCandidateModal
              candidateName={
                cloakCandidates ? rejectionContext.candidate.reference : rejectionContext.candidate.candidate.first_name
              }
              isShown
              onConfirm={onRejectConfirmed}
              onCancel={onRejectCanceled}
            />
          ) : (
            ''
          )}
          <DragDropContext
            onDragEnd={result =>
              onDragEnd(result, candidatesByStatuses, setCandidatesByStatuses, indexes, addToast, setRejectionContext)
            }
          >
            {candidatesByStatuses.map(column => {
              return (
                <Droppable
                  droppableId={column.name}
                  mode="virtual"
                  renderClone={(provided, snapshot, rubric) => (
                    <KanbanItem
                      provided={provided}
                      snapshot={snapshot}
                      candidate={column.items[rubric.source.index]}
                      style={provided.draggableProps.style}
                    />
                  )}
                >
                  {(provided, snapshot) => {
                    const itemCount = column.items.length;
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`${styles.column} ${snapshot.isDraggingOver ? 'dragging' : ''}`}
                      >
                        <div className={styles.columnTitle}>{column.name}</div>
                        <FixedSizeList
                          height={columnHeight}
                          itemCount={itemCount}
                          itemSize={165}
                          width={230}
                          outerRef={provided.innerRef}
                          itemData={column.items}
                          className="candidate-list-kanban"
                        >
                          {Row}
                        </FixedSizeList>
                      </div>
                    );
                  }}
                </Droppable>
              );
            })}
          </DragDropContext>
        </div>
      )}
    </div>
  );
};

export default ParticipantsKanban;
