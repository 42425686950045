import { AssessmentPurpose } from 'components/Assessment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { Modal } from '../Modal';
import './HowIsThisCalculatedButton.scss';

interface Props {
  isVerificationCode?: boolean;
}

const HowIsThisCalculatedButton: React.FC<Props> = ({ isVerificationCode }) => {
  const [showModal, setShowModal] = useState(false);
  const purpose: AssessmentPurpose = useSelector((state: RootState) => state.assessment?.currentAssessment.purpose);
  return (
    <div className="how-is-this-calculated-container">
      <button type="button" onClick={() => setShowModal(true)}>
        How is this calculated?
      </button>
      <Modal containerClass="how-is-this-calculated-modal" isShown={showModal} handleClose={() => setShowModal(false)}>
        <div className="popup-content-box">
          <h1>Self Awareness Methodology</h1>
          <h2>Self Rating</h2>
          {isVerificationCode ? (
            <p>
              Before taking the exercise, we asked you to rate yourself from 1 to 10 for each skill covered in the
              exercise. We compare these self ratings to every other participants’ self ratings for the same skills.
              Having a self rating of 80th percentile means you rated yourself higher than 80% of all other participants
              across Alooba for that skill.
            </p>
          ) : (
            <p>
              Before {purpose === 'ld' ? 'an employee takes an exercise' : 'a candidate takes a test'}, we ask them to
              rate themselves from 1 to 10 for each skill in the {purpose === 'ld' ? 'exercise' : 'test'}. We compare
              these self ratings to every other {purpose === 'ld' ? 'employees’' : 'candidates’'} self ratings for the
              same skills. Having a self-rating of 80th percentile means the
              {purpose === 'ld' ? ' employee' : ' candidate'} rates themselves higher than 80% of all other participants
              across Alooba for that skill.
            </p>
          )}
          <h2>Score</h2>
          {isVerificationCode ? (
            <p>
              Once you complete an exercise, we calculate your percentage score for each skill in the exercise. We then
              compare this score to all other participants’ scores for the same skills. A score in the 40th percentile
              for SQL, for example, means you performed better than 40% of all other participants across Alooba who also
              had SQL questions in their test. Note: participants may have received different SQL questions, so it’s
              only a high-level comparison.
            </p>
          ) : (
            <p>
              Once {purpose === 'ld' ? 'an employee' : 'a candidate'} has completed{' '}
              {purpose === 'ld' ? 'an exercise' : 'a test'}, we calculate their percentage score for each skill in the{' '}
              {purpose === 'ld' ? 'exercise' : 'test'}. We then compare this score to all other{' '}
              {purpose === 'ld' ? 'employees’' : 'candidates’'} scores for the same skills. A score in the 40th
              percentile for SQL, for example, means they performed better than 40% of all other participants across
              Alooba who also had SQL questions in their test. Note: {purpose === 'ld' ? 'employees' : 'candidates'} may
              have received different SQL questions, so it’s only a high level comparison.
            </p>
          )}
          <h2>Self Awareness</h2>
          {isVerificationCode ? (
            <p>
              Self awareness is a comparison of how close your own skill self rating was to your actual score achieved.
              If your score was higher than your self rating, you underestimated your abilities. If your score was lower
              than your self rating, you overestimated your abilities.
            </p>
          ) : (
            <p>
              Self awareness means their own skill self rating was close to their actual score achieved. If their score
              was higher than their self-rating, they underestimated their abilities. If their score was lower than
              their self-rating, they overestimated their abilities.
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default HowIsThisCalculatedButton;
