import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import * as crypto from 'crypto';
import { useToasts } from 'react-toast-notifications';
import { Button } from '../Shared';

export interface OAuthProps {
  authUri: string;
  clientId: string;
  redirectUri: string;
  responseType?: string;
  audience?: string;
  scopes?: string[];
  prompt?: string;
}

export const OAuthIntegrationPartner = ({
  name,
  logo,
  auth,
  loading,
  connected,
  setConnected,
}: {
  name: string;
  logo?: string;
  auth?: OAuthProps;
  loading?: boolean;
  connected?: string;
  setConnected?: any;
}): JSX.Element => {
  // `connected` will be `null` when it's not yet connected, or 'connected' when already connected.
  // It will be set to `new` when first connected, which will trigger the toast notification.
  // This uses localStorage to enable listening to changes in another tab, as the OAuth flow will
  // happen in a popup.
  const storageKey = `@alooba/integration/${name.toLowerCase()}`;
  window.addEventListener('storage', e => {
    if (e.key === `${storageKey}/state` && e.newValue === 'connected') {
      setConnected('new');
    }
  });
  const { addToast } = useToasts();
  useEffect(() => {
    if (connected === 'new') {
      addToast({
        type: 'success',
        msg: `${name} is now connected`,
      });
    }
  }, [connected, addToast, name]);

  const handleClick = (): void => {
    // handle clicking the 'Enable' button
    const title = `${name} auth`;
    const state = crypto
      .randomBytes(10)
      .toString('base64')
      .replace(/[\W_]+/g, '')
      .substr(0, 10);
    const width = 500;
    const height = 780;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    let url = `${auth.authUri}?client_id=${auth.clientId}&redirect_uri=${auth.redirectUri}&response_type=${auth.responseType}&state=${state}&prompt=${auth.prompt}&audience=${auth.audience}&scope=`;
    url += auth.scopes.join(' ');
    // Save the `state` in the `localStorage` to be used by the callback to verify the state
    localStorage.setItem(`${storageKey}/auth_state`, state);
    localStorage.removeItem(`${storageKey}/state`);
    window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
  };

  return (
    <div className="integration-partner">
      <Button
        loading={loading}
        loadingPlaceholder={loading}
        variant="sub-primary md"
        text={connected === 'connected' || connected === 'new' ? 'Connected' : 'Enable'}
        disabled={connected === 'connected' || connected === 'new'}
        onClick={handleClick}
      />
      {loading && <Skeleton height={32} width={160} />}
      {!loading && (logo ? <img alt={name} src={logo} /> : name)}
    </div>
  );
};
