import React, { useEffect, useState } from 'react';
import Dropdown from 'components/Shared/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTimezones } from 'store/actions/utils.actions';
import { RootState } from 'store/rootReducer';

interface TimezoneDropDownProps {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: any;
  handleChange?: any;
  menuPlacement?: any;
  defaultTimezones?: Array<any>;
  errorTxt?: string;
}
const MemoizedTimezoneDropDown = React.memo(function TimezoneDropDown(props: TimezoneDropDownProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { timezones: storeTimezones } = useSelector((state: RootState) => state.utils);

  const unprocessedTimezones = (props.defaultTimezones || storeTimezones) ?? [];
  const timezones = unprocessedTimezones.map(zone => {
    return {
      label: `(GMT${zone.offset}) ${zone.code}`,
      value: zone.code,
      code: zone.code,
      offset: zone.offset,
    };
  });

  useEffect(() => {
    if (timezones.length <= 0) {
      dispatch(fetchTimezones());
    } else {
      setLoading(false);
    }
  }, [dispatch, timezones.length]);

  return (
    <Dropdown
      id={props.id}
      dropdownOptions={timezones}
      label={props.label}
      loading={loading}
      placeholder={props.placeholder}
      value={props.value ?? ''}
      handleChange={props.handleChange}
      menuPlacement={props.menuPlacement}
      errorTxt={props.errorTxt}
    />
  );
});
export default MemoizedTimezoneDropDown;
