import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getFormattedAssessmentName } from 'helpers';
import LightTooltip from '../Tooltip/LightTooltip';
import '../../../styles/heading.scss';
import styles from './PageHeader.module.scss';
import ErasedCandidateInfo from '../Erased/ErasedCandidateInfo';

interface Props {
  assessment?: any;
  candidateName?: string;
  isCandidateErased?: boolean;
  ActionListComponent?: ReactNode;
  loading?: boolean;
  disableBreadcrumb?: boolean;
  trialBannerShow?: boolean;
}
const AssessmentHeader = ({
  assessment,
  candidateName = null,
  isCandidateErased = false,
  ActionListComponent,
  loading,
  disableBreadcrumb = false,
  trialBannerShow = false,
}: Props): JSX.Element => {
  const history = useHistory();
  const { collapsed } = useSelector((state: RootState) => state.layout);
  const { currentTab } = useSelector((state: RootState) => state.assessment);
  const headerWidth = collapsed ? 'calc(100% - 115px)' : 'calc(100% - 300px)';
  const assessmentTab = currentTab ?? 'overview';
  return (
    <div className={`${styles.pageHeaderContainer} ${trialBannerShow ? styles.hasBanner : ''}`}>
      <div className={styles.pageHeader} style={{ width: headerWidth }}>
        {loading ? <Skeleton width={300} /> : ''}
        {candidateName && !loading ? (
          <>
            {disableBreadcrumb ? (
              <span className={styles.assessmentLink}>
                <h3>{assessment.recruiter_test_name}</h3>
              </span>
            ) : (
              <a
                href={`/assessment/${assessment.id}-${getFormattedAssessmentName(
                  assessment.recruiter_test_name,
                )}/${assessmentTab}`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(
                    `/assessment/${assessment.id}-${getFormattedAssessmentName(
                      assessment.recruiter_test_name,
                    )}/${assessmentTab}`,
                  );
                }}
                className={styles.assessmentLink}
              >
                <h3>{assessment.recruiter_test_name}</h3>
              </a>
            )}
            <span className={styles.breadcrumb}>/</span>
            {isCandidateErased && (
              <span className={styles.candidateName}>
                <ErasedCandidateInfo />
              </span>
            )}
            {!isCandidateErased && (
              <LightTooltip title={candidateName} arrow>
                <span className={styles.candidateName}>{candidateName}</span>
              </LightTooltip>
            )}
          </>
        ) : (
          <h3>{!loading ? `${assessment.recruiter_test_name}` : ''}</h3>
        )}
        {!loading ? ActionListComponent : ''}
      </div>
    </div>
  );
};

export default AssessmentHeader;
