import React, { useRef } from 'react';
import ConfigurableColumns from 'components/CandidateResultsList/ConfigurableColumns';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { stopEvent } from '../../../helpers/events';
import './index.scss';
import AloobaTooltip from '../Tooltip/AloobaTooltip';

type Order = 'asc' | 'desc';

interface DynamicTableHeaderProps {
  fields?: any;
  hasScroll?: boolean;
  sortingProps?: any;
  actionMenu?: any;
  StickyTableCellLeft: any;
  StickyTableCellRight: any;
  configurableColumns?: boolean;
  shouldCloak?: boolean;
  toggleColumns?: (columns: string[]) => any;
}
const DynamicTableHeader = ({
  fields,
  hasScroll,
  actionMenu,
  sortingProps,
  StickyTableCellLeft,
  StickyTableCellRight,
  configurableColumns,
  toggleColumns,
  shouldCloak,
}: DynamicTableHeaderProps): JSX.Element => {
  const hasSticky = useRef(null);
  hasSticky.current = false;
  const headerCell = (field: any): any => {
    const alignments = {
      center: 'text-center',
      right: 'text-right',
      left: 'text-left',
    };
    const placement = field.align ? alignments[field.align] : '';
    let cell = <div className={`${placement}`}>{field.label}</div>;

    if (sortingProps && !field.nonSortable) {
      const { sortingAction, orderBy, orderDirection } = sortingProps;
      const handleSorting = (e: React.SyntheticEvent<EventTarget>, row: any): void => {
        stopEvent(e);
        sortingAction({
          field: row.label,
          direction: sortDirection(row),
        });
      };
      const sortDirection = (sortField: any): Order => {
        if (orderBy === sortField.label) {
          return orderDirection === 'asc' ? 'desc' : 'asc';
        }
        return 'asc';
      };
      const direction = (directionField: any): Order => {
        if (orderBy === directionField.label) {
          return orderDirection;
        }
        return 'asc';
      };
      cell = (
        <TableSortLabel
          className={`${field.type === 'percentage' && 'text-center'} ${placement}`}
          active={orderBy === field.label}
          direction={direction(field)}
          onClick={e => handleSorting(e, field)}
        >
          {field.label}
        </TableSortLabel>
      );
    }

    if (field.hint) {
      cell = (
        <AloobaTooltip arrow title={field.hint}>
          {cell}
        </AloobaTooltip>
      );
    }

    return cell;
  };
  const fieldScroll = (field): string => {
    if (hasScroll) {
      return field.scroll || '';
    }
    return '';
  };
  const tableCell = (field): any => {
    if (!field.hidden) {
      if (field.sticky && field.sticky === 'left') {
        hasSticky.current = true;
        return (
          <StickyTableCellLeft className={`row-cell-header sticky sticky-left ${fieldScroll(field)}`} key={field.label}>
            {headerCell(field)}
          </StickyTableCellLeft>
        );
      }
      if (field.sticky && field.sticky === 'right') {
        hasSticky.current = true;
        return (
          <StickyTableCellRight
            className={`row-cell-header sticky sticky-right ${fieldScroll(field)}`}
            key={field.label}
          >
            {headerCell(field)}
          </StickyTableCellRight>
        );
      }
      return (
        <TableCell className={`row-cell-header ${field.type === 'percentage' && 'text-center'}`} key={field.label}>
          {headerCell(field)}
        </TableCell>
      );
    }
    return <></>;
  };
  const actionMenuCell = (): any => {
    if (!actionMenu) {
      return <></>;
    }
    if (hasSticky.current === true) {
      return <StickyTableCellRight className="action-menu-cell">&nbsp;</StickyTableCellRight>;
    }
    return <TableCell className="action-menu-cell">&nbsp;</TableCell>;
  };

  const toggleColumnsCell = (): any => {
    const toggle = (columns: string[]): any => {
      toggleColumns(columns);
    };
    if (!actionMenu) {
      return <></>;
    }
    if (configurableColumns) {
      return (
        <StickyTableCellRight className="row-cell-header sticky sticky-right">
          <ConfigurableColumns shouldCloak={shouldCloak} toggleColumns={toggle} columns={fields} />
        </StickyTableCellRight>
      );
    }
    return actionMenuCell();
  };

  return (
    <TableHead>
      <TableRow key="header-row" hover={false} className="dynamic-table-row">
        {fields.map(field => {
          return tableCell(field);
        })}
        {toggleColumnsCell()}
      </TableRow>
    </TableHead>
  );
};
export default DynamicTableHeader;
