import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlanUpgradeModal from 'components/Shared/PlanUpgradeModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { hasOrgFeature } from 'Authorization/Check';
import FilterMapper from './FilterMapper';
import './index.scss';
import StatIcon from '../../Shared/StatIcon';
import DownloadResultsIcon from '../../../images/icons/download-results-blue.svg';

const moreFiltersBtn = {
  label: '',
  name: 'items',
  type: 'MultiSelectFilter',
  category: 'main',
  multiple: true,
  options: [],
  defaultValue: [],
  canHide: false,
  visible: true,
};
export type filtersProps = {
  filters: Array<any>;
  onFilterChange: any;
  onExport?: any;
  showResetFiltersButton?: any;
};
const Filters = ({ filters, onFilterChange, onExport, showResetFiltersButton = false }: filtersProps): JSX.Element => {
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [key, setKey] = useState(1);
  const [isExporting, setIsExporting] = useState(false);
  const [more, setMore] = useState(
    currentFilters.filter(filter => filter.category === 'more' && filter.initialVisible).map(filter => filter.name),
  );
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState<boolean>(false);
  const currentAssessment = useSelector((state: RootState) => state.assessment?.currentAssessment);
  const assessmentWord = currentAssessment?.purpose === 'ld' ? 'exercise' : 'assessment';

  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);
  const clearFilters = (): void => {
    setKey(key + 1);
    setMore([]);
    setCurrentFilters(prevState =>
      prevState.map(filter => {
        return {
          ...filter,
          initialValue: undefined,
          initialVisible: undefined,
        };
      }),
    );
    onFilterChange({ reset: true });
  };
  const handleSetMore = ({ items }): void => {
    setMore(items);
  };
  const hideMoreFilter = (name: string): void => {
    setMore(more.filter(filter => filter !== name));
  };
  const moreFilters = useMemo((): Array<any> => {
    return currentFilters.filter(filter => filter.category === 'more');
  }, [currentFilters]);
  const mainFilters = useMemo((): Array<any> => {
    return currentFilters.filter(filter => filter.category === 'main');
  }, [currentFilters]);
  const exportResults = (): void => {
    if (!hasOrgFeature('export_results')) {
      setShowUpgradePlanModal(true);
      return;
    }
    setIsExporting(true);
    onExport().finally(() => {
      setIsExporting(false);
    });
  };
  const containsAll = (arr1: any, arr2: any): boolean => {
    return arr1?.every(value => arr2?.includes(value));
  };
  const resetFiltersVisible: boolean = useMemo(() => {
    return (
      moreFilters.filter(filter => more.some(activeFilter => activeFilter === filter.name)).length > 0 ||
      showResetFiltersButton ||
      mainFilters[0].initialValue ||
      !(
        containsAll(mainFilters[1].initialValue, mainFilters[1].defaultValues) &&
        containsAll(mainFilters[1].defaultValues, mainFilters[1].initialValue)
      )
    );
  }, [showResetFiltersButton, moreFilters, more, mainFilters]);
  return (
    <div style={{ marginBottom: '20px' }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
        spacing={2}
        key={key}
        className="filter-container-grid"
      >
        {mainFilters.map(filter => (
          <FilterMapper key={filter.name} {...filter} onChange={onFilterChange} />
        ))}
        {moreFilters.length > 0 && (
          <FilterMapper
            {...moreFiltersBtn}
            onChange={handleSetMore}
            withCheckbox={false}
            optionKey="key"
            optionLabel="label"
            options={moreFilters.map(filter => ({
              key: filter.name,
              label: filter.label,
            }))}
            selectClassName="score-btn-select"
            menuClassName="score-btn-select-menu"
            allowRemovingItems={false}
            value={more}
            selectProps={{
              renderValue: () => (
                <>
                  <FontAwesomeIcon className="icon" icon={faPlus} />
                  Add Filter
                </>
              ),
            }}
          />
        )}
        {moreFilters.map(filter => (
          <FilterMapper
            key={filter.name}
            {...filter}
            onChange={onFilterChange}
            visible={filter.visible || more.some(activeFilter => activeFilter === filter.name)}
            hide={() => hideMoreFilter(filter.name)}
          />
        ))}
        {resetFiltersVisible && (
          <Grid item>
            <button className="sub-primary md btn-no-background btn-40-height" type="button" onClick={clearFilters}>
              Reset Filters
            </button>
          </Grid>
        )}
        {onExport && (
          <Grid item className="download-results-icon-container">
            {isExporting ? (
              <CircularProgress size={14} />
            ) : (
              <StatIcon
                tooltip="Download"
                icon={DownloadResultsIcon}
                alt="configuration"
                isLink
                handleImageClick={exportResults}
              />
            )}
          </Grid>
        )}
      </Grid>
      <PlanUpgradeModal
        isShown={showUpgradePlanModal}
        setModalVisibility={setShowUpgradePlanModal}
        title={`Upgrade Your Account to Download ${assessmentWord[0].toUpperCase() + assessmentWord.slice(1)} Results`}
      >
        <p>
          Exporting results is not supported on your current subscription plan. Upgrade your subscription to gain access
          to {assessmentWord}
          {` `}
          downloadable results as well as many other amazing features Alooba has to offer.
        </p>
      </PlanUpgradeModal>
    </div>
  );
};
export default Filters;
