import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { secondsToHms } from 'helpers/datetime';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import TotalScore from './TotalScore';
import TimeIcon from '../../../../images/icons/estimated-duration.svg';
import ReloadIcon from '../../../../images/icons/reload-icon-blue.svg';
import AddTimeIcon from '../../../../images/icons/plus-time.svg';
import './TestHeader.scss';

interface Props {
  scoredPercent: number;
  testDetails?: any;
  loading?: boolean;
  toggleShowTestRetakeModal?: any;
  toggleShowAddTimeModal?: any;
  enableRetake?: any;
}
export default function TestHeader({
  scoredPercent,
  testDetails,
  loading,
  toggleShowTestRetakeModal,
  toggleShowAddTimeModal,
  enableRetake = true,
}: Props): any {
  const showTotalScore = testDetails?.test_type_response_type !== 'rating';
  const { currentAssessment } = useSelector((state: RootState) => state.assessment) || {};

  return (
    <div className="test-header">
      {loading ? (
        <Skeleton width={250} height={64} style={{ borderRadius: '32px' }} />
      ) : (
        <>
          {showTotalScore && (
            <TotalScore
              scoredPercent={scoredPercent}
              totalMarks={testDetails?.total_score?.max_score}
              scoredMarks={testDetails?.total_score?.score}
            />
          )}
        </>
      )}
      <div className="spacer" />
      <div className="total-test-time">
        {loading ? (
          <Skeleton width={150} height={20} />
        ) : (
          <>
            {testDetails.time_taken ? (
              <>
                <img src={TimeIcon} alt="Time taken icon" />
                <span className="test-duration-text">Total Test Duration:</span>
                <span>{secondsToHms(testDetails.time_taken)}</span>
              </>
            ) : (
              ''
            )}
            {!currentAssessment?.is_archived && enableRetake && testDetails.test_type_response_type !== 'rating' && (
              <>
                <span className="divider">|</span>
                <Tooltip title="Grant additional time">
                  <div className="add-time-icon">
                    <img src={AddTimeIcon} alt="Add time" height="18" onClick={toggleShowAddTimeModal} />
                  </div>
                </Tooltip>
              </>
            )}
            {!currentAssessment?.is_archived && enableRetake && (
              <>
                <span className="divider">|</span>
                <div className="retake-icon">
                  <img src={ReloadIcon} alt="Request retake icon" onClick={toggleShowTestRetakeModal} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
