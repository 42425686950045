import React from 'react';
import './QuestionSidebar.scss';
import { correctnessIconMap, getCorrectness, ratingIconMap } from '../testUtils';
import Tooltip from '../../Tooltip/LightTooltip';

export default function QuestionButton({
  question,
  selected,
  onQuestionSelected,
  isInterview,
}: {
  question: any;
  selected?: boolean;
  onQuestionSelected: (q: any) => void;
  isInterview?: boolean;
}): any {
  let correctness;
  let rating;

  if (question) {
    const unanswered =
      (!question.score || question.score === '0') &&
      !isInterview &&
      ((question.has_response && !question.response_submitted) || (question.has_answers && !question.answer_selected));
    correctness = getCorrectness(
      question.score === null ? question.score : parseInt(question.score, 10),
      parseInt(question.max_score, 10),
      unanswered,
    );

    if (isInterview && question.is_rating) {
      const selectedAnswer = question.answers.filter(answer => {
        return answer.selected;
      });
      if (selectedAnswer.length > 0) {
        rating = selectedAnswer[0].answer;
      } else {
        correctness = {
          key: 'evaluate',
          icon: correctnessIconMap.evaluate,
        };
      }
    }
  }

  return (
    <button
      className={`candidate-question-button ${correctness?.key} ${selected ? 'selected' : ''}`}
      onClick={() => onQuestionSelected(question)}
      type="button"
    >
      <div>{question.position}</div>
      <div>
        {correctness?.key === 'evaluate' ? (
          <Tooltip title={correctness?.label ?? ''} placement="top" arrow>
            <img src={correctness.icon} alt={`${correctness.key} icon`} />
          </Tooltip>
        ) : (
          <>
            {correctness && correctness.icon !== null && <img src={correctness.icon} alt={`${correctness.key} icon`} />}
            {rating && <img className="rating" src={ratingIconMap[rating]} alt={`${rating} icon`} />}
          </>
        )}
      </div>
    </button>
  );
}
