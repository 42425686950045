import './index.scss';
import React from 'react';
import { Helmet } from 'react-helmet';

const NoActiveSubscription: React.FC = () => {
  return (
    <div className="signup-flow">
      <Helmet>
        <title>No active subscription</title>
      </Helmet>
      <div className="subscription-inactive-wrapper">
        <h1>Subscription Inactive</h1>
        <p className="welcome">
          Unfortunately, you don&apos;t have an active subscription to Alooba. To regain access and continue enjoying
          all the features, please contact your Admin to renew your subscription.
        </p>
      </div>
    </div>
  );
};

export default NoActiveSubscription;
