import React, { useMemo, useCallback } from 'react';
import { Grid, Box } from '@material-ui/core';
import LightTooltip from '../Tooltip/LightTooltip';
import { PROGRESSBAR_COLORS } from './colors';

const ProgressBar: React.FC<{
  question: any;
  options: Array<any>;
  summary: any;
  getTitle: (string, any) => string;
}> = ({ question, options, summary, getTitle }): JSX.Element => {
  const totalKey = useMemo((): string => {
    return `${question.key}_num`;
  }, [question.key]);
  const getProgressBarWidth: (option) => any = useCallback(
    option => {
      return summary[totalKey] > 0 ? summary[`${question.key}_${option.key}`] / summary[totalKey] : 0;
    },
    [question.key, summary, totalKey],
  );
  const getProgressBarTitle = (option): string => {
    return `${(getProgressBarWidth(option) * 100).toFixed(2)}% ${option.title}`;
  };
  const neutralOrBetter = useMemo((): number => {
    return (
      options
        .slice(0, 3)
        .reduce((previousValue, currentValue) => getProgressBarWidth(currentValue) + previousValue, 0) * 100
    );
  }, [getProgressBarWidth, options]);
  const neutralOrBetterValueAsString = useMemo((): string => {
    return neutralOrBetter.toFixed(0);
  }, [neutralOrBetter]);
  const neutralOrBetterClassName = useMemo((): string => {
    let color = '';
    if (summary[totalKey] >= 5) {
      if (neutralOrBetter < 50) {
        color = 'red-text';
      } else if (neutralOrBetter < 75) {
        color = 'orange-text';
      } else {
        color = 'color-blue';
      }
    }
    return color;
  }, [neutralOrBetter, summary, totalKey]);
  if (summary[totalKey] < 1) return null;
  return (
    <Grid className="progress-bar-container" container xs={12} item spacing={2} alignContent="center">
      <Grid item container sm={12} md={5} lg={4} xl={3} spacing={0} justifyContent="space-between">
        <Grid item>
          <LightTooltip arrow title={question.description} placement="top">
            <h3 className="progress-bar-label text-light">{question.title}</h3>
          </LightTooltip>
        </Grid>
        <Grid item>
          <LightTooltip arrow title={getTitle(neutralOrBetterClassName, neutralOrBetterValueAsString)} placement="top">
            <h3 className={`progress-bar-label bold ${neutralOrBetterClassName}`}>{neutralOrBetterValueAsString}%</h3>
          </LightTooltip>
        </Grid>
      </Grid>
      <Grid item container sm={12} md={7} lg={8} xl={9} spacing={0} alignContent="center">
        {options.map(option => (
          <LightTooltip arrow key={option.no} title={getProgressBarTitle(option)} placement="top">
            <Box
              component={Grid}
              mt="0 !important"
              pt="0 !important"
              height={20}
              bgcolor={PROGRESSBAR_COLORS[option.key]}
              width={getProgressBarWidth(option)}
            />
          </LightTooltip>
        ))}
      </Grid>
    </Grid>
  );
};
export default ProgressBar;
