import React, { useState, useMemo } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { calculateTextWidth } from 'helpers/text';
import Dropdown from '../Dropdown';
import useWindowSize from '../../../hooks/useWindowSize';
import './index.scss';
import { useStyles, dropdownStyles, dropdownWithTrialBannerStyles } from './DynamicTabs.styles';

interface DynamicTabsProps {
  tabsData: any;
  handleChange?: any;
  value?: any;
  content: any;
  breakpoint?: number;
  containerWidth?: number;
  trialBannerShow?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface TabInfoInterface {
  tabTitle: string;
  contentLink?: string;
  component?: any;
  disabled?: boolean;
}

function TabPanel(props: TabPanelProps): any {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={0}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

export const getTabIndex = (e: any, tabsData: TabInfoInterface[]): number => {
  let index = 0;
  let result = -1;
  tabsData.every(tab => {
    if (result === -1 && tab.tabTitle === e.label) {
      result = index;
      return false;
    }
    index += 1;
    return true;
  });

  return result;
};

export default function DynamicTabs(props: DynamicTabsProps): any {
  const { tabsData, handleChange, value, content, breakpoint, containerWidth, trialBannerShow } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = (): void => {
    setWindowWidth(window.innerWidth);
  };
  useWindowSize(handleResize);

  const classes = useStyles();
  const mobileBreakpoint = useMemo(() => {
    if (breakpoint) return breakpoint;
    let margins = 0;
    let tabsText = '';
    tabsData.forEach(tab => {
      tabsText += tab.tabTitle;
      margins += 16;
    });
    const textWidth = calculateTextWidth(tabsText);
    const result = textWidth + margins + 350;
    return result > 1200 ? result : 1200;
  }, [tabsData, breakpoint]);

  let containerStyle = {};
  if (containerWidth && containerWidth > 0) {
    containerStyle = { width: `${containerWidth}px` };
  }

  return (
    <div className={`${classes.root} dynamic-tab-container`}>
      {windowWidth > mobileBreakpoint ? (
        <Tabs
          value={value}
          onChange={handleChange}
          className={`tab-container ${classes.tabs}`}
          TabIndicatorProps={{ className: classes.indicator }}
          style={containerStyle}
        >
          {tabsData.map(tabInfo => (
            <Tab
              label={tabInfo.tabTitle}
              key={tabInfo.tabTitle}
              disabled={tabInfo.disabled}
              classes={{
                selected: classes.tabSelected,
                wrapper: classes.iconLabelWrapper,
              }}
              icon={tabInfo.icon}
            />
          ))}
        </Tabs>
      ) : (
        <Dropdown
          isSearchable={false}
          value={`${value}`}
          menuPlacement="bottom"
          placeholder={`${tabsData[value]?.tabTitle || 'Loading...'}`}
          handleChange={e => {
            handleChange(null, getTabIndex(e, tabsData));
          }}
          dropdownOptions={tabsData
            .filter(tab => !tab.disabled)
            .map(tab => {
              return {
                value: tab,
                label: tab.tabTitle,
              };
            })}
          customStyles={trialBannerShow ? dropdownWithTrialBannerStyles : dropdownStyles}
        />
      )}
      <Box className={`${classes.tab}`}>
        {tabsData.map((tabInfo, index) => (
          <TabPanel index={index} value={value} key={`${tabInfo.tabTitle}-box`}>
            {content}
          </TabPanel>
        ))}
      </Box>
    </div>
  );
}
