import React from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';

const SubHeader: React.FC = ({ children }) => {
  const history = useHistory();
  const handleClickBack = (): void => {
    history.go(-1);
  };
  return (
    <div className="sub-header">
      <h2 className="header-content">
        <div role="button" aria-label="Go Back" tabIndex={0} className="back-chevron" onClick={handleClickBack} />
        {children}
      </h2>
    </div>
  );
};

export default SubHeader;
