import React, { useState } from 'react';
import CandidateCard from './CandidateCard';
import useWindowSize from '../../hooks/useWindowSize';
import { OrganizationDetails } from '../../api/profile.api';

export declare interface TopResultsProps {
  topResults: any;
  subjects: any;
  userDetails: any;
  organization: OrganizationDetails;
  assessment: any;
}
const TopResults = ({ assessment, topResults, subjects, userDetails, organization }: TopResultsProps): JSX.Element => {
  const [maxTopResults, setMaxTopResults] = useState<number>(4);
  const handleResize = (): void => {
    if (window.innerWidth >= 1370) {
      setMaxTopResults(4);
      return;
    }
    if (window.innerWidth > 1280) {
      setMaxTopResults(3);
      return;
    }
    if (window.innerWidth >= 780) {
      setMaxTopResults(2);
      return;
    }
    setMaxTopResults(1);
  };
  useWindowSize(handleResize);

  if (!topResults.length) {
    return null;
  }
  return (
    <>
      <p className="section-title">Top Results</p>
      <div className="top-results">
        {topResults.slice(0, maxTopResults).map(candidate => (
          <CandidateCard
            candidate={candidate}
            subjects={subjects}
            userDetails={userDetails}
            organization={organization}
            assessment={assessment}
          />
        ))}
      </div>
    </>
  );
};

export default TopResults;
