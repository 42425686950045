import React, { useEffect, useState } from 'react';
import { TwitterPicker } from 'react-color';
import './colorpicker.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import colors from './colors.module.scss';

interface props {
  value?: string;
  onConfirm?: (string) => void;
  loading?: boolean;
}

const ColorPicker: React.FC<props> = ({ value = '#001e42', onConfirm, loading = false }) => {
  const [selectedColor, setSelectedColor] = useState(value);
  const [displayPicker, setDisplayPicker] = useState(false);

  useEffect(() => {
    if (value) {
      setSelectedColor(value);
    }
  }, [value]);

  const handleClick = (): void => {
    if (!displayPicker) {
      setDisplayPicker(true);
    }
  };
  const handleChange = (color): void => {
    setSelectedColor(color.hex);
  };
  const handleConfirm = (): void => {
    setDisplayPicker(false);
    if (onConfirm) {
      onConfirm(selectedColor);
    }
  };

  const handleClose = (): void => {
    setSelectedColor(value);
    setDisplayPicker(false);
  };

  const twitterStyle = {
    default: {
      input: {
        display: 'none',
      },
      hash: {
        display: 'none',
      },
    },
  };
  const selectableColors = [
    '#001e42',
    colors.blue,
    '#FF6900',
    '#FCB900',
    '#7BDCB5',
    '#0693E3',
    '#607D8B',
    '#EB144C',
    '#F78DA7',
    '#673AB7',
  ];
  if (loading) {
    return (
      <div className="color-picker">
        <FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
      </div>
    );
  }
  return (
    <div className="color-picker">
      <div
        data-testid="color-picker-button"
        tabIndex={0}
        role="button"
        onClick={handleClick}
        className="color"
        style={{ backgroundColor: selectedColor }}
      >
        &nbsp;
        {displayPicker ? (
          <div className="popover">
            <TwitterPicker styles={twitterStyle} onChange={handleChange} colors={selectableColors} />
            <button className="save" type="button" onClick={handleConfirm} data-testid="color-picker-button-save">
              Save
            </button>
            <button className="cancel" type="button" onClick={handleClose} data-testid="color-picker-button-cancel">
              Cancel
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ColorPicker;
