import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import FieldContainer from '../FieldContainer';
import CustomTextField from '../../CustomTextField';
import './index.scss';

export type textFilterProps = {
  name: string;
  label: string;
  description?: string;
  canHide: boolean;
  visible: boolean;
  initialValue?: string;
  onChange: any;
  hide: any;
};
const TextFilter = ({
  name,
  label,
  description,
  canHide,
  visible,
  initialValue,
  onChange,
  hide,
}: textFilterProps): JSX.Element => {
  const [value, setValue] = useState(initialValue || '');
  const onInputChange = (event: any): void => {
    setValue(event.target.value);
    onChange({
      [`${name}`]: event.target.value,
    });
  };
  const reset = (): void => {
    setValue('');
    onChange({
      [`${name}`]: null,
    });
  };
  const resetAndHide = (): void => {
    reset();
    hide();
  };
  return (
    <FieldContainer hide={resetAndHide} label="" description={description} canHide={canHide} visible={visible}>
      <CustomTextField
        containerClassName="custom-search-input"
        textFieldProps={{
          className: `${canHide ? 'more-text-field' : 'main-text-field'}`,
          label,
          onChange: onInputChange,
          value,
        }}
      >
        <div className="custom-text-input-icon" tabIndex={-1} role="navigation" aria-hidden>
          {value ? (
            <FontAwesomeIcon type="regular" onClick={reset} icon={faTimes} />
          ) : (
            <FontAwesomeIcon type="regular" icon={faSearch} />
          )}
        </div>
      </CustomTextField>
    </FieldContainer>
  );
};
export default TextFilter;
