import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { RouteProps } from 'react-router-dom';
import './Layout.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import GlobalBars from 'components/Shared/GlobalBars';
import Footer from 'components/Footer';
import Header from 'components/Header';
import TrialBanner from 'components/TrialBanner';
import { Decoded } from './Layout';
import { emptyJwt } from '../../../helpers';

interface IProps extends RouteProps {
  children: React.ReactNode;
  hasBars?: boolean;
}

const MinimalLayout: React.FC<IProps> = ({ children, hasBars }) => {
  const [showDemoBar, setShowDemoBar] = useState(false);
  const [trialBannerShow, setTrialBannerShow] = useState<boolean>(false);

  const { userDetails, loading } = useSelector((state: RootState) => state.profile);
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const subscription = useSelector((state: RootState) => state.subscription);
  const organisationId = userDetails?.recruiter_detail?.organisation_id;

  useEffect(() => {
    const decoded: Decoded = jwt_decode(accessToken || emptyJwt);
    setShowDemoBar(decoded && decoded.ut === 'recruiter' && decoded.paid === false && !subscription?.subscription);
  }, [accessToken, subscription]);

  useEffect(() => {
    setTrialBannerShow(subscription?.subscription?.status === 'trialing');
  }, [subscription?.subscription?.status]);

  return (
    <>
      <Header hideMenuModal flat accomodateBanner={trialBannerShow} />
      {trialBannerShow && <TrialBanner subscription={subscription.subscription} organisationId={organisationId} />}
      <div className={`content ${trialBannerShow ? 'has-banner' : ''}`}>
        <main className="main-no-menu">
          {hasBars && (
            <GlobalBars
              notVerified={!(userDetails && userDetails.is_activated)}
              email={userDetails && userDetails.email}
              hasPaid={showDemoBar}
              loading={loading}
            />
          )}
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
};

export default MinimalLayout;
