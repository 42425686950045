import { AdditionalCreditPackage } from 'api/contract';
import { Button } from 'components/Shared';
import React from 'react';

interface CreditBundleProps {
  bundle: AdditionalCreditPackage;
  savingPercentage: number;
  onBuyNow: (priceId: string) => void;
}

const userLocale = navigator.language || 'en-US';

const CreditBundle: React.FC<CreditBundleProps> = ({ bundle, savingPercentage, onBuyNow }) => {
  return (
    <div className="credit-bundle-card">
      <h3 className="credits">{bundle.credits.toLocaleString()} Credits</h3>
      <p>
        Cost:{' '}
        <strong>
          {new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency: bundle.currency,
            currencyDisplay: 'symbol',
          }).format(bundle.cost)}
        </strong>
      </p>
      {savingPercentage > 0 && (
        <p className="savings">
          <strong>{savingPercentage}%</strong> Savings
        </p>
      )}
      <div className="buy-now">
        <Button variant="primary md" onClick={() => onBuyNow(bundle.price_id)} text="Buy Now" />
      </div>
    </div>
  );
};

export default CreditBundle;
