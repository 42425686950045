import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAssessmentCandidatesQueryParams } from 'api/contract';

interface SearchCandidateState {
  searchCandidatesPaginator: {
    hiring: any;
    junior: any;
    ld: any;
  };
  searchCandidatesCurrentPage: {
    hiring: number;
    junior: number;
    ld: number;
  };
  searchCandidatesQueryParams: {
    hiring: GetAssessmentCandidatesQueryParams | null;
    junior: GetAssessmentCandidatesQueryParams | null;
    ld: GetAssessmentCandidatesQueryParams | null;
  };
}

type Purpose = 'hiring' | 'ld' | 'junior';

const initialState: SearchCandidateState = {
  searchCandidatesPaginator: {
    hiring: {
      data: [],
    },
    junior: {
      data: [],
    },
    ld: {
      data: [],
    },
  },
  searchCandidatesCurrentPage: {
    hiring: 1,
    junior: 1,
    ld: 1,
  },
  searchCandidatesQueryParams: {
    hiring: null,
    junior: null,
    ld: null,
  },
};

const SearchCandidateReducer = createSlice({
  name: 'searchCandidate',
  initialState,
  reducers: {
    updateSearchCandidatesQueryParams: (state, action: PayloadAction<{ purpose: Purpose; params: any }>) => {
      const { purpose } = action.payload;
      if (purpose) {
        state.searchCandidatesQueryParams[purpose] = {
          ...state.searchCandidatesQueryParams[purpose],
          ...action.payload.params,
        };
      }
    },
    setSearchCandidatesPaginator: (state, action: PayloadAction<{ purpose: Purpose; paginator: any }>) => {
      const { purpose } = action.payload;
      if (purpose) {
        state.searchCandidatesPaginator[purpose] = action.payload.paginator;
      }
    },
    setSearchCandidatesCurrentPage: (state, action: PayloadAction<{ purpose: Purpose; page: number }>) => {
      const { purpose } = action.payload;
      if (purpose) {
        state.searchCandidatesCurrentPage[purpose] = action.payload.page;
      }
    },
  },
});

export const {
  updateSearchCandidatesQueryParams,
  setSearchCandidatesPaginator,
  setSearchCandidatesCurrentPage,
} = SearchCandidateReducer.actions;
export default SearchCandidateReducer.reducer;
