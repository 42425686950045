import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UtilsState {
  timezones: any[] | null;
  forceRefresh: number;
  error: string | null;
}

const initialState: UtilsState = {
  timezones: null,
  forceRefresh: 0,
  error: null,
};

const menuReducer = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setTimezones: (state, action: PayloadAction<any[]>) => {
      state.timezones = action.payload;
    },
    triggerRefresh: state => {
      state.forceRefresh += 1;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { setTimezones, triggerRefresh, setError } = menuReducer.actions;
export default menuReducer.reducer;
