import React, { useState, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { SearchIcon } from '../Icon/Search';
import { CloseIcon } from '../Icon/Close';
import './index.scss';

interface DynamicTableProps {
  loading: boolean;
  placeHolder?: string;
  tableSearchProps: any;
}
const DynamicTableSearch = ({
  loading = true,
  placeHolder = 'Search Name, Email or Reference',
  tableSearchProps,
}: DynamicTableProps): JSX.Element => {
  const { searchTerm, filterSearch } = tableSearchProps;
  const [icon, setIcon] = useState<any>(<SearchIcon />);
  const [action, setAction] = useState<string>('search');
  const [currentValue, setCurrentValue] = useState<any>(searchTerm);
  const searchRef = useRef(null);

  useEffect(() => {
    if (!currentValue || currentValue === '') {
      setAction('search');
      setIcon(<SearchIcon />);
    } else if (searchTerm === currentValue) {
      setAction('clear');
      setIcon(<CloseIcon />);
    }
  }, [searchTerm, currentValue]);
  if (loading) {
    return (
      <div className="dynamic-table-search input-container">
        <Skeleton width={200} height={30} />
      </div>
    );
  }
  let filterSearchTO: any;
  const handleIcon = (e: any): void => {
    clearTimeout(filterSearchTO);
    filterSearchTO = setTimeout(() => {
      setCurrentValue(e.target.value);
      filterSearch(e.target.value);
    }, 1000);
  };
  const handleClickIcon = (): void => {
    if (action === 'clear') {
      setCurrentValue('');
      filterSearch('');
      searchRef.current.value = '';
      setAction('search');
    } else if (action === 'search') {
      searchRef.current.focus();
      searchRef.current.placeholder = '';
    }
  };
  const handleBlur = (): void => {
    searchRef.current.placeholder = placeHolder;
  };
  const handleFocus = (): void => {
    searchRef.current.placeholder = '';
  };
  return (
    <div className="dynamic-table-search">
      <div className="input-container">
        <input
          type="text"
          ref={searchRef}
          placeholder={placeHolder}
          onChange={handleIcon}
          defaultValue={currentValue}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </div>
      <div className="icon" onClick={handleClickIcon} tabIndex={-1} role="navigation" aria-hidden>
        {icon}
      </div>
    </div>
  );
};
export default DynamicTableSearch;
