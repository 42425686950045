import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { RootState } from 'store/rootReducer';
import MemoizedTimezoneDropDown from 'components/Shared/Dropdown/MemoizedTimezoneDropDown';
import DateTimePicker from 'components/Shared/DateTimePicker';
import Input from 'components/Shared/Input';
import { fullDateTime } from 'helpers/datetime';
import { fetchTimezones } from 'store/actions/utils.actions';
import Button from '../../../Button';

interface InviteCandidateProps {
  assessment: any;
  values: any;
  setFormValue: any;
  candidateOrEmployee: string;
  assessmentOrExercise: string;
  daysToComplete: number;
  errorMap: any;
  canInviteSelf: boolean;
  invitingSelf: boolean;
  handleInviteSelf: any;
}

const InviteCandidate = (props: InviteCandidateProps): JSX.Element => {
  const {
    assessment,
    setFormValue,
    values,
    candidateOrEmployee,
    assessmentOrExercise,
    daysToComplete,
    errorMap,
    canInviteSelf,
    invitingSelf,
    handleInviteSelf,
  } = props;

  const dispatch = useDispatch();
  const { timezones } = useSelector((state: RootState) => state.utils);
  useEffect(() => {
    dispatch(fetchTimezones());
  }, [dispatch]);

  const handleTimezoneChange = (key: string, value: any): void => {
    if (key === 'tz_code') {
      setFormValue('tz_code', value.code);
      setFormValue('tz_offset', value.offset);
    } else {
      setFormValue('tz_code_expiration', value.code);
      setFormValue('tz_offset_expiration', value.offset);
    }
  };

  const handleDateTimeChange = (key: string, value: any): void => {
    setFormValue(key, value);
  };

  const invitationDateInput = (
    <Input
      type="text"
      label="Invitation Date"
      autoComplete="off"
      placeholder="Select an invitation date"
      errorTxt={errorMap.invitation_date}
      onChange={e => handleDateTimeChange('invitation_date', e.target.value)}
      addErrorMargin
    />
  );
  const InvitationDateTimePickerProps = {
    customInput: invitationDateInput,
    utc: values.invitation_date,
    handleChange: value => handleDateTimeChange('invitation_date', value),
    props: {
      minDate: moment(fullDateTime(new Date().toUTCString(), 'UTC')).toDate(),
    },
  };

  const expirationDateInput = (
    <Input
      type="text"
      label="Expiration Date"
      autoComplete="off"
      placeholder="Select an expiration date"
      errorTxt={errorMap.expiration_date}
      onChange={e => handleDateTimeChange('expiration_date', e.target.value)}
      addErrorMargin
    />
  );
  const ExpirationDateTimePickerProps = {
    customInput: expirationDateInput,
    utc: values.expiration_date,
    handleChange: value => handleDateTimeChange('expiration_date', value),
    props: {
      minDate: values.invitation_date
        ? moment(values.invitation_date).add(1, 'days').tz(values.tz_code_expiration).toDate()
        : moment(new Date().toUTCString()).add(1, 'days').tz(values.tz_code_expiration).toDate(),
    },
  };

  return (
    <div className="invite-candidate">
      <h2>
        Invite a{candidateOrEmployee === 'employee' && 'n'} {candidateOrEmployee} to:{' '}
        <b>{assessment.recruiter_test_name}</b>
      </h2>
      <span className={`invite-yourself-cta ${!canInviteSelf ? 'hidden' : ''}`}>
        Want to try the {assessmentOrExercise} yourself?{' '}
        <a
          href="/"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            if (!invitingSelf) handleInviteSelf();
          }}
        >
          Invite Yourself
          {invitingSelf && (
            <>
              &ensp;
              <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
            </>
          )}
        </a>
      </span>
      <form className="form">
        <div className="field-row">
          <Input
            type="text"
            label="First Name*"
            autoComplete="off"
            placeholder="First Name"
            errorTxt={errorMap.first_name}
            onChange={e => setFormValue('first_name', e.target.value)}
            addErrorMargin
          />

          <Input
            type="text"
            label="Last Name*"
            autoComplete="off"
            placeholder="Last Name"
            errorTxt={errorMap.last_name}
            onChange={e => setFormValue('last_name', e.target.value)}
            addErrorMargin
          />
        </div>

        <div className="field-row">
          <Input
            type="email"
            label="Email Address*"
            autoComplete="off"
            placeholder="Email Address"
            errorTxt={errorMap.email_address}
            onChange={e => setFormValue('email_address', e.target.value)}
            addErrorMargin
          />

          <Input
            type="text"
            label="Phone Number"
            autoComplete="off"
            placeholder="+1 9999999999"
            errorTxt={errorMap.phone_number}
            onChange={e => setFormValue('phone_number', e.target.value)}
            addErrorMargin
          />
        </div>

        <div className="add-invitation-date">
          <p className="datetime-section-title">
            By default, {candidateOrEmployee}s you assign will receive invitations immediately. You can choose a
            different invitation date if you like.
          </p>

          <Button
            type="button"
            onClick={() => setFormValue('has_invitation_date', values.has_invitation_date ? 0 : 1)}
            variant="sub-primary md"
            text={`${values.has_invitation_date ? 'Remove' : 'Add'} Invitation Date`}
          />

          <div className={`datetime-section ${!values.has_invitation_date ? 'hidden' : ''}`}>
            <MemoizedTimezoneDropDown
              label="Timezone"
              placeholder="Search Timezones..."
              value={values.tz_code}
              handleChange={value => handleTimezoneChange('tz_code', value)}
              menuPlacement="top"
              defaultTimezones={timezones}
            />
            <DateTimePicker {...InvitationDateTimePickerProps} />
          </div>
        </div>
        <div className="add-expiration-date">
          <p className="datetime-section-title">
            By default, {candidateOrEmployee}s you assign will have {daysToComplete} days to complete the{' '}
            {assessmentOrExercise}. You can customize this if you like.
          </p>

          <Button
            id="InviteCandidate-add-expiration-date"
            type="button"
            onClick={() => setFormValue('has_expiration_date', values.has_expiration_date ? 0 : 1)}
            variant="sub-primary md"
            text={`${values.has_expiration_date ? 'Remove' : 'Add'} Expiration Date`}
          />

          <div className={`datetime-section ${!values.has_expiration_date ? 'hidden' : ''}`}>
            <MemoizedTimezoneDropDown
              label="Timezone"
              placeholder="Search Timezones..."
              value={values.tz_code_expiration}
              handleChange={value => handleTimezoneChange('tz_code_expiration', value)}
              menuPlacement="top"
              defaultTimezones={timezones}
            />
            <DateTimePicker {...ExpirationDateTimePickerProps} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default InviteCandidate;
