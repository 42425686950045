import React from 'react';
import './OverviewTab.scss';

interface CandidatePositionHistogramProps {
  buckets: { bucket: number; count: number }[];
  score_percent: number;
}
const CandidatePositionHistogram: React.FC<CandidatePositionHistogramProps> = ({ buckets, score_percent }) => {
  const candidateBucket = Math.min(9, Math.round(score_percent / 10));
  let largestBucket = 0;
  let sortedBuckets = [...buckets];
  sortedBuckets = sortedBuckets.sort((a, b) => a.bucket - b.bucket);
  sortedBuckets.forEach(b => {
    if (b.count > largestBucket) {
      largestBucket = b.count;
    }
  });

  const getHeight = (count: number): number => {
    return (count / largestBucket) * 100;
  };

  return (
    <div className="candidate-position-buckets">
      {sortedBuckets.map((bucket: { bucket: number; count: number }) => (
        <div
          className={`bucket ${getHeight(bucket.count) === 0 ? 'placeholder' : ''}`}
          style={{
            height: `${getHeight(bucket.count)}%`,
            backgroundColor: bucket.bucket === candidateBucket ? '#F68C3E' : '#004385',
            marginRight: '1px',
          }}
        />
      ))}
    </div>
  );
};

export default CandidatePositionHistogram;
