import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Button from 'components/Shared/Button';
import { connectSmartRecruiter } from 'api/integration.api';
import AloobaLogo from '../assets/dark-logo.svg';
import { hasOrgFeature } from '../Authorization/Check';

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const SmartRecruitersIntegrationPage: React.FC = () => {
  const query = useQuery();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleAgree = (): Promise<void> => {
    const companyId = query.get('companyId');
    if (!companyId) return;
    setLoading(true);
    connectSmartRecruiter(companyId)
      .then(response => {
        setDone(true);
        addToast({
          type: 'success',
          msg: response.data.message,
        });
      })
      .catch(error => {
        addToast({
          type: 'error',
          msg: error.response.data.message,
        });
      })
      .finally(() => setLoading(false));
  };

  let component = (
    <div>
      Enabling an ATS integration is not supported on your current subscription plan. Upgrade your subscription to
      integrate with our supported ATSs as well as gaining access to many other amazing features Alooba has to offer.
      <br />
      <br />
      <Button
        type="button"
        onClick={() => window.open('/billing', '_blank')}
        variant="primary md center"
        text="Upgrade Subscription"
      />
    </div>
  );
  if (hasOrgFeature('ats')) {
    if (done) {
      component = <div>You have connected SmartRecruiters with your Alooba account.</div>;
    } else {
      component = (
        <div>
          Alooba is partnering with SmartRecruiters to provide seamless integration between our platforms. By enabling
          this integration, you can easily send assessments to your candidates directly from your SmartRecruiters
          account.
          <br />
          <br />
          To proceed, please click the button below to authorize the integration.
          <br />
          <br />
          <Button
            type="button"
            onClick={handleAgree}
            variant="primary md center"
            text="Authorize Integration"
            loading={loading}
            loadingTxt=""
            disabled={loading}
          />
          <br />
          <br />
          Note: By authorizing this integration, you agree to share certain data between Alooba and SmartRecruiters.
          This includes candidate information such as name, email address, and assessment scores. You can revoke this
          authorization at any time from your SmartRecruiters account settings.
        </div>
      );
    }
  }
  return (
    <>
      <Helmet>
        <title>Enable Alooba and SmartRecruiters integration | alooba.com</title>
        <meta name="description" content="Enable Alooba and SmartRecruiters integration" />
      </Helmet>
      <div style={{ margin: '20px' }}>
        <img src={AloobaLogo} alt="Alooba Logo" style={{ width: '142px', margin: '0 auto', display: 'block' }} />
        <h2>Enable Alooba and SmartRecruiters integration</h2>
        <br />
        {component}
      </div>
    </>
  );
};

export default SmartRecruitersIntegrationPage;
