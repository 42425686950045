import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getSubscription, subscribe } from 'api/subscription.api';
import { Modal } from 'components/Shared/Modal';
import { PlanProps } from 'components/Billing/SubscriptionPlanOption';
import Button from 'components/Shared/Button';
import RadioInput from 'components/Shared/Input/radio';
import './SubscribeModal.scss';
import { useToasts } from 'react-toast-notifications';
import { refreshCurrentTokens } from 'store/actions/auth.actions';
import Tooltip from 'components/Shared/Tooltip';
import { useHistory } from 'react-router-dom';
import PaymentMethods from './PaymentMethods';
import BillingDetails from './BillingDetails';

interface SubscribeModalProps {
  isShown: boolean;
  setModalVisibility: (isShown: boolean) => void;
  plan: PlanProps;
  currency: string;
  canUseTrial?: boolean;
}

const SubscribeModal = (props: SubscribeModalProps): JSX.Element => {
  const { isShown, setModalVisibility, plan, currency, canUseTrial } = props;
  const [loading, setLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const [billingName, setBillingName] = useState(null);
  const [billingEmail, setBillingEmail] = useState(null);
  const [billingCountry, setBillingCountry] = useState(null);
  const [abn, setAbn] = useState(null);
  const [postalcode, setPostalcode] = useState(null);
  const { addToast } = useToasts();
  const orgId = userDetails?.recruiter_detail?.organisation_id;
  const dispatch = useDispatch();
  const history = useHistory();

  setModalVisibility(isShown);

  const customProps = {
    loading,
    cancelVariant: 'secondary md',
    setModalVisibility,
    containerClass: 'subscription-modal',
  };

  // filter options with prices
  const options = plan.prices[currency] || [];
  // sort options by period_months
  options.sort((a, b) => {
    return a.period_months - b.period_months;
  });

  const [selectedOption, setSelectedOption] = useState(options.length > 0 ? options[options.length - 1] : null);

  const [period, setPeriod] = useState('');
  useEffect(() => {
    if (selectedOption) {
      // set the period to be the current date plus the period in months formatted in the user's local date format
      const now = new Date(Date.now());
      const end = new Date(Date.now());
      end.setMonth(end.getMonth() + selectedOption.period_months);
      end.setDate(end.getDate() - 1);
      if (canUseTrial) {
        now.setDate(now.getDate() + 7);
        end.setDate(end.getDate() + 7);
      }

      setPeriod(`${now.toLocaleDateString()} - ${end.toLocaleDateString()}`);
    }
  }, [canUseTrial, selectedOption]);

  useEffect(() => {
    if (billingCountry !== 'AU') {
      setAbn(null);
    }
    if (!['US', 'CA'].includes(billingCountry)) {
      setPostalcode(null);
    }
  }, [billingCountry]);

  const handleChangeOption = (option): void => {
    setSelectedOption(option);
  };

  let totalPrice = null;
  let tax = false;
  if (selectedOption) {
    totalPrice = selectedOption.price;
    if (billingCountry === 'AU' && totalPrice !== null) {
      tax = true;
      totalPrice += totalPrice * 0.1; // 10% GST
    }
  }

  const canSubscribe = !!(
    selectedPaymentMethod &&
    billingName &&
    billingEmail &&
    billingCountry &&
    (!['US', 'CA'].includes(billingCountry) || postalcode)
  );

  const handleSubscribe = async (): Promise<void> => {
    setLoading(true);
    try {
      await subscribe(
        orgId,
        selectedOption.stripe_price_id,
        currency,
        billingName,
        billingEmail,
        billingCountry,
        abn,
        postalcode,
        canUseTrial,
      );
      let updatedSubscriptionDetails = null;
      /* eslint-disable no-await-in-loop */
      for (let attempt = 0; attempt < 20; attempt += 1) {
        const response = await getSubscription(orgId);
        updatedSubscriptionDetails = response.data.data ?? null;
        if (updatedSubscriptionDetails?.latest_charge?.status !== 'pending') {
          break;
        }
      }

      if (!updatedSubscriptionDetails) {
        throw new Error('Subscription failed. Please try again.');
      }
      if (updatedSubscriptionDetails?.latest_charge?.status === 'pending') {
        throw new Error('Subscription payment is pending. Please check back later.');
      }
      if (updatedSubscriptionDetails.latest_charge?.status === 'failed') {
        throw new Error(updatedSubscriptionDetails.latest_charge.failure_message);
      }
      await dispatch(refreshCurrentTokens());
      addToast({
        type: 'success',
        msg: 'Your subscription is now active.',
      });
      setLoading(false);
      setModalVisibility(false);
      history.push('/alooba-assess/create-assessment');
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        msg: error.response?.data?.errors?.message || error.message,
      });
      throw error;
    }
  };

  return (
    <Modal {...{ ...props, ...customProps }}>
      <>
        <div>
          {canUseTrial ? (
            <>
              <h2>Start Your 7 Day Free Trial of Alooba {plan.name}</h2>
              <p className="enjoy-trial">
                Enjoy 7 days of Alooba {plan.name} for free!
                <br />
                <b>
                  You won’t be charged unless you continue past the trial. Cancel anytime during the trial period to
                  avoid payment.
                </b>
                <br />
                Select the plan you’d like to continue with after your trial.
              </p>
            </>
          ) : (
            <h2>Subscribe to Alooba {plan.name}</h2>
          )}
        </div>
        {options.length > 0 ? (
          <>
            <div className="payment-options">
              {options.map((option, i) => (
                <div
                  key={option.period}
                  className={`payment-option ${options.length > 1 ? 'selectable' : ''}`}
                  onClick={() => handleChangeOption(option)}
                  onKeyDown={() => handleChangeOption(option)}
                  role="button"
                  tabIndex={i}
                >
                  {options.length > 1 && (
                    <div className="select">
                      <RadioInput
                        name="subscription-plan-option"
                        id={`${option.period}-subscription-plan-option`}
                        checked={selectedOption.period === option.period}
                      />
                    </div>
                  )}
                  <div className="period">{option.period}</div>
                  <div className="credits">
                    <strong>{option.subscription_credits.toLocaleString()}</strong>
                    <br />
                    {option.period.toLowerCase()} subscription credits
                  </div>
                  <div className="billing-period">Billed {option.period.toLowerCase()}</div>
                  <div className="monthly-price">
                    <strong>
                      {option.currency_symbol}
                      {option.monthly_price}
                    </strong>
                    <br />
                    {currency} per month
                  </div>
                </div>
              ))}
              {tax && <p className="disclaimer">Prices are exclusive of any applicable taxes.</p>}
            </div>
            <PaymentMethods
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
            {selectedPaymentMethod && (
              <BillingDetails
                orgId={orgId}
                name={billingName}
                setName={setBillingName}
                email={billingEmail}
                setEmail={setBillingEmail}
                country={billingCountry}
                setCountry={setBillingCountry}
                abn={abn}
                setAbn={setAbn}
                postalcode={postalcode}
                setPostalcode={setPostalcode}
              />
            )}
          </>
        ) : (
          <div className="custom-pricing">
            <h3>Let&apos;s talk</h3>
            <p>Contact us for custom pricing to suite your needs.</p>
          </div>
        )}
        <div className="modal-footer">
          {selectedOption &&
            (canUseTrial ? (
              <p>
                By starting your free trial, you hereby authorize Alooba to collect your payment details and agree to be
                charged{' '}
                <strong>
                  {selectedOption.currency_symbol}
                  {totalPrice.toLocaleString()}
                </strong>{' '}
                {currency} {tax && '(including GST)'} for the period {period} if you do not cancel before the end of
                your 7-day trial. After this, your subscription will automatically renew at the end of each period
                unless canceled. You may cancel at any time, but no refunds will be issued for unused portions of your
                subscription. Full subscription terms are available{' '}
                <a
                  href={`${process.env.REACT_APP_LANDING_PAGE_URL}/terms-and-conditions/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            ) : (
              <p>
                You hereby authorize Alooba to charge you{' '}
                <strong>
                  {selectedOption.currency_symbol}
                  {totalPrice.toLocaleString()}
                </strong>{' '}
                {currency} {tax && '(including GST)'} for the period {period} and to automatically charge you at the end
                of each period until you cancel your subscription. You may cancel at any time, however, you will not be
                entitled to a refund upon cancellation. Full subscription terms are available{' '}
                <a
                  href={`${process.env.REACT_APP_LANDING_PAGE_URL}/terms-and-conditions/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            ))}
          <div className="modal-footer-actions">
            <Button
              variant="sub-primary lg"
              text="Cancel"
              disabled={loading}
              onClick={() => setModalVisibility(false)}
            />
            {options.length > 0 ? (
              <Tooltip tooltip={!canSubscribe ? 'You must add a payment method.' : ''}>
                <Button
                  variant="primary lg"
                  text={canUseTrial ? 'Start Free Trial' : 'Subscribe'}
                  onClick={handleSubscribe}
                  disabled={!canSubscribe || loading}
                  loading={loading}
                  loadingTxt={canUseTrial ? 'Starting Your Free Trial...' : 'Subscribing...'}
                />
              </Tooltip>
            ) : (
              <Button
                variant="primary lg"
                text="Talk to Us"
                onClick={() => {
                  if (window && window.location) {
                    window.location.href = `${process.env.REACT_APP_LANDING_PAGE_URL}/discuss-plans/`;
                  }
                }}
              />
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};
export default SubscribeModal;
