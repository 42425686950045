import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CandidateState {
  candidate: Candidate | null;
  results: CandidateResults;
  loading: LoadingState;
  commentError: null | { message: string; id?: number };
  /** Hiring status state. Not the actual hiring status of a candidate. */
  hiringStatus: { updated: boolean; errorMessage: string | null };
  selectedTestId?: number | null;
  error: string | null;
  toastError: string | null;
  candidateSkillAwareness: CandidateSelfAwareness[];
  candidateResources: CandidateResources;
  testEvents: Record<number, TestEvent[]>;
  cloaked: boolean;
}

export interface TestEvent {
  id: number;
  reference: string;
  test_id: number;
  question_number: number;
  event: string;
  params: any;
  created_at: string;
  event_time?: string;
  original_event_time?: string;
}

export interface Recruiter {
  first_name: string;
  last_name?: string;
  email: string;
  is_deleted: boolean;
}

export interface ScoreBucket {
  bucket: number;
  count: number;
}
export interface Candidate {
  id: number;
  reference: string;
  email_address: string;
  status: string;
  first_name: string;
  last_name?: string;
  full_name?: string;
  invited_at: string;
  expires_on?: string;
  submitted_at: string;
  phone: string;
  score: number;
  score_percent: number;
  hiring_status: string;
  candidate_test_information?: any[];
  information_fields: any[];
  related_assessments: any[];
  suspicious_activities: any[];
  comments: any[];
  feedback?: any;
  tests?: any[];
  enable_snapshot?: boolean;
  enable_screen_recording?: boolean;
  playback_url?: string;
  video_records?: string[];
  resume_url?: string;
  snapshots?: Snapshot[];
  recruiter_user_id?: number;
  invite_source?: string;
  organisation_name?: string;
  verification_code?: string;
  recruiter?: Recruiter;
  percentile?: number;
  subject_scores?: any[];
  candidates_scores_buckets?: ScoreBucket[];
  candidates_skills_scores_buckets?: any[];
  assessment_modified_after_submission?: boolean;
  is_erased?: boolean;
  can_compare?: boolean;
  can_extend_expiry_date?: boolean;
}

export interface Snapshot {
  id: number;
  image_url: string;
  test: string;
  created_at: string;
  face_number?: number;
  face_match?: boolean;
  cheating_labels?: any[];
}

export interface Comment {
  id?: number;
  name: string;
  comment: string;
  created_at: string;
  writable: boolean;
  user_id: number;
}

interface CandidateResults {
  tests: any;
}

export interface CandidateSelfAwareness {
  subject_id: number;
  subject: string;
  reference: string;
  accuracy: string;
  score: number;
  self_rating: number;
}
export interface SkillResource {
  id: number;
  link: string;
  title: string;
  subject_id: number;
  topic_id: number;
  organisation_id: number;
}
export interface Topics {
  id: number;
  subject_id: number;
  topic: string;
  score: number;
  max_score: number;
  requires_evaluation: number;
  skill_resources: SkillResource[];
}
export interface Subjects {
  id: number;
  subject: string;
  description: string;
  score: number;
  max_score: number;
  requires_evaluation: number;
  skill_resources: SkillResource[];
  isLoading?: boolean;
}
export interface CandidateResources {
  topics: Topics[];
  subjects: Subjects[];
  is_summary_enabled?: boolean;
}
interface LoadingState {
  candidate: boolean;
  comments: boolean;
  selfAwareness: boolean;
  insights: boolean;
  resources: boolean;
}

const initialState: CandidateState = {
  candidate: null,
  results: {
    tests: {},
  },
  loading: {
    candidate: false,
    comments: false,
    selfAwareness: false,
    insights: false,
    resources: false,
  },
  hiringStatus: {
    updated: false,
    errorMessage: null,
  },
  selectedTestId: null,
  commentError: null,
  error: null,
  toastError: null,
  candidateSkillAwareness: [],
  candidateResources: {
    subjects: [],
    topics: [],
    is_summary_enabled: false,
  },
  testEvents: {},
  cloaked: false,
};

const emptySummarySubject: Subjects = {
  id: 0,
  subject: 'Summary',
  description: '',
  score: 0,
  max_score: 0,
  skill_resources: [],
  requires_evaluation: 0,
  isLoading: false,
};

const menuReducer = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ component: string; value: boolean }>) => {
      state.loading[action.payload.component] = action.payload.value;
    },
    getCandidateStart: state => {
      state.loading.candidate = true;
      // if new candidate is about to be loaded then clear the self awareness data
      // so that it loads
      state.candidateSkillAwareness = [];
      state.error = null;
    },
    getCandidateSuccess: (state, action: PayloadAction<Candidate>) => {
      state.candidate = action.payload;
      state.loading.candidate = false;
    },
    saveNewCommentStart: state => {
      state.loading.comments = true;
    },
    pushCandidateComment: (state, action: PayloadAction<Comment>) => {
      state.candidate.comments.unshift(action.payload);
      state.loading.comments = false;
    },
    saveCommentFailure: (state, action: PayloadAction<{ message: string; id?: number }>) => {
      state.commentError = action.payload;
      state.loading.comments = false;
    },
    clearCommentFailure: state => {
      state.commentError = null;
    },
    deleteCommentSuccess: (state, action: PayloadAction<number>) => {
      state.candidate.comments = state.candidate.comments.filter(comment => comment.id !== action.payload);
    },
    editCommentSuccess: (state, action: PayloadAction<Comment>) => {
      const i = state.candidate.comments.findIndex(c => c.id === action.payload.id);
      state.candidate.comments[i] = action.payload;
    },
    resetHiringStatus: state => {
      state.hiringStatus = {
        updated: false,
        errorMessage: null,
      };
    },
    editHiringStatusSuccess: state => {
      state.hiringStatus = {
        updated: true,
        errorMessage: null,
      };
    },
    editHiringStatusFailure: (state, action: PayloadAction<string>) => {
      state.hiringStatus = {
        updated: false,
        errorMessage: action.payload,
      };
    },
    getCandidateFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading.candidate = false;
    },
    getTestResultsSuccess: (state, action: PayloadAction<{ testId: number; test: any }>) => {
      const { testId, test } = action.payload;
      state.results.tests[testId] = test;
      state.results.tests[testId].selectedQuestionIndex = 0;
      const question = state.results.tests[testId].questions[0];
      state.results.tests[testId].currentQuestion = question;
    },
    getTestResultsFailure: (state, action: PayloadAction<number>) => {
      const testId = action.payload;
      state.error = `Could not fetch test data from server (${testId})`;
    },
    getTestEventsSuccess: (state, action: PayloadAction<{ testId: number; events: TestEvent[] }>) => {
      let testEvents: TestEvent[] = state.testEvents[action.payload.testId];
      if (!testEvents) {
        testEvents = [];
      }
      testEvents = testEvents.concat(action.payload.events);
      state.testEvents[action.payload.testId] = testEvents;
    },
    getTestEventsFailure: (state, action: PayloadAction<{ testId: number; details: string }>) => {
      state.toastError = `Could not fetch test events: (${action.payload.details})`;
    },
    setInsightsLoadingState: (state, action: PayloadAction<boolean>) => {
      state.loading.insights = action.payload;
    },
    getResourcesStart: state => {
      state.loading.resources = true;
    },
    getResourcesSuccess: (state, action: PayloadAction<CandidateResources>) => {
      if (action.payload.is_summary_enabled) {
        action.payload.subjects.unshift(emptySummarySubject);
      }
      state.candidateResources = action.payload;
      state.loading.resources = false;
    },
    getResourcesFailure: (state, action: PayloadAction<string>) => {
      state.toastError = action.payload;
      state.loading.resources = false;
    },
    getSummaryStart: state => {
      state.candidateResources.subjects[0].isLoading = true;
    },
    getSummarySuccess: (state, action: PayloadAction<string>) => {
      state.candidateResources.subjects[0].description = action.payload;
      state.candidateResources.subjects[0].isLoading = false;
    },
    getSummaryFailure: (state, action: PayloadAction<string>) => {
      state.toastError = action.payload;
      state.candidateResources.subjects[0].isLoading = false;
    },
    getSelfAwarenessStart: state => {
      state.loading.selfAwareness = true;
    },
    getSelfAwarenessSuccess: (state, action: PayloadAction<CandidateSelfAwareness[]>) => {
      state.candidateSkillAwareness = action.payload;
      state.loading.selfAwareness = false;
    },
    getSelfAwarenessFailure: (state, action: PayloadAction<string>) => {
      state.toastError = action.payload;
    },
    selectTest: (state, action: PayloadAction<{ testId?: number }>) => {
      const { testId } = action.payload;
      state.selectedTestId = testId;
    },
    setTestProperty: (
      state,
      action: PayloadAction<{
        testId: number;
        propertyName: string;
        value: any;
      }>,
    ) => {
      const { testId, propertyName, value } = action.payload;
      const { tests } = state.results;
      if (!tests[testId]) {
        tests[testId] = {
          questions: [],
        };
      }
      const test = tests[testId];
      test[propertyName] = value;
    },
    setTestQuestionProperty: (
      state,
      action: PayloadAction<{
        testId: number;
        questionIndex: number;
        propertyName: string;
        value: any;
      }>,
    ) => {
      const { testId, questionIndex, propertyName, value } = action.payload;
      state.results.tests[testId].questions[questionIndex][propertyName] = value;
    },
    setQuestionScore: (
      state,
      action: PayloadAction<{
        testId: number;
        marks: number;
        subjectIndex: number;
        selectedQuestionIndex: number;
        questionIndex: number;
      }>,
    ) => {
      const { testId, marks, subjectIndex, questionIndex, selectedQuestionIndex } = action.payload;
      state.results.tests[testId].questions[selectedQuestionIndex].score = `${marks}`;

      state.results.tests[testId].subjects_with_questions[subjectIndex].questions[questionIndex].score = marks;
    },
    calculateTestSubjectScore(
      state,
      action: PayloadAction<{
        testId: number;
        subjectIndex: number;
      }>,
    ) {
      const { testId, subjectIndex } = action.payload;
      const maxScore = state.results.tests[testId].subjects_with_questions[subjectIndex].questions.reduce(
        (maxScore, question) => {
          if (maxScore === null) {
            maxScore = 0;
          }
          return maxScore + parseInt(question.max_score, 10);
        },
        null,
      );

      const score = state.results.tests[testId].subjects_with_questions[subjectIndex].questions.reduce(
        (score, question) => {
          if (score === null) {
            score = 0;
          }
          return score + parseInt(question.score, 10);
        },
        null,
      );

      const percentage = Math.round((score / maxScore) * 100);
      state.results.tests[testId].subjects_with_questions[subjectIndex].scored_percent = percentage;
      state.results.tests[testId].subjects_with_questions[subjectIndex].score = score;
      state.results.tests[testId].subjects_with_questions[subjectIndex].max_score = maxScore;
    },
    setSubjectScore: (
      state,
      action: PayloadAction<{
        testId: number;
        scoredPercent: number;
        subjectIndex: number;
      }>,
    ) => {
      const { testId, scoredPercent, subjectIndex } = action.payload;

      const subject_score = state.candidate?.subject_scores?.find(
        s => s.subject === state.results.tests[testId].subjects_with_questions[subjectIndex].subject,
      );
      if (subject_score) {
        subject_score.scored_percent = scoredPercent;
      } else {
        state.candidate.subject_scores.push({
          subject: state.results.tests[testId].subjects_with_questions[subjectIndex].subject,
          scored_percent: scoredPercent,
        });
      }
    },
    setTotalScore: (
      state,
      action: PayloadAction<{
        testId: number;
        testIndex: number;
        score: number;
        maxScore: number;
        overallScore: number;
      }>,
    ) => {
      const { testId, testIndex, score, maxScore, overallScore } = action.payload;
      const scoredPercent = Math.round(score / maxScore);
      if (state.results.tests[testId].total_score) {
        state.results.tests[testId].total_score.score = score;
        state.results.tests[testId].total_score.scored_percent = scoredPercent;
      } else {
        state.results.tests[testId].total_score = {
          score,
          max_score: maxScore,
          scored_percent: scoredPercent,
        };
      }
      state.candidate.tests[testIndex].evaluation_status = 'Evaluated';
      let updateAssessmentStatus = true;
      for (let i = 0; i < state.candidate.tests.length; i += 1) {
        if (
          state.candidate.tests[i].evaluation_status === 'Requires Evaluation' ||
          state.candidate.tests[i].is_submitted === false
        ) {
          updateAssessmentStatus = false;
        }
      }
      if (updateAssessmentStatus) {
        state.candidate.status = 'Completed';
      }
      if (overallScore !== null && overallScore !== undefined) {
        state.candidate.score_percent = overallScore;
      }
    },
    toggleCollapseSubject: (
      state,
      action: PayloadAction<{
        testId: number;
        subjectIndex: number;
      }>,
    ) => {
      const { testId, subjectIndex } = action.payload;
      const subject = state.results.tests[testId].subjects_with_questions[subjectIndex];
      subject.collapsed = !subject.collapsed;
    },
    changeCandidateTestStatus: (state, action: PayloadAction<{ status: string }>) => {
      const { status } = action.payload;
      state.candidate.status = status;
    },
    changeTestsCompletedStatus: (state, action: PayloadAction<{ testIds: number[]; is_submitted: boolean }>) => {
      const { testIds, is_submitted } = action.payload;
      testIds.forEach(testId => {
        const test = state.candidate.tests.find(t => t.id === testId);
        if (test) {
          test.is_submitted = is_submitted;
        }
      });
    },
    clearTestScores: state => {
      state.candidate.score_percent = null;
      state.candidate.percentile = null;
      state.candidate.tests?.forEach(test => {
        test.is_submitted = false;
      });
    },
    setExpiresOn: (state, action: PayloadAction<{ expires_on: string }>) => {
      const { expires_on } = action.payload;
      state.candidate.expires_on = expires_on;
    },
    setCloaked: (state, action: PayloadAction<{ cloaked: boolean }>) => {
      const { cloaked } = action.payload;
      state.cloaked = cloaked;
    },
  },
});

export const {
  clearCommentFailure,
  setLoading,
  getCandidateStart,
  getCandidateSuccess,
  getCandidateFailure,
  getTestResultsSuccess,
  getTestResultsFailure,
  saveNewCommentStart,
  saveCommentFailure,
  pushCandidateComment,
  deleteCommentSuccess,
  editCommentSuccess,
  editHiringStatusSuccess,
  editHiringStatusFailure,
  selectTest,
  setTestProperty,
  setTestQuestionProperty,
  getSelfAwarenessStart,
  getSelfAwarenessSuccess,
  getSelfAwarenessFailure,
  setQuestionScore,
  resetHiringStatus,
  setSubjectScore,
  calculateTestSubjectScore,
  setTotalScore,
  getResourcesStart,
  getResourcesSuccess,
  getResourcesFailure,
  getSummaryStart,
  getSummarySuccess,
  getSummaryFailure,
  setInsightsLoadingState,
  changeCandidateTestStatus,
  toggleCollapseSubject,
  getTestEventsSuccess,
  getTestEventsFailure,
  changeTestsCompletedStatus,
  clearTestScores,
  setExpiresOn,
  setCloaked,
} = menuReducer.actions;
export default menuReducer.reducer;
