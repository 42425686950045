import React from 'react';

import { LoadingIcon } from 'components/Shared/Icon/Loading';
import { GridIcon } from 'components/Shared/Icon/Grid';
import ReloadIcon from '../../images/icons/reload-icon-blue.svg';
import './index.scss';

interface Props {
  loading?: boolean;
  message?: string;
  onRetry?: () => void;
}
const TableBlank = ({ loading, message = 'No candidates invited yet.', onRetry }: Props): JSX.Element => {
  return (
    <div className="participants-container blank main_white_bg">
      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="column-center-cross-axis-alignment">
          <GridIcon text={message} />
          {onRetry && (
            <span className="retry-row" role="button" onClick={onRetry} tabIndex={0}>
              <img src={ReloadIcon} alt="Request retake icon" />
              retry
            </span>
          )}
        </div>
      )}
    </div>
  );
};
export default TableBlank;
