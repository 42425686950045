import React from 'react';

import LocationIcon from 'images/icons/globe-blue.svg';
import UrlIcon from 'images/icons/url-blue.svg';
// eslint bug workaround
// https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope
// eslint-disable-next-line no-shadow
export enum InformationFieldType {
  URL = 'URL',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
}

interface CandidateInformationFieldProps {
  label: string;
  value: string;
  type: InformationFieldType;
}

const InformationField: React.FC<CandidateInformationFieldProps> = ({ label, value, type }): JSX.Element => {
  return (
    <div className="information-field-container my2">
      <div className="information-field">{label}</div>

      <div className="information-field-value">
        {label === 'Location' ? <img className="personal-info-icon" src={LocationIcon} alt="Location Icon" /> : ''}
        {value}

        {type === InformationFieldType.URL ? (
          <a href={value} target="_blank" rel="noreferrer">
            <img className="personal-info-icon ml3" src={UrlIcon} alt="URL Icon" />
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default InformationField;
