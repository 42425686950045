import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { getSubscription } from 'store/actions/subscription.actions';
import { getCustomerPortalLink } from 'api/subscription.api';
import { useToasts } from 'react-toast-notifications';
import SubscriptionPricingTable from './SubscriptionPricingTable';
import UpsellLiteSubscription from './UpsellLiteSubscription';
import Button from '../Shared/Button';
import CreditUsage from './CreditUsage';

const BillingOverviewTab: React.FC = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [loadingLink, setLoadingLink] = useState(false);
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { subscription, loading, error } = useSelector((state: RootState) => state.subscription);
  const orgId = userDetails?.recruiter_detail?.organisation_id;

  useEffect(() => {
    if (orgId && !subscription) {
      dispatch(getSubscription(orgId));
    }
  }, [orgId, dispatch, subscription]);

  const getLink = async (): Promise<void> => {
    try {
      setLoadingLink(true);
      const response = await getCustomerPortalLink(orgId);
      if (response?.data?.data?.url) {
        window.location.assign(response.data.data.url);
      }
    } catch (e) {
      setLoadingLink(false);
      addToast({ type: 'error', msg: e.response?.data?.message || e.message });
    }
  };

  return (
    <div className="billing-details">
      <div className="subscription main-container">
        <div>
          {subscription && subscription.plan?.name !== 'Enterprise' && (
            <Button
              variant="primary md right"
              text="Manage Subscription & Billing Details"
              onClick={getLink}
              loading={loadingLink}
              loadingTxt="Redirecting..."
            />
          )}
          <h3 className="mb2">Subscription</h3>
          {subscription ? (
            <div>
              <p>
                <strong>Current Plan: </strong>
                {subscription.plan.name}
              </p>
              {subscription.status === 'trialing' && subscription.trial_ends_at && (
                <p>
                  <strong>Free Trial Ending On: </strong>
                  {new Date(subscription.trial_ends_at).toLocaleDateString()}
                </p>
              )}
              {!subscription.ends_at &&
                subscription.status !== 'trialing' &&
                subscription.renews_at &&
                new Date(subscription.renews_at) > new Date() && (
                  <p>
                    <strong>Renewing On: </strong>
                    {new Date(subscription.renews_at).toLocaleDateString()}
                  </p>
                )}
              {subscription.ends_at && (
                <p>
                  <strong>Subscription Ending On: </strong>
                  {new Date(subscription.ends_at).toLocaleDateString()}
                </p>
              )}
            </div>
          ) : (
            <div>
              {loading && <p>Loading...</p>}
              {error && <p>{error}</p>}
              {loading === false && !error && (
                <>
                  <p>
                    Unlock the full potential of Alooba with a subscription plan that fits your hiring needs. Visit our{' '}
                    <a href={`${process.env.REACT_APP_ALOOBA_LEGACY_URL}/pricing`} target="_blank" rel="noreferrer">
                      pricing page
                    </a>{' '}
                    for more details of what&apos;s included in earch plan.
                  </p>
                  <SubscriptionPricingTable />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <CreditUsage />
      <UpsellLiteSubscription />
    </div>
  );
};

export default BillingOverviewTab;
