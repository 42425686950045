import React from 'react';
import { goToLegacyApp } from 'hooks';
import FlatPill from 'components/Shared/Pill/flatPill';
import authorize, { isRestricted } from '../../Authorization/Check';

interface AssessmentCardNotPublishedProps {
  assessment?: any;
}
const AssessmentCardNotPublished = ({ assessment }: AssessmentCardNotPublishedProps): JSX.Element => {
  const restricted = isRestricted(assessment);
  const handleClick = (e: React.SyntheticEvent<EventTarget>): void => {
    goToLegacyApp(e, `create-assessment-customized-confirm/${assessment.id}`);
  };
  let flatPill;
  if (authorize('assessment', 'edit', assessment.role.user_id) && !restricted) {
    flatPill = <FlatPill onClick={handleClick} text="Complete Assessment" variant="primary-alt" />;
  } else {
    flatPill = '';
  }
  return flatPill;
};

export default AssessmentCardNotPublished;
