import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Shared/Icon/Chevron';
import NavigationItem from './NavigationItem';
import { MenuItemType } from './Menu';
import { toggleMenu } from '../../store/reducers/layout';

export type NavigationType = {
  items: MenuItemType[];
};

const Navigation: React.FC<NavigationType> = ({ items }) => {
  const dispatch = useDispatch();
  const { collapsed } = useSelector((state: RootState) => state.layout);
  const handleToggleMenu = (): void => {
    dispatch(toggleMenu());
  };

  return (
    <>
      <input
        type="checkbox"
        id="menuToggle"
        className="input-collapse"
        onClick={handleToggleMenu}
        defaultChecked={collapsed}
      />
      {items.length > 0 && (
        <nav
          role="navigation"
          aria-label="side-navigation"
          className={`side-navigation-panel ${collapsed ? 'collapsed' : ''}`}
        >
          <>
            <label htmlFor="menuToggle" className="icon-collapse">
              {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </label>
            {items.map(item => (
              <NavigationItem key={item.itemId} item={item} showMenu={item.showMenu} collapsed={collapsed} />
            ))}
          </>
        </nav>
      )}
    </>
  );
};

export default Navigation;
