import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { deleteParticipant } from 'api/assessment.api';
import { Modal } from 'components/Shared/Modal';
import { RefreshContext } from 'components/Participants/context/refresh';
import './index.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { handleError } from '../../../handleError';

interface DeleteCandidateModalProps {
  candidate: any;
  setModalVisibility: any;
  isShown: boolean;
  is_cloaked?: boolean;
  redirectTo?: string;
  getData: any;
}
const DeleteCandidateModal = (props: DeleteCandidateModalProps): JSX.Element => {
  const history = useHistory();
  const { candidate, setModalVisibility, redirectTo, is_cloaked } = props;
  const candidateName =
    is_cloaked ||
    candidate['Cloak Candidates'] ||
    (candidate.data_erasure_request_id !== null && candidate.data_erasure_request_id !== undefined)
      ? candidate.Reference || candidate.reference
      : candidate.full_name || candidate.Name;
  const [deleting, setDeleting] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useContext(RefreshContext);
  const { addToast } = useToasts();
  const { participantsPaginator } = useSelector((state: RootState) => state.assessmentCandidate);

  const getParticipantTablePage = (): number => {
    return participantsPaginator.to === participantsPaginator.from && participantsPaginator.current_page > 1
      ? participantsPaginator.current_page - 1
      : participantsPaginator.current_page;
  };

  const handleButtonAction = async (e: React.SyntheticEvent<EventTarget>): Promise<any> => {
    e.stopPropagation();
    e.preventDefault();
    if (deleting) {
      return;
    }
    setDeleting(true);
    const errorToast = (msg: string): void => {
      addToast({
        type: 'error',
        msg,
      });
    };
    deleteParticipant({
      candidate_test_id: candidate.candidate_test_id ?? candidate.id,
    })
      .then(response => {
        if (response.data.success) {
          addToast({
            type: 'success',
            msg: `${candidateName} has been deleted.`,
          });
          if (props.getData) {
            props.getData({
              page: getParticipantTablePage(),
            });
          }
          if (redirectTo) {
            history.push(redirectTo);
          } else {
            setFirstLoad(!firstLoad);
          }
        } else {
          errorToast(response.data.message);
        }
      })
      .catch(e => {
        errorToast('Unable to remove participant');
        handleError(e);
      })
      .finally(() => {
        setDeleting(false);
        setModalVisibility(false);
      });
  };

  const customProps = {
    loading: deleting,
    actionText: 'Delete',
    showCancel: true,
    cancelVariant: 'sub-primary',
    hideCloseButton: true,
    handleButtonAction,
    containerClass: 'participants-modal delete-participant-modal',
  };

  const warningMessage =
    candidate.status === 'Invited' || candidate.Status === 'Invited'
      ? 'Are you sure you want to revoke the assessment invitation?'
      : 'Be careful, this is irreversible.';

  return (
    <Modal {...{ ...props, ...customProps }}>
      <>
        <h2 data-testid="delete-candidate-modal-header">{`Delete ${candidateName}'s Assessment`}</h2>
        <br />
        <p>{warningMessage}</p>
        <hr />
      </>
    </Modal>
  );
};
export default DeleteCandidateModal;
