import React from 'react';
import './QuestionButton.scss';

export interface Props {
  questionDetails: QuestionDetails;
  pendingResponses: number;
  onQuestionSelected: (question: QuestionDetails) => void;
}

export interface QuestionDetails {
  questionId: number;
  difficulty: string;
  position: number;
  question: string;
  subjects: string[];
  background?: string;
  modelResponse: string;
  positiveIndicators: string;
  negativeIndicators: string;
  maxScore: number;
  candidateResponses: [];
}
const QuestionButton: React.FC<Props> = ({ questionDetails, pendingResponses, onQuestionSelected }) => {
  return (
    <button
      type="button"
      onClick={() => {
        onQuestionSelected(questionDetails);
      }}
      disabled={pendingResponses < 1}
    >
      <div className="question-button">
        <div className="question-number">Q{questionDetails.position}</div>
        <div className="badge">{pendingResponses}</div>
      </div>
    </button>
  );
};
export default QuestionButton;
