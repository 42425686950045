import React, { useMemo, useState } from 'react';
import './ManageCredits.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { AdditionalCreditPackage } from 'api/contract';
import CreditBundle from './CreditBundle';
import './AdditionalCreditPackages.scss';
import PurchaseCreditsModal from './PurchaseCreditsModal';

function AdditionalCreditPackages(): any {
  const packages = useSelector((state: RootState) => state.subscription?.availablePackages);
  const [buyNowModalVisible, setBuyNowModalVisible] = useState<boolean>(false);
  const [selectedBundle, setSelectedBundle] = useState<AdditionalCreditPackage | null>(null);

  const buyNowModalProps = useMemo(() => {
    return {
      isShown: buyNowModalVisible,
      setModalVisibility: setBuyNowModalVisible,
      bundle: selectedBundle,
    };
  }, [buyNowModalVisible, selectedBundle]);

  const onBuyNow = (price_id): void => {
    setSelectedBundle(packages.find(pkg => pkg.price_id === price_id));
    setBuyNowModalVisible(true);
  };
  const calculateSavingsPercentage = (bundle: AdditionalCreditPackage): number => {
    const smallestBundle = packages.reduce((acc, curr) => (acc.credits < curr.credits ? acc : curr));
    const baselineRate = smallestBundle.credits / smallestBundle.cost;
    const costAtBaseline = bundle.credits / baselineRate;
    const savings = ((costAtBaseline - bundle.cost) / costAtBaseline) * 100;
    return Math.round(savings);
  };
  return (
    <div className="additional-credit-packages">
      <h2>Purchase Additional Credits</h2>
      <p>Purchase credits ahead of time to enjoy bigger savings and uninterrupted access.</p>
      <div className="packages main-container container">
        {packages?.map(pkg => (
          <CreditBundle
            bundle={pkg}
            onBuyNow={() => onBuyNow(pkg.price_id)}
            savingPercentage={calculateSavingsPercentage(pkg)}
          />
        ))}
      </div>
      {selectedBundle && <PurchaseCreditsModal {...buyNowModalProps} />}
    </div>
  );
}

export default AdditionalCreditPackages;
