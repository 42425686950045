import React from 'react';
import EvaluationIcon from 'images/icons/requires-evaluation-blue.svg';
import CircleProgressBar from '../../CircleProgressBar';
import '../index.scss';
import Tooltip from '../../Tooltip/LightTooltip';

interface Props {
  scoredPercent: number;
  scoredMarks: number;
  totalMarks: number;
  label?: string;
}
export default function TotalScore({ scoredPercent, scoredMarks, totalMarks, label = 'Total Score' }: Props): any {
  const evaluation = scoredPercent === null;
  return (
    <div className="total-test-score">
      <div className="score-circle">
        <CircleProgressBar strokeWidth={3} fillColor="white" size={64} percent={evaluation ? 0 : scoredPercent}>
          {evaluation ? (
            <Tooltip title="Requires Evaluation" placement="top" arrow>
              <img src={EvaluationIcon} alt="Requires evaluation icon" />
            </Tooltip>
          ) : (
            <span className="scored-percent">{scoredPercent}%</span>
          )}
        </CircleProgressBar>
      </div>
      <div className="score-details">
        <span className="total-score-title">{label}</span>
        <br />
        <h4>
          {!evaluation ? (
            <>
              <span className="correct-answers">{scoredMarks}</span> /{totalMarks}
            </>
          ) : (
            ''
          )}
        </h4>
      </div>
    </div>
  );
}
