import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useToasts } from 'react-toast-notifications';
import { handleError } from 'handleError';
import { Modal } from '../Shared/Modal';
import { Button } from '../Shared';
import { generateKey } from '../../api/integration.api';

export interface ApiKey {
  id?: number;
  api_key?: string;
  integration?: string;
  masked?: boolean;
}

interface ApiKeyIntegrationProps {
  name: string;
  logo?: string;
  loading?: boolean;
  apiKey?: ApiKey;
  setApiKey?: any;
  children?: JSX.Element;
}

export const ApiKeyIntegrationPartner = ({
  name,
  logo,
  loading,
  apiKey,
  setApiKey,
  children,
}: ApiKeyIntegrationProps): JSX.Element => {
  const { addToast } = useToasts();
  const [mode, setMode] = useState<string>('generate');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalButtonText, setModalButtonText] = useState<string>();
  const [generating, setGenerating] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<JSX.Element>();
  const [modalCancelText, setModalCancelText] = useState<string>('Cancel');

  useEffect(() => {
    if (apiKey && apiKey.masked) {
      // If we have an API Key and it's masked the modal will be used to regenerate the key.
      setMode('regenerate');
    } else if (apiKey) {
      // If we have an unmaksed API Key then we can show the key in the modal.
      setMode('key');
    } else {
      // If we don't have any API Key then the modal will be used to generate a new one.
      setMode('generate');
    }
  }, [apiKey]);

  useEffect(() => {
    switch (mode) {
      default:
      case 'generate':
        setModalContent(
          <div className="api-key-content">
            <h4 className="mb4">{`Generate ${name} API Key`}</h4>
            <p>{`Generate an API Key to be used with ${name}.`}</p>
          </div>,
        );
        setModalButtonText('Generate API Key');
        setModalCancelText('Cancel');
        break;
      case 'regenerate':
        setModalContent(
          <div>
            <h4 className="mb4">{`API Key For ${name} Exists`}</h4>
            <p>
              {`An API Key matching `}
              <code>{apiKey.api_key}</code>
              {` has been generated for ${name}. Ensure this is configured in ${name}.`}
            </p>
          </div>,
        );
        setModalButtonText('Regenerate');
        setModalCancelText('Close');
        break;
      case 'key':
        setModalContent(
          <div>
            <h4 className="mb4">{`${name} API Key Generated`}</h4>
            <p>{`Your ${name} API Key is:`}</p>
            <pre>{apiKey.api_key}</pre>
            <p>
              {`
                Do not share this API Key with anyone other than ${name}.
                Ensure you store and communicate this API Key securely.
                You will not be able to retrieve this API Key again.
                If you lose it you will need to generate a new one.
              `}
            </p>
          </div>,
        );
        setModalButtonText(null);
        setModalCancelText('Close');
        break;
      case 'confirmation':
        setModalContent(
          <div>
            <h4 className="mb4">{`Regenerate API Key For ${name}`}</h4>
            <p>
              {`
                Are you sure you wish to regenerate the API Key for ${name}?
                Once regenerated the current API Key will be disabled, and
                the integration will not be re-enabled until the new API Key
                has been configured within ${name}.
              `}
            </p>
          </div>,
        );
        setModalButtonText('Regenerate API Key');
        setModalCancelText('Cancel');
        break;
    }
  }, [mode, apiKey, name]);

  // If they close the confirmation modal, reset the modal state
  useEffect(() => {
    if (!showModal && mode === 'confirmation') {
      setMode('regenerate');
    }
  }, [showModal, mode]);

  const handleAction = async (): Promise<void> => {
    if (mode === 'regenerate') {
      setMode('confirmation');
    } else {
      // handle generating API Key
      setGenerating(true);
      generateKey(name.toLowerCase())
        .then(response => {
          setApiKey(response.data);
          addToast({
            type: 'success',
            msg: `${name} API Key Generated`,
          });
        })
        .catch(err => {
          addToast({
            type: 'error',
            msg: err.response?.data?.errors?.detail ?? `Unable to generate the ${name} API Key`,
          });
          if (err.response.status !== 403) {
            handleError(err);
          }
        })
        .finally(() => {
          setGenerating(false);
        });
    }
  };

  return (
    <div className="integration-partner">
      {children}
      <Button
        loading={loading}
        loadingPlaceholder={loading}
        variant="sub-primary md"
        text={apiKey ? 'Connected' : 'Generate API Key'}
        onClick={() => setShowModal(true)}
      />
      {loading && <Skeleton height={32} width={160} />}
      {!loading && (logo ? <img alt={name} src={logo} /> : <h4>{name}</h4>)}
      <Modal
        loadingTxt="Generating API Key..."
        loading={generating}
        isShown={showModal}
        actionText={modalButtonText}
        handleButtonAction={handleAction}
        setModalVisibility={setShowModal}
        showCancel={!!modalCancelText}
        cancelButtonText={modalCancelText}
        cancelVariant="sub-primary"
      >
        {modalContent}
      </Modal>
    </div>
  );
};
