import React from 'react';

type IconProps = {
  size?: string | number;
  fill?: string;
};

const ChevronDown: React.FC<IconProps> = ({ size = 8, fill = '#004385' }) => {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 8 6" fill="none">
        <path d="M0.9375 0.71875L4 3.78125L7.0625 0.71875L8 1.65625L4 5.65625L0 1.65625L0.9375 0.71875Z" fill={fill} />
      </svg>
    </>
  );
};

export const ChevronDownIcon = React.memo(ChevronDown);

const ChevronUp: React.FC<IconProps> = ({ size = 8, fill = '#004385' }) => {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 8 6" fill="none">
        <path d="M0.9375 5.28125L4 2.21875L7.0625 5.28125L8 4.34375L4 0.34375L0 4.34375L0.9375 5.28125Z" fill={fill} />
      </svg>
    </>
  );
};

export const ChevronUpIcon = React.memo(ChevronUp);

const ChevronLeft: React.FC<IconProps> = ({ size = 8, fill = '#004385' }) => {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.28125 7.0625L2.21875 4L5.28125 0.9375L4.34375 0L0.34375 4L4.34375 8L5.28125 7.0625Z" fill={fill} />
      </svg>
    </>
  );
};

export const ChevronLeftIcon = React.memo(ChevronLeft);

const ChevronRight: React.FC<IconProps> = ({ size = 12, fill = '#004385' }) => {
  return (
    <>
      <svg
        width={size}
        height={Number(size) - Number(size) / 3}
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.75 0.25H11.25V1.42578H0.75V0.25ZM0.75 3.17578H11.25V4.32422H0.75V3.17578ZM0.75 6.07422H11.25V7.25H0.75V6.07422Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export const ChevronRightIcon = React.memo(ChevronRight);
