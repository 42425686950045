import React from 'react';
// import Skeleton from 'react-loading-skeleton';

interface Props {
  fields: string[];
  data?: any[];
  loading?: boolean;
  className?: string;
  message?: string;
}

const SQLOutputTable = ({ fields, data, loading, className = '', message = null }: Props): JSX.Element => {
  return (
    <div className={`sql-output-table ${className}`}>
      {!loading && !message && (
        <table>
          <thead>
            <tr className="sql-table-heading">
              {fields.map(field => (
                <th>{field}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(row => (
              <tr>
                {fields.map((field, index) => (
                  <td>{row[index]}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="sql-table-footer">
              <td colSpan={fields.length}>
                Total Rows: <b>{data.length}</b>
              </td>
            </tr>
          </tfoot>
        </table>
      )}
      {message && (
        <div className={className}>
          <p className="sql-error-message">{message}</p>
        </div>
      )}
    </div>
  );
};

export default SQLOutputTable;
