import React from 'react';
import CircleProgressBar from 'components/Shared/CircleProgressBar';
import Carousel from 'components/Shared/Carousel';
import GraphicPlaceholder from 'components/Shared/GraphicPlaceholder';
import Skeleton from 'react-loading-skeleton';
import './OverviewCarousel.scss';

const OverviewCarousel: React.FC<{ avgs: any }> = ({ avgs }): JSX.Element => {
  const render = (): JSX.Element => {
    if (avgs && avgs.length) {
      return (
        <Carousel childWidth={120} title="Average Score Per Skill">
          {avgs.map(avg => (
            <div className="overview-carousel-item">
              <CircleProgressBar strokeWidth={8} size={120} percent={avg.avg}>
                <h2>{`${avg.avg.split('.')[0]}%`}</h2>
              </CircleProgressBar>
              <p className="overview-carousel-subject">{avg.subject}</p>
            </div>
          ))}
        </Carousel>
      );
    }
    if (avgs) {
      return (
        <div className="overview-carousel-placeholder-container">
          <GraphicPlaceholder variant="pie" />
        </div>
      );
    }
    return <Skeleton height={200} />;
  };

  return render();
};

export default OverviewCarousel;
