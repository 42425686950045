import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Modal } from 'components/Shared/Modal';
import { addInterviewQuestion } from 'api/interview.api';
import { RootState } from 'store/rootReducer';
import { Input } from 'components/Shared';
import './index.scss';
import { QuestionType } from 'helpers/constants';

interface AddQuestionModalProps {
  isShown: boolean;
  setModalVisibility: (isShown: boolean) => void;
  setTopicModalVisibility: (isShown: boolean) => void;
  selectedSkill: any;
  selectedSkillIndex: any;
  setAddedQuestionId: (id: number) => void;
}

const DEFAULT_TIME_ALLOCATION = 5;
const DEFAULT_QUESTION_SCORE = 4;

const AddQuestionModal = (props: AddQuestionModalProps): JSX.Element => {
  const { setModalVisibility, setTopicModalVisibility, selectedSkill, selectedSkillIndex, setAddedQuestionId } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [questionTitle, setQuestionTitle] = useState<string>('');
  const [questionDuration, setQuestionDuration] = useState<number>(DEFAULT_TIME_ALLOCATION);
  const [questionScore, setQuestionScore] = useState<number>(DEFAULT_QUESTION_SCORE);
  const [questionError, setQuestionError] = useState<string>('');
  const [durationError, setDurationError] = useState<string>('');
  const [scoreError, setScoreError] = useState<string>('');
  const { addToast } = useToasts();
  const { questionContext, skills, additionalCriteriaSkills } = useSelector((state: RootState) => state.interview);
  const [skill, setSkill] = useState<any>(selectedSkill);

  useEffect(() => {
    if (selectedSkill) {
      setSkill(selectedSkill);
    } else if (questionContext === 'additional-criteria') {
      setSkill({
        questions_type: QuestionType.AdditionalCriteria,
        ...additionalCriteriaSkills[selectedSkillIndex],
      });
    } else {
      setSkill({
        questions_type: QuestionType.Skill,
        ...skills[selectedSkillIndex],
      });
    }
  }, [additionalCriteriaSkills, questionContext, selectedSkill, selectedSkillIndex, skills]);

  const [enableTotalMarks, enableDuration] = useMemo(
    () => [
      skill?.questions_type !== QuestionType.Informational,
      skill?.questions_type !== QuestionType.AdditionalCriteria,
    ],
    [skill],
  );

  const handleClose = useCallback((): void => {
    setQuestionTitle('');
    setQuestionDuration(DEFAULT_TIME_ALLOCATION);
    setQuestionScore(DEFAULT_QUESTION_SCORE);
    setLoading(false);
    setModalVisibility(false);
    setTopicModalVisibility(true);
  }, [setLoading, setModalVisibility, setTopicModalVisibility]);

  const handleButtonAction = useCallback((): void => {
    setQuestionError('');
    setDurationError('');
    setScoreError('');
    if (questionTitle.length > 500) {
      setQuestionError('Question length cannot be more than 500 characters');
      return;
    } else if (questionTitle.length < 4) {
      setQuestionError('Question length cannot be less than 4 characters');
      return;
    }
    if (enableTotalMarks) {
      if (questionScore > 15) {
        setScoreError('Total marks cannot be more than 15');
        return;
      } else if (questionScore < 1) {
        setScoreError('Total marks cannot be less than 1');
        return;
      } else if (!questionScore) {
        setScoreError('Total marks cannot be empty');
        return;
      }
    }
    if (enableDuration) {
      if (questionDuration > 60) {
        setDurationError('Time allocation cannot be more than 60 minutes');
        return;
      } else if (questionDuration < 1) {
        setDurationError('Time allocation cannot be less than 1 minute');
        return;
      } else if (!questionDuration) {
        setDurationError('Time allocation cannot be empty');
        return;
      }
    }

    setLoading(true);
    addInterviewQuestion(questionTitle, skill.id, questionDuration, questionScore, skill.questions_type)
      .then(res => {
        setAddedQuestionId(res.data.data.id);
        setQuestionTitle('');
        setQuestionDuration(DEFAULT_TIME_ALLOCATION);
        setQuestionScore(DEFAULT_QUESTION_SCORE);
        setModalVisibility(false);
        setTopicModalVisibility(true);
      })
      .catch(() => {
        addToast({
          msg: 'There was a problem adding a new question.',
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    addToast,
    enableDuration,
    enableTotalMarks,
    questionDuration,
    questionScore,
    questionTitle,
    setAddedQuestionId,
    setModalVisibility,
    setTopicModalVisibility,
    skill?.id,
    skill?.questions_type,
  ]);

  const onQuestionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setQuestionTitle(value);
  };

  const onTotalScoreChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = parseInt(e.target.value, 10);
    setQuestionScore(value);
  };

  const onDurationChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = parseInt(e.target.value, 10);
    setQuestionDuration(value);
  };

  const customProps = {
    loading,
    setModalVisibility,
    containerClass: 'add-question-modal-wrapper',
    actionText: 'Add Question',
    handleButtonAction,
    showCancel: true,
    cancelButtonText: 'Cancel',
    cancelVariant: 'sub-primary',
    handleClose: () => handleClose(),
  };

  return (
    <Modal {...{ ...props, ...customProps }}>
      <div className="add-question-modal">
        <h2>Add Custom Question</h2>
        <p>Add a custom question under the {skill?.name} topic.</p>
        <div>
          <Input
            label="Question*"
            autoFocus
            min={3}
            max={500}
            value={questionTitle}
            errorTxt={questionError}
            type="text"
            onChange={onQuestionChange}
          />
        </div>
        {enableTotalMarks && (
          <div>
            <Input
              label="Total Marks*"
              value={questionScore.toString()}
              onChange={onTotalScoreChange}
              errorTxt={scoreError}
              min={1}
              max={15}
              type="number"
            />
          </div>
        )}
        {enableDuration && (
          <div>
            <Input
              label="Time allocation (in minutes)*"
              value={questionDuration.toString()}
              onChange={onDurationChange}
              errorTxt={durationError}
              min={1}
              max={60}
              type="number"
            />
          </div>
        )}
        <hr />
      </div>
    </Modal>
  );
};
export default AddQuestionModal;
