import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TemplatesState {
  loading: boolean;
  templates: any[] | null;
  job_titles: any[] | null;
  levels: any[] | null;
  stages: any[] | null;
  error: string | null;
}

const initialState: TemplatesState = {
  templates: null,
  job_titles: null,
  levels: null,
  stages: null,
  loading: false,
  error: null,
};

const templatesReducer = createSlice({
  name: 'template',
  initialState,
  reducers: {
    getTemplatesStart: state => {
      if (state.templates === null) {
        state.loading = true;
      }
      state.error = null;
    },
    getTemplatesSuccess: (state, action: PayloadAction<any[]>) => {
      state.templates = action.payload;
      state.loading = false;
    },
    getTemplatesFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setTemplates: (state, action: PayloadAction<any[]>) => {
      state.templates = action.payload;
    },
    setJobTitles: (state, action: PayloadAction<any[]>) => {
      state.job_titles = action.payload;
    },
    setLevels: (state, action: PayloadAction<any[]>) => {
      state.levels = action.payload;
    },
    setStages: (state, action: PayloadAction<any[]>) => {
      state.stages = action.payload;
    },
  },
});

export const {
  getTemplatesStart,
  getTemplatesSuccess,
  getTemplatesFailure,
  setTemplates,
  setJobTitles,
  setLevels,
  setStages,
} = templatesReducer.actions;
export default templatesReducer.reducer;
