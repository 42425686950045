import { createSlice } from '@reduxjs/toolkit';

interface AppState {
  loading: boolean;
  stripePaymentDetailsShown: boolean;
}

const initialState: AppState = {
  loading: false,
  stripePaymentDetailsShown: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    stripePaymentDetailsShown(state) {
      state.stripePaymentDetailsShown = true;
    },
  },
});

export const { startLoading, stopLoading, stripePaymentDetailsShown } = appSlice.actions;
export default appSlice.reducer;
