import { handleError } from 'handleError';
import authApi from './index';

export interface AuthResult {
  access_token: string;
  refresh_token: string;
  expires_in: string;
  userId?: string;
  data?: { mfaId: string };
  message?: string;
  success?: boolean;
}

export interface SamlLoginConfiguration {
  login_url: string;
  force_sso: boolean;
}

export async function authenticate(username?: string | null, password?: string | null): Promise<AuthResult> {
  const response = await authApi.post('/token', {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    username,
    password,
  });

  return response.data;
}
export async function validateMFA(code?: string | null, mfaId?: string | null): Promise<AuthResult> {
  const response = await authApi.post('/mfa_token', {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    mfa_id: mfaId,
    code,
  });

  return response.data;
}

export async function revokeToken(): Promise<AuthResult> {
  const response = await authApi.post('/revoke', {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    grant_type: 'password',
  });
  return response.data;
}

export async function revalidateToken(refreshToken: string): Promise<AuthResult> {
  const response = await authApi.post('/token', {
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  });
  return response.data;
}

export async function authenticateSocial(driver?: string | null, code?: string | null): Promise<AuthResult> {
  const response = await authApi.get(`/auth/${driver}`, {
    params: {
      code,
      client_id: process.env.REACT_APP_CLIENT_ID,
    },
  });

  return response.data;
}

export async function getSamlConfiguration(email: string): Promise<SamlLoginConfiguration> {
  try {
    const response = await authApi.get(`/saml/config`, {
      params: {
        email,
        client_id: process.env.REACT_APP_CLIENT_ID,
      },
    });
    return response.data.data;
  } catch (err) {
    // 404 is expected if the user is not configured for SAML
    if (err.response?.status !== 404) {
      handleError(err);
    }
    return null;
  }
}
