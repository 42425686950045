import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { useErrorHandler } from 'react-error-boundary';
import { useToasts } from 'react-toast-notifications';
import Input from 'components/Shared/Input';
import { Modal } from 'components/Shared/Modal';
import { stopEvent } from 'helpers/events';
import './index.scss';
import { grantAdditionalTime } from 'api/candidate.api';
import Switcher from 'components/Shared/Switcher';
import { fullDateTime } from 'helpers/datetime';
import moment from 'moment-timezone';
import { fetchTimezones } from 'store/actions/utils.actions';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from 'components/Shared/MainContainer/FormContainer';
import MemoizedTimezoneDropDown from 'components/Shared/Dropdown/MemoizedTimezoneDropDown';
import DateTimePicker from 'components/Shared/DateTimePicker';
import { RootState } from 'store/rootReducer';
import { changeCandidateTestStatus, changeTestsCompletedStatus, setTestProperty } from 'store/reducers/candidate';

interface Assessment {
  purpose: string;
}

interface AddTimeModalProps {
  assessment: Assessment;
  candidate: any;
  userDetails: any;
  shouldCloakPII?: boolean;
  setModalVisibility: any;
  isShown: boolean;
  test_id?: number;
}

const DEFAULT_ADDITIONAL_TIME = 10;
const MINIMUM_ADDITIONAL_TIME = 3;
const MAXIMUM_ADDITIONAL_TIME = 60;

const AddTimeModal = (props: AddTimeModalProps): JSX.Element => {
  const { shouldCloakPII, candidate, setModalVisibility, test_id, assessment, userDetails } = props;
  const handleError = useErrorHandler();
  const [confirming, setConfirming] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);
  const [showExtendSection, setShowExtendSection] = useState<boolean>(false);
  const [mustValidate, setMustValidate] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [additionalTimeErrorText, setAdditionalTimeErrorText] = useState<string>('');
  const [additionalTime, setAdditionalTime] = useState<number>(DEFAULT_ADDITIONAL_TIME);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { timezones } = useSelector((state: RootState) => state.utils);

  const userTimezone = useMemo(() => {
    if (!userDetails?.user_timezone) return 'UTC';
    return userDetails.user_timezone;
  }, [userDetails]);

  useEffect(() => {
    if (candidate.expires_on < moment().format('YYYY-MM-DD HH:mm:ss')) {
      setExpired(true);
      setShowExtendSection(true);
      setMustValidate(true);
    }
  }, [candidate.expires_on]);

  const test = useMemo(() => {
    return candidate?.tests?.filter(test => test.id === test_id)[0];
  }, [candidate?.tests, test_id]);

  useEffect(() => {
    setValues((prevValues: any) => ({
      ...prevValues,
      test_id: test?.id,
    }));
  }, [test]);

  const [values, setValues] = useState<any>({
    tz_code: userTimezone,
    candidate_test_id: candidate.id,
    test_id: test?.id,
    must_extend: false,
    additional_time: DEFAULT_ADDITIONAL_TIME,
    new_expiry_date: '',
    no_send_expiry_email: 'true',
  });

  useEffect(() => {
    setValues((prevValues: any) => ({
      ...prevValues,
      must_extend: showExtendSection,
    }));
  }, [showExtendSection]);

  const handleAdditionalTimeChange = (event): void => {
    const { value } = event.target;
    if (!value) {
      setAdditionalTimeErrorText('Please provide a valid number of minutes');
    }
    if (value < MINIMUM_ADDITIONAL_TIME) {
      setAdditionalTimeErrorText(`The minimum additional time is ${MINIMUM_ADDITIONAL_TIME} minutes`);
    }
    if (value > MAXIMUM_ADDITIONAL_TIME) {
      setAdditionalTimeErrorText(`The maximum additional time is ${MAXIMUM_ADDITIONAL_TIME} minutes`);
    }
    if (value >= MINIMUM_ADDITIONAL_TIME && value <= MAXIMUM_ADDITIONAL_TIME) {
      setAdditionalTimeErrorText('');
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setAdditionalTime(value);
  };

  useEffect(() => {
    setValues(prevValues => ({
      ...prevValues,
      additional_time: additionalTime,
    }));
  }, [additionalTime]);

  const candidateIdentifier = useMemo(() => {
    if (shouldCloakPII) {
      return candidate.reference;
    }
    return candidate.first_name;
  }, [candidate.first_name, candidate.reference, shouldCloakPII]);

  const testIdentifier = useMemo(() => {
    if (assessment.purpose === 'ld') {
      return 'exercise';
    }
    return 'test';
  }, [assessment]);

  const handleButtonAction = async (e: React.SyntheticEvent<EventTarget>): Promise<any> => {
    stopEvent(e);
    if (!validate()) {
      return;
    }
    if (confirming) {
      return;
    }
    setConfirming(true);
    grantAdditionalTime(formik.values)
      .then(() => {
        addToast({
          type: 'success',
          msg: 'Additional time granted',
        });
        dispatch(changeCandidateTestStatus({ status: 'Incomplete' }));
        dispatch(
          changeTestsCompletedStatus({
            testIds: [values.test_id],
            is_submitted: false,
          }),
        );
        dispatch(
          setTestProperty({
            testId: values.test_id,
            propertyName: 'status',
            value: 'Not Started',
          }),
        );
      })
      .catch(error => {
        addToast({
          type: 'error',
          msg: 'Unable to grant additional time',
        });
        handleError(error);
      })
      .finally(() => {
        setConfirming(false);
        handleClose();
      });
  };

  const handleClose = (): void => {
    setModalVisibility(false);
    setAdditionalTime(DEFAULT_ADDITIONAL_TIME);
    setConfirming(false);
  };

  const handleTimezoneChange = (option): void => {
    const changeValues = {
      tz_code: option.code,
      new_expiry_date: '',
    };
    setValues({
      ...values,
      ...changeValues,
    });
  };
  const handleDateTimeChange = (value: string): void => {
    setValues({
      ...values,
      ...{
        new_expiry_date: value,
      },
    });
    setMustValidate(true);
  };
  const handleToggleExtend = (): void => {
    setValues({
      ...values,
      ...{
        must_extend: !showExtendSection,
      },
    });
    setShowExtendSection(!showExtendSection);
    setMustValidate(true);
  };

  const expiryDate = moment(candidate.expires_on);

  const validate = useCallback((): boolean => {
    if (!showExtendSection) {
      return true;
    }
    if (values.new_expiry_date === '') {
      setErrorText('You must provide a new expiry date.');
      return false;
    }
    const nowFormatted = moment().utc().format('YYYY-MM-DD HH:mm:ss');
    const now = moment(nowFormatted);
    const expiryDateUtc = moment(values.new_expiry_date).tz(values.tz_code).utc().format('YYYY-MM-DD HH:mm:ss');
    const newExpiry = moment(expiryDateUtc);
    if (newExpiry.diff(expiryDate, 'minutes') <= 1441) {
      setErrorText('New expiry date must be at least 24 hours after current expiry date.');
      return false;
    }
    if (newExpiry.diff(now, 'minutes') <= 1441) {
      setErrorText('New expiry date must be at least 24 hours after current expiry date.');
      return false;
    }
    setErrorText('');
    return true;
  }, [values.new_expiry_date, values.tz_code, showExtendSection, expiryDate]);

  useEffect(() => {
    if (mustValidate) {
      validate();
      setMustValidate(false);
    }
  }, [mustValidate, validate]);

  useEffect(() => {
    dispatch(fetchTimezones());
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    onSubmit: (): void => {
      validate();
    },
  });

  const customInput = (
    <Input
      type="text"
      label="New Expiry Date"
      autoComplete="off"
      placeholder="Select a new expiry"
      errorTxt={errorText}
      onChange={e => handleDateTimeChange(e.target.value)}
    />
  );

  const DateTimePickerProps = {
    customInput,
    utc: values.new_expiry_date,
    handleChange: handleDateTimeChange,
    props: {
      minDate: moment(fullDateTime(candidate.expires_on, userTimezone)).toDate(),
    },
  };

  const customProps = {
    loading: confirming,
    actionText: 'Grant Time',
    showCancel: true,
    cancelVariant: 'sub-primary',
    handleButtonAction,
    handleClose,
    containerClass: 'participants-modal retake-assessment-modal',
    isSubmit: true,
  };

  return (
    <Modal {...{ ...props, ...customProps, disabled }}>
      <>
        <h2>Grant Additional Time</h2>
        <br />
        <p>
          You are about to grant extra time for the {test?.test_type} {testIdentifier} for {candidateIdentifier}
          {candidate.name}. This will allow them to resume their previous {testIdentifier} and potentially refine their
          existing answers. Please specify how many additional minutes you&apos;d like to grant them.
        </p>
        <Input
          type="number"
          label={`Minutes to Grant (${MINIMUM_ADDITIONAL_TIME}-${MAXIMUM_ADDITIONAL_TIME})`}
          min={3}
          max={60}
          value={`${additionalTime}`}
          onChange={handleAdditionalTimeChange}
          errorTxt={additionalTimeErrorText}
        />
        <br />
        {!expired ? (
          <>
            <b>The current expiry date is:</b>{' '}
            {`${fullDateTime(candidate.expires_on, userTimezone, 'lll [(GMT]Z[)]')} ${userTimezone}`}
            <div className="extend-switcher">
              <p>Would you like to extend it?</p>
              <Switcher checked={showExtendSection} onChange={handleToggleExtend} />
            </div>
          </>
        ) : (
          <>
            <b>The invitation expired on:</b>{' '}
            {`${fullDateTime(candidate.expires_on, userTimezone, 'lll [(GMT]Z[)]')} ${userTimezone}`}
            <div className="extend-switcher">
              <p>You must extend the invitation.</p>
            </div>
          </>
        )}
        {showExtendSection && userDetails && userDetails.user_timezone && (
          <div className="extend-expiry">
            <FormContainer>
              <div className="container">
                <div className="row">
                  <MemoizedTimezoneDropDown
                    label="Timezone"
                    placeholder="Search Timezones..."
                    value={formik.values.tz_code}
                    handleChange={handleTimezoneChange}
                    menuPlacement="top"
                    defaultTimezones={timezones}
                  />
                  <DateTimePicker {...DateTimePickerProps} />
                </div>
              </div>
            </FormContainer>
          </div>
        )}
        <hr />
        <p>
          <b>Note:</b> Granting additional time will alter the status of the{' '}
          {assessment.purpose === 'ld' ? 'exercise' : 'test'}. Ensure that this action aligns with your{' '}
          {assessment.purpose === 'ld' ? 'exercise' : 'assessment'} strategy.
        </p>
      </>
    </Modal>
  );
};
export default AddTimeModal;
