import React, { useState } from 'react';
import { Button } from 'components/Shared';
import { useToasts } from 'react-toast-notifications';
import SubHeader from '../Shared/SubHeader';
import './index.scss';

interface Props {
  type?: string;
  header?: string;
  description: string;
  image: string;
  action: (assessmentId: number) => Promise<any>;
}

const CreateTestIntro: React.FC<Props> = ({ type, header, description, image, action }) => {
  const { addToast } = useToasts();
  const [creating, setCreating] = useState<boolean>(false);
  if (!type) {
    type = 'Test';
  }

  const assessmentId = parseInt(new URLSearchParams(window.location.search).get('assessment'), 10);

  const handleCreateClick = async (): Promise<any> => {
    setCreating(true);
    try {
      await action(assessmentId);
      setCreating(false);
      addToast({
        type: 'success',
        msg: `${type} created.`,
      });
    } catch (error) {
      setCreating(false);
      addToast({
        type: 'error',
        msg: error.response?.data?.errors ? error.response?.data?.errors.detail : `Unable to create ${type}.`,
      });
    }
  };

  return (
    <div>
      <SubHeader>
        <div className="page-title">Create {type}</div>
      </SubHeader>
      <div className="full-width-container">
        {header && <h3 className="mb4">{header}</h3>}

        <div className="main-container test-intro">
          <div className="test-description">{description}</div>
          <div className="test-hero-image">
            <img src={image} alt={type} />
          </div>
        </div>

        <div className="button-bar">
          <Button
            addButton
            disabled={creating}
            onClick={handleCreateClick}
            variant="primary md right"
            text={`Create ${type}`}
          />
          <div className="clear" />
        </div>
      </div>
    </div>
  );
};

export default CreateTestIntro;
