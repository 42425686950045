import React from 'react';

import AloobaTooltip from 'components/Shared/Tooltip/AloobaTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import './index.scss';

interface Props {
  placement?: 'top' | 'bottom' | 'left' | 'right';
  size?: SizeProp;
  showIcon?: boolean;
  participantType?: 'employee' | 'candidate or employee' | 'candidate' | 'participant';
}

const ErasedCandidateInfo: React.FC<Props> = ({ placement, size, showIcon = true, participantType = 'candidate' }) => {
  return (
    <AloobaTooltip
      arrow
      title={`This ${participantType} has requested their data to be erased. You can no longer view their personal details.`}
      placement={placement}
    >
      <div className="erased-candidate-info">
        {showIcon && <FontAwesomeIcon className="icon" icon={faEraser} size={size} />}
        Erased
      </div>
    </AloobaTooltip>
  );
};

export default ErasedCandidateInfo;
