import { GetAssessmentCandidatesQueryParams } from 'api/contract';
import { formatCandidateComparisonData } from 'helpers/compareCandidates';
import React, { ReactElement, useEffect, useState } from 'react';
import './ComparisonResult.scss';
import CircleProgressBar from 'components/Shared/CircleProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { Link } from 'react-router-dom';
import { getCandidateDetails } from 'store/actions/candidate.actions';
import moment from 'moment';
import LightTooltip from 'components/Shared/Tooltip/LightTooltip';
import ClockIcon from '../../images/warning.svg';

const TESTS = [
  'MCQ',
  'Data Analysis',
  'SQL',
  'Free Response',
  'Analytics Coding',
  'Personality Profiling',
  'Interview',
];

const PERCENTAGE_RESULTS = ['overall_scores', 'subject_scores', 'test_scores'];

type ComparisonBasis = 'overall_scores' | 'subject_scores' | 'test_scores' | 'information_fields';
interface ComparisonResultProps {
  params: GetAssessmentCandidatesQueryParams;
  firstCandidate: any;
  secondCandidate: any;
  cloaked: boolean;
}

const comparisonTitles = {
  overall_scores: 'Overall Scores',
  subject_scores: 'Skill Scores',
  test_scores: 'Test Scores',
  candidate_information: 'Candidate Information',
};

const ComparisonResult: React.FC<ComparisonResultProps> = ({ params, firstCandidate, secondCandidate, cloaked }) => {
  const [result, setResult] = useState(null);
  const currentAssessment = useSelector((state: RootState) => state.assessment?.currentAssessment);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstCandidate?.Reference && secondCandidate?.Reference && params) {
      const firstCandidateTests = firstCandidate.test_scores.map(ts => ts.test_type).filter(key => TESTS.includes(key));
      const secondCandidateTests = secondCandidate.test_scores
        .map(ts => ts.test_type)
        .filter(key => TESTS.includes(key));
      let totalTests = firstCandidateTests.concat(secondCandidateTests);
      totalTests = totalTests.filter((item, pos) => totalTests.indexOf(item) === pos);

      setResult(formatCandidateComparisonData(firstCandidate, secondCandidate, params, totalTests));
    }
  }, [firstCandidate, secondCandidate, params]);

  const getCandidateScore = (scores, comparison: ComparisonBasis, reference: string): any => {
    const score = scores.find(score => score.reference === reference);
    if (score && score.value !== null && PERCENTAGE_RESULTS.includes(comparison)) {
      return score.value;
    } else if (score && score.value) {
      return score.value;
    }
    return 'N/A';
  };

  const candidateHasHigherScore = (scores, comparison: ComparisonBasis, reference: string): boolean => {
    const selectedCandidateScore = scores.find(score => score.reference === reference);
    const otherCandidateScore = scores.find(score => score.reference !== reference);
    if (selectedCandidateScore && otherCandidateScore) {
      if (PERCENTAGE_RESULTS.includes(comparison)) {
        return parseFloat(selectedCandidateScore.value) > parseFloat(otherCandidateScore.value);
      }
    }
    return false;
  };

  const getAssessmentChangedAlert = (candidate): ReactElement => {
    if (candidate['Submitted On'] && moment(candidate['Submitted On']) < moment(candidate['Parts Updated At'])) {
      let message = '';
      if (currentAssessment?.purpose === 'ld') {
        message = 'The exercise configuration was changed after this employee took the exercise';
      } else {
        message = 'The assessment configuration was changed after this candidate took the assessment';
      }
      return (
        <span style={{ marginLeft: '6px' }}>
          <LightTooltip title={message} placement="top" arrow>
            <img src={ClockIcon} alt="Updated" />
          </LightTooltip>
        </span>
      );
    }
    return <></>;
  };

  return (
    <div>
      {result && (
        <div className="comparison-result">
          <div className="table-row table-head">
            <div className="row-item"> </div>
            <div className="row-item candidate-name">
              <Link
                to={`/candidate-details/${firstCandidate.Reference}/overview`}
                onClick={() => dispatch(getCandidateDetails(firstCandidate.Reference))}
              >
                {cloaked ? firstCandidate.Reference : firstCandidate.Name}
              </Link>
              {getAssessmentChangedAlert(firstCandidate)}
            </div>
            <div className="row-item candidate-name">
              <Link
                to={`/candidate-details/${secondCandidate.Reference}/overview`}
                onClick={() => dispatch(getCandidateDetails(secondCandidate.Reference))}
              >
                {cloaked ? secondCandidate.Reference : secondCandidate.Name}
              </Link>
              {getAssessmentChangedAlert(secondCandidate)}
            </div>
          </div>
          {Object.keys(result).map(
            (comparison: ComparisonBasis) =>
              Object.keys(result[comparison]).length > 0 && (
                <div>
                  <h3 className="comparison-section-title">{comparisonTitles[comparison]}</h3>
                  {Object.keys(result[comparison]).map((key: any) => (
                    <div className="table-row">
                      <div className="row-item comparison-basis">
                        {comparison === 'test_scores' && key === 'MCQ' ? 'Concepts & Knowledge' : key}
                      </div>
                      <div className="row-item score">
                        {PERCENTAGE_RESULTS.includes(comparison) &&
                        getCandidateScore(result[comparison][key], comparison, firstCandidate?.Reference) !== 'N/A' ? (
                          <CircleProgressBar
                            strokeWidth={6}
                            fillColor={
                              candidateHasHigherScore(result[comparison][key], comparison, firstCandidate?.Reference)
                                ? '#99dd99'
                                : 'none'
                            }
                            size={70}
                            percent={getCandidateScore(result[comparison][key], comparison, firstCandidate?.Reference)}
                          >
                            <div>
                              {`${Math.round(
                                getCandidateScore(result[comparison][key], comparison, firstCandidate?.Reference),
                              )}%`}
                            </div>
                          </CircleProgressBar>
                        ) : (
                          getCandidateScore(result[comparison][key], comparison, firstCandidate?.Reference)
                        )}{' '}
                      </div>
                      <div className="row-item score">
                        {PERCENTAGE_RESULTS.includes(comparison) &&
                        getCandidateScore(result[comparison][key], comparison, secondCandidate?.Reference) !== 'N/A' ? (
                          <CircleProgressBar
                            strokeWidth={6}
                            fillColor={
                              candidateHasHigherScore(result[comparison][key], comparison, secondCandidate?.Reference)
                                ? '#99dd99'
                                : 'none'
                            }
                            size={70}
                            percent={getCandidateScore(result[comparison][key], comparison, secondCandidate?.Reference)}
                          >
                            <div>
                              {`${Math.round(
                                getCandidateScore(result[comparison][key], comparison, secondCandidate?.Reference),
                              )}%`}
                            </div>
                          </CircleProgressBar>
                        ) : (
                          getCandidateScore(result[comparison][key], comparison, secondCandidate?.Reference)
                        )}{' '}
                      </div>
                    </div>
                  ))}
                </div>
              ),
          )}
        </div>
      )}
      {!secondCandidate && (
        <div className="select-candidates">
          Select another {currentAssessment?.purpose === 'ld' ? 'employee' : 'candidate'} to compare
        </div>
      )}
    </div>
  );
};

export default ComparisonResult;
