import React, { useState, useEffect, useCallback, useMemo, ReactElement } from 'react';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../../styles/spacing.styles.scss';
import './index.scss';
import CompletedBlank from 'components/CandidateResultsList/CompletedBlank';
import DateFilter from 'components/Shared/Filters/DateFilter';
import ErasedCandidateInfo from 'components/Shared/Erased/ErasedCandidateInfo';
import MultiSelectFilter from '../Shared/Filters/MultiSelectFilter';
import { DynamicTablePropsContext } from '../Shared/DynamicTable/context/DynamicTablePropsContext';
import TextWithTooltip from '../Shared/Tooltip/TextWithTooltip';
import { searchCandidates, getSubjects } from '../../api/assessment.api';
import { debounce } from '../../helpers/events';
import { selectCandidateRow } from '../../helpers/store';
import { emptyJwt } from '../../helpers';
import {} from '../../helpers/text';
import { relativeDate, fullDateTime } from '../../helpers/datetime';
import { LoaderContext } from '../../context/loader';
import { RefreshContext } from '../Participants/context/refresh';
import useDynamicTable from '../../hooks/useDynamicTable';
import CompletedTable from '../CandidateResultsList/CompletedTable';
import TableBlank from '../Participants/TableBlank';
import { TableField } from '../CandidateResultsList/completedTableFields';
import { RootState } from '../../store/rootReducer';
import ActionMenuContainer from '../Participants/ActionMenuContainer';
import styles from '../Assessment/Assessment.module.scss';

import {
  updateSearchCandidatesQueryParams,
  setSearchCandidatesPaginator,
  setSearchCandidatesCurrentPage,
  updateSelectedProducts,
} from '../../store/reducers/assessmentCandidate';
import { getOrgDetails } from '../../store/actions/organization.actions';

const getDataCallback = {
  callback: (data: any): any => {
    return data;
  },
};

const fields: TableField[] = [
  {
    label: 'Name',
    position: 4,
  },
  {
    label: 'Email',
    position: 5,
  },
];
const piiFields = ['Name', 'Email'];

const totalScoreField: TableField = {
  label: 'AVG Total Score',
  position: 100,
  type: 'percentage',
  sticky: 'right',
  scroll: 'start',
};

type ProductFilterType = { label: string; value: string };
const productFilter: ProductFilterType[] = [
  {
    label: 'Alooba Assess',
    value: 'hiring',
  },
  {
    label: 'Alooba Junior',
    value: 'junior',
  },
  {
    label: 'Alooba Growth',
    value: 'ld',
  },
];
const purposeMap = {
  hiring: 'Alooba Assess',
  junior: 'Alooba Junior',
  ld: 'Alooba Growth',
};

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const CandidateSearch = (): JSX.Element => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { organizationDetails: organization } = useSelector((state: RootState) => state.organization);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [tableFields] = useState<TableField[]>(fields);
  const [subjects, setSubjects] = useState<any[]>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [dateFilter, setDateFilter] = useState<any>({});
  const [selectedSubjects, setSelectedSubjects] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [, setSearch] = useState<string>('');
  const [noDataMessage, setNoDataMessage] = useState<string>('Please select subjects to filter by');
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const [showRestrictedWarning, setShowRestrictedWarning] = useState<boolean>(false);
  const [is_cloaked, setIsCloaked] = useState<boolean>(false);

  const organizationCloakedCandidates = useSelector(
    (state: RootState) => state.organization.organizationDetails?.cloak_candidates,
  );
  const recruiterCloakedCandidates = useSelector(
    (state: RootState) => state.profile.userDetails.recruiter_detail?.cloak_candidates,
  );

  useEffect(() => {
    if (organizationCloakedCandidates || recruiterCloakedCandidates) {
      setIsCloaked(true);
    }
  }, [organizationCloakedCandidates, recruiterCloakedCandidates]);

  const purpose = query.get('purpose');
  const {
    searchCandidatesPaginator: paginator,
    searchCandidatesQueryParams: queryParams,
    searchCandidatesCurrentPage: currentPage,
  }: any = useSelector((state: RootState) => state.assessmentCandidate);
  useEffect(() => {
    const decoded: { pl: any[] } = jwt_decode(accessToken || emptyJwt);
    if (decoded.pl.includes('Restricted')) {
      setShowRestrictedWarning(true);
    }
  }, [accessToken]);
  const { isLast, nextPage, changePerPage, filterSearch, sortingAction, tablePaginatorProps } = useDynamicTable(
    getDataCallback,
    paginator,
    currentPage,
    setSearch,
  );

  const shouldCloakPII = useMemo(() => {
    return userDetails?.recruiter_detail?.cloak_candidates || organization.cloak_candidates;
  }, [userDetails, organization]);

  useEffect(() => {
    const orgId = userDetails?.recruiter_detail?.organisation_id;
    if (orgId && shouldCloakPII === null) {
      dispatch(getOrgDetails(orgId));
    }
  }, [organization, userDetails, dispatch, shouldCloakPII]);

  const candidateAssessmentFields: TableField[] = !selectedSubjects.length
    ? [
        {
          label: 'Reference',
          position: 1,
        },
        {
          label: 'Assessment',
          nonSortable: true,
          position: 2,
        },
        {
          label: 'Invited On',
          type: 'datetime',
          position: 3,
        },
      ]
    : [];
  const [completedTableProps, setCompletedTableProps] = useState<any>({
    loading: isTableLoading,
    fields: tableFields.concat([totalScoreField]),
    data: [],
    paginator,
    filters: [],
    configurableRows: true,
    noDataMessage,
  });
  const [blank, setBlank] = useState<boolean>(true);

  const defaultQueryParams = useMemo(() => {
    const filteredSubjects = subjects.filter(subject => selectedSubjects.includes(subject.subject));
    const filteredProducts = productFilter.filter(product => selectedProducts.includes(product.label));
    return {
      subjects: filteredSubjects.map(subject => subject.id),
      products: filteredProducts.map(product => product.value),
      fromDate: dateFilter['date[from]'],
      toDate: dateFilter['date[to]'],
      fields: tableFields.map(field => field.label).concat(filteredSubjects.map(subject => subject.subject)),
      perPage,
    };
  }, [subjects, tableFields, perPage, selectedProducts, selectedSubjects, dateFilter]);

  useEffect(() => {
    getSubjects().then(response => {
      setSubjects(response.data.data);
    });
  }, []);

  useEffect(() => {
    getDataCallback.callback({ ...defaultQueryParams });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubjects, selectedProducts, dateFilter]);

  useEffect(() => {
    if (selectedSubjects.length || selectedProducts.length) {
      setNoDataMessage('No results matching your filters');
    } else {
      setNoDataMessage(`No matching ${candidateOrEmployee.toLowerCase()}s found`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubjects]);

  const getData = async (data): Promise<any> => {
    setIsError(false);
    setTableLoading(true);
    let state = defaultQueryParams;
    if (!data.reset) {
      state = {
        ...queryParams,
        ...data,
      };
    }
    dispatch(updateSearchCandidatesQueryParams(state));
    const res = await searchCandidates(state);
    if (!res) {
      setIsError(true);
    } else {
      dispatch(setSearchCandidatesPaginator(res.data.data));
      setBlank(firstLoad ? res.data.data.total === 0 : false);
      setPerPage(res.data.data.per_page);
      if (data.page) {
        dispatch(setSearchCandidatesCurrentPage(data.page));
      }
      setFirstLoad(false);
    }
    setTableLoading(false);
    setLoaded(true);
  };
  getDataCallback.callback = useCallback(getData, [defaultQueryParams, firstLoad, queryParams, dispatch]);

  const candidateOrEmployee = useMemo(() => {
    if (selectedProducts.includes('Alooba Growth')) {
      if (selectedProducts.length === 1) {
        return 'Employee';
      }
      return 'Candidates or Employee';
    }
    return 'Candidate';
  }, [selectedProducts]);

  const processCell = useCallback(
    (row: any, field: any): any => {
      if (!row[field.label]) {
        if (field.type === 'percentage') {
          if (field.label === 'Total Score') {
            return <div className="total-score bold">N/A</div>;
          }
          return <div className="skill-score text-center">N/A</div>;
        } else if (field.label === 'Assessment') {
          return <div className="reference bold">{row.recruiter_test_name}</div>;
        }
        return 'N/A';
      }
      const value = row[field.label];
      const isErased = row.data_erasure_request_id !== null && row.data_erasure_request_id !== undefined;
      if (isErased && field.label === 'Name') {
        let participantType: 'employee' | 'candidate or employee' | 'candidate' = 'candidate';
        if (candidateOrEmployee === 'Employee') {
          participantType = 'employee';
        } else if (candidateOrEmployee === 'Candidates or Employee') {
          participantType = 'candidate or employee';
        }
        return <ErasedCandidateInfo placement="top" participantType={participantType} />;
      }
      if (piiFields.includes(field.label) && row['Cloak Candidates']) {
        return <div className="">-</div>;
      }
      if (field.label === 'Name') {
        return <div className="name bold">{value}</div>;
      } else if (field.label === 'Reference') {
        return <div className="reference bold">{value}</div>;
      }

      if (field.type === 'datetime') {
        return (
          <div className="text-muted">
            <TextWithTooltip
              text={relativeDate(value, userDetails.user_timezone)}
              tooltip={fullDateTime(value, userDetails.user_timezone, 'll [at] LT [(GMT]Z[)]')}
              placement="top"
            />
          </div>
        );
      }
      if (field.type === 'percentage') {
        if (field.label === 'Total Score') {
          return <div className="total-score bold last-col">{`${value}%`}</div>;
        }
        return <div className="text-center skill-score">{`${value}%`}</div>;
      }
      return value;
    },
    [userDetails.user_timezone, candidateOrEmployee],
  );

  const preSelectedProducts = useMemo(() => {
    if (purpose) {
      setSelectedProducts([purposeMap[purpose]]);
    } else {
      setSelectedProducts([]);
    }
    if (purposeMap[purpose]) {
      return [purposeMap[purpose]];
    }
    return [];
  }, [purpose]);
  const assessmentOrExercise = useMemo(() => {
    if (selectedProducts.includes('Alooba Growth')) {
      if (selectedProducts.length === 1) {
        return 'exercise';
      }
      return 'assessments or exercise';
    }
    return 'assessment';
  }, [selectedProducts]);

  const getPath = useCallback((row): string => {
    return `/candidate-details/${row.Reference}/overview`;
  }, []);

  const actionMenu = useCallback(
    (row: any): ReactElement => {
      return (
        <ActionMenuContainer
          {...{
            row,
            userDetails,
            assessment: row,
            showTestDuration: false,
            shouldCloakPII: is_cloaked || row['Cloak Candidates'],
          }}
        />
      );
    },
    [is_cloaked, userDetails],
  );

  const retry = (): void => {
    getDataCallback.callback({});
  };

  useEffect(() => {
    dispatch(updateSelectedProducts({ selectedProducts }));
  }, [selectedProducts, dispatch]);

  useEffect(() => {
    if (!loaded || blank) {
      return;
    }
    const allTableFields = candidateAssessmentFields.concat(tableFields).concat(
      subjects
        .filter(subject => selectedSubjects.includes(subject.subject))
        .map(subject => ({
          label: `AVG ${subject.subject} Score`,
          type: 'percentage',
          nonSortable: true,
        })),
    );
    const extraProps = {
      getPath: undefined,
      actionMenu: undefined,
      onClickRow: undefined,
    };
    if (selectedSubjects.length) {
      allTableFields.push(totalScoreField);
    } else {
      allTableFields.push({
        label: 'Status',
        sticky: 'right',
        scroll: 'start',
        position: 100,
      });
      extraProps.getPath = getPath;
      extraProps.actionMenu = actionMenu;
      extraProps.onClickRow = (row: any): void => {
        selectCandidateRow(row);
      };
    }

    const tableSearchProps = {
      filterSearch,
      searchTerm: queryParams.searchTerm,
    };
    const sortingProps = {
      sortingAction,
      orderBy: queryParams.orderBy,
      orderDirection: queryParams.orderDirection,
    };
    setCompletedTableProps({
      loading: false,
      data: paginator.data,
      fields: allTableFields.filter(field => {
        if (shouldCloakPII && !selectedSubjects.length) {
          return !piiFields.includes(field.label);
        }
        return true;
      }),
      fromDate: dateFilter['date[from]'],
      toDate: dateFilter['date[to]'],
      paginator,
      tableSearchProps,
      sortingProps,
      actionMenu,
      filters: [],
      processCell,
      configurableRows: true,
      tablePaginatorProps,
      noDataMessage,
      ...extraProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loaded,
    currentPage,
    paginator,
    queryParams,
    blank,
    userDetails.user_timezone,
    userDetails,
    nextPage,
    changePerPage,
    isLast,
    isTableLoading,
    processCell,
    tableFields,
    selectedSubjects,
    subjects,
    dateFilter,
    shouldCloakPII,
  ]);

  return (
    <div className={`search-candidates ${!selectedSubjects.length ? 'clickable' : ''}`}>
      <RefreshContext.Provider value={[firstLoad, setFirstLoad]}>
        <h2>Search {candidateOrEmployee}s</h2>
        <p className="assessment-description mt2">
          Search existing {candidateOrEmployee.toLocaleLowerCase()}s across all your {assessmentOrExercise}s to fill a
          position. This could save you time by checking for existing candidates with the required skills before
          creating an assessment for the position.
        </p>
        {showRestrictedWarning && (
          <div className={`${styles.warningBar} mt4`}>
            <div className={styles.textContent}>
              <p>
                Your account only has access to the results of the candidates you added. Results of candidates you
                didn&apos;t add will not show here.
              </p>
            </div>
          </div>
        )}
        <div className="filters-container">
          <div className="subject-filter">
            <MultiSelectFilter
              label="Product Filter"
              options={productFilter.map(product => product.label)}
              defaultValues={preSelectedProducts}
              onChange={debounce(selected => {
                setSelectedProducts(selected.products);
              }, 500)}
              key={purpose}
              canHide={false}
              hide={false}
              name="products"
              visible
            />
          </div>
          <div className="subject-filter">
            <MultiSelectFilter
              label="Skills Filter"
              description="Filter by average score across assessments that have all of the selected skills."
              options={subjects.map(subject => subject.subject)}
              onChange={debounce(selected => {
                if (selected.subjects.length !== subjects.length) {
                  setSelectedSubjects(selected.subjects);
                } else {
                  setSelectedSubjects([]);
                }
              }, 500)}
              canHide={false}
              hide={false}
              name="subjects"
              visible
            />
          </div>
          <div className="date-filter">
            <DateFilter
              label="Date Filter"
              name="date"
              description="Filter by invitation date."
              onChange={debounce(value => {
                setDateFilter(value);
              }, 500)}
              canHide={false}
              hide={false}
              visible
            />
          </div>
        </div>
        <LoaderContext.Provider value={[isTableLoading, setTableLoading]}>
          {isError && <TableBlank message={`Unable to fetch ${candidateOrEmployee.toLowerCase()}s.`} onRetry={retry} />}

          {!isError &&
            (isTableLoading ? (
              <CompletedBlank {...{ loading: true }} />
            ) : (
              <DynamicTablePropsContext.Provider value={[completedTableProps, setCompletedTableProps]}>
                <CompletedTable {...{ loading: false }} />
              </DynamicTablePropsContext.Provider>
            ))}
        </LoaderContext.Provider>
      </RefreshContext.Provider>
    </div>
  );
};

export default CandidateSearch;
