import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import './index.scss';

interface DateTimePickerProps {
  id?: string;
  customInput?: any;
  handleChange?: any;
  utc?: string;
  props?: any;
}

const DateTimePicker = ({ customInput, handleChange, utc, props }: DateTimePickerProps): JSX.Element => {
  const [currentUtc, setCurrentUtc] = useState<string>(utc);
  useEffect(() => {
    if (!utc || utc === '') {
      setCurrentUtc('');
    }
  }, [setCurrentUtc, utc]);
  const handleChangeDatePicker = (date: any): void => {
    const value = moment(date).format('YYYY/MM/DD HH:mm:ss');
    setCurrentUtc(value);
    if (handleChange) {
      handleChange(value);
    }
  };
  return (
    <div className="datetime-picker-wrapper">
      <DatePicker
        selected={currentUtc && currentUtc !== '' && new Date(currentUtc)}
        onChange={handleChangeDatePicker}
        onSelect={handleChangeDatePicker}
        showTimeSelect
        fixedHeight
        timeIntervals={5}
        customInput={customInput}
        dateFormat="MMMM d, yyyy h:mm aa"
        className="datetime-picker-input"
        onChangeRaw={e => {
          e.preventDefault();
        }}
        placeholderText={customInput?.props.placeholder ?? 'Select a new expiry'}
        {...props}
      />
      <input type="hidden" value={currentUtc ?? ''} />
    </div>
  );
};
export default DateTimePicker;
