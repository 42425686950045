import { handleError } from 'handleError';
import store, { AppThunk } from '../store';
import { getTimezones } from '../../api/profile-api';
import { setTimezones, setError } from '../reducers/utils';

export const fetchTimezones = (): AppThunk => {
  return async dispatch => {
    let { timezones } = store.getState().utils;

    if (!timezones) {
      const cachedTimezones = localStorage.getItem('@alooba/timezones');
      timezones = cachedTimezones ? JSON.parse(cachedTimezones) : null;
      dispatch(setTimezones(timezones));
    }

    if (timezones) {
      return;
    }

    getTimezones()
      .then(res => {
        const formattedTimezones =
          res &&
          res.data &&
          res.data.data.map(zone => {
            return {
              label: `(GMT${zone.offset}) ${zone.code}`,
              code: zone.code,
              offset: zone.offset,
            };
          });
        localStorage.setItem('@alooba/timezones', JSON.stringify(formattedTimezones));
        dispatch(setTimezones(formattedTimezones));
      })
      .catch(err => {
        handleError(Object.assign(err, { stack: new Error().stack }));
        dispatch(setError(err.message));
      });
  };
};
