import './index.scss';
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import MainLogo from 'images/logo-cloud-only.svg';
import { Button } from '../Shared';
import CircularRadioInput from '../Shared/Input/circularRadio';
import CardContainer from '../Shared/MainContainer/CardContainer';
import TextArea from '../Shared/Input/textArea';
import NotFound from '../NotFound';
import { submitNpsFeedback, checkNpsRequest } from '../../api/assessment.api';
import ThankYou from './ThankYou';

export type NPS_STATUSES = 'Requested' | 'Declined' | 'Agreed' | 'Forwarded' | 'Completed';

const NpsLanding = (): JSX.Element => {
  const [rating, setRating] = useState(null);
  const [feedbackText, setFeedbackText] = useState<string>(null);
  const [channel, setChannel] = useState<string>(null);
  const [done, setDone] = useState(false);
  const [codeFound, setCodeFound] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const inputRef = useRef(null);
  const [positiveFeedback, setPositiveFeedback] = useState(false);

  const { code } = useParams<{ code?: string }>();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('rating')) {
      setRating(parseInt(params.get('rating'), 10));
    }
    setChannel(params.get('channel'));
  }, [location]);

  useEffect(() => {
    if (rating !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, rating]);

  useEffect(() => {
    if (code === 'thank-you') {
      setDone(true);
      return;
    }
    setLoading(true);
    checkNpsRequest(code)
      .then(response => {
        const data = response?.data?.data;
        const feedback = data?.feedback?.feedback ? JSON.parse(data?.feedback?.feedback) : null;
        setPositiveFeedback(
          feedback &&
            ['agree', 's_agree'].includes(feedback.positive_experience) &&
            ['agree', 's_agree'].includes(feedback.software_worked_well),
        );
      })
      .catch(_e => {
        setCodeFound(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [code]);

  const canSubmit = (code, channel, rating): boolean => {
    return Number.isInteger(rating) && rating >= 0 && rating <= 10 && code !== 'thank-you' && channel;
  };

  useEffect(() => {
    if (canSubmit(code, channel, rating)) {
      submitNpsFeedback({ code, channel, rating, reason: null });
    }
  }, [rating, code, channel]);

  const handleSubmit = (): void => {
    if (canSubmit(code, channel, rating)) {
      setSubmitting(true);
      submitNpsFeedback({
        code,
        channel: 'survey-page',
        rating,
        reason: feedbackText,
      })
        .then(response => {
          const sentiment = response?.data?.data?.sentiment;
          let shouldG2 = ['Positive', 'Very Positive'].includes(sentiment) && [7, 8, 9, 10].includes(rating);
          if (!shouldG2) {
            const isUser = response?.data?.data?.nsp_survey_request?.user_id !== null;
            shouldG2 =
              [9, 10].includes(rating) &&
              !['Negative', 'Very Negative'].includes(sentiment) &&
              (positiveFeedback || isUser);
          }
          if (shouldG2) {
            const firstName = response?.data?.data?.nsp_survey_request?.candidate?.first_name;
            const queryParams = new URLSearchParams({
              firstName,
              isCandidate: firstName ? 'true' : 'false',
            }).toString();
            history.push(`/feedback/nps/${code}/g2-review?${queryParams}`);
          } else {
            history.push('/feedback/nps/thank-you');
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const scoreRow = (): JSX.Element[] => {
    return [...Array(11)].map((e, i) => {
      return (
        <CircularRadioInput
          label={`${i}`}
          checked={i === rating}
          handleClick={() => {
            setRating(i);
            setChannel('survey-page');
          }}
        />
      );
    });
  };

  const ratingAvailable = (): JSX.Element => {
    if (rating === null || !(Number.isInteger(rating) && rating >= 0 && rating <= 10)) {
      return null;
    }
    return (
      <>
        <hr />
        <h2>
          Can you please tell us why you chose
          <span className="rate-value"> {rating}/10</span>?
        </h2>
        <div className="explanation">
          <TextArea
            id="user-experience"
            placeholder="Provide an optional note on your experience with Alooba..."
            onChange={e => setFeedbackText(e.target.value)}
            inputRef={inputRef}
          />
        </div>
        <Button
          variant="primary md"
          text={submitting ? 'Submitting' : 'Submit'}
          onClick={handleSubmit}
          disabled={submitting}
        />
      </>
    );
  };

  const form = (): JSX.Element => (
    <>
      <h2>How likely are you to recommend the assessment platform Alooba to a coworker or colleague?</h2>
      <div className="ratings">
        <div className="radio-buttons">{scoreRow()}</div>
        <div className="row">
          <span className="hint left">0 - Not likely</span>
          <span className="hint right">10 - Very likely</span>
        </div>
      </div>
      {ratingAvailable()}
    </>
  );

  if (!codeFound) {
    return <NotFound />;
  }

  const content = (): JSX.Element => (done ? <ThankYou /> : form());

  return (
    <div className="nps-landing">
      <CardContainer>
        <div className="logo-container">
          <img src={MainLogo} alt="Main Logo" className="main-logo" />
        </div>
        <hr />
        {loading ? <Skeleton height={40} width={400} /> : content()}
      </CardContainer>
    </div>
  );
};

export default NpsLanding;
