type Sticky = 'left' | 'right';
type Scroll = 'start' | 'end';

export interface TableField {
  label: string;
  index?: number | string;
  type?: string;
  relation?: string;
  cloak?: boolean;
  sticky?: Sticky;
  scroll?: Scroll;
  hidden?: boolean;
  nonSortable?: boolean;
  position?: number;
  excludeAssessments?: Array<string>;
  hint?: string;
}

export const getSelectFields = (): TableField[] => [
  {
    label: 'Reference',
    position: 1,
  },
  {
    label: 'Name',
    position: 1,
  },
  {
    label: 'Email',
    position: 1,
  },
  {
    label: 'Hiring Status',
    position: 1,
    excludeAssessments: ['ld'],
  },
  {
    label: 'Submitted On',
    position: 1,
  },
  {
    label: 'Completed On',
    position: 1,
  },
  {
    label: 'Invited On',
    position: 1,
  },
  {
    label: 'Total Score',
    position: 1,
  },
  {
    label: 'Percentile Rank',
    position: 1,
  },
  {
    label: 'Status',
    position: 1,
  },
  {
    label: 'Parts Updated At',
    position: 1,
  },
];

export const getTableFields = (subjects = [], candidateInformationFields = [], parts = []): TableField[] => {
  const result: TableField[] = [
    {
      label: 'Reference',
      sticky: 'left',
      position: 1,
    },
    {
      label: 'Name',
      cloak: true,
      sticky: 'left',
      scroll: 'end',
      position: 2,
    },
    {
      label: 'Email',
      cloak: true,
      hidden: true,
      position: 3,
    },
    {
      label: 'Hiring Status',
      hidden: false,
      position: 4,
      excludeAssessments: ['ld'],
    },
    {
      label: 'Invited On',
      type: 'datetime',
      hidden: true,
      position: 5,
    },
    {
      label: 'Submitted On',
      type: 'datetime',
      position: 6,
    },
    {
      label: 'Completed On',
      type: 'datetime',
      position: 1,
      hidden: true,
    },
    {
      label: 'Status',
      position: 1,
      hidden: true,
    },
  ];

  candidateInformationFields.forEach(info => {
    result.push({
      label: info.label,
      hidden: true,
      position: 10,
    });
  });

  const isMultiPart = parts.length > 1;
  subjects.forEach(subject => {
    result.push({
      label: subject.subject,
      type: 'percentage',
      position: 20,
      hidden: isMultiPart,
    });
  });

  if (isMultiPart) {
    parts.forEach(part => {
      if (['rating', 'interview'].includes(part.type.response_type)) {
        return;
      }
      let partType = part.type.test_type;
      if (partType === 'MCQ') {
        partType = 'Concepts & Knowledge';
      }
      if (['SQL', 'Data Analysis'].includes(partType)) {
        partType += ' Test';
      }
      result.push({
        label: partType,
        type: 'percentage',
        position: 20,
      });
    });
  }

  result.push({
    label: 'Percentile Rank',
    hidden: true,
    position: 21,
  });

  result.push({
    label: 'Total Score',
    type: 'percentage',
    sticky: 'right',
    scroll: 'start',
    position: 30,
  });

  return result;
};
