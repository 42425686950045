import * as React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import './modalForm.scss';
import { RootState } from 'store/rootReducer';
import { useSelector } from 'react-redux';
import Button from '../Button';
import Input from '../Input';
import RadioInput, { RadioInputProps } from '../Input/radio';
import AloobaPhoneInput from '../Input/AloobaPhoneInput';
import MultiSelect, { SelectInputOption } from '../MultiSelect';
import { hasOrgFeature } from '../../../Authorization/Check';

export interface ModalFormProps {
  heading: string; // Header text of Modal
  fields: ModalFormField[]; // Array of form field objects
  submitButtonLabel: string; // Submit button text
  closeModal: any; // Function to update parent to hide this component
  handleSubmit: any; // Promise that runs when submitting
  formSchema: any; // Yup + Formik form validation logic
  sendToast?: any; // Function to call useToasts() in parent
  submitButtonLoadingText?: string; // Submit button loading text
  subText?: string; // Text underneath the header
}

// this is bugged
// eslint-disable-next-line no-shadow
export enum ModalFormFieldTypes {
  text = 'text',
  tel = 'tel',
  radio = 'radio',
  email = 'email',
  multiselect = 'multiselect',
}

export interface ModalFormField {
  label: string;
  type: ModalFormFieldTypes;
  name: string; // Field value
  helperText?: string;
  radioOptions?: RadioInputProps[]; // Handle multiple radio options for single field
  disabled?: boolean; // Disable interaction
  selectOptions?: SelectInputOption[]; // options to display for the multiselect field
  initialValue?: any;
  selectProperties?: {
    inline?: boolean;
    dropdownPlaceholder?: string;
    noOptionsMessage?: string;
  };
  countryCode?: string;
}

interface FormikValuesType {
  permissions?: string;
}

const ModalForm: React.FunctionComponent<ModalFormProps> = (props: ModalFormProps) => {
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const formInitialValues: FormikValuesType = {};
  props.fields.forEach(field => {
    if (field.initialValue) {
      formInitialValues[field.name] = field.initialValue;
    }
  });
  const { subscription } = useSelector((state: RootState) => state.subscription);

  const getFieldHelperText = (field: ModalFormField, value: any): string =>
    field.radioOptions.find((o: RadioInputProps) => o.value === value)?.helperText;

  const handleSubmit = async (formData): Promise<void> => {
    setLoading(true);
    try {
      const response = await props.handleSubmit(formData);
      if (props.sendToast) {
        props.sendToast({
          type: 'success',
          msg: response.data.message || 'Success',
        });
      }
      props.closeModal();
    } catch (e) {
      if (e.response?.data?.errors) {
        if (props.sendToast) {
          props.sendToast({
            type: 'error',
            msg: `${e.response?.data?.errors[0].title}: ${e.response?.data?.errors[0].detail}`,
          });
        }
      }
    }
    setLoading(false);
  };

  const canSetUserPermission = (formData): boolean => {
    if (
      formData.permissions &&
      !['Owner', 'Admin'].includes(formData.permissions) &&
      !hasOrgFeature('user_permissions')
    ) {
      return false;
    }
    if (!subscription && formData?.permissions !== 'Admin' && props.submitButtonLabel !== 'Remove User') {
      return false;
    }
    return true;
  };

  const modalForm = (
    <Formik
      initialValues={formInitialValues}
      validationSchema={props.formSchema}
      onSubmit={values => {
        handleSubmit(values);
      }}
    >
      {formik => {
        return (
          <div className="formModal-container">
            <Form className="formModal-form">
              <h3>{props.heading}</h3>
              {props.subText && <p style={{ whiteSpace: 'pre-line' }}>{props.subText}</p>}
              {props.fields.map((field: ModalFormField) => {
                return (
                  <div className="form-row" key={field.name}>
                    {field.type === 'radio' && (
                      <>
                        <div className="radio-buttons">
                          <div className="mb2">
                            <label>{field.label}</label>
                          </div>
                          {field.radioOptions.map((radioOption: RadioInputProps) => {
                            return (
                              <>
                                <RadioInput
                                  onChange={e => {
                                    field.helperText = radioOption.helperText;
                                    formik.setFieldValue(field.name, e.target.value);
                                  }}
                                  value={radioOption.value}
                                  label={radioOption.label}
                                  name={field.name}
                                  checked={radioOption.value === formik.values[field.name]}
                                  key={radioOption.value}
                                  disabled={field.disabled || false}
                                />
                              </>
                            );
                          })}
                        </div>
                        {getFieldHelperText(field, formik.values[field.name]) ? (
                          <div className="form-text hint mt2">
                            {getFieldHelperText(field, formik.values[field.name])}
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                    {field.type === 'tel' && (
                      <AloobaPhoneInput
                        onChange={e => formik.setFieldValue(field.name, e)}
                        label={field.label}
                        errorTxt={formik.errors[field.name] ? formik.errors[field.name] : ''}
                        disabled={field.disabled || false}
                        placeholder=""
                        value={formik.values[field.name]}
                        defaultCountryCode={field.countryCode}
                      />
                    )}
                    {(field.type === 'text' || field.type === 'email') && (
                      <Input
                        type={field.type}
                        label={field.label}
                        onChange={
                          e => formik.setFieldValue(field.name, e.target.value) // ES confused with formatting here
                        }
                        errorTxt={formik.errors[field.name] ? formik.errors[field.name] : ''}
                        name={field.name}
                        value={field.initialValue}
                        disabled={field.disabled || false}
                      />
                    )}
                    {field.type === 'multiselect' ? (
                      <MultiSelect
                        label={field.label}
                        inline={field.selectProperties?.inline || true}
                        minInlineWidth="150px"
                        noOptionsMessage={field.selectProperties?.noOptionsMessage}
                        dropdownPlaceholder={field.selectProperties?.dropdownPlaceholder}
                        options={field.selectOptions}
                        value={formik.values[field.name]}
                        handleOptionSelected={e => {
                          const value = [].concat(formik.values[field.name]);
                          if (e?.clickUpgrade) {
                            history.push('/billing');
                          } else {
                            value.push(e);
                            formik.setFieldValue(field.name, value);
                          }
                        }}
                        handleValueRemoved={(e, removed) => {
                          const value = formik.values[field.name].filter(v => v.value !== removed.value);
                          formik.setFieldValue(field.name, value);
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
              {!canSetUserPermission(formik.values) && formik.values?.permissions !== 'Owner' && (
                <div className="upgrade-subscription">
                  <p>
                    Adding a user with {formik.values.permissions} permission is not included in your current
                    subscription plan.
                  </p>
                  <p>
                    Change the user permission to Admin or{' '}
                    <Link to="billing">
                      <b>upgrade your subscription</b>
                    </Link>{' '}
                    to access user permission options and many other amazing features Alooba has to offer.
                  </p>
                </div>
              )}
              <div className="button-row">
                <Button
                  type="submit"
                  variant="primary md"
                  text={props.submitButtonLabel}
                  loading={loading}
                  loadingTxt={props.submitButtonLoadingText}
                  disabled={
                    loading ||
                    !canSetUserPermission(formik.values) ||
                    (props.fields.length > 0 && JSON.stringify(formInitialValues) === JSON.stringify(formik.values))
                  }
                />
                <Button type="button" variant="sub-primary md" text="Cancel" onClick={props.closeModal} />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );

  return ReactDOM.createPortal(modalForm, document.body);
};

export default ModalForm;
