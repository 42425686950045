import React from 'react';
import pieChart from '../../../images/icons/pie-chart-placeholder.svg';
import barGraph from '../../../images/icons/bar-graph-placeholder.svg';
import './index.scss';

const GraphicPlaceholder: React.FC<{ variant?: string }> = ({ variant = 'bar' }) => {
  const variants = {
    pie: pieChart,
    bar: barGraph,
  };
  return (
    <div className="graphic-placeholder-wrapper">
      <img src={variants[variant]} alt={variant} />
      <p>When audience insights are available they will show up here.</p>
    </div>
  );
};

export default GraphicPlaceholder;
