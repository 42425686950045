import React from 'react';
import './vertical-divider.scss';

interface Props {
  className?: string;
}

const VerticalDivider = ({ className = '' }: Props): JSX.Element => <div className={`${className} vertical-divider`} />;

export default VerticalDivider;
