import React from 'react';
import styles from './Tooltip.module.scss';
import AloobaTooltip from './AloobaTooltip';

interface Props {
  tooltip: string;
  text: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  pointer?: boolean;
}

const TextWithTooltip: React.FC<Props> = ({ tooltip, text, placement = 'top', pointer = true }) => {
  return (
    <AloobaTooltip arrow title={tooltip} placement={placement}>
      <span className={pointer ? styles.withTooltip : null}>{text}</span>
    </AloobaTooltip>
  );
};

export default TextWithTooltip;
