import React, { useEffect, useState, useMemo } from 'react';
import { useToasts } from 'react-toast-notifications';
import './settingsSwitcher.scss';
import { handleError } from 'handleError';
import Switcher from './index';

type handleChangeFn = (checked: boolean) => Promise<any>;

interface Props {
  title: string;
  innerProps?: any;
  handleChange: handleChangeFn;
  successMessage: string;
  errorMessage: string;
  initialValue: boolean;
  beta?: boolean;
  divClassName?: string;
}

const SettingsSwitcher: React.FC<Props> = (props: Props) => {
  const { title, innerProps, handleChange, successMessage, errorMessage, initialValue, beta, divClassName } = props;
  const [checked, setChecked] = useState(false);
  const { addToast } = useToasts();

  const onChange = (): void => {
    const newVal = !checked;
    setChecked(newVal);
    handleChange(newVal)
      .then(() => {
        addToast({
          type: 'success',
          msg: successMessage,
        });
      })
      .catch(e => {
        addToast({
          type: 'error',
          msg: errorMessage,
        });
        setChecked(!newVal);
        handleError(e);
      });
  };

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  const switcherProps = useMemo(
    () => ({
      ...innerProps,
      checked,
    }),
    [checked, innerProps],
  );
  return (
    <>
      <div className="title-row">
        <h3>{title}</h3>
        {beta && <div className="badge">Beta</div>}
      </div>
      <div className={divClassName ?? 'setting-switcher'}>
        <Switcher {...switcherProps} onChange={onChange} />
      </div>
    </>
  );
};

export default SettingsSwitcher;
