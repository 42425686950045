import { AssessmentPurpose, ProductFeature } from 'components/Assessment';
import CreateAssessment from 'components/CreateAssessment';
import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  feature: ProductFeature;
}

const CreateAssessmentPage: React.FC<Props> = ({ feature }) => {
  let assessmentPurpose: AssessmentPurpose;
  if (feature === 'assess') {
    assessmentPurpose = 'hiring';
  } else if (feature === 'junior') {
    assessmentPurpose = 'junior';
  } else if (feature === 'growth') {
    assessmentPurpose = 'ld';
  }

  return (
    <div className="container">
      <Helmet>
        <title>Create Assessment | alooba.com</title>
      </Helmet>
      <CreateAssessment assessmentPurpose={assessmentPurpose} />
    </div>
  );
};

export default CreateAssessmentPage;
