import React, { useMemo } from 'react';
import EstimatedDurationIcon from '../../images/icons/estimated-duration.svg';
import InfoIcon from '../../images/icons/info.svg';
import InfoBlueIcon from '../../images/icons/info-blue.svg';
import './index.scss';

interface Props {
  loading?: boolean;
  estimatedDuration: number;
  interview: any;
}

const InterviewDuration: React.FC<Props> = ({ loading, estimatedDuration, interview }) => {
  const durationStatus: string = useMemo(() => {
    if (!loading && estimatedDuration) {
      if (estimatedDuration < Math.round(interview.duration * 0.7)) {
        return 'under';
      } else if (estimatedDuration > Math.round(interview.duration * 1.1)) {
        return 'over';
      }
    }
    return 'accurate';
  }, [loading, estimatedDuration, interview]);
  return (
    <>
      {!loading && !!estimatedDuration && (
        <div className={`estimated-duration ${durationStatus === 'over' ? 'warning' : ''}`}>
          <img className="clock-icon" src={EstimatedDurationIcon} alt="estimated duration icon" /> Estimated Duration:
          <span className="estimated-duration-value">{Math.round(estimatedDuration / 60)} minutes</span>
          {durationStatus !== 'accurate' && (
            <>
              <img className="info-icon" src={durationStatus === 'over' ? InfoIcon : InfoBlueIcon} alt="info icon" />
              <span className="estimated-duration-message">
                {durationStatus === 'over' &&
                  'You may want to reduce the number of questions, or increase the interview duration.'}
                {durationStatus === 'under' && 'You may want to add more questions, or reduce the interview duration.'}
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default InterviewDuration;
