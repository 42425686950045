import React, { useMemo } from 'react';
import { Candidate } from 'store/reducers/candidate';
import Skeleton from 'react-loading-skeleton';
import { AssessmentPurpose } from 'components/Assessment';
import InfoIcon from '../../images/icons/info-blue.svg';
import './index.scss';

export interface Props {
  candidate?: Candidate;
  shouldCloakPII?: boolean;
  purpose?: AssessmentPurpose;
}

const questionArray = [
  {
    key: 'assessed_skills_well',
    label: 'The questions assessed relevant skills:',
    response: null,
  },
  {
    key: 'questions_difficult',
    label: 'The questions were a fair level of difficulty:',
    response: null,
  },
  {
    key: 'enough_time',
    label: 'There was enough time to answer all the questions:',
    response: null,
  },
  {
    key: 'software_worked_well',
    label: 'The testing software functioned normally and it was easy to use:',
    response: null,
  },
  {
    key: 'positive_experience',
    label: 'I had an overall positive experience taking this assessment:',
    response: null,
  },
  {
    key: 'wasnt_distracted',
    label: 'I was not distracted while taking this assessment and put in my best effort:',
    response: null,
  },
  {
    key: 'tools_used_for_da',
    label: 'Which tool(s) did you use to do the data analysis?',
    response: null,
  },
  {
    key: 'anything_else',
    label: 'Is there anything else you’d like us to know?',
    response: null,
  },
];

const responseMap = {
  s_agree: 'Strongly Agree',
  agree: 'Agree',
  neutral: 'Neutral',
  disagree: 'Disagree',
  s_disagree: 'Strongly Disagree',
};

const UploadedFileCard: React.FC<Props> = ({ candidate, shouldCloakPII, purpose }) => {
  const candidateTitle = useMemo(() => {
    if (!shouldCloakPII) {
      return candidate?.full_name;
    }
    if (purpose === 'ld') {
      return 'This employee';
    }
    return 'This candidate';
  }, [candidate, shouldCloakPII, purpose]);

  const feedback = useMemo(() => {
    if (candidate?.feedback) {
      const feedbackObj = candidate.feedback;
      for (let i = 0; i < questionArray.length; i += 1) {
        const item = questionArray[i];
        if (item.key === 'tools_used_for_da') {
          if (feedbackObj[item.key]) questionArray[i].response = feedbackObj[item.key].join(',');
        } else if (item.key === 'anything_else') {
          questionArray[i].response = feedbackObj[item.key];
        } else {
          questionArray[i].response = responseMap[feedbackObj[item.key]];
        }
      }
      return questionArray.filter(v => Object.keys(candidate.feedback).includes(v.key));
    }
    return [];
  }, [candidate]);

  const responseClass = (item): string => {
    if (!item || !item.response) {
      return 'Empty';
    }
    return item?.response?.replace(' ', '-');
  };

  return (
    <div className="main-container">
      {candidate ? (
        <>
          {!feedback.length ? (
            <div className="feedback-status-message">
              <img src={InfoIcon} alt="info icon" />
              <div>{candidateTitle} hasn&apos;t provided any feedback.</div>
            </div>
          ) : (
            ''
          )}
          {feedback.map(item => (
            <div className={`feedback-item ${item.key}`} key={item.key}>
              <div className="feedback-question">{item.label}</div>
              <div className={`feedback-response ${responseClass(item)} ${item.key}`}>
                {item.response ? item.response : 'Empty'}
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          <Skeleton width="100%" height={32} />
          <br />
          <br />
          <Skeleton width="100%" height={32} />
          <br />
          <br />
          <Skeleton width="100%" height={32} />
        </>
      )}
    </div>
  );
};
export default UploadedFileCard;
