import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RefreshContext } from 'components/Participants/context/refresh';
import { LoaderContext } from 'context/loader';
import useDynamicTable from 'hooks/useDynamicTable';
import { DynamicTablePropsContext } from 'components/Shared/DynamicTable/context/DynamicTablePropsContext';
import './hiringTeam.scss';
import TableBlank from 'components/Participants/TableBlank';
import { RootState } from 'store/rootReducer';
import TextWithTooltip from 'components/Shared/Tooltip/TextWithTooltip';
import { fullDateTime, relativeDate } from 'helpers/datetime';
import LightTooltip from 'components/Shared/Tooltip/LightTooltip';
import CustomSwitch from 'components/Shared/CustomSwitch';
import PlanUpgradeModal from 'components/Shared/PlanUpgradeModal';
import { getOrganizationUsers, updateHiringTeamUser } from 'api/profile.api';
import { useToasts } from 'react-toast-notifications';
import { SelectInputOption } from 'components/Shared/MultiSelect';
import { handleError } from 'handleError';
import { getTimezones } from '../../api/profile-api';
import { HiringTeamUser, tableFields } from './UsersTableFields';
import OrganizationUsersTable from './OrganizationUsersTable';
import {
  updateUsersQueryParams,
  setUsersPaginator,
  setUsersCurrentPage,
  setUsersSearch,
  resetUsersQueryParams,
} from '../../store/reducers/organizationUsers';
import ActionMenuContainer from './ActionMenuContainer';
import { hasOrgFeature } from '../../Authorization/Check';

const getDataCallback = {
  callback: (data: any): any => {
    return data;
  },
};

const filters = [
  {
    label: 'Search by Name or Email',
    name: 'searchTerm',
    type: 'textFilter',
    category: 'main',
    canHide: false,
    visible: true,
    position: 1,
  },
  {
    label: 'Permissions',
    name: 'permissions',
    type: 'MultiSelectFilter',
    category: 'main',
    multiple: true,
    options: ['Owner', 'Admin', 'Standard', 'Restricted'],
    defaultValues: [],
    canHide: false,
    visible: true,
    position: 2,
  },
  {
    label: 'Last Login',
    name: 'lastLogin',
    type: 'DateFilter',
    category: 'main',
    canHide: false,
    visible: true,
    position: 4,
  },
];

interface OrganizationUsersProps {
  handleClickUpdateUser: any;
  setSelectedUser: any;
  setShowDeleteUserModal: any;
  setShowSuspendUserModal: any;
  availableGroups: any[];
  forceRefetch?: number;
}

const OrganizationUsers: React.FC<OrganizationUsersProps> = ({
  handleClickUpdateUser,
  setSelectedUser,
  setShowDeleteUserModal,
  setShowSuspendUserModal,
  availableGroups,
  forceRefetch,
}: OrganizationUsersProps) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { addToast } = useToasts();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [timezones, setTimezones] = useState([]);
  const setPaginator = setUsersPaginator;
  const setCurrentPage = setUsersCurrentPage;
  const setSearch = (searchTerm): void => {
    dispatch(setUsersSearch(searchTerm));
  };
  const { userGroups } = useSelector((state: RootState) => state.users);

  const canSSO = hasOrgFeature('sso');

  const {
    usersPaginator: paginator,
    usersQueryParams: queryParams,
    usersCurrentPage: currentPage,
    usersSearch: search,
  } = useSelector((state: RootState) => state.organizationUsers);

  const { isLast, nextPage, filterSearch, changePerPage, sortingAction, tablePaginatorProps } = useDynamicTable(
    getDataCallback,
    paginator,
    currentPage,
    setSearch,
  );

  const [userTableProps, setUserTableProps] = useState<any>({
    loading: isLoading,
    fields: tableFields,
    data: [],
    paginator,
  });
  const [blank, setBlank] = useState<boolean>(true);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

  useEffect(() => {
    getTimezones().then(res => {
      const formattedTimezones =
        res &&
        res.data &&
        res.data.data.map(zone => {
          return {
            label: `(GMT${zone.offset}) ${zone.code}`,
            code: zone.code,
            offset: zone.offset,
          };
        });
      setTimezones(formattedTimezones);
    });
  }, []);

  const actionMenu = useCallback(
    (row: any): ReactElement => {
      return (
        <ActionMenuContainer
          row={row}
          handleClickUpdateUser={handleClickUpdateUser}
          setSelectedUser={setSelectedUser}
          setShowDeleteUserModal={setShowDeleteUserModal}
          setShowSuspendUserModal={setShowSuspendUserModal}
        />
      );
    },
    [handleClickUpdateUser, setSelectedUser, setShowDeleteUserModal, setShowSuspendUserModal],
  );

  const getData = async (data: any): Promise<any> => {
    const defaultQueryParams = {
      perPage: 10,
      page: 1,
      searchTerm: '',
      orderBy: 'Name',
      orderDirection: 'asc',
      permissions: [],
    };
    setIsError(false);
    setIsLoading(true);
    let state = defaultQueryParams;
    if (!data.reset) {
      state = { ...queryParams, ...data };
      dispatch(updateUsersQueryParams(state));
    } else {
      dispatch(resetUsersQueryParams());
    }
    const res = await getOrganizationUsers(userDetails?.recruiter_detail?.organisation_id, state);
    if (res?.data?.data) {
      dispatch(setPaginator(res.data.data));
      setBlank(firstLoad ? res.data.data.total === 0 : false);
      dispatch(setCurrentPage(res.data.data.current_page));
    } else {
      setIsError(true);
    }
    setIsLoading(false);
    setLoaded(true);
  };
  getDataCallback.callback = useCallback(getData, [
    dispatch,
    setCurrentPage,
    setPaginator,
    queryParams,
    firstLoad,
    userDetails,
  ]);

  useEffect(() => {
    const organisation_id = userDetails?.recruiter_detail?.organisation_id;
    if (!organisation_id) {
      return;
    }
    if (firstLoad) {
      if (paginator.total === undefined) {
        setLoaded(false);
        getDataCallback.callback({});
      } else {
        setLoaded(true);
        setIsLoading(false);
        setBlank(paginator.total === 0);
        // reset search params when return to page
        if (queryParams.searchTerm !== '' || queryParams.permissions.length > 0 || queryParams['lastLogin[from]']) {
          getDataCallback.callback({ reset: true });
        }
      }
      setFirstLoad(false);
    }
  }, [firstLoad, paginator.total, userDetails, queryParams]);

  useEffect(() => {
    if (!forceRefetch) {
      return;
    }
    getDataCallback.callback({});
  }, [forceRefetch]);

  const getUsersWithGroups = useCallback((): any[] => {
    return paginator.data.map((user: HiringTeamUser) => {
      const tempUser = { ...user };
      tempUser.groups = userGroups[tempUser.id]?.map((groupId: number) => {
        const groupDetails = availableGroups.find((group: SelectInputOption) => groupId === group.value);
        return groupDetails;
      });
      if (!tempUser.groups) {
        tempUser.groups = [];
      }
      return tempUser;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableGroups, paginator.data]);

  const processCell = useCallback(
    (row: any, field: any): any => {
      const toggleMFAStatus = (user: any, organisation_id: number, mfa_enabled: boolean): Promise<any> => {
        if (!hasOrgFeature('mfa') && mfa_enabled) {
          setShowUpgradeModal(true);
          return Promise.resolve();
        }
        const tempOrganizationUsers = getUsersWithGroups();
        setUserTableProps((prev: any) => ({
          ...prev,
          data: tempOrganizationUsers.map((u: any) => ({
            ...u,
            'MFA Enabled': u.id === user.id ? mfa_enabled : u['MFA Enabled'],
          })),
        }));
        return updateHiringTeamUser({
          user_id: user.id,
          organisation_id,
          mfa_enabled,
        })
          .then(() => {
            addToast({
              type: 'success',
              msg: `MFA is now ${mfa_enabled ? 'enabled' : 'disabled'} for ${user.Name}.`,
            });
          })
          .catch(e => {
            setUserTableProps((prev: any) => ({
              ...prev,
              data: tempOrganizationUsers,
            }));
            if (e.response?.status === 402) {
              setUserTableProps((prev: any) => ({
                ...prev,
                data: tempOrganizationUsers.map((u: any) => ({
                  ...u,
                  'MFA Enabled': u.id === user.id ? !mfa_enabled : u['MFA Enabled'],
                })),
              }));
              setShowUpgradeModal(true);
              addToast({
                type: 'error',
                msg: 'You must upgrade your account to enable MFA for organization users.',
              });
              return;
            }
            addToast({
              type: 'error',
              msg: `Failed to ${mfa_enabled ? 'enable' : 'disable'} MFA for ${user.Name}.`,
            });
            handleError(e);
          });
      };

      if (row[field.label] === null) {
        return 'N/A';
      }
      const value = row[field.label];

      if (field.label === 'Name') {
        return (
          <div className="candidate-name-cell">
            <div>
              {value} {row.id === userDetails.id ? ' (You)' : ''}
            </div>
          </div>
        );
      }
      if (field.label === 'MFA Enabled') {
        let disabledTooltipText = 'You must set a phone number before enabling MFA';
        if (canSSO) {
          disabledTooltipText =
            'MFA is disabled for SSO users. Speak to your Active Directory administrator to enable MFA.';
        }
        return (
          <LightTooltip
            className="no-hover"
            title={!canSSO && row['Phone Number'] ? '' : disabledTooltipText}
            placement="top"
            arrow
          >
            <span>
              <CustomSwitch
                checked={row['MFA Enabled']}
                color="primary"
                onChange={() =>
                  toggleMFAStatus(row, userDetails?.recruiter_detail?.organisation_id, !row['MFA Enabled'])
                }
                name="checkedA"
                disabled={canSSO || !row['Phone Number'] || row.Status === 'Suspended'}
              />
            </span>
          </LightTooltip>
        );
      }
      if (field.type === 'datetime') {
        return (
          <div className="no-hover">
            <TextWithTooltip
              text={relativeDate(value, userDetails.user_timezone)}
              tooltip={fullDateTime(value, userDetails.user_timezone, 'll [at] LT [(GMT]Z[)]')}
              placement="top"
            />
          </div>
        );
      }
      return value;
    },
    [
      getUsersWithGroups,
      addToast,
      userDetails.id,
      userDetails?.recruiter_detail?.organisation_id,
      userDetails.user_timezone,
      canSSO,
    ],
  );

  const onFilterChange = useCallback((data: any): void => {
    getDataCallback.callback({
      ...data,
      page: 1,
    });
  }, []);

  const retry = (): void => {
    getDataCallback.callback({});
  };

  useEffect(() => {
    if (!loaded || blank) {
      return;
    }
    const sortingProps = {
      sortingAction,
      orderBy: queryParams.orderBy,
      orderDirection: queryParams.orderDirection,
    };
    const usersWithGroups = getUsersWithGroups();
    setUserTableProps({
      loading: false,
      fields: tableFields,
      filters,
      data: usersWithGroups,
      tablePaginatorProps,
      actionMenu,
      onFilterChange,
      processCell,
      tableSearchProps: {
        filterSearch,
        searchTerm: queryParams.searchTerm,
      },
      sortingProps,
      noDataMessage: 'No hiring team members found.',
      showResetFiltersButton: true,
    });
    setLoaded(false);
  }, [
    loaded,
    currentPage,
    paginator,
    onFilterChange,
    search,
    queryParams,
    blank,
    userDetails.user_timezone,
    userDetails,
    actionMenu,
    filterSearch,
    nextPage,
    changePerPage,
    sortingAction,
    isLast,
    tablePaginatorProps,
    processCell,
    userGroups,
    availableGroups,
    getUsersWithGroups,
  ]);

  useEffect(() => {
    const users = getUsersWithGroups();
    setUserTableProps((prev: any) => ({
      ...prev,
      data: users,
    }));
  }, [getUsersWithGroups]);

  return (
    <>
      <RefreshContext.Provider value={[firstLoad, setFirstLoad, getDataCallback, timezones]}>
        <LoaderContext.Provider value={[isLoading, setIsLoading]}>
          {isError && <TableBlank {...{ loading: isLoading }} message="Unable to fetch users" onRetry={retry} />}

          {!isError &&
            (blank ? (
              <TableBlank loading={isLoading} message="No users added yet." />
            ) : (
              <DynamicTablePropsContext.Provider value={[userTableProps, setUserTableProps]}>
                <OrganizationUsersTable />
              </DynamicTablePropsContext.Provider>
            ))}
        </LoaderContext.Provider>
        <PlanUpgradeModal
          isShown={showUpgradeModal}
          setModalVisibility={setShowUpgradeModal}
          title="Upgrade your account to enable Multi-Factor Authentication"
          hasActionButton={false}
        >
          <p>
            Multi-Factor Authentication (MFA) is not currently available on your account. Please reach out to your
            Alooba account manager or&nbsp;
            <a href="mailto:support@alooba.com">support@alooba.com</a> to discuss how to gain access to MFA.
          </p>
        </PlanUpgradeModal>
      </RefreshContext.Provider>
    </>
  );
};

export default OrganizationUsers;
