import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getBillingDetails } from 'store/actions/billing.actions';
import Input from 'components/Shared/Input';
import CountryDropdown from 'components/Shared/Dropdown/CountryDropdown';
import './BillingDetails.scss';

interface BillingDetailsProps {
  orgId: number;
  name: string;
  email: string;
  country: string;
  abn: string;
  postalcode: string;
  setName: (name: string) => void;
  setEmail: (email: string) => void;
  setCountry: (country: string) => void;
  setAbn: (abn: string) => void;
  setPostalcode: (postalcode: string) => void;
}

const BillingDetails: React.FC<BillingDetailsProps> = ({
  orgId,
  name,
  email,
  country,
  abn,
  postalcode,
  setName,
  setEmail,
  setCountry,
  setAbn,
  setPostalcode,
}) => {
  const dispatch = useDispatch();
  const billing = useSelector((state: RootState) => state.billing);

  useEffect(() => {
    if (orgId) {
      dispatch(getBillingDetails(orgId));
    }
  }, [orgId, dispatch]);

  // When we get the billing details from the server we'll fill the form
  useEffect(() => {
    if (billing.billingDetails !== null) {
      setName(billing.billingDetails.name);
      setEmail(billing.billingDetails.email);
      setCountry(billing.billingDetails.country);
      setAbn(billing.billingDetails.abn);
      setPostalcode(billing.billingDetails.postal_code);
    }
  }, [billing.billingDetails, setName, setEmail, setCountry, setAbn, setPostalcode]);

  return (
    <div className="billing-details">
      <div>
        <h3>Billing Details</h3>
        <div className="form-row">
          <Input
            label="Billing Organization Name"
            value={name || ''}
            type="text"
            disabled={billing.loading}
            onChange={e => setName(e.target.value)}
            errorTxt={!billing.loading && !name ? 'Billing name is required' : ''}
          />
          <Input
            label="Billing Email"
            value={email || ''}
            type="email"
            disabled={billing.loading}
            onChange={e => setEmail(e.target.value)}
            errorTxt={!billing.loading && !email ? 'Billing email is required' : ''}
          />
        </div>
        <div className="form-row">
          <CountryDropdown
            id="billing-country"
            label="Country"
            placeholder="Select your country of incorporation"
            value={country || ''}
            disabled={billing.loading}
            handleChange={e => setCountry(e.value)}
            varient="form-field"
            errorTxt={!billing.loading && !country ? 'Billing country is required' : ''}
          />
          {country === 'AU' && (
            <Input
              label="ABN"
              value={abn || ''}
              type="text"
              disabled={billing.loading}
              onChange={e => setAbn(e.target.value)}
            />
          )}
          {['US', 'CA'].includes(country) && (
            <Input
              label="Postal Code"
              value={postalcode || ''}
              type="text"
              disabled={billing.loading}
              onChange={e => setPostalcode(e.target.value)}
              errorTxt={!billing.loading && !postalcode ? 'Postal code is required' : ''}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
