import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { Input } from 'components/Shared';
import { useToasts } from 'react-toast-notifications';

interface Props {
  title: string;
  setTitle: (title: string) => void;
  saveAssessmentTitle: (title: string) => void;
  template: any;
  onUpdateTemplate: (updatedTemplate: any) => void;
}

function TemplateAssessmentSummary({
  title,
  setTitle,
  saveAssessmentTitle,
  template,
  onUpdateTemplate,
}: Props): JSX.Element {
  const { job_titles, levels, stages } = useSelector((state: RootState) => state.template);
  const { addToast } = useToasts();

  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [isRoleEditing, setIsRoleEditing] = useState(false);
  const [isLevelEditing, setIsLevelEditing] = useState(false);
  const [isStageEditing, setIsStageEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [selectedRole, setSelectedRole] = useState(
    job_titles.find(role => role.job_title === template.job_title) || null,
  );
  const [selectedLevel, setSelectedLevel] = useState(
    levels.find(level => level.id === template.job_title_level_id) || null,
  );

  const [selectedStage, setSelectedStage] = useState(null);

  const [filteredLevels, setFilteredLevels] = useState([]);
  const [filteredStages, setFilteredStages] = useState([]);

  useEffect(() => {
    if (selectedRole) {
      const newFilteredLevels = levels.filter(level => level.job_title_id === selectedRole.id);
      setFilteredLevels(newFilteredLevels);

      const matchingLevel = newFilteredLevels.find(level => level.level === selectedLevel?.level);
      setSelectedLevel(matchingLevel || newFilteredLevels[0] || null);
      if (selectedStage) {
        setSelectedStage(null);
      }
      setFilteredStages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole, levels, selectedLevel?.level]);

  useEffect(() => {
    if (selectedStage && template && selectedStage.product_id !== template.product_id) {
      onUpdateTemplate({
        title: selectedLevel?.role_title,
        product_id: selectedStage?.product_id,
        stage: selectedStage?.stage,
        job_title_level_id: selectedLevel?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage]);

  useEffect(() => {
    if (selectedLevel && template) {
      const newFilteredStages = stages.filter(stage => stage.job_title_level_id === selectedLevel.id);
      setFilteredStages(newFilteredStages);

      const matchingStage = newFilteredStages.find(stage => {
        return stage.stage === template?.stage && stage.product_id === template?.product_id;
      });
      setSelectedStage(matchingStage || newFilteredStages[0] || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel]);

  const handleRoleChange = (selectedOption): void => {
    setSelectedRole(selectedOption);
    setIsRoleEditing(false);
  };

  const handleLevelChange = (selectedOption): void => {
    setSelectedLevel(selectedOption);
    setIsLevelEditing(false);
  };

  const handleStageChange = (selectedOption): void => {
    setSelectedStage(selectedOption);
    setIsStageEditing(false);
  };

  const dropdownStyle = {
    dropdownIndicator: provided => ({
      ...provided,
      display: 'none',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
  };

  return (
    <div className="template-summary">
      {isTitleEditing ? (
        <div className="editable-input">
          <Input
            type="text"
            value={editedTitle}
            onChange={e => {
              setEditedTitle(e.target.value);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setIsTitleEditing(false);
                if (!editedTitle || editedTitle.length < 3) {
                  setEditedTitle(title);
                  addToast({
                    type: 'error',
                    msg: 'Assessment title should be at least 3 characters long',
                  });
                  return;
                }
                setTitle(editedTitle);
                saveAssessmentTitle(editedTitle);
              }
              if (e.key === 'Escape') {
                setIsTitleEditing(false);
                setEditedTitle(title);
              }
            }}
            onBlur={() => {
              setIsTitleEditing(false);
              if (!editedTitle || editedTitle.length < 3) {
                setEditedTitle(title);
                addToast({
                  type: 'error',
                  msg: 'Assessment title should be at least 3 characters long',
                });
                return;
              }
              setTitle(editedTitle);
              saveAssessmentTitle(editedTitle);
            }}
            autoFocus
            id="assessment-title"
          />
        </div>
      ) : (
        <h2 onClick={() => setIsTitleEditing(true)}>{title}</h2>
      )}

      <div className="template-options">
        <div className="job-title" role="button" tabIndex={-1} onBlur={() => setIsRoleEditing(false)}>
          <div className="head">Role</div>
          <div className="value-wrapper">
            {isRoleEditing ? (
              <Select
                menuIsOpen
                styles={dropdownStyle}
                options={job_titles}
                getOptionLabel={option => option.job_title}
                getOptionValue={option => option.id}
                value={selectedRole}
                onChange={handleRoleChange}
                autoFocus
                className="select-dropdown"
                onBlur={() => setIsRoleEditing(false)}
              />
            ) : (
              <div className="value" role="button" tabIndex={-1} onClick={() => setIsRoleEditing(true)}>
                {selectedRole?.job_title || 'Select role'}
              </div>
            )}
          </div>
        </div>

        <div className="level" role="button" tabIndex={-1} onBlur={() => setIsLevelEditing(false)}>
          <div className="head">Level</div>
          <div className="value-wrapper">
            {isLevelEditing ? (
              <Select
                menuIsOpen
                styles={dropdownStyle}
                options={filteredLevels}
                getOptionLabel={option => option.level}
                getOptionValue={option => option.id}
                value={selectedLevel}
                onChange={handleLevelChange}
                autoFocus
                className="select-dropdown"
                onBlur={() => setIsLevelEditing(false)}
              />
            ) : (
              <div className="value" role="button" tabIndex={-1} onClick={() => setIsLevelEditing(true)}>
                {selectedLevel?.level || 'Select level'}
              </div>
            )}
          </div>
        </div>

        <div className="stage" role="button" tabIndex={-1} onBlur={() => setIsStageEditing(false)}>
          <div className="head">Stage</div>
          <div className="value-wrapper">
            {isStageEditing ? (
              <Select
                menuIsOpen
                styles={dropdownStyle}
                options={filteredStages}
                getOptionLabel={option => option.stage}
                getOptionValue={option => `${option.id}-${option.job_title_level_id}-${option.stage}`}
                value={selectedStage}
                onChange={handleStageChange}
                autoFocus
                className="select-dropdown"
                onBlur={() => setIsStageEditing(false)}
              />
            ) : (
              <div className="value" role="button" tabIndex={-1} onClick={() => setIsStageEditing(true)}>
                {selectedStage?.stage || 'Select stage'}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateAssessmentSummary;
