import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { RootState } from 'store/rootReducer';
import { setInsightsLoading } from 'store/actions/candidate.actions';
import MCQAnswer from 'components/Shared/Test/Answer/MCQAnswer';
import { getQuestionInsights, getWorkingFiles, getDataSets } from 'api/candidate.api';
import { setTestProperty, setTestQuestionProperty } from 'store/reducers/candidate';
import TestTabLayout from './TestTabLayout';
import './TestLayout.scss';

export default function MCQTest({
  testId,
  testType,
  recruiterTestId,
  candidateTestId,
  candidateReference,
  toggleShowTestRetakeModal,
  toggleShowAddTimeModal,
  hideSensitiveInfo = false,
}: {
  testId: number;
  testType: string;
  recruiterTestId: number;
  candidateTestId: number;
  candidateReference: string;
  toggleShowTestRetakeModal: any;
  toggleShowAddTimeModal: any;
  hideSensitiveInfo?: boolean;
}): any {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { results } = useSelector((state: RootState) => state.candidate);

  // Create a simpler aliased name for the test
  const test = results.tests[testId];

  const throwIfNotNetworkError = (e: Error): void => {
    if (e.message !== 'Network Error') {
      throw e;
    }
  };
  // Fetch the question insights, working files and/or data-sets if needed
  useEffect(() => {
    // Fetch insights
    if (
      test?.questions.length > 0 &&
      test?.questions[test?.selectedQuestionIndex].question_id &&
      !test?.questions[test?.selectedQuestionIndex].insights
    ) {
      dispatch(setInsightsLoading(true));
      getQuestionInsights(test.questions[test.selectedQuestionIndex].question_id)
        .then(response => {
          dispatch(
            setTestQuestionProperty({
              testId,
              questionIndex: test.selectedQuestionIndex,
              propertyName: 'insights',
              value: response.data.data,
            }),
          );
        })
        .catch(e => {
          addToast({
            type: 'error',
            msg: `Could not fetch insights for question ${test.selectedQuestionIndex}`,
          });
          throwIfNotNetworkError(e);
        })
        .finally(() => dispatch(setInsightsLoading(false)));

      if (test && test.test_type === 'Data Analysis') {
        // Fetch Data Sets
        if (
          test?.questions[test?.selectedQuestionIndex].question_id &&
          test?.questions[test?.selectedQuestionIndex].data_set_ids &&
          !test?.questions[test?.selectedQuestionIndex].dataSets
        ) {
          getDataSets(JSON.parse(test?.questions[test?.selectedQuestionIndex].data_set_ids))
            .then(response => {
              dispatch(
                setTestQuestionProperty({
                  testId,
                  questionIndex: test.selectedQuestionIndex,
                  propertyName: 'dataSets',
                  value: response.data.data,
                }),
              );
            })
            .catch(e => {
              addToast({
                type: 'error',
                msg: `Could not fetch data sets for question ${test.selectedQuestionIndex}`,
              });
              throwIfNotNetworkError(e);
            });
        }

        // Fetch Working files
        if (testId && candidateReference && !test?.workingFiles) {
          getWorkingFiles(candidateReference, testId)
            .then(response => {
              dispatch(
                setTestProperty({
                  testId,
                  propertyName: 'workingFiles',
                  value: response.data.data,
                }),
              );
            })
            .catch(e => {
              addToast({
                type: 'error',
                msg: `Could not fetch data sets for question ${test.selectedQuestionIndex}`,
              });
              throwIfNotNetworkError(e);
            });
        }
      }
    }
  }, [
    test?.selectedQuestionIndex,
    test?.questions,
    test?.test_type,
    test?.workingFiles,
    test,
    testId,
    candidateReference,
    dispatch,
    addToast,
  ]);

  return (
    <TestTabLayout
      testId={testId}
      testType={testType}
      recruiterTestId={recruiterTestId}
      candidateTestId={candidateTestId}
      toggleShowTestRetakeModal={toggleShowTestRetakeModal}
      toggleShowAddTimeModal={toggleShowAddTimeModal}
      insights={test?.questions[test?.selectedQuestionIndex]?.insights}
      dataSets={test?.questions[test?.selectedQuestionIndex]?.dataSets}
      skillResources={test?.questions[test?.selectedQuestionIndex]?.skill_resources}
      files={test?.workingFiles}
      hideSensitiveInfo={hideSensitiveInfo}
    >
      {!hideSensitiveInfo && <MCQAnswer question={test?.questions[test.selectedQuestionIndex]} />}
    </TestTabLayout>
  );
}
