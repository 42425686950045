import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { RefreshContext as ParticipantsRefreshContext } from 'components/Participants/context/refresh';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import InviteCandidateIcon from 'images/icons/invite-candidate-blue.svg';
import { inviteYourself } from 'api/assessment.api';
import { RootState } from 'store/rootReducer';
import VerticalDivider from '../../Shared/VerticalDivider';
import { AssessmentTabProps } from '../../Assessment/assessment-tab-props';

const InviteYourselfButton = (props: AssessmentTabProps): JSX.Element => {
  const { assessment, loading, setAssessment } = props;
  const [, , getDataCallback] = useContext(ParticipantsRefreshContext);
  const [processing, setProcessing] = useState(loading);
  const [processed, setProcessed] = useState(false);
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { addToast } = useToasts();
  const label = 'Invite Yourself';
  if (processing) {
    return (
      <div className="button-bar-item">
        <FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
        {label}
        <VerticalDivider className="button-bar-divider" />
      </div>
    );
  }
  if (processed) {
    return <></>;
  }
  const handleButtonClick = async (e): Promise<void> => {
    e.preventDefault();
    if (processing) {
      return;
    }
    setProcessing(true);
    const bodyFormData = new FormData();
    bodyFormData.append('recruiter_test_id', assessment.id);
    try {
      await inviteYourself(bodyFormData);
      addToast({
        type: 'success',
        msg: `${userDetails.first_name} ${userDetails.last_name} has been added.\n An invitation has been sent to ${userDetails.email}.`,
      });
      setProcessed(true);
      setAssessment({ ...assessment, has_invited_yourself: true });
      getDataCallback.callback({});
    } catch (se) {
      addToast({
        type: 'error',
        msg: 'Unable to add you to the assessment',
      });
    }
    setProcessing(false);
  };

  return (
    <>
      <div role="button" onClick={handleButtonClick} className="button-bar-item" tabIndex={-1}>
        <img src={InviteCandidateIcon} alt={label || 'tooltip'} />
        <span className="button-bar-label">{label}</span>
      </div>
      <VerticalDivider className="button-bar-divider" />
    </>
  );
};

export default InviteYourselfButton;
