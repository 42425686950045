import * as React from 'react';
import SuspicousIcon from 'images/icons/suspicious-activity.svg';
import AuthIcon from 'images/icons/user.svg';
import { Link } from 'react-router-dom';

export interface SuspiciousActivity {
  name: string;
  explanation: string;
  related_candidate_reference?: string;
  related_candidate_name?: string;
  confidence?: number;
  is_test_level?: boolean;
  suspicious_activity_category?: string;
}
const SuspiciousActivities: React.FC<{
  suspiciousActivities: SuspiciousActivity[];
  candidateDetailsHidden?: boolean;
  candidateReference?: string;
}> = ({ suspiciousActivities, candidateDetailsHidden = false, candidateReference = null }) => {
  if (!suspiciousActivities || suspiciousActivities.length === 0) {
    return null;
  }
  const groupedSuspiciousActivities = suspiciousActivities.reduce((acc, activity) => {
    if (!acc[activity.suspicious_activity_category]) {
      acc[activity.suspicious_activity_category] = [];
    }
    acc[activity.suspicious_activity_category].push(activity);
    return acc;
  }, {});
  const sortedSuspiciousActivities = Object.keys(groupedSuspiciousActivities)
    .sort()
    .map(categoryName => ({
      category: categoryName,
      activities: groupedSuspiciousActivities[categoryName],
    }));

  return (
    <>
      <hr />
      <h2 className="mt3">
        <img className="mr1" src={SuspicousIcon} alt="Suspicious activity icon" />
        Suspicious Activit{suspiciousActivities?.length > 1 ? 'ies' : 'y'}
      </h2>
      <div className="suspicious-activity-card">
        {sortedSuspiciousActivities.map(group => {
          return (
            <>
              {!['null', 'undefined'].includes(group.category) ? (
                <h2 className="category-header">{group.category}</h2>
              ) : (
                ''
              )}
              <div className={!['null', 'undefined'].includes(group.category) ? 'category-details' : ''}>
                {group.activities?.map(activity => {
                  return (
                    <div key={activity.name} className="suspicious-activity">
                      <h4>
                        {activity.name}
                        {activity.confidence ? <span> (Confidence Level: {activity.confidence}%)</span> : ''}
                        {activity.is_test_level ? (
                          <span className="hint"> (Check the individual tests for more info.)</span>
                        ) : (
                          ''
                        )}
                      </h4>
                      <p>{activity.explanation}</p>
                      {activity.related_candidate_reference &&
                      activity.related_candidate_reference !== candidateReference ? (
                        <div>
                          <img className="personal-info-icon mr2" src={AuthIcon} alt="User Icon" />
                          <Link to={`/candidate-details/${activity.related_candidate_reference}/overview`}>
                            <strong>
                              {activity.related_candidate_name !== null &&
                              activity.related_candidate_name.length > 0 &&
                              !candidateDetailsHidden
                                ? activity.related_candidate_name
                                : activity.related_candidate_reference}
                            </strong>
                          </Link>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SuspiciousActivities;
