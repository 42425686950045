import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileResult } from '../../api/profile.api';

interface ProfileState {
  userDetails: ProfileResult | Record<string, unknown>;
  loading: boolean;
  error: string | null;
}

const initialState: ProfileState = {
  userDetails: { recruiter_detail: { cloak_candidates: true } },
  loading: true,
  error: null,
};

const profileReducer = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getUserStart: state => {
      state.error = null;
    },
    getUserSuccess: (state, action: PayloadAction<ProfileResult>) => {
      const { data } = action.payload;
      state.userDetails = data;
      state.loading = false;
    },
    getUserFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    changeCloakingSuccess(state, action: PayloadAction<{ checked: boolean }>) {
      state.userDetails.recruiter_detail.cloak_candidates = action.payload.checked;
    },
  },
});

export const { getUserFailure, getUserSuccess, getUserStart, changeCloakingSuccess } = profileReducer.actions;
export default profileReducer.reducer;
