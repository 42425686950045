import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Draggable } from 'react-beautiful-dnd';

import useHover from 'hooks/useHover';
import Fade from 'components/Shared/Fade';
import QuestionDuration from '../../CreateTest/QuestionDuration';
import DeleteIcon from '../../../images/icons/delete.svg';
import dndIcon from '../../../images/icons/dnd.svg';

interface Props {
  question: any;
  index: number;
  selectedQuestion: any;
  setSelectedQuestion: any;
  handleRemoveQuestion: any;
  removingMap: any;
}

const SkillQuestion: React.FC<Props> = ({
  question,
  index,
  selectedQuestion,
  setSelectedQuestion,
  handleRemoveQuestion,
  removingMap,
}) => {
  const [hoverRef, isHovering] = useHover();

  const isDeletable = useMemo(() => question.question_type?.question_type !== 'MCQ Rating', [question.question_type]);

  return (
    <Draggable draggableId={`question_${question.id}`} index={index}>
      {provided2 => (
        <div {...provided2.draggableProps} ref={provided2.innerRef} key={`question-${question.id}`}>
          <div
            ref={hoverRef}
            className={`question ${selectedQuestion && question.id === selectedQuestion.id ? 'selected' : ''}`}
            role="button"
            tabIndex={0}
            onClick={() => {
              setSelectedQuestion(question);
            }}
          >
            <div className="question-title">
              <img
                height={23}
                width={12}
                src={dndIcon}
                alt="drag-and-drop"
                className="dnd-icon"
                {...provided2.dragHandleProps}
              />
              {question.title}
            </div>
            <div className="right">
              {question.duration > 0 && question.question_type?.question_type !== 'Additional Criteria' && (
                <QuestionDuration duration={question.duration} />
              )}
              <div
                className={`question-delete-button${isDeletable ? '' : '-filler'}`}
                onClick={() => handleRemoveQuestion(question.question_id)}
                role="button"
                style={{ visibility: isDeletable ? 'visible' : 'hidden' }}
                tabIndex={isDeletable ? 0 : -1}
              >
                {removingMap[question.question_id] ? (
                  <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                ) : (
                  <Fade fadeIn={isHovering}>
                    <img src={DeleteIcon} alt="delete icon" />
                  </Fade>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default SkillQuestion;
