import { useState } from 'react';

interface ICollapsedMap {
  positive_indicators?: boolean;
  negative_indicators?: boolean;
  model_response?: boolean;
  candidate_response?: boolean;
  video_transcription?: boolean;
}

type Section =
  | 'positive_indicators'
  | 'negative_indicators'
  | 'model_response'
  | 'candidate_response'
  | 'video_transcription';

const useCollapsableResponse = (
  initialCollapsedMap: ICollapsedMap,
): [collapsedMap: ICollapsedMap, toggleCollapsed: (section: string) => void] => {
  const [collapsedMap, setCollapsedMap] = useState(initialCollapsedMap);

  const toggleCollapsed = (section: Section): void => {
    setCollapsedMap({
      ...collapsedMap,
      [section]: !collapsedMap[section],
    });
  };

  return [collapsedMap, toggleCollapsed];
};

export default useCollapsableResponse;
