import { apiLegacy } from '../api-legacy';
import api from './index';
import authAxios from '../api-auth';

export async function retakeTest(postData: {
  candidate_test_id: number;
  must_extend: boolean;
  delete_test_part_num: number;
  new_expiry_date?: string;
  new_expiry_date_verbose?: string;
  tz_offset?: string;
  tz_code?: string;
  test_id: number;
  no_send_expiry_email: boolean;
}): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/retake-test-ajax`;
  return apiLegacy.post(url, postData);
}

export async function grantAdditionalTime(postData: {
  tz_code?: string;
  candidate_test_id: number;
  test_id: number;
  must_extend: boolean;
  additional_time: number;
  new_expiry_date?: string;
  no_send_expiry_email: boolean;
}): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/grant-additional-time`;
  return apiLegacy.post(url, postData);
}

export async function getSqlResults(postData: { test_question_id: number; query: string }): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/execute/sql`;
  return apiLegacy.post(url, postData);
}

export async function getCodingTestCaseResults(postData: {
  test_question_id: number;
  code: string;
  language: string;
  test_case_id: number;
}): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/execute/test`;
  return apiLegacy.post(url, postData);
}

export async function getCandidateByReference(reference: string, uncloak = false): Promise<any> {
  let url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  if (uncloak) {
    url += '&uncloak=true';
  }
  return api.get(url);
}

export async function getCandidateByVerificationCode(verificationCode: string, uncloak = false): Promise<any> {
  let url = `${process.env.REACT_APP_SERVER_URL}/candidate/results/${verificationCode}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  if (uncloak) {
    url += '&uncloak=true';
  }
  return authAxios.get(url).catch(() => {
    window.location.href = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/test-invitation/${verificationCode}/`;
  });
}

export async function getTestByReferenceAndId(reference: string, testId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}/tests/${testId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function getTestEventsByReferenceAndId(reference: string, testId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}/tests/${testId}/events?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}
export async function getTestByVerificationCodeAndId(reference: string, testId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidate/results/${reference}/tests/${testId}?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return authAxios.get(url);
}
export async function getWorkingFiles(reference: string, testId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}/tests/${testId}/working-files?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function getDataSets(dataSetIds: number[]): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/questions/data-sets`;
  return api.get(url, {
    params: {
      client_id: process.env.REACT_APP_CLIENT_ID,
      dataSetIds,
    },
  });
}

export async function getQuestionInsights(questionId: number): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/questions/${questionId}/insights?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return authAxios.get(url);
}

export async function updateHiringStatus(
  reference: string,
  hiringStatus: string,
  rejectionDetails?: {
    reason: string;
    note?: string;
    notifyCandidate: boolean;
  },
): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}/hiring-status?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  let payload = {};
  if (rejectionDetails) {
    payload = {
      hiring_status: hiringStatus,
      rejection_reason: rejectionDetails.reason,
      note: rejectionDetails.note,
      notify_candidate: rejectionDetails.notifyCandidate,
    };
  } else {
    payload = {
      hiring_status: hiringStatus,
    };
  }

  return api.patch(url, payload);
}

export async function getSelfAwarenessScore(reference: string): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidates/${reference}/self-awareness?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return api.get(url);
}

export async function getSelfAwarenessScoreByVerificationCode(verificationCode: string): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidate/results/${verificationCode}/self-awareness?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return authAxios.get(url);
}

export async function getResourcesByVerificationCode(verificationCode: string): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidate/results/${verificationCode}/resources?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return authAxios.get(url);
}

export async function getSummaryByVerificationCode(verificationCode: string): Promise<any> {
  const url = `${process.env.REACT_APP_SERVER_URL}/candidate/results/${verificationCode}/summary?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  return authAxios.get(url);
}

export async function inviteCandidateManual(
  data: {
    recruiter_test_id: number;
    has_invitation_date: number;
    has_expiration_date: number;
    email_address: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  },
  purpose: string,
): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/add-candidate-manual-ajax?purpose=${purpose}`;
  return apiLegacy.post(url, data);
}

export async function inviteCandidateManualConfirm(data: {
  recruiter_test_id: number;
  has_invitation_date: number;
  has_expiration_date: number;
  expiration_date: string;
  tz_code: string;
  tz_offset: string;
  tz_code_expiration: string;
  tz_offset_expiration: string;
  email_address: string;
  first_name: string;
  last_name: string;
  invitation_date: string;
  phone_number: string;
}): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/add-candidate-manual-confirm-ajax`;
  return apiLegacy.post(url, data);
}

export async function resendInvitationEmail(candidate_test_id: number): Promise<any> {
  const url = `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/resend-invitation-email`;
  return apiLegacy.post(url, { candidate_test_id });
}
