import React, { useEffect, useState } from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function JobDescriptionExtraction(): JSX.Element {
  const [progressMessage, setProgressMessage] = useState<string>('');
  const [animationState, setAnimationState] = useState<'visible' | 'fading'>('visible');

  useEffect(() => {
    const messages = [
      'Extracting details from your job description...',
      'Analyzing your job description details...',
      "Identifying your role's responsibilities and requirements...",
      'Mapping role requirements to assessment frameworks...',
      'Prioritizing most relevant skills to evaluate...',
      'Selecting appropriate questions for each skill area...',
      'Calibrating question difficulty for this position...',
      'Creating balanced question set across all competencies...',
      'Tailoring questions to your specific requirements...',
      'Optimizing assessment length and complexity...',
      'Ensuring comprehensive skill coverage...',
      'Assembling your complete assessment...',
      'Finalizing assessment structure and flow...',
      'Getting everything ready for your candidates...',
      'Almost there! Preparing your custom assessment...',
    ];

    setProgressMessage(messages[0]);
    let currentMessageIndex = 1;

    const messageInterval = setInterval(() => {
      if (currentMessageIndex < messages.length) {
        // Start fade out
        setAnimationState('fading');

        // After fade out completes, change the message and fade in
        setTimeout(() => {
          setProgressMessage(messages[currentMessageIndex]);
          setAnimationState('visible');
          currentMessageIndex += 1;
        }, 500); // Half second for fade out
      } else {
        clearInterval(messageInterval);
      }
    }, 5000);

    // Clean up interval on unmount
    return () => {
      clearInterval(messageInterval);
    };
  }, []);

  return (
    <div className="main-container generating-assessment">
      <h3 className="generating-assessment-text">Constructing an assessment for your job description...</h3>
      <FontAwesomeIcon size="3x" className="fa-spin" icon={faCircleNotch} color="#004385" />
      {progressMessage && <div className={`progress-message ${animationState}`}>{progressMessage}</div>}
    </div>
  );
}

export default JobDescriptionExtraction;
