import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetOrganizationUsersQueryParams } from 'api/contract';
import { HiringTeamUser } from 'components/HiringTeam/UsersTableFields';

interface OrganizationUsersState {
  usersPaginator: any;
  usersCurrentPage: number;
  usersSearch: string;
  usersQueryParams: GetOrganizationUsersQueryParams;
}

const initialState: OrganizationUsersState = {
  usersPaginator: {
    data: [],
  },
  usersCurrentPage: 1,
  usersSearch: '',
  usersQueryParams: {
    perPage: 10,
    page: 1,
    searchTerm: '',
    orderBy: 'Name',
    orderDirection: 'asc',
    permissions: [],
  },
};

const OrganizationUsersReducer = createSlice({
  name: 'organizationUsers',
  initialState,
  reducers: {
    updateUsersQueryParams: (state, action: PayloadAction<any>) => {
      state.usersQueryParams = {
        ...state.usersQueryParams,
        ...action.payload,
      };
    },
    setUsersPaginator: (state, action: PayloadAction<any>) => {
      state.usersPaginator = action.payload;
    },
    setUsersCurrentPage: (state, action: PayloadAction<number>) => {
      state.usersCurrentPage = action.payload;
    },
    setUsersSearch: (state, action: PayloadAction<string>) => {
      state.usersSearch = action.payload;
    },
    resetUsersQueryParams: state => {
      state.usersQueryParams = initialState.usersQueryParams;
    },
    updateOrganizationUserInfo: (
      state,
      action: PayloadAction<{
        id: number;
        'Phone Number': string;
        Permissions: string;
        'MFA Enabled': boolean;
        Status: string;
      }>,
    ) => {
      state.usersPaginator.data = state.usersPaginator.data.map(user => {
        if (user.id === action.payload.id) {
          return {
            ...user,
            'Phone Number': action.payload['Phone Number'] ?? user['Phone Number'],
            Permissions: action.payload.Permissions ?? user.Permissions,
            'MFA Enabled': action.payload['MFA Enabled'] ?? user['MFA Enabled'],
            Status: action.payload.Status ?? user.Status,
          };
        }
        return user;
      });
    },
    deleteOrganizationUser: (state, action: PayloadAction<number>) => {
      state.usersPaginator.data = state.usersPaginator.data.filter(user => user.id !== action.payload);
    },
    addOrganizationUser: (state, action: PayloadAction<HiringTeamUser>) => {
      state.usersPaginator.data = [action.payload, ...state.usersPaginator.data];
    },
  },
});

export const {
  updateUsersQueryParams,
  setUsersPaginator,
  setUsersCurrentPage,
  setUsersSearch,
  resetUsersQueryParams,
  addOrganizationUser,
  updateOrganizationUserInfo,
  deleteOrganizationUser,
} = OrganizationUsersReducer.actions;
export default OrganizationUsersReducer.reducer;
