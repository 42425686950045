import React, { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import BounceLoader from 'react-spinners/BounceLoader';
import { authenticate } from '../../api/integration.api';
import './integration.scss';

const IntegrationAuth = ({ partner, code, state }: { partner: string; code?: string; state?: string }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleError = useErrorHandler();
  const storageKey = `@alooba/integration/${partner.toLowerCase()}`;
  let error = null;

  useEffect(() => {
    const saveAuth = async (): Promise<void> => {
      if (state === localStorage.getItem(`${storageKey}/auth_state`)) {
        setLoading(true);
        await authenticate(partner.toLowerCase(), code);
        localStorage.setItem(`${storageKey}/state`, 'connected');
        window.close();
      }
      setLoading(false);
    };
    saveAuth().catch(err => handleError(err));
  }, [partner, code, state, handleError, storageKey]);

  if (state !== localStorage.getItem(`${storageKey}/auth_state`)) {
    error = 'Invalid state';
  }
  const partnerName = partner.charAt(0).toUpperCase() + partner.slice(1);
  return (
    <div className="container">
      <h2 className="mb4">{`Connecting to ${partnerName}`}</h2>
      {loading ? (
        <div className="integration-loader">
          <BounceLoader color="gray" />
        </div>
      ) : (
        ''
      )}
      {error ? <p>{`Unable to connect to ${partnerName} due to "${error}"`}</p> : ''}
    </div>
  );
};

export default IntegrationAuth;
