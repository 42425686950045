import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import jwt_decode from 'jwt-decode';
import OrganizationIcon from '../../assets/organization-icon.svg';
import AloobaAssessIcon from '../../assets/alooba-assess.svg';
import AloobaJuniorIcon from '../../assets/alooba-junior.svg';
import AloobaGrowthIcon from '../../assets/alooba-growth.svg';
import QuestionBankIcon from '../../assets/question-bank-icon.svg';
import Navigation from './Navigation';
import '../../styles/global.styles.scss';
import './Menu.scss';
import { emptyJwt } from '../../helpers';

export interface Decoded {
  ufid: number;
  pl: string;
  paid: boolean;
}

export type MenuItemType = {
  title: string;
  itemId: string;
  isLegacy?: boolean;
  disable?: boolean;
  icon?: React.FC<unknown>;
  open?: boolean | undefined;
  menuSubItems?: MenuItemType[];
  showMenu?: boolean;
};

export const PagePermission = (accessToken: string, route: string): boolean => {
  const decoded: Decoded = jwt_decode(accessToken || emptyJwt);
  const routes = {
    '/question-bank': decoded.paid && ['Admin', 'Owner', 'Standard'].includes(decoded.pl?.[0]),
    '/manage-users': ['Admin', 'Owner'].includes(decoded.pl?.[0]),
    '/groups': ['Admin', 'Owner'].includes(decoded.pl?.[0]),
    '/organization-settings': ['Admin', 'Owner'].includes(decoded.pl?.[0]),
    '/organization-templates': ['Admin', 'Owner'].includes(decoded.pl?.[0]),
  };
  if (typeof routes[route] !== 'undefined') {
    return routes[route];
  }
  return true;
};

const Menu: React.FC = () => {
  const { accessToken } = useSelector((state: RootState) => state.auth);

  const menuItems: MenuItemType[] = [
    {
      title: 'Alooba Assess',
      itemId: '/#alooba-assess',
      showMenu: true,
      open: true,
      icon: () => <img src={AloobaAssessIcon} alt="Dashboard Icon" height="24" />,
      menuSubItems: [
        {
          title: 'Assessments',
          itemId: '/alooba-assess',
        },
        {
          title: 'Candidates',
          itemId: '/search-candidates?purpose=hiring',
        },
      ],
    },
    {
      title: 'Alooba Junior',
      itemId: '/#alooba-junior',
      showMenu: true,
      icon: () => <img src={AloobaJuniorIcon} alt="Dashboard Icon" height="24" width="24" />,
      menuSubItems: [
        {
          title: 'Assessments',
          itemId: '/alooba-junior',
        },
        {
          title: 'Candidates',
          itemId: '/search-candidates?purpose=junior',
        },
      ],
    },
    {
      title: 'Alooba Growth',
      itemId: '/#alooba-growth',
      showMenu: true,
      icon: () => <img src={AloobaGrowthIcon} alt="Dashboard Icon" height="24" width="24" />,
      menuSubItems: [
        {
          title: 'Exercises',
          itemId: '/alooba-growth',
        },
        {
          title: 'Employees',
          itemId: '/search-candidates?purpose=ld',
        },
        {
          title: 'Insights',
          itemId: '/alooba-growth/insights',
          isLegacy: false,
        },
      ],
    },
    {
      title: 'Question Bank',
      itemId: '/question-bank',
      isLegacy: true,
      showMenu: PagePermission(accessToken, '/question-bank'),
      icon: () => <img src={QuestionBankIcon} alt="QuestionBank Icon" />,
    },
    {
      title: 'Organization',
      itemId: '#organization',
      showMenu: PagePermission(accessToken, '/manage-users'),
      icon: () => <img src={OrganizationIcon} alt="Organization Icon" height="24" width="24" />,
      open: true,
      menuSubItems: [
        {
          title: 'Users',
          itemId: '/manage-users',
        },
        {
          title: 'Groups',
          itemId: '/groups',
        },
        {
          title: 'Settings',
          itemId: '/organization-settings',
        },
        {
          title: 'Templates',
          itemId: '/organization-templates',
        },
        {
          title: 'Billing',
          itemId: '/billing',
        },
      ],
    },
  ];

  return <Navigation items={menuItems} />;
};

export default Menu;
