import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useChatbot } from 'hooks';
import { deactivatePageHeader } from '../store/reducers/layout';
import MFA from '../components/Auth/MFA';

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

interface UrlParams {
  mfaId?: string;
}
const MFAPage: React.FC = () => {
  useChatbot();
  const dispatch = useDispatch();
  dispatch(deactivatePageHeader());
  const query = useQuery();
  const { mfaId }: UrlParams = useParams();
  const redirectUrl = query.get('redirectUrl');
  const message = query.get('message');
  const email = query.get('email');
  return (
    <>
      <Helmet>
        <title>Verify Your Phone Number | alooba.com</title>
        <meta name="description" content="Verify Your Phone Number to access your test results and the dashboard." />
      </Helmet>
      <MFA mfaId={mfaId} redirectUrl={redirectUrl} message={message} email={email} />
    </>
  );
};

export default MFAPage;
