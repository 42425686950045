import { ordinal_number } from 'helpers/numbers';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SelfAwarenessData } from '.';
import './SelfAwarenessTable.scss';

interface Props {
  skills: SelfAwarenessData[];
  onSkillActivate: (skill: SelfAwarenessData) => void;
  loading: boolean;
}

const SelfAwarenessTable: React.FC<Props> = ({ skills, onSkillActivate, loading }) => {
  if (loading) {
    return <Skeleton count={5} height="48px" />;
  }
  return (
    <div className="awareness-table">
      <div className="skill-heading skill-row">
        <div className="skill-name">Skills</div>
        <div className="skill-awareness-score">Score</div>
        <div className="skill-awareness-score">Self Rating</div>
        <div className="skill-awareness">Self Awareness</div>
      </div>
      {skills.map((skill: SelfAwarenessData) => {
        return (
          <div
            className={`skill-row ${skill.isHighlighted ? 'highlight' : ''}`}
            onFocus={() => onSkillActivate(skill)}
            onMouseOver={() => onSkillActivate(skill)}
            onMouseLeave={() => onSkillActivate(null)}
          >
            <div className="skill-name">
              <div className={`skill-icon ${skill.accuracy}`}>{skill.shortLabel}</div>
              <div>{skill.label}</div>
            </div>
            <div className="skill-awareness-score actual-score">
              <div className="label">Score</div>
              {ordinal_number(skill.scorePercentile)} Percentile
            </div>
            <div className="skill-awareness-score self-rating">
              <div className="label">Self Rating</div>
              {ordinal_number(skill.selfRating)} Percentile
            </div>
            <div className="skill-awareness">
              <div className="label">Self Awareness</div>
              {skill.selfAwareness}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SelfAwarenessTable;
