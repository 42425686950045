import { handleError } from 'handleError';
import store, { AppThunk } from '../store';
import { setUserGroups, setGettingUsers, setUsers } from '../reducers/users';
import { getOrganizationUsers } from '../../api/profile.api';

export const overwriteUserGroups = (userId: number, groups: number[]): AppThunk => {
  return async dispatch => dispatch(setUserGroups({ userId, groups }));
};

/**
 * Fetch unpaginated list of users
 *
 * @param orgId
 */
export const fetchUsers = (orgId: number): AppThunk => {
  return async dispatch => {
    const { users } = store.getState();
    if (users.users || users.gettingUsers) {
      return;
    }
    dispatch(setGettingUsers(true));

    getOrganizationUsers(orgId, { perPage: -1 })
      .then(res => {
        dispatch(setGettingUsers(false));
        // data is deep because endpoint always includes pagination meta data
        dispatch(setUsers(res.data.data.data));
      })
      .catch(err => {
        handleError(Object.assign(err, { stack: new Error().stack }));
      });
  };
};
