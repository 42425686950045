import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import MultiSelect, { SelectInputOption } from 'components/Shared/MultiSelect';
import { Input } from '../Shared';
import CardContainer from '../Shared/MainContainer/CardContainer';

interface MemberProps {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
}
interface Props {
  value?: string;
  id?: string;
  defaultValue?: string;
  handleGroupName?: (event: React.ChangeEvent<HTMLLIElement>) => void;
  handleUpdateGroupName?: (event: React.MouseEvent<HTMLLIElement>) => void;
  handleOnFocus?: (event: React.MouseEvent<HTMLLIElement>) => void;
  members?: MemberProps[];
  orgMembersList?: any;
  handleAddUser?: (event: React.ChangeEvent<HTMLLIElement>) => Promise<void>;
  handleRemoveUser?: (event: any, option: SelectInputOption) => Promise<void>;
  handleDeleteGroup?: any;
  handleGroupSelect?: any;
  deletingGroup?: boolean;
  errorText?: string;
  autoFocus?: boolean;
}
const GroupForm = ({
  handleGroupName,
  handleUpdateGroupName,
  handleOnFocus,
  value,
  defaultValue,
  members,
  orgMembersList,
  handleAddUser,
  handleRemoveUser,
  id,
  handleDeleteGroup,
  errorText,
  autoFocus,
  deletingGroup,
  handleGroupSelect,
}: Props): JSX.Element => {
  const [savingPlaceholder, setSavingPlaceholder] = useState<string>();
  const optionsBeingDeleted = useMemo(() => [], []);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [displayableGroupMembers, setDisplayableGroupMembers] = useState([]);

  useEffect(() => {
    setDisplayableGroupMembers(
      members.map(m => {
        return {
          label: `${m.first_name} ${m.last_name}`,
          value: m.id,
          hoverText: m.email,
          isBeingRemoved: optionsBeingDeleted.indexOf(m.id) >= 0,
        };
      }),
    );
    setIsDeleting(false);
  }, [optionsBeingDeleted, members, isDeleting]);

  const membersDropdown = useMemo(
    () =>
      orgMembersList.filter(
        user => !members.find(member => member.id === user.id) && user.code !== savingPlaceholder, // don't show the user in the placeholder in the dropdown options
      ),
    [orgMembersList, members, savingPlaceholder],
  );

  const cardProps = {
    className: 'member-form-content',
  };

  const onUserRemoved = (e, option): void => {
    setIsDeleting(true);
    optionsBeingDeleted.push(option.value);
    handleRemoveUser(e, option).finally(() => {
      const i = optionsBeingDeleted.indexOf(option.value);
      if (i >= 0) {
        optionsBeingDeleted.splice(i, 1);
        setIsDeleting(true); // re-render
      }
    });
  };

  const onUserAdded = (e: any): void => {
    setSavingPlaceholder(e.code);
    handleAddUser(e).finally(() => setSavingPlaceholder(null));
  };

  return (
    <div className="member-form mb4" onClick={handleGroupSelect} role="button" tabIndex={-1}>
      <CardContainer {...cardProps}>
        <Input
          type="text"
          id={id}
          errorTxt={errorText}
          defaultValue={defaultValue}
          name="group_name"
          onBlur={handleUpdateGroupName}
          onFocus={handleOnFocus}
          onChange={handleGroupName}
          value={value}
          label="Group Name:"
          autoFocus={autoFocus}
        />
        <div className="members">
          <label htmlFor="member">{`Members (${members && members.length ? members.length : 0}):`}</label>
          <MultiSelect
            inline
            dropdownPlaceholder="Add Member"
            options={membersDropdown}
            value={displayableGroupMembers}
            minInlineWidth="150px"
            savingPlaceholder={savingPlaceholder}
            handleOptionSelected={onUserAdded}
            handleValueRemoved={onUserRemoved}
          />
        </div>
      </CardContainer>
      <div tabIndex={-1} className="delete" onClick={handleDeleteGroup} role="button">
        {deletingGroup ? (
          <FontAwesomeIcon className="icon fa-spin" icon={faSpinner} />
        ) : (
          <FontAwesomeIcon className="icon" icon={faTrashAlt} />
        )}
      </div>
    </div>
  );
};

export default GroupForm;
