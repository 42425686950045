import jwt_decode from 'jwt-decode';
import store, { AppThunk } from '../store';
import { MFALogin, UserLogin } from '../../type';
import * as authApi from '../../api-auth/auth-api';
import legacyApi from '../../api-legacy';
import * as actions from '../reducers/auth';
import { emptyJwt } from '../../helpers';
import { getUserDetails } from './profile.actions';
import { userApi } from '../../api/profile.api';
import { SamlLoginConfiguration } from '../../api-auth/auth-api';

type saveTokensProps = {
  userCredentials: any;
  data: any;
};
export const sendResetPasswordEmail = async (email): Promise<any> => {
  return legacyApi.post<any>('/reset-password-ajax', {
    login: email,
  });
};
export const activate = async (code, password, email = null): Promise<any> => {
  return legacyApi.post<any>('/set-new-password-ajax', {
    email,
    code,
    password,
    password_confirmation: password,
    run_from: email ? '' : 'invited-recruiter',
  });
};
export const saveTokens = async ({ userCredentials, data }: saveTokensProps): Promise<any> => {
  if (!data.access_token) {
    return null;
  }
  const legacy = await legacyApi.post<any>('/authenticate', {
    username: userCredentials.email,
    token: data.access_token,
  });
  localStorage.setItem('@alooba/access_token', data.access_token);
  localStorage.setItem('@alooba/refresh_token', data.refresh_token);
  localStorage.setItem('@alooba/expires_in', data.expires_in);
  store.dispatch(actions.setTokensToState(data));
  store.dispatch(actions.loginSuccess());
  const decoded: { ufid: number } = jwt_decode(data.access_token || emptyJwt);
  store.dispatch(getUserDetails(decoded.ufid));
  return { dashboard_url: legacy.data.redirect_url };
};
export const loginWithCredentials = async (userCredentials: UserLogin): Promise<any> => {
  try {
    store.dispatch(actions.loginStart());
    const data = await authApi.authenticate(userCredentials.email, userCredentials.password);
    if (data.data) {
      store.dispatch(actions.loginSuccess());
      return data;
    }
    return saveTokens({ userCredentials, data });
  } catch (err) {
    store.dispatch(actions.loginFailure('Invalid Credentials'));
    return null;
  }
};
export const loginWithMFA = async (userCredentials: MFALogin): Promise<{ dashboard_url: string | null }> => {
  try {
    store.dispatch(actions.loginStart());
    const data = await authApi.validateMFA(userCredentials.code, userCredentials.mfaId);
    return saveTokens({ userCredentials, data });
  } catch (err) {
    store.dispatch(actions.loginFailure(err.response?.data?.message));
    if (err.response?.data?.message === 'Code has expired') {
      window.location.href = '/login';
    }
    return null;
  }
};

const tokensFromStateAfterRefreshing = async (): Promise<{
  refreshingTokens: boolean;
  accessToken: string;
}> => {
  return new Promise(resolve => {
    let { refreshingTokens, accessToken } = store.getState().auth;

    const updateAccessToken = (): void => {
      refreshingTokens = store.getState().auth.refreshingTokens;
      accessToken = store.getState().auth.accessToken;
      // We are interested in when the refreshingTokens turns false, because
      // it will indicate that the new accessToken was received.
      if (!refreshingTokens) {
        resolve({ refreshingTokens, accessToken });
      }
    };

    // This subscribes to all changes to the state and calls the method above
    store.subscribe(updateAccessToken);
  });
};

export const waitForAccessToken = async (): Promise<any> => {
  let { refreshingTokens, accessToken } = store.getState().auth;

  if (!refreshingTokens) {
    const ttlBuffer = 60;
    if (accessToken) {
      const decoded: { ufid: number; exp: any } = jwt_decode(accessToken);
      // Will refresh the accessToken if it is ttlBuffer seconds close to expiring
      if (Date.now() / 1000 + ttlBuffer > decoded.exp) {
        store.dispatch(refreshCurrentTokens());
        ({ refreshingTokens, accessToken } = store.getState().auth);
      }
    }
  }

  // For subsequent requests, if token are being refreshed, the new requests
  // should wait until refreshingTokens turns false again, meaning
  // a new accessToken was received. Using a promise to wait until then.
  if (refreshingTokens) {
    ({ refreshingTokens, accessToken } = await tokensFromStateAfterRefreshing());
  }

  return accessToken;
};

export const getCurrentTokens = (): any => {
  let { accessToken, refreshToken, expiresIn } = store.getState().auth;
  if (!accessToken || !refreshToken) {
    ({ accessToken, refreshToken, expiresIn } = getTokensFromLocalStorage());
  }
  return { accessToken, refreshToken, expiresIn };
};

export const getTokensFromLocalStorage = (): any => {
  const accessToken = localStorage.getItem('@alooba/access_token');
  const refreshToken = localStorage.getItem('@alooba/refresh_token');
  const expiresIn = localStorage.getItem('@alooba/expires_in');
  if (accessToken && refreshToken) {
    // If the tokens arent on the state, and they are on localstorage,
    // then this was called on a page load
    store.dispatch(
      actions.setTokensToState({
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: expiresIn,
      }),
    );
    store.dispatch(actions.refreshTokensSuccess());
    return { accessToken, refreshToken, expiresIn };
  }
  return store.getState().auth;
};

export const refreshCurrentTokens = (): AppThunk => async dispatch => {
  try {
    const { refreshingTokens, refreshToken } = store.getState().auth;
    if (!refreshingTokens) {
      dispatch(actions.refreshTokensStart());
      localStorage.setItem('@alooba/refreshing_tokens', 'true');
      const data = await authApi.revalidateToken(refreshToken);
      localStorage.setItem('@alooba/access_token', data.access_token);
      localStorage.setItem('@alooba/refresh_token', data.refresh_token);
      localStorage.setItem('@alooba/expires_in', data.expires_in);
      dispatch(actions.setTokensToState(data));
      const isUserDataPopulated = store.getState().profile.userDetails.id;
      if (!isUserDataPopulated) {
        const token = data.access_token;
        const decoded: { ufid: number; exp: any } = jwt_decode(token);
        dispatch(getUserDetails(decoded.ufid));
      }
      dispatch(actions.refreshTokensSuccess());
      localStorage.setItem('@alooba/refreshing_tokens', '');
      await legacyApi.post<any>('/last-seen', {});
    }
  } catch (err) {
    if (err.message === 'Request failed with status code 400') {
      window.location.href = `/logout`;
    }
  }
};

export const loginWithSocial = async (driver: string, code: string): Promise<{ redirect_url: string | null }> => {
  try {
    const data = await authApi.authenticateSocial(driver, code);
    localStorage.setItem('@alooba/access_token', data.access_token);
    localStorage.setItem('@alooba/refresh_token', data.refresh_token);
    localStorage.setItem('@alooba/expires_in', data.expires_in);
    store.dispatch(actions.setTokensToState(data));
    store.dispatch(actions.refreshTokensSuccess());
    const decoded: { ufid: number } = jwt_decode(data.access_token || emptyJwt);
    const user = await userApi(decoded.ufid);
    const legacy = await legacyApi.post<any>('/authenticate', {
      username: user.data.email,
      token: data.access_token,
    });
    return { redirect_url: legacy.data.redirect_url };
  } catch (err) {
    return null;
  }
};

export const logoutUser = (): void => {
  store.dispatch(actions.unsetTokens('Refresh Token Failed'));
  localStorage.removeItem('@alooba/access_token');
  localStorage.removeItem('@alooba/refresh_token');
  localStorage.removeItem('@alooba/expires_in');
  localStorage.removeItem('@alooba/refreshing_tokens');
  localStorage.removeItem('@alooba/dashboard');
};

export const getSamlConfig = (email: string): Promise<SamlLoginConfiguration | null> => {
  return new Promise(resolve => {
    store.dispatch(actions.getSamlLoginStart());
    authApi.getSamlConfiguration(email).then(config => {
      store.dispatch(actions.getSamlLoginEnd());
      resolve(config);
    });
  });
};
