import React, { useState } from 'react';
import './globalBars.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import Button from '../Button';
import { resendEmailVerificationLink } from '../../../api/assessment.api';
import { goToLegacyApp } from '../../../hooks';
import EmailIcon from '../../../images/emailVerification.png';

interface Props {
  notVerified?: boolean;
  hasPaid?: boolean;
  loading?: boolean;
  email?: string;
}

const GlobalBars = ({ notVerified, hasPaid, loading, email }: Props): JSX.Element => {
  const [showBar, setShowVerificationBar] = useState<boolean>(true);
  const [resending, setResending] = useState<boolean>(false);
  const [showDemo, setShowDemoBar] = useState<boolean>(true);
  const { addToast } = useToasts();

  const handleShowGlobalBar = (): void => {
    setShowVerificationBar(false);
  };
  const handleShowDemoBar = (): void => {
    setShowDemoBar(false);
  };
  const resendVerificationLink = async (): Promise<void> => {
    setResending(true);
    const response = await resendEmailVerificationLink();
    if (response.status === 200) {
      addToast({
        type: 'success',
        msg: `Verification email sent to ${email}.`,
      });
      setShowVerificationBar(false);
    }
    setResending(false);
  };
  return (
    <>
      {!loading && showBar && notVerified ? (
        <div className="custom-alert">
          <div className="email-icon">
            <img src={EmailIcon} alt="email" />
          </div>
          <div className="text-content">
            <p>Your email address is unverified. Please click the link in the verification email we sent.</p>
            <Button
              variant="secondary md"
              text="Resend Verification"
              loading={resending}
              loadingTxt="Resending..."
              onClick={resendVerificationLink}
            />
            <div className="close-bar" role="button" tabIndex={-1} onClick={handleShowGlobalBar}>
              <FontAwesomeIcon className="icon" icon={faTimes} />
            </div>
          </div>
        </div>
      ) : null}
      {!loading && hasPaid && showDemo ? (
        <div id="book-demo">
          <div>
            <p>You are viewing a sample Alooba account with limited access.</p>
            <p>
              Please <a href={`${process.env.REACT_APP_LANDING_PAGE_URL}/contact-us`}>contact us</a> for a demo and to
              discuss the next steps.
            </p>
          </div>
          <Button variant="sub-primary md" text="Book Demo" onClick={e => goToLegacyApp(e, `request-demo`)} />
          <div role="button" tabIndex={0} className="close-bar" onClick={handleShowDemoBar}>
            <FontAwesomeIcon className="icon" icon={faTimes} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GlobalBars;
