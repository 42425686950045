import './sso.scss';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormContainer from '../Shared/MainContainer/FormContainer';
import { Button } from '../Shared';
import PlanUpgradeModal from '../Shared/PlanUpgradeModal';
import MicrosoftLogo from '../../assets/partner/microsoft.svg';
import { hasOrgFeature } from '../../Authorization/Check';

const SSO = (): JSX.Element => {
  const history = useHistory();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  return (
    <>
      <h2 className="sso-title" id="sso-settings">
        Single Sign-On
      </h2>
      <FormContainer>
        <div className="container">
          <p>
            Single Sign-On (SSO) allows you to use your organization&apos;s existing identity provider to log in to
            Alooba.
          </p>
          <div className="sso-identity-provider">
            <div className="sso-identity-provider-name">
              <img src={MicrosoftLogo} alt="Microsoft Logo" />
              Azure Active Directory
            </div>
            <Button
              variant="sub-primary md"
              text="Configure"
              onClick={() => {
                if (hasOrgFeature('sso')) {
                  history.push('/sso-configuration');
                } else {
                  setShowUpgradeModal(true);
                }
              }}
            />
          </div>
          <hr />
          <h3 className="mb2">Is your Identity Provider not listed above?</h3>
          <p>
            Please&nbsp;
            <a href={`${process.env.REACT_APP_LANDING_PAGE_URL}/contact-us`}>Contact Us</a>
            &nbsp;to discuss integration with your Identity Provider.
          </p>
        </div>
      </FormContainer>
      <PlanUpgradeModal
        setModalVisibility={setShowUpgradeModal}
        isShown={showUpgradeModal}
        title="Upgrade your account to enable Single Sign-On"
        actionText={null}
      >
        <p>
          Single Sign-On (SSO) is not currently available on your account. Please reach out to your Alooba account
          manager or <a href="mailto:support@alooba.com">support@alooba.com</a> to discuss how to gain access SSO.
        </p>
      </PlanUpgradeModal>
    </>
  );
};

export default SSO;
