import React, { useState, useEffect, useCallback, useContext, ReactElement, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/rootReducer';
import 'styles/spacing.styles.scss';
import './index.scss';
import { DynamicTablePropsContext } from 'components/Shared/DynamicTable/context/DynamicTablePropsContext';
import TextWithTooltip from 'components/Shared/Tooltip/TextWithTooltip';
import {
  getAssessmentSubjects,
  getAssessmentCandidates,
  getAssessmentInformationFields,
  exportAssessmentCandidates,
} from 'api/assessment.api';
import { GetAssessmentCandidatesQueryParams } from 'api/contract';

import { selectCandidateRow } from 'helpers/store';
import { relativeDate, fullDateTime } from 'helpers/datetime';
import { getPercentileOrdinalIndicator } from 'helpers/numbers';
import { LoaderContext } from 'context/loader';
import { RefreshContext } from 'components/Participants/context/refresh';
import useDynamicTable from 'hooks/useDynamicTable';
import moment from 'moment';
import ErasedCandidateInfo from 'components/Shared/Erased/ErasedCandidateInfo';
import { useToasts } from 'react-toast-notifications';
import ActionMenuContainer from './ActionMenuContainer';
import { assessmentContext } from '../Assessment';
import { getTableFields, getSelectFields } from './completedTableFields';
import { getOrgDetails } from '../../store/actions/organization.actions';
import { getHiringStatuses } from '../../store/actions/assessment.actions';
import {
  setResultsPaginator,
  setResultsCurrentPage,
  setResultsSearch,
  setResultsTableFields,
  setResultsSubjects,
  setResultsInformationFields,
  resetAssessmentResultsCache,
  setAssessmentId,
  setResultsQueryParams,
} from '../../store/reducers/assessmentCandidate';
import CompletedBlank from './CompletedBlank';
import CompletedTable from './CompletedTable';
import SuspiciousActivityIcon from '../../images/icons/suspicious-activity.svg';
import BenchmarkIcon from '../../images/icons/benchmark.svg';
import RequiresEvaluationIcon from '../../images/icons/requires-evaluation-blue.svg';
import ClockIcon from '../../images/warning.svg';
import LightTooltip from '../Shared/Tooltip/LightTooltip';
import TableBlank from '../Participants/TableBlank';
import TopResults from './TopResults';
import { DEFAULT_PASSING_SCORE } from '../../helpers/constants';

/**
 * This object is created to be passed by reference to the
 * useDynamicTable custom hook, because in javascript, the only variables
 * that are passed by reference are arrays and objects.
 */
const getDataCallback = {
  callback: (data: any): any => {
    return data;
  },
};
const getFilterTypeByField = (field: any): string => {
  if (field.type === 'DROPDOWN') {
    return 'MultiSelectFilter';
  }
  if (field.type === 'NUMBER') {
    return 'ScoreFilter';
  }
  return 'textFilter';
};
const completedStatuses = ['Completed', 'Requires Evaluation'];
const mainFilters = [
  {
    label: 'Search Name, Email or Reference',
    name: 'searchTerm',
    type: 'textFilter',
    category: 'main',
    canHide: false,
    visible: true,
    position: 1,
  },
  {
    label: 'Assessment Status',
    name: 'status',
    type: 'MultiSelectFilter',
    category: 'main',
    multiple: true,
    options: [
      'Completed',
      'Requires Evaluation',
      'Incomplete Rejected',
      'Incomplete Expired',
      'In Progress',
      'Incomplete',
    ],
    defaultValues: ['Completed', 'Requires Evaluation'],
    canHide: false,
    visible: true,
    position: 2,
  },
];

const CandidateResults: React.FC = () => {
  const dispatch = useDispatch();
  const { assessment, loading } = useContext(assessmentContext);
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const { organizationDetails: organization } = useSelector((state: RootState) => state.organization);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isTableLoading, setTableLoading] = useState<boolean>(true);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [topResults, setTopResults] = useState<any>([]);
  const [excludeQueryFields, setExcludeQueryFields] = useState<string[]>([]);
  const [prevQueryFields, setPrevQueryFields] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const setSearch = (searchTerm): void => {
    dispatch(setResultsSearch(searchTerm));
  };
  const cachedAssessmentId = useSelector((state: RootState) => state.assessmentCandidate.assessmentId);
  if (assessment && assessment.id !== cachedAssessmentId) {
    dispatch(resetAssessmentResultsCache());
    dispatch(setAssessmentId(assessment.id));
  }
  const {
    resultsPaginator: paginator,
    resultsQueryParams: queryParams,
    resultsCurrentPage: currentPage,
    resultsSearch: search,
    resultsTableFields: tableFields,
    resultsSubjects: subjects,
    resultsInformationFields: informationFields,
  }: any = useSelector((state: RootState) => state.assessmentCandidate);

  const { hiringStatuses } = useSelector((state: RootState) => state.assessment);
  const { addToast } = useToasts();

  const { isLast, nextPage, filterSearch, changePerPage, sortingAction, tablePaginatorProps } = useDynamicTable(
    getDataCallback,
    paginator,
    currentPage,
    setSearch,
  );

  const [completedTableProps, setCompletedTableProps] = useState<any>({
    loading: isTableLoading,
    fields: tableFields,
    data: [],
    paginator,
    filters: [],
    configurableRows: true,
  });

  const [blank, setBlank] = useState<boolean>(true);

  const defaultQueryParams = useMemo(
    (): GetAssessmentCandidatesQueryParams => ({
      assessmentId: assessment.id,
      fields: getSelectFields()
        .sort((field1, field2) => field1.position - field2.position)
        .filter(
          value =>
            !excludeQueryFields.includes(value.label) ||
            (value.excludeAssessments && value.excludeAssessments.includes(assessment.purpose)),
        )
        .map(field => field.label),
      skills: subjects
        .filter(val => !excludeQueryFields.includes(val.subject))
        .map(val => {
          return val.subject;
        }),
      flags: ['Benchmark'],
      perPage: 10,
      page: currentPage,
      searchTerm: '',
      orderBy: 'Total Score',
      orderDirection: 'desc',
      status: completedStatuses,
      candidateInformationFields: informationFields
        .filter(value => !excludeQueryFields.includes(value.label))
        .map(value => value.label),
      actionMenu: ['Delete Assessment'],
    }),
    [assessment.id, assessment.purpose, subjects, currentPage, informationFields, excludeQueryFields],
  );
  const addDisplayFields = (state, excludedFields): any => {
    const displayFields = state.fields.filter(field => {
      return !excludedFields.includes(field);
    });
    return {
      ...state,
      displayFields,
    };
  };

  const totalScore = useCallback((): number => {
    if (!assessment.id) {
      return null;
    }
    if (assessment.passing_score) {
      return assessment.passing_score;
    }
    if (assessment.benchmark_score) {
      return assessment.benchmark_score * 100;
    }
    return DEFAULT_PASSING_SCORE;
  }, [assessment]);
  const getData = async (data: any): Promise<any> => {
    setIsError(false);
    setTableLoading(true);
    let subjectsRes = subjects;
    if (subjects.length <= 0) {
      const getSubjectsResponse = await getAssessmentSubjects(assessment.id, true);
      subjectsRes = getSubjectsResponse?.data?.data;
      const fieldsRes = await getAssessmentInformationFields(assessment.id);
      const filteredInformation = fieldsRes?.data?.data?.filter(item => !item.is_anonymised);
      if (fieldsRes?.data?.data) {
        dispatch(setResultsInformationFields(filteredInformation));
      }
      if (subjectsRes) {
        dispatch(setResultsSubjects(subjectsRes));
        dispatch(
          setResultsTableFields(
            getTableFields(subjectsRes, filteredInformation, assessment.product.parts)
              .filter(value => !value.excludeAssessments || !value.excludeAssessments.includes(assessment.purpose))
              .sort((field1, field2) => field1.position - field2.position),
          ),
        );
      } else {
        setIsError(true);
      }
    }

    const skills = subjectsRes
      ?.filter(val => {
        return !excludeQueryFields.includes(val.subject);
      })
      .map(val => val.subject);

    let state = defaultQueryParams;
    if (!data.reset) {
      state = {
        ...queryParams,
        skills,
        ...data,
      };
    }
    state = addDisplayFields(state, excludeQueryFields);
    state = { ...state, assessmentId: assessment.id };
    dispatch(setResultsQueryParams(state));
    const res = await getAssessmentCandidates(state);
    if (!res) {
      setIsError(true);
    } else {
      dispatch(setResultsPaginator(res.data.data));
      setBlank(false);
      if (firstLoad && topResults.length === 0) {
        setTopResults(
          res.data.data.data.filter(
            candidate => !(candidate.Status === 'Requires Evaluation' && candidate['Total Score'] == null),
          ),
        );
      }
      dispatch(setResultsCurrentPage(res.data.data.current_page));
    }
    setTableLoading(false);
    setLoaded(true);
  };
  getDataCallback.callback = useCallback(getData, [
    subjects,
    defaultQueryParams,
    excludeQueryFields,
    assessment.id,
    assessment.purpose,
    queryParams,
    firstLoad,
    topResults.length,
    dispatch,
    assessment.product.parts,
  ]);

  const loadAssessmentHiringStatusesData = useCallback(async () => {
    dispatch(getHiringStatuses());
  }, [dispatch]);

  useEffect(() => {
    if (assessment.purpose !== 'ld') {
      if (hiringStatuses.length === 1) loadAssessmentHiringStatusesData();
    }
  }, [assessment.purpose, loadAssessmentHiringStatusesData, hiringStatuses]);
  useEffect(() => {
    const orgId = userDetails?.recruiter_detail?.organisation_id;
    if (orgId) {
      dispatch(getOrgDetails(orgId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const topResultsFilteredByScore = useMemo(() => {
    return topResults.filter(candidate => candidate['Total Score'] >= totalScore());
  }, [topResults, totalScore]);

  useEffect(() => {
    if (firstLoad && queryParams) {
      if (paginator.total === undefined) {
        getDataCallback.callback({});
      }
      setFirstLoad(false);
    }
  }, [queryParams, firstLoad, paginator.total]);

  useEffect(() => {
    if (firstLoad) {
      if (paginator.total === undefined) {
        if (queryParams !== defaultQueryParams) {
          dispatch(setResultsQueryParams(defaultQueryParams));
        }
        setLoaded(false);
      } else {
        setLoaded(true);
        setBlank(false);
      }
    }
  }, [firstLoad, paginator.total, queryParams, defaultQueryParams, dispatch]);

  useEffect(() => {
    if (queryParams) {
      if (excludeQueryFields !== prevQueryFields) {
        getDataCallback.callback({
          candidateInformationFields: informationFields
            .filter(value => !excludeQueryFields.includes(value.label))
            .map(value => value.label),
        });
      }
      setPrevQueryFields(excludeQueryFields);
    }
  }, [excludeQueryFields, prevQueryFields, informationFields, queryParams]);

  const getPath = useCallback((row): string => {
    if (process.env.REACT_APP_NEW_CANDIDATE_RESULTS) {
      return `/candidate-details/${row.Reference}/overview`;
    } else {
      return `${process.env.REACT_APP_ALOOBA_LEGACY_URL}/individual-candidate-result/${row.Reference}`;
    }
  }, []);

  const processCell = useCallback(
    (row: any, field: any): any => {
      let assessmentModifiedMessage = '';
      if (assessment?.purpose === 'ld') {
        assessmentModifiedMessage = 'The exercise configuration was changed after this employee took the exercise';
      } else {
        assessmentModifiedMessage = 'The assessment configuration was changed after this candidate took the assessment';
      }

      if (row[field.label] !== 0 && !row[field.label]) {
        if (field.type === 'percentage') {
          if (field.label === 'Total Score') {
            return <div className="total-score bold">N/A</div>;
          }
          return <div className="skill-score text-center">N/A</div>;
        }
        return 'N/A';
      }
      const value = row[field.label];

      const isErased = row.data_erasure_request_id !== null && row.data_erasure_request_id !== undefined;
      if (isErased && field.label === 'Name') {
        return <ErasedCandidateInfo placement="top" />;
      }

      if (field.label === 'Percentile Rank') {
        return (
          <div className="total-score bold">
            {Math.round(value * 100)}
            {getPercentileOrdinalIndicator(value)}
          </div>
        );
      }

      if (field.label === 'Reference') {
        return (
          <div className="reference-cell-container">
            <div className="text-muted underline-on-hover">{value}</div>
            &ensp;
            <div className="candidate-flags">
              {row.suspicious_activity ? (
                <LightTooltip title="Suspicious Activity" placement="top" arrow>
                  <img src={SuspiciousActivityIcon} alt="Suspicious Activity" />
                </LightTooltip>
              ) : (
                ''
              )}
              {row.Status === 'Requires Evaluation' ? (
                <LightTooltip title="Requires Evaluation" placement="top" arrow>
                  <img src={RequiresEvaluationIcon} alt="Requires Evaluation" />
                </LightTooltip>
              ) : (
                ''
              )}
              {row['Submitted On'] && moment(row['Submitted On']) < moment(row['Parts Updated At']) && (
                <LightTooltip title={assessmentModifiedMessage} placement="top" arrow>
                  <img src={ClockIcon} alt="Updated" />
                </LightTooltip>
              )}
              {row.Benchmark === row.candidate_test_id ? (
                <LightTooltip title="Benchmark" placement="top" arrow>
                  <img src={BenchmarkIcon} alt="Benchmark" />
                </LightTooltip>
              ) : (
                ''
              )}
            </div>
          </div>
        );
      }
      if (field.label === 'Name') {
        return (
          <div className="candidate-name-cell primary-color">
            <div className="underline-on-hover">{value}</div>
          </div>
        );
      }
      if (field.type === 'datetime') {
        return (
          <div className="text-muted">
            <TextWithTooltip
              text={relativeDate(value, userDetails.user_timezone)}
              tooltip={fullDateTime(value, userDetails.user_timezone, 'll [at] LT [(GMT]Z[)]')}
              placement="top"
            />
          </div>
        );
      }
      if (field.type === 'percentage') {
        if (field.label === 'Total Score') {
          let className = '';
          if (row.Status === 'Requires Evaluation') {
            className = 'primary-color';
          }
          if (row.Benchmark === row.candidate_test_id) {
            className = 'success';
          }
          return <div className={`${className} total-score bold last-col`}>{`${value}%`}</div>;
        }
        return <div className="text-center skill-score">{`${value}%`}</div>;
      }
      return value;
    },
    [userDetails.user_timezone, assessment?.purpose],
  );
  const shouldCloakPII = useMemo(() => {
    return (
      userDetails?.recruiter_detail?.cloak_candidates || organization.cloak_candidates || assessment.cloak_candidates
    );
  }, [userDetails, organization, assessment]);
  const getFields = useCallback((): any => {
    if (shouldCloakPII) {
      return tableFields.map(field => {
        if (!field.cloak) {
          return field;
        }
        return {
          hidden: true,
          cloak: true,
        };
      });
    }
    return tableFields;
  }, [tableFields, shouldCloakPII]);
  const actionMenu = useCallback((row: any): ReactElement => {
    return <ActionMenuContainer {...{ row }} />;
  }, []);

  const getFilterInitialValues = (filters: any[], queryParams: any): any[] => {
    return filters.map(filter => {
      if (filter.type === 'ScoreFilter') {
        const initialVisible = Boolean(
          queryParams && (queryParams[`${filter.name}[min]`] || queryParams[`${filter.name}[max]`]),
        );
        const initialValue = {
          min: queryParams && queryParams[`${filter.name}[min]`],
          max: queryParams && queryParams[`${filter.name}[max]`],
        };
        return {
          ...filter,
          initialVisible,
          initialValue,
        };
      }
      if (filter.type === 'DateFilter') {
        const initialVisible = Boolean(
          queryParams && queryParams[`${filter.name}[from]`] && queryParams[`${filter.name}[to]`],
        );
        const initialValue = {
          from: queryParams && queryParams[`${filter.name}[from]`],
          to: queryParams && queryParams[`${filter.name}[to]`],
        };
        return {
          ...filter,
          initialVisible,
          initialValue,
        };
      }
      if (filter.type === 'TextFilter' || filter.type === 'textFilter') {
        const initialVisible = Boolean(queryParams && queryParams[filter.name]);
        const initialValue = queryParams && queryParams[filter.name];
        return {
          ...filter,
          initialVisible,
          initialValue,
        };
      }
      if (filter.type === 'MultiSelectFilter') {
        const initialVisible = Boolean(queryParams && queryParams[filter.name]);
        const initialValue = queryParams && queryParams[filter.name];
        return {
          ...filter,
          initialVisible,
          initialValue,
        };
      }
      return filter;
    });
  };

  const filters = useMemo((): Array<any> => {
    let filterArray = [];
    let moreFilters = [];

    if (assessment.purpose !== 'ld') {
      const hiringStatusesFilter = {
        label: 'Hiring Status',
        name: 'hiringStatus',
        type: 'MultiSelectFilter',
        category: 'more',
        multiple: true,
        options: hiringStatuses,
        optionKey: 'id',
        optionLabel: 'status',
        defaultValues: [],
        canHide: false,
        defaultToNull: true,
        visible: false,
        position: 1,
      };
      moreFilters.push(hiringStatusesFilter);
    }

    moreFilters.push({
      label: 'Submitted on',
      name: 'submitted_on',
      type: 'DateFilter',
      category: 'more',
      canHide: true,
      visible: false,
      position: 2,
    });

    moreFilters = moreFilters.concat(
      informationFields.map(field => {
        const filterName = `information_fields[${field.id}]`;
        return {
          ...field,
          label: field.label,
          name: filterName,
          type: getFilterTypeByField(field),
          category: 'more',
          multiple: field.type === 'DROPDOWN',
          options: field.options || field.predefined_options,
          canHide: true,
        };
      }),
    );

    moreFilters.push({
      label: 'Total Score',
      name: 'overallScore',
      type: 'ScoreFilter',
      category: 'more',
      canHide: true,
      visible: false,
      isPercent: true,
    });

    moreFilters = moreFilters.concat(
      subjects.map(subject => {
        const filterName = `subjects[subject_score_${subject.id}]`;
        return {
          label: `${subject.subject} Score`,
          name: filterName,
          type: 'ScoreFilter',
          category: 'more',
          canHide: true,
          visible: false,
          isPercent: true,
        };
      }),
    );

    const tempMainFilters = [...mainFilters];
    tempMainFilters.sort((filter1, filter2) => filter1.position - filter2.position);
    filterArray = [...tempMainFilters, ...moreFilters];
    filterArray = getFilterInitialValues(filterArray, queryParams);
    return filterArray;
  }, [subjects, informationFields, assessment.purpose, queryParams, hiringStatuses]);

  const onFilterChange = useCallback((data: any): void => {
    getDataCallback.callback({
      ...data,
      page: 1,
    });
  }, []);
  const onExport = useCallback(async (): Promise<any> => {
    const skills = subjects
      ?.filter(val => {
        return !excludeQueryFields.includes(val.subject);
      })
      .map(val => val.subject);

    let state = {
      ...queryParams,
      skills,
    };
    state = addDisplayFields(state, excludeQueryFields);
    state = {
      ...state,
      assessmentId: assessment.id,
      includedFields: getFields()
        .filter(field => !field.hidden)
        .map(field => field.label),
      perPage: null,
    };
    const a = document.createElement('a');
    a.download = `${assessment.recruiter_test_name} Candidate Report ${new Date().toISOString().slice(0, 10)}.xlsx`;
    return exportAssessmentCandidates(state)
      .then(res => {
        if (res.data.type === 'application/json') {
          res.data.text().then(text => {
            const jsonData = JSON.parse(text);
            addToast({
              type: 'success',
              msg: jsonData.message,
            });
          });
          return;
        }
        const url = window.URL.createObjectURL(
          new Blob([res?.data], {
            type: res?.headers?.['content-type'],
          }),
        );
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        addToast({
          type: 'error',
          msg: 'Sorry, something went wrong. Please try again',
        });
      });
  }, [subjects, queryParams, excludeQueryFields, assessment.id, assessment.recruiter_test_name, getFields, addToast]);

  const viewCandidateResults = (row): void => {
    selectCandidateRow(row);
  };

  const retry = (): void => {
    getDataCallback.callback({});
  };

  useEffect(() => {
    if (!loaded) {
      return;
    }
    const toggleColumns = async (changedColumns: any): Promise<any> => {
      const newTableFields = tableFields.map(field => {
        if (changedColumns.includes(field.label)) {
          return {
            ...field,
            hidden: !field.hidden,
          };
        }
        return field;
      });
      dispatch(setResultsTableFields(newTableFields));
      setExcludeQueryFields(newTableFields.filter(field => field.hidden).map(field => field.label));
    };

    const sortingProps = {
      sortingAction,
      orderBy: queryParams?.orderBy ?? defaultQueryParams.orderBy,
      orderDirection: queryParams?.orderDirection ?? defaultQueryParams.orderDirection,
    };
    setCompletedTableProps({
      loading: false,
      data: paginator.data,
      fields: getFields(),
      toggleColumns,
      tablePaginatorProps,
      tableSearchProps: {
        filterSearch,
        searchTerm: queryParams?.searchTerm ?? defaultQueryParams.searchTerm,
      },
      sortingProps,
      actionMenu,
      getPath,
      processCell,
      filters,
      onFilterChange,
      configurableColumns: true,
      openInNewTab: !process.env.REACT_APP_NEW_CANDIDATE_RESULTS,
      onClickRow: viewCandidateResults,
      shouldCloak: assessment.cloak_candidates,
      onExport,
    });
    setLoaded(false);
  }, [
    dispatch,
    loaded,
    currentPage,
    paginator,
    search,
    queryParams,
    blank,
    userDetails.user_timezone,
    userDetails,
    assessment,
    filterSearch,
    nextPage,
    changePerPage,
    sortingAction,
    isLast,
    isTableLoading,
    getPath,
    actionMenu,
    getFields,
    processCell,
    tablePaginatorProps,
    filters,
    onFilterChange,
    excludeQueryFields,
    tableFields,
    defaultQueryParams.orderBy,
    defaultQueryParams.orderDirection,
    defaultQueryParams.searchTerm,
    onExport,
  ]);

  return (
    <>
      <TopResults
        topResults={topResultsFilteredByScore}
        subjects={subjects}
        userDetails={userDetails}
        organization={organization}
        assessment={assessment}
      />
      <p className="section-title completed-participants">
        Completed {assessment.purpose === 'ld' ? 'Participants' : 'Candidates'}
      </p>

      <RefreshContext.Provider value={[firstLoad, setFirstLoad]}>
        <LoaderContext.Provider value={[isTableLoading, setTableLoading]}>
          {isError && <TableBlank {...{ loading }} message="Unable to fetch participants." onRetry={retry} />}

          {!isError &&
            (blank && isTableLoading ? (
              <CompletedBlank {...{ loading: isTableLoading }} />
            ) : (
              <DynamicTablePropsContext.Provider value={[completedTableProps, setCompletedTableProps]}>
                <CompletedTable {...{ loading }} />
              </DynamicTablePropsContext.Provider>
            ))}
        </LoaderContext.Provider>
      </RefreshContext.Provider>
    </>
  );
};

export default CandidateResults;
