import { Features } from 'Authorization';
import React from 'react';
import { Helmet } from 'react-helmet';

const ACCESS_ERRORS = {
  assess: {
    title: 'You do not currently have access to Alooba Assess.',
    text:
      'Please reach out to your account manager to upgrade your account to gain access to Alooba Assess to start ' +
      "identifying hidden gems by objectively assessing your candidates' relevant skills.",
  },
  junior: {
    title: 'You do not currently have access to Alooba Junior.',
    text:
      'Please reach out to your account manager to upgrade your account to gain access to Alooba Junior to start ' +
      'future-proofing your workforce with graduate and intern data literacy assessments.',
  },
  growth: {
    title: 'You do not currently have access to Alooba Growth.',
    text:
      'Please reach out to your account manager to upgrade your account to gain access to Alooba Growth to start ' +
      'identifying your team’s data literacy gaps with objective and impartial assessments.',
  },
  interview: {
    title: 'You do not currently have access to Alooba Interview.',
    text:
      'Alooba Interview is currently in Beta. Please reach out to your account manager if you would like early ' +
      'access to the Alooba Interview product.',
  },
  permission: {
    title: 'You do not currently have access to this page.',
    text: 'If you believe this is a mistake, please reach out to your account manager to change your account access.',
  },
};
const NoAccess: React.FC<{
  feature?: Features;
}> = ({ feature }) => {
  return (
    <div className="no-access-container">
      <Helmet>
        <title>Access Denied to this page | alooba.com</title>
      </Helmet>
      {feature ? (
        <div>
          <h2 className="no-access-header">{ACCESS_ERRORS[feature].title}</h2>
          <p className="no-access-text">{ACCESS_ERRORS[feature].text}</p>
        </div>
      ) : (
        <div>
          <h2 className="no-access-header">You do not currently have access to this product</h2>
          <p className="no-access-text">
            Please reach out to your account manager to upgrade your account to gain access to this product.
          </p>
        </div>
      )}
    </div>
  );
};
export default NoAccess;
